import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { currentStepSelector } from 'shared/selectors';
import SellerSignup from 'signup/components/SellerSignup';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import { scrollToTop } from 'shared/utils/scroll';
import useEnsureFullSeller from 'namespaces/shared/hooks/useEnsureFullSeller';
import { firstStep } from 'models/SignupOrder';

const SellerSignupContainer = () => {
  const { status } = useEnsureFullSeller();
  const currentStep = useSelector(currentStepSelector);

  useEffect(scrollToTop, [currentStep]);

  return status === 'loaded' ? (
    <SellerSignup currentStep={currentStep || firstStep} />
  ) : (
    <LoadingAnimation />
  );
};

export default SellerSignupContainer;
