export const isLoaded = () => typeof analytics !== 'undefined';

export const page = (page: string, properties?: Record<string, unknown>) => {
  analytics.page(page, { ...properties });
};

export const identify = (id: string, properties?: Record<string, unknown>) => {
  analytics.identify(id, properties);
};

export const event = (
  eventName: string,
  properties?: Record<string, unknown>,
  callback?: () => void
) => {
  analytics.track(eventName, properties, callback);
};
