import React from 'react';
import { Modal } from 'shared/bootstrap';
import Psd2Banks from 'shared/components/Psd2Banks';
import * as I18n from 'shared/utils/I18n';

const t = (key) => I18n.nt('app.profile.connectModal', key);

export interface Props {
  onClose: () => void;
  redirectPath: string;
}

const ConnectModal = ({ onClose, redirectPath }: Props) => (
  <Modal onHide={onClose} show>
    <Modal.Header closeButton>
      <Modal.Title>
        {t('add_account')}
        <span className="icon icon-lock tw-ml-2 tw-align-top tw-text-floryn-green" />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="ConnectWithBank">
        <div className="space-y-24">
          <label className="control-label">
            <span>{t('connection_title')}</span>
          </label>
          <p className="ConnectionInstructions">{t('process_info')}</p>
          <Psd2Banks allowedPdfUpload={false} redirectPath={redirectPath} />
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer />
  </Modal>
);

export default ConnectModal;
