import { combineReducers } from 'redux';
import sharedReducers from 'shared/reducers';
import session from './modules/session';

import loadEntities from 'shared/redux/loadEntities';
import { checkBundleVersionReducer } from 'shared/redux/middleware/checkBundleVersion';

const entities = [
  'accounts',
  'attachments',
  'bankEntries',
  'campaignCompanies',
  'candidateCompanies',
  'companies',
  'contacts',
  'contracts',
  'customerAccounts',
  'documents',
  'imports',
  'invoiceContacts',
  'invoices',
  'mutations',
  'repayments',
  'sellers',
  'signupDebtors',
  'users',
];

const entityReducers = entities.reduce(
  (reducers, entityName) => ({
    ...reducers,
    [entityName]: (state = {}, action = {}) =>
      loadEntities(entityName, state, action),
  }),
  {}
);

export default {
  shared: combineReducers(sharedReducers),
  session,
  entities: combineReducers(entityReducers),
  version: checkBundleVersionReducer,
};
