import React from 'react';
import classNames from 'classnames';
import { translate } from 'shared/utils/I18n';
import StreamlineIcon from './StreamlineIcon';

const t = translate('deliveries_page.transfer_item');

const TransferItem = (props) => {
  let icon;

  switch (props.status.type) {
    case 'success':
      icon = (
        <StreamlineIcon
          icon="Common File Text Check"
          className="tw-h-5 tw-w-5 tw-text-floryn-green"
          strokeWidth={1.5}
        />
      );
      break;
    case 'not_asked':
      icon = (
        <StreamlineIcon
          icon="Common File Text Clock"
          className="tw-h-5 tw-w-5 tw-text-gray-600"
          strokeWidth={1.5}
        />
      );
      break;
    case 'pending':
      icon = (
        <StreamlineIcon
          icon="Common File Text Upload"
          className="tw-h-5 tw-w-5 tw-text-yellow-600"
          strokeWidth={1.5}
        />
      );
      break;
    case 'failure':
      icon = (
        <StreamlineIcon
          icon="Common File Text Warning"
          className="tw-h-5 tw-w-5 tw-text-red-600"
          strokeWidth={1.5}
        />
      );
      break;
  }

  const textColorClasses = {
    'tw-text-gray-700': props.status.type === 'pending',
    'tw-text-red-700': props.status.type === 'failure',
    'tw-text-gray-400': props.status.type === 'not_asked',
    'tw-text-floryn-green': props.status.type === 'success',
  };

  return (
    <li className="tw-space-y-2 tw-px-3 tw-py-4" data-testid="transfer-item">
      <div className="tw-flex tw-items-center tw-gap-3">
        {icon}
        <div className="tw-flex-1" data-testid="transfer-item-name">
          <span>{props.file.name}</span>
        </div>
        <span
          className={classNames('tw-text-sm', textColorClasses)}
          data-testid="transfer-item-status"
        >
          {t(props.status.type)}
        </span>
      </div>
    </li>
  );
};

export default TransferItem;
