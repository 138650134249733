import React from 'react';
import { translate } from 'shared/utils/I18n';
import { dateFormat } from 'shared/utils/Helpers';
import { MissingPeriod } from 'types/Review';

const t = translate('review_wizard.iban_panel');

interface Props {
  missingPeriods: MissingPeriod[];
}

function missingPeriodDates(missingPeriod: MissingPeriod): {
  startDate: string;
  endDate: string;
} {
  return {
    startDate: dateFormat(new Date(missingPeriod.startDate)),
    endDate: dateFormat(new Date(missingPeriod.endDate)),
  };
}

const Warnings = ({ missingPeriods }: Props) => {
  if (missingPeriods.length === 1) {
    return (
      <div className="rw-warning">
        <b>{t('missing_periods_title')}</b>{' '}
        {t('missing_period', missingPeriodDates(missingPeriods[0]))}
      </div>
    );
  } else {
    return (
      <div className="rw-warning">
        <b>{t('missing_periods_title')}</b>
        <ul>
          {missingPeriods.map((m, i) => (
            <li key={i}>{t('missing_period', missingPeriodDates(m))}</li>
          ))}
        </ul>
      </div>
    );
  }
};
export default Warnings;
