import React from 'react';
import classNames from 'classnames';
import { nt } from 'shared/utils/I18n';
import useFrontpageTabs from './useFrontpageTabs';

const ns = 'charlie.frontpage_tabs';

const FrontpageTabs = () => {
  const { currentSeller, selectTab, tabs } = useFrontpageTabs();

  if (tabs.length < 2) {
    return null;
  }

  return (
    <div className="FrontpageTabs">
      <div className="seller-tabs-container">
        <nav className="seller-tabs-large" aria-label={nt(ns, 'title')}>
          {tabs.map((tab) => (
            <a
              href="#"
              className={classNames('seller-tab', {
                'seller-tab-active': tab.id === currentSeller.id,
              })}
              key={tab.id}
              onClick={(e) => {
                e.preventDefault();
                selectTab(tab.id);
              }}
            >
              {tab.title}
            </a>
          ))}
        </nav>
        <div className="seller-tabs-small">
          <select
            className="form-control"
            defaultValue={currentSeller.id}
            onChange={(e) => {
              selectTab(parseInt(e.target.value));
            }}
          >
            {tabs.map((tab) => (
              <option key={tab.id} value={tab.id}>
                {tab.title}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default FrontpageTabs;
