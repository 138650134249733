import React, { useEffect } from 'react';
import { useForm, FieldValues, useWatch } from 'react-hook-form';

import Overview from './Overview';
import AddStatements from './AddStatements';
import Layout from './Layout';
import DebtorListForm from './DebtorListForm';
import Modal from './Modal';
import { isAlfa } from 'models/Seller';
import { translate } from 'shared/utils/I18n';
import { DebtorList } from './modules';
import { WizardProps } from './loadWizard';
import { trackEvent } from 'shared/utils/tracker';
import {
  loadWithPotentialGapsForAllAttachments,
  toAdding,
} from 'shared/modules/reviewWizardSlice';
import useAppDispatch from 'shared/hooks/useAppDispatch';

const t = translate('review_wizard.wizard');

export default ({
  seller,
  reviewId,
  step,
  reviewSheet,
  onSubmit,
  onConnectBank,
  redirectPath,
  addStatementsStatus,
  onDeleteAttachment,
  onSubmitDebtorList,
  onUpdateAddStatementsStatus,
  toOverview,
  sellerComment,
  onSellerCommentChange,
  onOpenDebtorList,
  setOverrideWarnings,
  debtorList,
  submitStatus,
}: WizardProps) => {
  const {
    control,
    reset,
    formState: { touchedFields },
  } = useForm<WizardProps & FieldValues>();
  const dispatch = useAppDispatch();
  const bankStatementAttachments = useWatch({
    control,
    name: 'bankStatementAttachments',
  });

  const toAddingStatements = () => {
    dispatch(toAdding());
    reset();
  };

  useEffect(() => {
    if (touchedFields.bankStatementAttachments) {
      dispatch(
        loadWithPotentialGapsForAllAttachments(
          bankStatementAttachments.map((b) => b.id)
        )
      );
    }
  }, [bankStatementAttachments]);

  useEffect(() => {
    trackEvent('ReviewWizard.Step', { sellerId: seller.id, step, reviewId });
  }, [step, seller, reviewId]);

  return (
    <Layout isAlfa={isAlfa(seller)}>
      <Overview
        setOverrideWarnings={setOverrideWarnings}
        reviewSheet={reviewSheet}
        onSubmit={onSubmit}
        onAdd={toAddingStatements}
        sellerComment={sellerComment}
        onDeleteAttachment={onDeleteAttachment}
        onSellerCommentChange={onSellerCommentChange}
        onOpenDebtorList={onOpenDebtorList}
        debtorList={debtorList}
        submitStatus={submitStatus}
      />
      {DebtorList.isApplicable(debtorList) && (
        <DebtorListForm
          show={step === 'debtorList'}
          onHide={toOverview}
          reviewId={reviewId}
          sellerId={seller.id}
          onSubmit={onSubmitDebtorList}
          initialValues={DebtorList.toTask(debtorList)}
        />
      )}
      <Modal
        show={step === 'addStatements'}
        onHide={toOverview}
        title={t('add_transactions')}
      >
        <AddStatements
          control={control}
          status={addStatementsStatus}
          onConnectBank={onConnectBank}
          seller={seller}
          redirectPath={redirectPath}
          onUpdate={onUpdateAddStatementsStatus}
          reviewId={reviewId}
        />
      </Modal>
    </Layout>
  );
};
