import React from 'react';
import { nt } from 'shared/utils/I18n';

import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { Seller } from 'types';
import { toEuro, toPercentage } from 'shared/utils/Helpers';

const ns = 'signup.onboarding.withdrawal_fee_info';

const WithdrawalFeeInfo = () => {
  const seller = useCurrentSeller() as Seller;
  const creditDecision = seller.creditDecision;

  const creditLimit = creditDecision.creditLimit
    ? parseFloat(creditDecision.creditLimit)
    : 0.0;
  const withdrawalFeeRate = creditDecision.withdrawalFeeRate
    ? parseFloat(creditDecision.withdrawalFeeRate)
    : 0.0;

  if (creditLimit === 0 || withdrawalFeeRate === 0) {
    return null;
  }

  return (
    <div className="CostExplanation">
      <h4>{nt(ns, 'title')}</h4>
      <ul>
        <li>
          {nt(ns, 'explanation1_html', {
            limit: toEuro(creditLimit, 0),
          })}
        </li>
        <li>
          {nt(ns, 'explanation2_html', {
            percentage: toPercentage(withdrawalFeeRate),
          })}
        </li>
        <li>{nt(ns, 'explanation3')}</li>
      </ul>
    </div>
  );
};

export default WithdrawalFeeInfo;
