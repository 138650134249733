import React from 'react';
import classNames from 'classnames';

const Panel = (props) => {
  const testid = props['data-testid'] ?? 'panel';
  return (
    <div
      data-testid={testid}
      className={classNames(props.className, { 'rw-panel': true })}
    >
      {props.children}
    </div>
  );
};

export default Panel;
