import { useState } from 'react';
import { useForm } from 'react-hook-form';

import resolver from 'hookForm/resolver';
import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';

import useAppDispatch from 'shared/hooks/useAppDispatch';
import * as I18n from 'shared/utils/I18n';
import {
  validateGreaterThanOrEqualTo,
  validatePresence,
} from 'shared/utils/validation';
import { updateCustomerSignup } from 'signup/actions/CustomerSignup';
import { goToPreviousStep } from 'signup/actions/SellerSignup';
import { Billing, Priority, Purpose } from 'types/CustomerSignup';
import { customerSignupSelector } from 'shared/selectors';
import { useSelector } from 'react-redux';

interface FormData {
  billing: string;
  businessActivities: string;
  existingDebtAmount: string;
  explanation: string;
  priority: string;
  purpose: string;
  soleFinancer: string;
}

const validate = ({
  billing,
  existingDebtAmount,
  priority,
  purpose,
  soleFinancer,
}: FormData) => ({
  billing: validatePresence(billing),
  existingDebtAmount:
    validatePresence(existingDebtAmount) ||
    validateGreaterThanOrEqualTo(0)(existingDebtAmount),
  priority: validatePresence(priority),
  purpose: validatePresence(purpose),
  soleFinancer: validatePresence(soleFinancer),
});

const useAdditionalQuestionsStep = () => {
  const dispatch = useAppDispatch();
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);

  const customerSignup = useSelector(customerSignupSelector);
  const {
    billing,
    businessActivities,
    existingDebtAmount,
    explanation,
    priority,
    purpose,
    soleFinancer,
  } = customerSignup || {};

  const defaultValues = {
    billing,
    businessActivities,
    existingDebtAmount,
    explanation,
    priority,
    purpose,
    soleFinancer: `${soleFinancer || ''}`,
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<FormData>({ defaultValues, resolver: resolver(validate) });

  const submit = async (values: FormData) => {
    const response = await dispatch(updateCustomerSignup(values));

    if (response.error) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        response,
        setError
      );
      setServerErrorMessages(generalErrorMessages);
    }
  };

  const billingOptions: [string, string][] = Object.keys(Billing).map((key) => [
    Billing[key],
    I18n.attribute('customer_signup/billing', Billing[key]),
  ]);
  const priorityOptions: [string, string][] = Object.keys(Priority).map(
    (key) => [
      Priority[key],
      I18n.attribute('customer_signup/priority', Priority[key]),
    ]
  );
  const purposeOptions: [string, string][] = Object.keys(Purpose).map((key) => [
    Purpose[key],
    I18n.attribute('customer_signup/purpose', Purpose[key]),
  ]);

  return {
    billingOptions,
    control,
    goToPreviousStep: () => dispatch(goToPreviousStep()),
    isSubmitting,
    priorityOptions,
    purposeOptions,
    serverErrorMessages,
    submit: handleSubmit(submit),
  };
};

export default useAdditionalQuestionsStep;
