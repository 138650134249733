import React, { useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { generateHtmlElementId } from 'shared/utils/Helpers';
import classNames from 'classnames';

interface Props<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  label: string;
  name: Path<TFieldValues>;
  readOnly?: boolean;
}

const CheckboxInput = <TFieldValues extends FieldValues>({
  control,
  label,
  name,
  readOnly,
}: Props<TFieldValues>) => {
  const [id] = useState(generateHtmlElementId);

  const {
    field: { onChange, ref, value: fieldValue },
    fieldState: { error },
  } = useController<TFieldValues>({ control, name });

  const checked = fieldValue === true;

  return (
    <div className={error ? 'tw-text-red-dark' : ''}>
      <label
        className={classNames('tw-mb-0 tw-font-normal', checked && 'checked')}
        htmlFor={id}
      >
        <span className={'tw-mr-2'}>
          <input
            checked={checked}
            disabled={readOnly}
            id={id}
            name={name}
            onChange={onChange}
            type="checkbox"
            ref={ref}
          />
        </span>
        {label}
      </label>
    </div>
  );
};

export default CheckboxInput;
