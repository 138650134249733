import * as appRoutes from 'app/routes';
import { isCharlie, isDelta } from 'models/Seller';
import { CustomerAccount, MinimalSeller, Seller } from 'types';
import { getLoginPath } from 'shared/utils/routing/protectedRouteHelpers';

export const frontpageRoute = (
  customerAccount: CustomerAccount | undefined,
  seller: MinimalSeller | Seller | null | undefined,
  currentLocation: string | null
): string => {
  if (!seller) {
    if (currentLocation) {
      return getLoginPath(currentLocation);
    } else {
      return appRoutes.SIGNUP;
    }
  } else {
    switch (seller.status) {
      case 'draft':
        return signupRoute(seller);

      case 'submitted':
        return submittedSellerRoute(customerAccount);

      case 'approved':
      case 'cancelled':
        return approvedSellerRoute(seller);

      case 'inactive':
        return appRoutes.INACTIVE;

      case 'lost':
      case 'rejected':
        return appRoutes.REACTIVATION;
    }

    return appRoutes.LOGIN;
  }
};

const signupRoute = (seller: MinimalSeller | Seller): string => {
  if (
    !seller.expectedFinancingAmount ||
    (seller.entityKind === 'Seller' && !seller.expectedRevenue)
  ) {
    return appRoutes.SELLER_SIGNUP_ACCOUNT_STEP;
  } else {
    return appRoutes.SELLER_SIGNUP_BANK_STATEMENTS_STEP;
  }
};

const submittedSellerRoute = (
  customerAccount: CustomerAccount | undefined
): string => {
  if (customerAccount && !customerAccount.hasCompletedAdditionalQuestions) {
    return appRoutes.SELLER_SIGNUP_ADDITIONAL_QUESTIONS_STEP;
  }

  if (customerAccount && !customerAccount.hasCompletedUboIdentification) {
    return appRoutes.SELLER_SIGNUP_UBO_IDENTIFICATION_STEP;
  }

  return appRoutes.SELLER_ONBOARDING;
};

const approvedSellerRoute = (seller: MinimalSeller | Seller): string => {
  if (isCharlie(seller) || isDelta(seller)) {
    return appRoutes.CUSTOMER_APP_ROOT;
  } else {
    return appRoutes.INVOICES;
  }
};
