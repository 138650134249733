import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userSelector } from 'shared/selectors';
import ChangePasswordRequest from 'app/components/seller/profile/ChangePasswordRequest';
import { requestReset } from 'app/modules/profilePage';

const mapStateToProps = (state) => {
  const user = userSelector(state);
  const { passwordChangeRequested, requestPasswordLoading } =
    state.app.profilePage;

  return {
    email: user.email,
    loading: requestPasswordLoading,
    requested: passwordChangeRequested,
  };
};

class ChangePasswordRequestContainer extends Component {
  requestChangePassword = () => {
    const { email, requestReset } = this.props;

    requestReset(email, 'profile');
  };
  render() {
    const { email, loading, requested } = this.props;

    return (
      <ChangePasswordRequest
        email={email}
        loading={loading}
        requested={requested}
        requestChangePassword={this.requestChangePassword}
      />
    );
  }
}

ChangePasswordRequestContainer.propTypes = {
  email: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  requested: PropTypes.bool.isRequired,
  requestReset: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { requestReset })(
  ChangePasswordRequestContainer
);
