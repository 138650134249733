import React from 'react';
import { Seller } from 'types';
import { nt } from 'Onboarding/components/utils';
import { useCurrentSeller } from 'namespaces/shared/hooks';
import {
  dateFormat,
  toEuro,
  parseFloatOrUndefined,
} from 'namespaces/shared/utils/Helpers';

const t = nt('signup.onboarding.proposal_summary.securities');

const SecuritiesSummary = () => {
  const seller = useCurrentSeller() as Seller;
  const creditDecision = seller.creditDecision;
  const guarantorSum = parseFloatOrUndefined(creditDecision.guarantorSum);

  return (
    <>
      {!(
        creditDecision.debtorPledgeNotApplicable &&
        creditDecision.stockPledgeNotApplicable &&
        !guarantorSum &&
        !creditDecision.restriction
      ) && (
        <div className="tw-mt-3">
          <p>{t('title')}</p>
          {(creditDecision.debtorPledgeFirst ||
            creditDecision.debtorPledgeSecond) && (
            <div className="tw-mt-2">
              <li className="tw-mb-2">
                <span>
                  {creditDecision.debtorPledgeFirst
                    ? t('debtor_pledge', { type: t('first_pledge') })
                    : t('debtor_pledge', { type: t('second_pledge') })}
                </span>
              </li>
            </div>
          )}
          {(creditDecision.stockPledgeFirst ||
            creditDecision.stockPledgeSecond) && (
            <div className="tw-mt-2">
              <li className="tw-mb-2">
                <span>
                  {creditDecision.stockPledgeFirst
                    ? t('stock_pledge', { type: t('first_pledge') })
                    : t('stock_pledge', { type: t('second_pledge') })}
                </span>
              </li>
            </div>
          )}
          {guarantorSum && (
            <div className="tw-mt-2">
              <li className="tw-mb-2">
                <span>
                  {t('guarantors', {
                    amount: toEuro(creditDecision.guarantorSum),
                  })}
                </span>
              </li>
            </div>
          )}
          {creditDecision.restriction && (
            <div className="tw-mt-2">
              <li className="tw-mb-2">
                {creditDecision.restrictionType === 'scheduled' ? (
                  <span>
                    {t('scheduled_restriction', {
                      credit_limit: toEuro(creditDecision.creditLimit),
                      step_amount: toEuro(creditDecision.restrictionStepAmount),
                      target_amount: toEuro(creditDecision.restrictionTarget),
                      target_date: dateFormat(
                        creditDecision.restrictionTargetEndDate
                      ),
                      frequency: t(
                        `frequency_${creditDecision.restrictionFrequency}`
                      ),
                    })}
                  </span>
                ) : (
                  <span>
                    {t('floating_restriction', {
                      credit_limit: toEuro(creditDecision.creditLimit),
                      target_amount: toEuro(creditDecision.restrictionTarget),
                    })}
                  </span>
                )}
              </li>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SecuritiesSummary;
