import React from 'react';
import { Col } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { dateFormat } from 'shared/utils/Helpers';
import { Invoice } from 'types';

const ns = 'app.invoiceTitle';

interface Props {
  invoice?: Invoice;
  readonly: boolean;
}

const InvoiceTitle = ({ invoice, readonly }: Props) => {
  if (!invoice) {
    return null;
  }

  const title =
    invoice.state !== 'draft' || readonly
      ? I18n.nt(ns, 'invoice', { reference: invoice.reference })
      : I18n.nt(ns, 'confirm_financing');

  return (
    <Col xs={12} className="InvoiceTitle">
      <h2>{title}</h2>
      {invoice.importedAt && (
        <span className="imported-at">
          {I18n.nt(ns, 'importedAt', { date: dateFormat(invoice.importedAt) })}
        </span>
      )}
    </Col>
  );
};

export default InvoiceTitle;
