import React from 'react';
import { Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import useFlorynPayTermsAndConditions from './useFlorynPayTermsAndConditions';
import Button from 'shared/components/tailwind/Button';
import ServerError from 'shared/components/ServerError';

const ns = 'charlie.frontpage.floryn_pay_terms_and_conditions';

interface Props {
  parentPath: string;
}

const FlorynPayTermsAndConditions = ({ parentPath }: Props) => {
  const { close, loadPartnershipConditions, loadTermsAndConditions, status } =
    useFlorynPayTermsAndConditions(parentPath);

  const loading = status === 'loading';

  return (
    <Modal show onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {status === 'error' && <ServerError />}
        <div className="tw-flex tw-flex-col">
          <div>{I18n.nt(ns, 'explanation_html')}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          className="btn-primary"
          disabled={loading}
          onClick={loadPartnershipConditions}
        >
          {I18n.nt(ns, 'partnership_conditions')}
        </Button>
        <Button
          className="btn-primary"
          disabled={loading}
          onClick={loadTermsAndConditions}
        >
          {I18n.nt(ns, 'terms_and_conditions')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FlorynPayTermsAndConditions;
