import React from 'react';

import * as I18n from 'shared/utils/I18n';
import ContentWithSidebar from 'shared/components/tailwind/ContentWithSidebar';
import DefaultSidebar from 'shared/components/DefaultSidebar';

const i18nNs = 'app.profile.inactive';

const InactiveSeller = () => (
  <ContentWithSidebar sidebarChildren={<DefaultSidebar />}>
    <h3>{I18n.nt(i18nNs, 'title')}</h3>
    <p>{I18n.nt(i18nNs, 'message')}</p>
  </ContentWithSidebar>
);

export default InactiveSeller;
