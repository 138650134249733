import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import client from 'shared/utils/client';

type Status = '' | 'loading' | 'error' | 'loaded';

const useFlorynPayTermsAndConditions = (parentPath: string) => {
  const history = useHistory();
  const [status, setStatus] = useState<Status>('');

  const close = () => history.push(parentPath);

  const retrieveConditionUrls = async () => {
    setStatus('loading');

    const response = await client(
      'GET',
      `/api/floryn_pay_signups/terms_and_conditions`,
      {},
      { raiseError: false }
    );

    if (response.error) {
      setStatus('error');
      return;
    }

    setStatus('loaded');
    return response.payload;
  };

  const loadTermsAndConditions = async () => {
    const documentUrls = await retrieveConditionUrls();

    if (documentUrls?.termsAndConditions) {
      window.open(documentUrls.termsAndConditions, '_blank');
    }
  };

  const loadPartnershipConditions = async () => {
    const documentUrls = await retrieveConditionUrls();

    if (documentUrls?.partnershipConditions) {
      window.open(documentUrls.partnershipConditions, '_blank');
    }
  };

  return {
    close,
    loadPartnershipConditions,
    loadTermsAndConditions,
    status,
  };
};

export default useFlorynPayTermsAndConditions;
