import React from 'react';
import * as I18n from 'shared/utils/I18n';
import * as Helpers from 'shared/utils/Helpers';

const ns = 'app.invoiceStatus';

interface Props {
  isExact: boolean;
  lastActionAt: string;
}

const Draft = ({ isExact, lastActionAt }: Props) => {
  const heading = isExact ? 'draft.headingExact' : 'draft.heading';

  return (
    <div>
      <div className="heading">{I18n.nt(ns, heading)}</div>
      {I18n.nt(ns, 'draft.bodyHtml')}
      {lastActionAt && (
        <p className="lastActionAt">
          {I18n.nt(ns, 'lastChange', {
            date: Helpers.dateFormat(lastActionAt),
          })}
        </p>
      )}
    </div>
  );
};

export default Draft;
