import { useEffect, useState } from 'react';
import { useCurrentSeller } from 'namespaces/shared/hooks';
import client from 'shared/utils/client';
import { FlorynInvoice } from 'types';

type Status = '' | 'loading' | 'loaded' | 'error';

const compareFlorynInvoices = (a: FlorynInvoice, b: FlorynInvoice) =>
  a.invoiceDate > b.invoiceDate ||
  (a.invoiceDate === b.invoiceDate && a.sellerId < b.sellerId)
    ? 1
    : -1;

const useFlorynInvoices = () => {
  const [florynInvoices, setFlorynInvoices] = useState<FlorynInvoice[]>([]);
  const [status, setStatus] = useState<Status>('');
  const seller = useCurrentSeller();

  const loadFlorynInvoices = async () => {
    setStatus('loading');

    const response = await client(
      'GET',
      `/api/sellers/${seller.id}/floryn-invoices`,
      {},
      { raiseError: false }
    );

    if (response.error) {
      setStatus('error');
      return false;
    }

    setFlorynInvoices(
      (response.payload as FlorynInvoice[])
        .sort(compareFlorynInvoices)
        .reverse()
    );

    setStatus('loaded');
    return true;
  };

  useEffect(() => {
    loadFlorynInvoices();
  }, []);

  return { florynInvoices, status };
};

export default useFlorynInvoices;
