import React from 'react';
import { CandidateCompany, Company } from 'types';
import {
  hasAddress,
  formatHouseNumber,
} from 'shared/components/CompanyInfo/utils';

const getCompanyDescription = (company: CandidateCompany | Company) => {
  if (hasAddress(company)) {
    const { name, street, city } = company;

    return `${name} (${street} ${formatHouseNumber(company)}, ${city})`;
  } else {
    return company.name;
  }
};

interface Props {
  company: CandidateCompany | Company;
  onSelect: (company: CandidateCompany | Company) => void;
}

const CompanyListItem = ({ company, onSelect }: Props) => {
  const handleClick = () => onSelect(company);

  return (
    <li className="Company list-group-item" onClick={handleClick}>
      {getCompanyDescription(company)}
    </li>
  );
};

export default CompanyListItem;
