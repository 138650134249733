import React from 'react';

import { Alert, Button } from 'shared/bootstrap';
import { nt } from 'shared/utils/I18n';
import QuotedText from './QuotedText';
import { AnswerQuestion } from './useQuestions';
import Question from './question';
import { formatQuestionInfo, ns } from './utils';
import useOpenQuestion from './useOpenQuestion';
import TextArea from 'hookForm/TextArea';
import AttachmentsInput from 'hookForm/AttachmentsInput';
import ServerError from 'shared/components/ServerError';

interface Props {
  answerQuestion: AnswerQuestion;
  question: Question;
}

const OpenQuestion = ({ answerQuestion, question }: Props) => {
  const { control, handleSubmit, isSubmitting, status } = useOpenQuestion({
    answerQuestion,
    question,
  });

  return (
    <form className="Question">
      <QuotedText
        info={formatQuestionInfo(question)}
        text={question.question}
      />
      <TextArea
        control={control}
        hideLabel={true}
        includeLayoutClass={false}
        label={nt(ns, 'answer_field')}
        name="answer"
        placeholder={nt(ns, 'type_your_answer_here')}
        rows={4}
      />
      <AttachmentsInput
        control={control}
        hideLabel={true}
        includeLayoutClass={false}
        label={nt(ns, 'answer_files')}
        model="Question"
        modelId={question.id}
        name="answerFiles"
      />
      {status === 'no_answer' && (
        <Alert type="danger">{nt(ns, 'no_answer')}</Alert>
      )}
      {status === 'error' && <ServerError />}
      <div>
        <Button
          className="btn-primary"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {nt(ns, 'answer_button')}
        </Button>
      </div>
    </form>
  );
};

export default OpenQuestion;
