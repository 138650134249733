import React from 'react';
import { connect } from 'react-redux';

import ContentWithSidebar from 'shared/components/tailwind/ContentWithSidebar';
import { currentSellerSelector } from 'shared/selectors';
import OnboardingLink from './OnboardingLink/Index';
import { FinancialDashboardStatus, MinimalSeller, ReduxState } from 'types';
import YourFinance from 'namespaces/Onboarding/components/YourFinance';
import Support from 'namespaces/shared/components/DefaultSidebar/Support';
import FinancialDashboard from './Dashboard/FinancialDashboard';
import Page from './Page';

export const ns = 'signup.onboarding.financial_dashboard';

interface Props {
  financialDashboardStatus: FinancialDashboardStatus;
  seller: MinimalSeller;
}

const FinancialDashboardContainer = ({
  financialDashboardStatus,
  seller,
}: Props) => (
  <ContentWithSidebar
    sidebarChildren={
      <div className="FinancialDashboardSidebar">
        <YourFinance />
        {!['cancelled', 'processing', 'failed', 'timeout'].includes(
          financialDashboardStatus
        ) && <OnboardingLink />}
        <div className="SidebarPanelWithPadding">
          <Support />
        </div>
      </div>
    }
  >
    <FinancialDashboard status={financialDashboardStatus} seller={seller} />
  </ContentWithSidebar>
);

const mapStateToProps = (state: ReduxState) => ({
  financialDashboardStatus: state.financialDashboard.status,
  seller: currentSellerSelector(state),
});

const ForSignup = (data) => (
  <Page>
    <FinancialDashboardContainer {...data} />
  </Page>
);

export default connect(mapStateToProps)(ForSignup);
