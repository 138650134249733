import React, { ReactNode } from 'react';
import DefaultSidebar from 'shared/components/DefaultSidebar';
import Container from 'shared/components/tailwind/Container';
import ContentWithSidebar from 'shared/components/tailwind/ContentWithSidebar';
import SignupProgress from 'signup/shared/SignupProgress';
import RequestSummary from 'namespaces/signup/components/seller/RequestSummary';

interface Props {
  children: ReactNode;
  includeRequestSummary?: boolean;
}

const SignupContainer = ({ children, includeRequestSummary }: Props) => (
  <main className="Signup">
    <Container>
      <SignupProgress />
    </Container>
    <ContentWithSidebar
      aboveSidebarChildren={
        includeRequestSummary ? <RequestSummary /> : undefined
      }
      sidebarChildren={<DefaultSidebar />}
    >
      {includeRequestSummary && (
        <div className="md:tw-hidden">
          <RequestSummary />
        </div>
      )}
      {children}
    </ContentWithSidebar>
  </main>
);

export default SignupContainer;
