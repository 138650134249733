import { currentSellerSelector } from 'shared/selectors';

export const isAisConnected = (state) => {
  const seller = currentSellerSelector(state);
  return seller && seller.aisConnected === true;
};

export const bankStatementUploadType = (state) => {
  return state.app.profile.bankStatementUploadType;
};
