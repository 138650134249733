import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { updateSeller } from 'shared/actions/seller';
import { mainSellerSelector } from 'shared/selectors';
import { Seller } from 'types';
import { trackFormSubmit } from 'shared/utils/tracker';

import useAppDispatch from 'shared/hooks/useAppDispatch';

const useFinanceAllInvoices = () => {
  const dispatch = useAppDispatch();

  const mainSeller = useSelector(mainSellerSelector) as Seller;

  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm();

  const enable = async () => {
    await dispatch(updateSeller({ financeAllInvoices: new Date() }));
    trackFormSubmit('enable-finance-all-invoices');
  };

  const disable = async () => {
    await dispatch(updateSeller({ financeAllInvoices: null }));
    trackFormSubmit('disable-finance-all-invoices');
  };

  return {
    financeAllInvoices: mainSeller.financeAllInvoices,
    isSubmitSuccessful,
    isSubmitting,
    submitDisable: handleSubmit(disable),
    submitEnable: handleSubmit(enable),
  };
};

export default useFinanceAllInvoices;
