import React from 'react';
import { Route, Redirect } from 'react-router-dom';

interface Props {
  component: React.ComponentType;
  exact?: boolean;
  path: string | string[];
  redirectTo?: string;
  redirectWhen: boolean;
}

const ConditionalRedirectRoute = ({
  component,
  exact,
  path,
  redirectTo,
  redirectWhen,
}: Props) => {
  const renderRoute = () => {
    if (redirectWhen && redirectTo) {
      return <Redirect to={redirectTo} />;
    } else {
      return React.createElement(component);
    }
  };

  return <Route exact={exact} path={path} render={renderRoute} />;
};

export default ConditionalRedirectRoute;
