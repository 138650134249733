import { useDispatch } from 'react-redux';
import { useCurrentSeller } from 'shared/hooks';
import {
  goToNextStep,
  goToPreviousStep,
  setBankStatementUploadType,
} from 'signup/actions/SellerSignup';
import { isSubmitted } from 'models/Seller';
import { Seller } from 'types';

const useConnectWithBank = () => {
  const dispatch = useDispatch();
  const seller = useCurrentSeller() as Seller;

  const isSubmittedSeller = isSubmitted(seller);

  return {
    goToNextStep: () => dispatch(goToNextStep()),
    goToPreviousStep: () => dispatch(goToPreviousStep()),
    isSubmittedSeller,
    onSelectPdf: () => dispatch(setBankStatementUploadType('documents')),
  };
};

export default useConnectWithBank;
