import React, { ReactNode } from 'react';
import classNames from 'classnames';
import HintPopover from './HintPopover';

/*
includeLayoutClass (booleam, default true): include the form-group class, which adds a margin.
*/

interface Props {
  action?: {
    fn?: () => void;
    hint?: string;
    text?: string;
  };
  children: ReactNode;
  className?: string;
  includeLayoutClass?: boolean;
  hideLabel?: boolean;
  hint?: string;
  id?: string;
  invalid?: boolean;
  label: string;
  name?: string;
  required?: boolean;
  type?: string;
}

const InputWrapper = ({
  action,
  children,
  className,
  hideLabel,
  hint,
  id,
  includeLayoutClass = true,
  invalid,
  label,
  name,
  required,
  type,
}: Props) => {
  const classes = classNames(
    className,
    'FormField',
    includeLayoutClass && 'form-group',
    type && `type-${type}`,
    name && `field-${name}`,
    { invalid, required }
  );

  return (
    <div className={classes}>
      <label
        className={classNames('control-label', { 'sr-only': hideLabel })}
        htmlFor={id}
      >
        {label}
      </label>
      {hint && <HintPopover hint={hint} />}
      {action && (
        <div className="tw-float-right">
          <a
            className="tw-cursor-pointer tw-text-gray-500 tw-underline"
            onClick={action.fn}
          >
            {action.text}
          </a>
          {action.hint && (
            <HintPopover hint={action.hint} iconClassName="tw-text-gray-500" />
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default InputWrapper;
