import React from 'react';
import { Modal, Button } from 'shared/bootstrap';
import TextArea from 'hookForm/TextArea';
import AttachmentsInput from 'hookForm/AttachmentsInput';
import useAddressForm from './useAddressForm';
import { useCurrentSeller } from 'namespaces/shared/hooks';

interface Props {
  t: (key: string, options?) => string;
  onHide: () => void;
}

const Dialog = ({ onHide, t }: Props) => {
  const seller = useCurrentSeller();
  const { control, handleSubmit, isSubmittable } = useAddressForm(onHide);

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>{t('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextArea
          control={control}
          name="propertyAddress"
          label={t('address')}
        />
        <AttachmentsInput
          control={control}
          model="seller"
          modelId={seller.id}
          name="valuationReports"
          label={t('valuation_report')}
          required={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn-primary" disabled={!isSubmittable}>
          {t('close')}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default Dialog;
