import React from 'react';
import { format, isValid, parseISO } from 'date-fns';
// import order matters here, 'date-fns' must be imported first, otherwise importing
// 'react-datepicker' will result in typescript compiler errors
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

type OnChange<T> = (date: T, event: React.SyntheticEvent<any, Event>) => void;

const makeOnChangeForDatePicker: (
  ourOnChange: OnChange<string>
) => OnChange<Date> = (ourOnChange) => (date, event) => {
  const stringAsDate = dateToString(date);
  return ourOnChange(stringAsDate, event);
};

type DateToString = (d: Date) => string;
type ToDate = (d: undefined | string | Date) => Date | null;

export const dateToString: DateToString = (date) => {
  try {
    return format(date, 'yyyy-MM-dd');
  } catch (e) {
    if (e instanceof RangeError) {
      return '';
    }
    throw e;
  }
};

const stringToDate: ToDate = (input) => {
  if (!input) {
    return null;
  } else if (typeof input === 'string') {
    const date = parseISO(input);

    if (isValid(date)) {
      return date;
    } else {
      return null;
    }
  } else {
    return input;
  }
};

interface OverrideWithString {
  selected?: string;
  onChange: OnChange<string>;
  minDate?: string;
  maxDate?: string;
  startDate?: string;
  endDate?: string;
}

type Props = Omit<ReactDatePickerProps, keyof OverrideWithString> &
  OverrideWithString;

// DateStringPicker is a wrapper for the DatePicker component from react-datepicker
// Where DatePicker expects and returns Date objects, DateStringPicker uses strings in ISO8601 format
// representing dates
export const DateStringPicker = (props: Props) => {
  const { endDate, maxDate, minDate, onChange, selected, startDate, ...rest } =
    props;

  const onChangeForDatePicker = makeOnChangeForDatePicker(onChange);

  return (
    <DatePicker
      {...rest}
      endDate={stringToDate(endDate)}
      maxDate={stringToDate(maxDate)}
      minDate={stringToDate(minDate)}
      onChange={onChangeForDatePicker}
      selected={stringToDate(selected)}
      startDate={stringToDate(startDate)}
    />
  );
};
