import React from 'react';
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from 'shared/components/Tooltip';
import { nt } from 'shared/utils/I18n';
import InformationCircleSvg from 'svg/information_circle.svg';

const ns = 'charlie.frontpage.more_info';

interface Props {
  onDisplayTooltip?: () => void;
  tooltip: React.ReactNode;
}

const MoreInfo = ({ onDisplayTooltip, tooltip }: Props) => (
  <Tooltip onDisplayTooltip={onDisplayTooltip} ariaLabel={nt(ns, 'info')}>
    <TooltipTrigger>
      <InformationCircleSvg className="tw-h-[14px] tw-w-[14px]" />
    </TooltipTrigger>
    <TooltipContent>{tooltip}</TooltipContent>
  </Tooltip>
);

export default MoreInfo;
