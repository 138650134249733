import React from 'react';
import { Alert } from 'shared/bootstrap';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types';

import { nt } from 'shared/utils/I18n';
import { toEuro } from 'namespaces/shared/utils/Helpers';

const SettlementWarning = () => {
  const settlementCalculated = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.settlementCalculated
  );
  const settlementToBeCalculated = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.settlementToBeCalculated
  );
  const withdrawalEnabled = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard?.payload?.canWithdraw &&
      +state.charlie.dashboard?.payload?.availableBalance > 0.0
  );
  const settlement = useSelector((state: ReduxState) => {
    if (state.charlie.dashboard?.payload?.settlement) {
      return parseFloat(state.charlie.dashboard?.payload?.settlement);
    } else {
      return 0;
    }
  });

  const getMessageStart = () => {
    if (withdrawalEnabled) {
      return nt(
        'charlie.frontpage.warnings',
        'settlement_warning_start_withdrawal_enabled'
      );
    } else {
      return nt(
        'charlie.frontpage.warnings',
        'settlement_warning_start_withdrawal_disabled'
      );
    }
  };

  const getMessageEnd = () => {
    if (settlementCalculated) {
      return nt(
        'charlie.frontpage.warnings',
        'settlement_warning_end_after_calculation',
        { amount: toEuro(settlement) }
      );
    } else if (settlementToBeCalculated) {
      return nt(
        'charlie.frontpage.warnings',
        'settlement_warning_end_before_calculation'
      );
    } else {
      return '';
    }
  };

  const messageStart = getMessageStart();
  const messageEnd = getMessageEnd();

  if (messageEnd) {
    return (
      <Alert type="warning" className="SettlementWarning">
        <p>{messageStart}</p>
        <p>{messageEnd}</p>
      </Alert>
    );
  } else {
    return null;
  }
};

export default SettlementWarning;
