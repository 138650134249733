import React from 'react';
import OpenQuestion from './OpenQuestion';
import AnsweredQuestion from './AnsweredQuestion';
import { AnswerQuestion, ReopenQuestion } from './useQuestions';
import Question from './question';

interface Props {
  answerQuestion: AnswerQuestion;
  questions: Question[];
  reOpenQuestion: ReopenQuestion;
  title: string;
}

const QuestionList = ({
  questions,
  title,
  answerQuestion,
  reOpenQuestion,
}: Props) => {
  if (!questions.length) {
    return null;
  }

  return (
    <div className="QuestionList">
      <h4 className="md:tw-text-md tw-text-base">{title}</h4>
      {questions.map((question) =>
        question.answeredAt && !question.reOpened ? (
          <AnsweredQuestion
            key={question.id}
            question={question}
            reOpenQuestion={reOpenQuestion}
          />
        ) : (
          <OpenQuestion
            answerQuestion={answerQuestion}
            question={question}
            key={question.id}
          />
        )
      )}
    </div>
  );
};

export default QuestionList;
