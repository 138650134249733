import React from 'react';
import useSelectorWithShallowCompare from 'shared/hooks/useSelectorWithShallowCompare';
import { MinimalSeller, ReduxState } from 'types';
import * as I18n from 'shared/utils/I18n';
import { currentSellerSelector } from 'shared/selectors';
import SummaryLayout from './DashboardSummaryLayout';
import Settlement from './Settlement';
import AmountField from './AmountField';

const ns = 'charlie.frontpage.account_summary';

const LoanSummary = () => {
  const { dashboard, numberOfWeeksToPayOffDebt } =
    useSelectorWithShallowCompare((state: ReduxState) => {
      const { numberOfWeeksToPayOffDebt } = currentSellerSelector(
        state
      ) as MinimalSeller;

      return {
        dashboard: state.charlie.dashboard.payload,
        numberOfWeeksToPayOffDebt,
      };
    });

  if (!dashboard) {
    return null;
  }

  const creditLimit = parseFloat(dashboard.creditLimit);

  // NB: if the seller has not paid off his loan yet, the current balance is negative
  const currentBalance = parseFloat(dashboard.currentBalance);
  const settlement = parseFloat(dashboard.settlement);

  const chartPercentage =
    currentBalance < 0 ? -currentBalance / creditLimit : 1.0;

  const renderBottomLeft = () => {
    if (settlement !== 0) {
      return <Settlement amount={settlement} />;
    } else if (numberOfWeeksToPayOffDebt) {
      return I18n.nt(ns, 'remaining_time', {
        count: numberOfWeeksToPayOffDebt,
      });
    } else {
      return <div />;
    }
  };

  const renderBottomRight = () => {
    return (
      <div className="tw-text-right">
        <AmountField amount={creditLimit} translationKey="original_amount" />
      </div>
    );
  };

  const topLeft = {
    amount: currentBalance === 0.0 ? 0.0 : -currentBalance,
    translationKey: 'amount_to_pay_off',
  };

  return (
    <SummaryLayout
      chartPercentage={chartPercentage}
      renderBottomLeft={renderBottomLeft}
      renderBottomRight={renderBottomRight}
      topLeft={topLeft}
    />
  );
};

export default LoanSummary;
