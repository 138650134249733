import React from 'react';

interface YourFinanceLineProps {
  label: string;
  value: string;
}

const YourFinanceLine = ({ label, value }: YourFinanceLineProps) => (
  <div className="YourFinanceLine">
    <span className="YourFinanceLabel">{label}: </span>
    <span className="YourFinanceValue">{value}</span>
  </div>
);

export default YourFinanceLine;
