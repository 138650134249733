import client from 'shared/utils/client';
import React, { useEffect, useState } from 'react';
import { Alert } from 'shared/components/tailwind/Alert';
import * as I18n from 'shared/utils/I18n';

const ns = 'app.profile.onboardingStep.modals.mandateStart';

interface InvitePayload {
  status: string;
  url: string;
}

const Mandate = () => {
  const [requestStatus, setRequestStatus] = useState<string>('');

  useEffect(() => {
    const fetchInviteLink = async () => {
      const response = await client<InvitePayload>(
        'GET',
        '/api/twikey_session/invite',
        {},
        { raiseError: false }
      );

      if (response.error) {
        setRequestStatus('error');
      } else {
        const { status, url } = response.payload;

        if (status === 'ok') {
          setRequestStatus('ok');
          window.location.href = url;
        } else {
          setRequestStatus('error');
        }
      }
    };

    fetchInviteLink().catch(() => setRequestStatus('error'));
  }, []);

  if (requestStatus === 'error') {
    return (
      <div className="md:px-0 tw-px-4 tw-py-8 md:tw-container sm:tw-px-8">
        <Alert type="danger">{I18n.nt(ns, 'twikey_error')}</Alert>
      </div>
    );
  }

  return null;
};

export default Mandate;
