import { User } from 'types';
import updateEntities from './updateEntities';
import { userSelector } from 'shared/selectors';

const updateUserInState = (values: Partial<User>) => (dispatch, getState) => {
  const user = userSelector(getState()) as User;

  dispatch(updateEntities({ ...user, ...values }));
};

export default updateUserInState;
