import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import client from 'shared/utils/client';
import { Guarantee } from 'types';
import { FormValues } from 'shared/components/GuaranteeForm';
import * as I18n from 'shared/utils/I18n';
import { LoginStatus } from './util';
import { trackFormSubmit } from 'shared/utils/tracker';

const ns = 'app.second_signer';

interface RouteParams {
  token: string;
}

interface SignTokenResult {
  guarantee: Guarantee | null;
  loginStatus: LoginStatus;
  sellerEmail: string;
  signGuarantee: (values: FormValues) => Promise<any>;
}

const useSignToken = (): SignTokenResult => {
  const { token } = useParams<RouteParams>();
  const [loginStatus, setLoginStatus] = useState<LoginStatus>('');
  const [guarantee, setGuarantee] = useState<Guarantee | null>(null);
  const [sellerEmail, setSellerEmail] = useState('');

  const authenticate = async () => {
    setLoginStatus('loading');

    try {
      const { payload } = await client(
        'post',
        '/api/second_signers/authenticate',
        { token }
      );

      if (payload.status === 'ok') {
        setGuarantee(payload.guarantee);
        setSellerEmail(payload.sellerEmail);
        setLoginStatus('logged_in');
      } else if (payload.status === 'expired_token') {
        setLoginStatus('expired_token');
      } else {
        setLoginStatus('error');
      }
    } catch {
      setLoginStatus('error');
    }
  };

  const signGuarantee = async (values: FormValues) => {
    try {
      const { payload } = await client('post', '/api/second_signers/sign', {
        token,
        guarantee: values,
      });

      trackFormSubmit('personal-guarantee');
      window.location.replace(payload.redirectUrl);
    } catch {
      throw I18n.nt(ns, 'sign_error');
    }
  };

  useEffect(() => {
    authenticate();
  }, []);

  return { guarantee, loginStatus, sellerEmail, signGuarantee };
};

export default useSignToken;
