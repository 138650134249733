import { connect } from 'react-redux';
import aisRedirector from 'shared/components/Consent/AisRedirector';
import LoadingWizard from './LoadingWizard';
import { load } from 'shared/modules/review';

const mapStateToProps = (state) => ({
  loading: state.charlie.reviewWizard.loading,
});

export default aisRedirector(
  connect(mapStateToProps, { loadWizardState: load })(LoadingWizard)
);
