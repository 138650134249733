import { useSelector } from 'react-redux';
import { ReduxState } from 'types';

const useAutomaticWithdrawal = () => {
  const automaticWithdrawalPermitted = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard?.payload?.automaticWithdrawalPermitted ?? false
  );
  const automaticWithdrawalActivated = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard?.payload?.automaticWithdrawalActivated ?? false
  );
  const automaticWithdrawalVisible =
    automaticWithdrawalPermitted || automaticWithdrawalActivated;

  return {
    automaticWithdrawalActivated,
    automaticWithdrawalVisible,
  };
};

export default useAutomaticWithdrawal;
