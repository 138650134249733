import React from 'react';
import { Button, Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { Review, Seller } from 'types';
import Psd2Banks from 'shared/components/Psd2Banks';
import { BankStatementUploadType } from 'types/BankStatementUploadType';
import { FINANCIAL_DASHBOARD } from 'app/routes';
import { trackFormSubmit } from 'shared/utils/tracker';
import Fieldset from './Fieldset';
import useBankTransactionsImportModal from './useBankTransactionsImportModal';

const ns = 'app.profile.onboardingStep.modals.bankStatementAttachments';

interface Props {
  bankStatementUploadType: BankStatementUploadType;
  closeUploadModal: () => void;
  reloadFinancialDashboardStatus: () => void;
  review: Review;
  seller: Seller;
  setBankStatementUploadType: (newType: BankStatementUploadType) => void;
}

const BankTransactionsImportModal = ({
  bankStatementUploadType,
  closeUploadModal,
  reloadFinancialDashboardStatus,
  review,
  seller,
  setBankStatementUploadType,
}: Props) => {
  const {
    attachmentsWithWarning,
    bankStatementAttachments,
    control,
    isSubmitting,
    removeInvalidAttachments,
    setUploading,
    uploading,
    warnings,
  } = useBankTransactionsImportModal({ review, seller });

  const toggleType = () => {
    return setBankStatementUploadType(
      bankStatementUploadType === 'psd2' ? 'documents' : 'psd2'
    );
  };

  const psd2Mode = bankStatementUploadType === 'psd2';
  const documentsMode = bankStatementUploadType !== 'psd2';

  const submit = () => {
    if (!seller.aisConnected) {
      reloadFinancialDashboardStatus();
    }
    trackFormSubmit('onboarding-upload-modal');
    closeUploadModal();
  };

  return (
    <Modal
      className="BankTransactionsImportModal"
      onHide={closeUploadModal}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {I18n.nt(ns, 'title')}
          <span className="icon icon-lock tw-ml-2 tw-align-top tw-text-floryn-green" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-24">
          {psd2Mode ? (
            <div className="connect-with-bank">
              <label>{I18n.nt(ns, 'psd2.title')}</label>
              <p>{I18n.nt(ns, 'psd2.intro')}</p>
              <Psd2Banks
                allowedPdfUpload
                onSelectPdf={() => setBankStatementUploadType('documents')}
                redirectPath={FINANCIAL_DASHBOARD}
              />
            </div>
          ) : (
            <div className="DocumentUpload">
              <Fieldset
                attachmentsWithWarning={attachmentsWithWarning}
                bankStatementAttachments={bankStatementAttachments}
                control={control}
                handleBankConnectionLink={toggleType}
                removeInvalidAttachments={removeInvalidAttachments}
                seller={seller}
                setUploading={setUploading}
                submitting={isSubmitting}
                uploading={uploading}
                warnings={warnings}
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <div className="ruler" />
      <Modal.Footer>
        {documentsMode && (
          <Button className="btn-primary" disabled={uploading} onClick={submit}>
            {I18n.nt(ns, 'submit')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default BankTransactionsImportModal;
