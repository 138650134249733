import React from 'react';
import { dateFormat, toEuro } from 'shared/utils/Helpers';
import { nt } from 'shared/utils/I18n';
import MoreInfo from './MoreInfo';

interface Props {
  amount: string;
  date: string;
  settlementCalculated: boolean;
  settlementToBeCalculated: boolean;
}

const ns = 'charlie.frontpage.account_summary';

const NextDebtCollectionExplanation = ({
  amount,
  date,
  settlementCalculated,
  settlementToBeCalculated,
}: Props) => (
  <div
    className="tw-flex tw-flex-col tw-items-end tw-gap-1"
    id="next_debt_collection_explanation"
  >
    <div className="tw-flex tw-flex-col tw-items-end tw-gap-0 tw-text-right tw-leading-none sm:tw-flex-row sm:tw-gap-1">
      <span>{nt(ns, 'next_debtcollection')}:</span>
      <span className="tw-flex tw-items-center tw-gap-1 tw-text-right">
        {nt(ns, 'amount_on_date', {
          amount: toEuro(amount),
          date: dateFormat(date),
        })}
        {settlementCalculated && (
          <span className="tw-leading-none">
            <MoreInfo tooltip={nt(ns, 'explain_settlement')} />
          </span>
        )}
        {parseFloat(amount) > 0 && settlementToBeCalculated && (
          <span className="tw-leading-none">
            <MoreInfo tooltip={nt(ns, 'explain_extra_debt_collection')} />
          </span>
        )}
      </span>
    </div>
  </div>
);

export default NextDebtCollectionExplanation;
