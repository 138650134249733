import React from 'react';
import AccountsList from './AccountsList';
import { Bank } from 'types';

interface Props {
  banks: Bank[];
  onRefresh: (bankName: string) => void;
  isDisabled: boolean;
}

const BanksList = ({ banks, onRefresh, isDisabled }: Props) => {
  return (
    <div>
      {banks.map((bank) => (
        <AccountsList
          key={bank.name}
          bank={bank}
          onRefresh={() => onRefresh(bank.name)}
          isDisabled={isDisabled}
        />
      ))}
    </div>
  );
};

export default BanksList;
