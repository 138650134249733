import React, { useMemo } from 'react';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { nt } from 'shared/utils/I18n';
import { toEuro } from 'shared/utils/Helpers';
import { WithdrawalInfo } from './calculateWithdrawalInfo';

const ns = 'signup.onboarding.repayment_and_interest_chart';

interface ChartRow {
  interest: number;
  repayment: number;
  week: number;
}

const generateChartData = (withdrawalInfo: WithdrawalInfo): ChartRow[] =>
  withdrawalInfo.payments.map((payment, index) => ({
    interest: payment.interest,
    repayment: Math.max(0, payment.debtCollection - payment.interest),
    week: index + 1,
  }));

const formatLegendKey = (key: string) => nt(ns, `legend.${key}`);

interface Props {
  withdrawalInfo: WithdrawalInfo;
}

const RepaymentAndInterestChart = ({ withdrawalInfo }: Props) => {
  const data = useMemo(
    () => generateChartData(withdrawalInfo),
    [withdrawalInfo]
  );

  return (
    <div className="tw-f-full tw-h-[300px]">
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 0, left: 0, bottom: 0, right: 10 }}
        >
          <XAxis
            dataKey="week"
            height={40}
            label={nt(ns, 'week')}
            tickFormatter={(value, index) =>
              index === data.length - 1 ? `${value}` : ''
            }
          />
          <YAxis
            width={60}
            tickCount={1}
            tickFormatter={(value) => toEuro(value, 0)}
          />
          <Legend formatter={formatLegendKey} height={40} verticalAlign="top" />
          <Tooltip
            labelFormatter={(index) => `${nt(ns, 'week')} ${index}`}
            formatter={(value: number, label: string) => [
              toEuro(value),
              formatLegendKey(label),
            ]}
          />
          <Bar
            type="monotone"
            dataKey="repayment"
            stackId="1"
            stroke="#00ABE9"
            fill="#00ABE9"
          />
          <Bar
            type="monotone"
            dataKey="interest"
            stackId="1"
            stroke="#002B61"
            fill="#002B61"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RepaymentAndInterestChart;
