import React, { ReactNode } from 'react';
import { capitalize } from 'lodash';

import NavigationBar from 'app/components/NavigationBar';
import CheckExpiredSession from 'app/components/CheckExpiredSession';
import { MinimalSeller } from 'types';
import { LinkDefinition } from 'app/components/NavigationBar/NavigationBarApproved';
import MobileAppBanner from './MobileAppBanner';

interface Props {
  children?: ReactNode;
  navBarLinks: LinkDefinition[];
  seller: MinimalSeller;
}

const SellerRoot = ({ children, navBarLinks, seller }: Props) => {
  return (
    <div className={`SellerRoot ${capitalize(seller.productVersion)}App`}>
      <MobileAppBanner />
      <NavigationBar links={navBarLinks} />
      <CheckExpiredSession />
      {children}
    </div>
  );
};

export default SellerRoot;
