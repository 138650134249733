import client from 'shared/utils/client';
import updateEntities from 'shared/modules/updateEntities';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import useCurrentSeller from './useCurrentSeller';
import { sessionExpired } from 'shared/redux/modules/session';

/* Load full seller
 * Returns a function that loads the full seller
 * Assumes existing seller entity (MinimumSeller or Seller type)
 * Updates the seller entity
 */
const useLoadFullSeller = () => {
  const dispatch = useAppDispatch();
  const sellerEntity = useCurrentSeller();

  const loadFullSeller = async () => {
    if (!sellerEntity) {
      throw new Error('loadFullSeller: must have a seller');
    }

    const response = await client(
      'GET',
      `/api/sellers/${sellerEntity.id}`,
      {},
      { raiseError: false }
    );

    if (response.error) {
      if (response.statusCode === 401) {
        dispatch(sessionExpired());
      }
      return false;
    }

    dispatch(updateEntities(response.payload));
    return true;
  };

  return loadFullSeller;
};

export default useLoadFullSeller;
