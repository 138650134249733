import React from 'react';
import { preventDefault } from 'namespaces/shared/utils/functions';
import * as I18n from 'shared/utils/I18n';
import { Button } from 'shared/bootstrap';
import ShadowBox from 'shared/components/tailwind/ShadowBox';

const ns = 'shared.psd2_banks.bank_button';

interface Props {
  bank: string;
  onSelect: () => void;
}

const BankButton = ({ bank, onSelect }: Props) => (
  <ShadowBox
    className={`BankButton ${bank} tw-flex tw-flex-col tw-items-center tw-gap-4 tw-py-6`}
  >
    <img
      className="tw-max-w-[120px] tw-grow"
      aria-label={bank}
      src={`/images/banks/${bank}.svg`}
    />
    <div className="tw-block tw-flex tw-w-5/6 tw-grow tw-items-end">
      <Button
        onClick={preventDefault(onSelect)}
        className="btn-primary tw-grow"
        data-testid={`${bank}-koppelen`}
      >
        {I18n.nt(ns, 'connect')}
      </Button>
    </div>
  </ShadowBox>
);

export default BankButton;
