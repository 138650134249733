const roundOffToThousands = (value: number) =>
  Math.ceil(value / 1000.0) * 1000.0;

interface Params {
  closingCommissionRate: number;
  creditLimit: number;
}

/* Return the minumum withdrawal amount.

The result is the max of:
* 20% of the credit limit, and
* the closing commission
*
* Background:
* The 20% is based on tests with production data.
* The closing commission is less than 20% in most cases.
*/
const calculateMinimumWithdrawalAmount = ({
  closingCommissionRate,
  creditLimit,
}: Params) => {
  const minimumRate = Math.max(0.2, closingCommissionRate);

  return roundOffToThousands(creditLimit * minimumRate);
};

export default calculateMinimumWithdrawalAmount;
