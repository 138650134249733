import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as routes from 'app/routes';
import Error_ from './Error';
import Guest from 'app/Guest';
import NotFound from './NotFound';
import OnboardingUser from 'OnboardingUser';
import Sellers from 'app/Sellers';
import SecondSigner from 'SecondSigner';
import PartnerSignup from 'PartnerSignup';
import { RedirectToFrontpage, RouteForSeller } from 'shared/utils/routing';
import useLoadSession from 'shared/hooks/useLoadSession';
import Logout from './Logout';

const AppRoot = () => {
  const { tokenError, loaded } = useLoadSession();

  if (tokenError) {
    // Handle token error by PartnerSignup component. Assume we're on the partner signup url.
    return (
      <div className="ApplicationRoot app">
        <Route
          path={routes.PARTNER_SIGNUP_BANK_STATEMENTS}
          component={PartnerSignup}
        />
      </div>
    );
  }

  if (!loaded) {
    return <span />;
  }

  return (
    <div className="ApplicationRoot app">
      <Switch>
        <Route path={routes.GUEST_ROOT} component={Guest} />
        <RouteForSeller
          path={[routes.CUSTOMER_APP_ROOT, routes.OVERVIEW_PAGE_TAB]}
          component={Sellers}
          condition={(seller) => Boolean(seller)}
        />
        <Route path={routes.ERROR} component={Error_} />
        <Route path={routes.SECOND_SIGNER} component={SecondSigner} />
        <Route path={routes.ONBOARDING_USER} component={OnboardingUser} />
        <RouteForSeller
          path={routes.PARTNER_SIGNUP_BANK_STATEMENTS}
          component={PartnerSignup}
          condition={(seller) =>
            !seller ||
            seller.status === 'draft' ||
            seller.status === 'submitted'
          }
        />
        <Route path={routes.LOGOUT} component={Logout} />
        <Route exact path={routes.APP_ROOT} component={RedirectToFrontpage} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default () => <Route path="/" component={AppRoot} />;
