import React from 'react';
import { Alert, Button } from 'shared/bootstrap';
import ContentWithSidebar from 'shared/components/tailwind/ContentWithSidebar';
import DefaultSidebar from 'shared/components/DefaultSidebar';
import * as I18n from 'shared/utils/I18n';
import useReactivation from './useReactivation';
import LoadingAnimation from 'shared/components/LoadingAnimation';

const ns = 'app.profile.reactivation';

const Reactivation = () => {
  const { loadSellerStatus, reactivate, statusMessage, submitStatus } =
    useReactivation();

  return (
    <ContentWithSidebar sidebarChildren={<DefaultSidebar />}>
      {loadSellerStatus !== 'loaded' ? (
        <LoadingAnimation />
      ) : (
        <div className="Reactivation">
          {submitStatus === 'error' && (
            <Alert type="danger">{I18n.nt(ns, 'error')}</Alert>
          )}
          {submitStatus === 'success' && (
            <Alert type="info">{I18n.nt(ns, 'success')}</Alert>
          )}
          <h3>{I18n.nt(ns, 'title')}</h3>
          <p>{statusMessage}</p>
          <p>{I18n.nt(ns, 'general_message')}</p>
          <Button
            className="btn-primary"
            onClick={reactivate}
            disabled={
              submitStatus === 'submitting' || submitStatus === 'success'
            }
          >
            {I18n.nt(ns, 'start_credit_request')}
          </Button>
        </div>
      )}
    </ContentWithSidebar>
  );
};

export default Reactivation;
