import React from 'react';
import { translate } from 'shared/utils/I18n';

const t = translate('review_wizard.loading_failure');

const LoadingFailure = () => (
  <div className="rw-container">
    <div className="row">
      <div className="col-md-6 col-md-offset-3 rw-space-y-32">
        <div className="rw-failure rw-space-y-16">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="rw-failure__icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h2>{t('title')}</h2>
          <p>{t('body')}</p>
        </div>
      </div>
    </div>
  </div>
);

export default LoadingFailure;
