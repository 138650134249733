import React from 'react';
import { TwikeyStatus } from 'types';

import * as I18n from 'shared/utils/I18n';
import SignProcessDescription from './seller/SignProcessDescription';

const ns = 'app.mandate_return';

const DigitalBankDescription = () => <p>{I18n.nt(ns, 'message.signed')}</p>;

interface AnalogBankDescriptionProps {
  iban?: string;
}

const AnalogBankDescription = ({ iban }: AnalogBankDescriptionProps) =>
  iban ? (
    <SpecificAnalogBankDescription iban={iban} />
  ) : (
    <p
      dangerouslySetInnerHTML={{
        __html: I18n.nt(ns, 'message.printed_generic'),
      }}
    />
  );

interface SpecificAnalogBankDescriptionProps {
  iban: string;
}

const SpecificAnalogBankDescription = ({
  iban,
}: SpecificAnalogBankDescriptionProps) => (
  <div>
    <p>{I18n.nt(ns, 'message.printed_specific')}</p>
    <SignProcessDescription iban={iban} />
  </div>
);

const FallbackDescription = () => (
  <p dangerouslySetInnerHTML={{ __html: I18n.nt(ns, 'message.default') }} />
);

interface MandateReturnMessageProps {
  iban?: string;
  status?: TwikeyStatus;
}

const MandateReturnMessage = ({ iban, status }: MandateReturnMessageProps) => {
  switch (status) {
    case 'signed':
      return <DigitalBankDescription />;

    case 'printed':
      return <AnalogBankDescription iban={iban} />;

    default:
      return <FallbackDescription />;
  }
};

export default MandateReturnMessage;
