import { useEffect, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { isContactSelected } from './util';
import { ContactsInputValue, NewInvoiceContact } from './types';
import { InvoiceContact } from 'types';

interface Params<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  initialAllContacts: InvoiceContact[];
  name: Path<TFieldValues>;
}

const useContactsInput = <TFieldValues extends FieldValues>({
  control,
  initialAllContacts,
  name,
}: Params<TFieldValues>) => {
  const [allContacts, setAllContacts] = useState<ContactsInputValue[]>([]);

  useEffect(() => {
    setAllContacts(initialAllContacts);
  }, []);

  const {
    field: { onChange, value },
  } = useController<TFieldValues>({ control, name });

  const selectedContacts = value as ContactsInputValue[];

  const toggleContact = (contact: ContactsInputValue) => {
    if (isContactSelected(selectedContacts, contact)) {
      onChange(selectedContacts.filter((c) => c.id !== contact.id));
    } else {
      onChange([...selectedContacts, contact]);
    }
  };

  const addContact = ({ email, fullName, id, locale }: NewInvoiceContact) => {
    const trimmedEmail = email.trim().toLowerCase();
    const newContact: NewInvoiceContact = {
      email: trimmedEmail,
      entityKind: 'NewInvoiceContact',
      fullName: fullName.trim(),
      id,
      locale,
    };

    if (allContacts.find((contact) => contact.email === newContact.email)) {
      // Replace contact with the same email
      setAllContacts(
        allContacts.map((contact) =>
          contact.email === trimmedEmail ? newContact : contact
        )
      );

      let newSelectedContacts: ContactsInputValue[] = [];

      // Remove replaced contact from selected contacts
      newSelectedContacts = selectedContacts.filter(
        (contact) => contact.email !== trimmedEmail
      );

      // Select updated contact
      newSelectedContacts = [...newSelectedContacts, newContact];

      onChange(newSelectedContacts);
    } else {
      // Add contact
      setAllContacts([...allContacts, newContact]);
      onChange([...selectedContacts, newContact]);
    }
  };

  return { addContact, allContacts, selectedContacts, toggleContact };
};

export default useContactsInput;
