import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { loadDashboard } from 'charlie/modules/dashboardSlice';
import { getQueryParam } from 'shared/utils/Helpers';
import { currentSellerSelector } from 'shared/selectors';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import useHasSeenPopupToday, {
  touchLocalStorage,
} from 'shared/hooks/useHasSeenPopupToday';
import { ReduxState, MinimalSeller } from 'types';
import { isAlfa } from 'models/Seller';
import useIsFrontpage from 'shared/hooks/useIsFrontpage';

const useOverviewPage = () => {
  const dispatch = useAppDispatch();
  const seller = useSelector(currentSellerSelector) as MinimalSeller;
  const isFrontpage = useIsFrontpage(seller);

  const dashboard = useSelector((state: ReduxState) => state.charlie.dashboard);
  const [reactivationPopupVisible, setReactivationPopupVisible] =
    useState(false);
  const [popupsEnabled, setPopupsEnabled] = useState(true);
  const hasSeenPopupToday = useHasSeenPopupToday();
  const hasAisFeedback = !!getQueryParam(location.search, 'status');

  useEffect(() => {
    if (isFrontpage) {
      touchLocalStorage('lastSeenPopup');
    } else {
      setPopupsEnabled(false);
    }
  }, []);

  useEffect(() => {
    dispatch(loadDashboard(seller.id));
  }, [seller.id]);

  const isLoaded = dashboard.dashboardStatus === 'LOADED';

  const canShowPopups =
    popupsEnabled && isFrontpage && (!hasSeenPopupToday || hasAisFeedback);

  const showReactivationPopup =
    (canShowPopups && dashboard.payload?.eligibleForReactivation) ||
    reactivationPopupVisible;

  const showRefreshPopup =
    canShowPopups && !showReactivationPopup && !isAlfa(seller);

  const onHideModal = () => {
    setReactivationPopupVisible(false);
    setPopupsEnabled(false);
  };

  const onShowModal = () => {
    setReactivationPopupVisible(true);
  };

  return {
    isLoaded,
    onHideModal,
    onShowModal,
    seller,
    showReactivationPopup,
    showRefreshPopup,
  };
};

export default useOverviewPage;
