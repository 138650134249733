import React from 'react';
import { Glyphicon } from 'shared/bootstrap';
import { Link } from 'react-router-dom';
import { SELLER_ONBOARDING } from 'app/routes';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import ContentWithSidebar from 'shared/components/tailwind/ContentWithSidebar';
import DefaultSidebar from 'shared/components/DefaultSidebar';
import { nt } from 'shared/utils/I18n';
import useQuestions from './useQuestions';
import QuestionList from './QuestionList';
import { ns } from './utils';

const QuestionsStep = () => {
  const {
    answeredQuestions,
    answerQuestion,
    introductionText,
    openQuestions,
    kind,
    reOpenQuestion,
    status,
  } = useQuestions();

  if (status !== 'loaded') {
    return <LoadingAnimation />;
  }

  return (
    <ContentWithSidebar sidebarChildren={<DefaultSidebar />}>
      <div className="QuestionsStep">
        <div>
          <Link
            to={SELLER_ONBOARDING}
            className="btn btn-default btn-light-inverted GlyphButton"
          >
            <Glyphicon glyph="arrow-left" />
            {nt(ns, 'back')}
          </Link>
        </div>
        <div>
          <h3 className="tw-text-lg md:tw-mb-4 md:tw-text-bootstrap-h3">
            {nt(ns, `title.${kind}`)}
          </h3>
          <div>{introductionText}</div>
        </div>
        <div className="question-lists">
          <QuestionList
            answerQuestion={answerQuestion}
            questions={openQuestions}
            reOpenQuestion={reOpenQuestion}
            title={nt(ns, 'open_questions')}
          />
          <QuestionList
            answerQuestion={answerQuestion}
            questions={answeredQuestions}
            reOpenQuestion={reOpenQuestion}
            title={nt(ns, 'answered_questions')}
          />
        </div>
      </div>
    </ContentWithSidebar>
  );
};

export default QuestionsStep;
