import React, { useState, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/browser';
import { AisLogin, loadAisLogin } from 'shared/utils/aisClient';
import { trackCrossPlatformEvent } from 'shared/utils/tracker';

interface Target {
  bank?: string;
  redirectUrl?: string;
}
export interface AisRedirectorProps {
  onRefresh: (bank: string, redirectUrl: string) => void;
}

/**
 * A higher-order component that encapsulates making the user make a POST
 * request to AIS in order to start the oauth flow toward the bank for PSD2
 * connections.
 *
 * In order for this to work, we need two things:
 *
 * - The bank to redirect to
 * - The redirect path that AIS will send the user to when done.
 *
 * This will generate a component that will login with AIS and render the given
 * component with a `onRefresh` function that can be called with a bank and
 * redirect path to start the flow.
 */
function aisRedirector<T extends AisRedirectorProps>(
  WrappedComponent: React.ComponentType<T>
) {
  return (props) => {
    const [{ bank, redirectUrl }, setTarget] = useState<Target>({});
    const [aisLogin, setAisLogin] = useState<AisLogin | null>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const handleRefresh = (bank: string, redirectUrl: string) =>
      setTarget({ bank, redirectUrl });

    const login = async () => {
      const response = await loadAisLogin(false, props.seller?.id);

      // We expect 'unauthorized' errors here and there due to, for example sessions
      // expiring, so let's just capture other types into Sentry.
      if (response.error && response.statusCode !== 401) {
        Sentry.captureMessage(
          `Could not load ais login data. Response: ${response.statusCode} ${response.payload}`
        );
      } else {
        setAisLogin(response.payload as AisLogin);
      }
    };

    useEffect(() => {
      login();
    }, []);

    useEffect(() => {
      if (aisLogin && bank && redirectUrl) {
        trackCrossPlatformEvent('psd2 consent initiated', { bank }, () =>
          formRef?.current?.submit()
        );
      }
    }, [aisLogin, formRef, bank, redirectUrl]);

    return (
      <>
        {aisLogin && bank && redirectUrl && (
          <form method="post" action={`${aisLogin.host}/oauth`} ref={formRef}>
            <input type="hidden" name="ais_token" value={aisLogin.token} />
            <input type="hidden" name="bank" value={bank} />
            <input type="hidden" name="redirect_path" value={redirectUrl} />
          </form>
        )}
        <WrappedComponent onRefresh={handleRefresh} {...props} />
      </>
    );
  };
}

export default aisRedirector;
