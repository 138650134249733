import React from 'react';

import * as I18n from 'shared/utils/I18n';

const ns = 'shared.dnb_disclaimer';

interface Props {
  includeTopMargin?: boolean;
}

const DnbDisclaimer = ({ includeTopMargin = true }: Props) => {
  const twTopMargin = includeTopMargin ? 8 : 4;

  return (
    <div
      className={`DnbDisclaimer tw-mt-${twTopMargin} tw-flex tw-text-gray-500`}
    >
      <span className="icon icon-lock tw-mr-3 tw-inline tw-text-[18px]" />
      {I18n.nt(ns, 'disclaimer_html')}
    </div>
  );
};

export default DnbDisclaimer;
