import { FieldValues, Path, UseFormSetError } from 'react-hook-form';
import { forEach } from 'lodash';
import { Result } from 'shared/utils/client';
import { nt } from 'shared/utils/I18n';

/* Handle error response for a form.

* For a 422 response:
* * Set error messages for fields.
* * Return general error messages.
* * Note that the 422 response contains validation errors.
*
* For other responses (e.g. 500):
* * Return a general error message (server error).
*
* Parameters:
* - response: server response
* - setError: method from react hook form
*/
const handleFormErrorsFromResponse = <TFieldValues extends FieldValues>(
  response: Result,
  setError: UseFormSetError<TFieldValues>
) => {
  if (!response.error) {
    return [];
  }

  if (response.statusCode !== 422) {
    return [nt('common', 'server_error')];
  }

  const validationErrors = response.payload.validationErrors as
    | Record<string, string[]>
    | undefined;

  forEach(validationErrors, (messages, fieldName) => {
    if (fieldName !== 'base') {
      setError(
        fieldName as Path<TFieldValues>,
        { message: messages[0] },
        { shouldFocus: true }
      );
    }
  });

  return validationErrors?.base || [];
};

export default handleFormErrorsFromResponse;
