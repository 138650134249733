import React from 'react';
import * as I18n from 'shared/utils/I18n';
import * as Helpers from 'shared/utils/Helpers';

const ns = 'app.invoiceStatus';

interface Props {
  isSendable: boolean;
  lastActionAt: string;
}

const Assessment = ({ isSendable, lastActionAt }: Props) => {
  const formattedLastActionAt = Helpers.dateFormat(lastActionAt);

  return (
    <div>
      <div className="heading">{I18n.nt(ns, 'assessment.heading')}</div>
      {I18n.nt(ns, 'assessment.invoiceReceivedHtml', {
        lastActionAt: formattedLastActionAt,
      })}

      {isSendable && I18n.nt(ns, 'assessment.invoiceVerificationHtml')}

      <p className="lastActionAt">
        {I18n.nt(ns, 'lastChange', { date: formattedLastActionAt })}
      </p>
    </div>
  );
};

export default Assessment;
