import React from 'react';
import { nt } from 'shared/utils/I18n';

const ns = 'signup.onboarding.dashboard_preview';

const DashboardPreview = () => (
  <div className="DashboardPreview">
    <h4>{nt(ns, 'title')}</h4>
    <p>{nt(ns, 'intro')}</p>
    <img src={'/images/charlie_dashboard.png'} title="persoonlijke omgeving" />
    <p>{nt(ns, 'more_info_html')}</p>
  </div>
);

export default DashboardPreview;
