import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import MandateReturn from 'signup/components/MandateReturn';
import { loadTwikeySession } from 'signup/actions/MandateReturn';
import { TwikeyStatus } from 'types';

enum TwikeySessionStatus {
  EMPTY = 'empty',
  LOADING = 'loading',
  LOADED = 'loaded',
}

type LoadTwikeySession = () => TwikeySessionStatus;

interface Payload {
  iban: string;
  status: TwikeyStatus;
}

interface Props {
  loadTwikeySession: LoadTwikeySession;
  payload: Payload;
  status: TwikeySessionStatus;
}

interface State {
  mandateReturn: {
    payload: Payload;
  };
}

const LoadMandate = ({
  loadTwikeySession,
}: {
  loadTwikeySession: LoadTwikeySession;
}) => {
  useEffect(() => {
    loadTwikeySession();
  }, []);

  return <LoadingAnimation />;
};

const MandateReturnContainer = ({
  loadTwikeySession,
  payload,
  status,
}: Props) =>
  status === TwikeySessionStatus.LOADED ? (
    <MandateReturn iban={payload.iban} twikeyStatus={payload.status} />
  ) : (
    <LoadMandate loadTwikeySession={loadTwikeySession} />
  );

const mapStateToProps = ({ mandateReturn }: State) => mandateReturn;

export default connect(mapStateToProps, { loadTwikeySession })(
  MandateReturnContainer
);
