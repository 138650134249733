import React from 'react';
import { Alert } from 'shared/bootstrap';
import { useDispatch } from 'react-redux';
import * as I18n from 'shared/utils/I18n';
import { reload } from 'FinancialDashboard/financialDashboardSlice';

const ns = 'signup.onboarding.financial_dashboard.loading_popup';

const TimeOut = () => {
  const dispatch = useDispatch();

  return (
    <Alert type="info" className="alert-field">
      <p className="AlertText">{I18n.nt(ns, 'timeout.info')}</p>
      <p className="AlertText Underline" onClick={() => dispatch(reload())}>
        {I18n.nt(ns, 'timeout.proceed_loading')}
      </p>
    </Alert>
  );
};

export default TimeOut;
