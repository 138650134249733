import React from 'react';
import { Modal } from 'shared/bootstrap';
import Notification from './Notification';
import useRepaymentStatus from './useRepaymentStatus';

interface Props {
  parentPath: string;
}

const RepaymentStatusModal = ({ parentPath }: Props) => {
  const { close, status, retry } = useRepaymentStatus(parentPath);

  return (
    <Modal show onHide={close}>
      <Notification status={status} retry={retry} close={close} />
    </Modal>
  );
};

export default RepaymentStatusModal;
