import React from 'react';
import RouteForSeller from './RouteForSeller';
import { Status } from 'types/Seller';

interface Props {
  component: React.ComponentType;
  exact?: boolean;
  path: string | string[];
  status: Status | Status[];
}

const RouteForSellerStatus = ({ component, exact, path, status }: Props) => (
  <RouteForSeller
    component={component}
    exact={exact}
    condition={(seller) =>
      ([] as string[]).concat(status).indexOf(seller.status) > -1
    }
    path={path}
  />
);

export default RouteForSellerStatus;
