import React, { ReactNode } from 'react';
import Advantages from './Advantages';
import Support from './Support';

interface Props {
  topChildren?: ReactNode;
}

const DefaultSidebar = ({ topChildren }: Props) => (
  <div className="tw-flex tw-flex-col tw-gap-y-6 tw-py-6 sm:tw-py-8 md:tw-gap-y-10 md:tw-px-4 md:tw-pb-24 md:tw-pt-12 lg:tw-px-8">
    {topChildren}
    <Advantages />
    <Support />
  </div>
);

export default DefaultSidebar;
