import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { fromDutchDate, toDutchDate } from 'shared/utils/Helpers';
import { isNil } from 'lodash';
import {
  validateDate,
  validateDateIsInPast,
  validateGreaterThanOrEqualTo,
  validateLessThanOrEqualTo,
  validateNumericality,
  validatePresence,
} from 'shared/utils/validation';
import * as I18n from 'shared/utils/I18n';
import client from 'shared/utils/client';
import resolver from 'hookForm/resolver';
import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';
import { UltimateBeneficialOwner } from 'types';
import { Role } from 'types/UltimateBeneficialOwner';

interface FormValues {
  birthDate: string;
  countryOfResidence: string;
  firstName: string;
  lastName: string;
  otherRole: string;
  role: Role;
  shareholderPercentage: string;
}

export const mustDisplayShareholderPercentageField = (role: Role) =>
  [Role.SHAREHOLDER, Role.PARTNER, Role.SHAREHOLDER_AND_DIRECTOR].includes(
    role
  );

const getInitialValues = (ubo: UltimateBeneficialOwner) => ({
  ...ubo,
  birthDate: toDutchDate(ubo.birthDate),
  countryOfResidence: ubo.countryOfResidence || undefined,
  shareholderPercentage: ubo.shareholderPercentage
    ? `${ubo.shareholderPercentage}`
    : undefined,
});

const validate = ({
  firstName,
  lastName,
  birthDate,
  role,
  otherRole,
  shareholderPercentage,
}: FormValues) => ({
  firstName: validatePresence(firstName),
  lastName: validatePresence(lastName),
  birthDate: validateDate(birthDate) || validateDateIsInPast(birthDate),
  role: validatePresence(role),
  otherRole: role === 'other' ? validatePresence(otherRole) : null,
  shareholderPercentage:
    isNil(shareholderPercentage) || shareholderPercentage === ''
      ? null
      : validateNumericality(shareholderPercentage) ||
        validateGreaterThanOrEqualTo(0)(shareholderPercentage) ||
        validateLessThanOrEqualTo(100)(shareholderPercentage),
});

const formValuesToPostValues = (values: FormValues) => ({
  ultimateBeneficialOwner: {
    ...values,
    birthDate: fromDutchDate(values.birthDate),
  },
});

interface Parameters {
  ubo: UltimateBeneficialOwner | null;
  addUboToState: (ubo: UltimateBeneficialOwner) => void;
  updateUboInState: (ubo: UltimateBeneficialOwner) => void;
  removeUboFromState: (ubo: UltimateBeneficialOwner) => void;
}

const initialValues = {
  countryOfResidence: 'NL',
};

export default ({
  addUboToState,
  ubo,
  updateUboInState,
  removeUboFromState,
}: Parameters) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setError,
  } = useForm<FormValues>({
    defaultValues: ubo ? getInitialValues(ubo) : initialValues,
    resolver: resolver(validate),
  });

  const createUbo = async (values: FormValues) => {
    const response = await client(
      'POST',
      '/api/ultimate_beneficial_owners',
      formValuesToPostValues(values),
      { raiseError: false }
    );

    if (response.error) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        response,
        setError
      );
      setServerErrorMessages(generalErrorMessages);
      return;
    }

    addUboToState(response.payload);
  };

  const updateUbo = async (values: FormValues) => {
    if (!ubo) {
      return;
    }

    const response = await client(
      'PUT',
      `/api/ultimate_beneficial_owners/${ubo.id}`,
      formValuesToPostValues(values),
      {
        raiseError: false,
      }
    );

    if (response.error) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        response,
        setError
      );
      setServerErrorMessages(generalErrorMessages);
      return;
    }

    updateUboInState(response.payload);
  };

  const deleteUbo = async () => {
    if (!ubo) {
      return;
    }

    setIsDeleting(true);
    await client('DELETE', `/api/ultimate_beneficial_owners/${ubo.id}`);
    setIsDeleting(false);

    removeUboFromState(ubo);
  };

  const submit = async (values: FormValues) => {
    if (ubo) {
      await updateUbo(values);
    } else {
      await createUbo(values);
    }
  };

  const roleOptions: [string, string][] = Object.keys(Role).map((key) => [
    Role[key],
    I18n.attribute('ultimate_beneficial_owner/role', Role[key]),
  ]);

  const role = useWatch({ control, name: 'role' });

  return {
    control,
    deleteUbo,
    displayShareholderPercentageField:
      mustDisplayShareholderPercentageField(role),
    isSubmitting,
    isDeleting,
    role,
    roleOptions,
    serverErrorMessages,
    submit: handleSubmit(submit),
  };
};
