import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import resolver from 'hookForm/resolver';
import client from 'shared/utils/client';
import { activateAccount } from 'shared/redux/modules/session';
import { User } from 'types';
import {
  currentSellerSelector,
  customerAccountSelector,
} from 'shared/selectors';
import { frontpageRoute } from 'shared/routes';
import {
  validateConfirmation,
  validatePassword,
} from 'shared/utils/validation';

type Status =
  | ''
  | 'loading_token'
  | 'token_not_found'
  | 'loaded_token'
  | 'activating'
  | 'activation_failed'
  | 'activated';

interface FormValues {
  password: string;
  passwordConfirmation: string;
}

interface RouterState {
  activationToken?: string;
}

const validateForm = ({ password, passwordConfirmation }: FormValues) => ({
  password: validatePassword(password),
  passwordConfirmation:
    validatePassword(passwordConfirmation) ||
    validateConfirmation(password, passwordConfirmation),
});

const useActivation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customerAccount = useSelector(customerAccountSelector);
  const seller = useSelector(currentSellerSelector);

  const [user, setUser] = useState<User | null>(null);
  const [status, setStatus] = useState<Status>('');

  const params = useParams<RouterState>();
  const { activationToken } = params;

  const loadActivation = async () => {
    setStatus('loading_token');

    const response = await client<User>(
      'GET',
      `/api/user_activations/${activationToken}`,
      {},
      { raiseError: false }
    );

    if (response.error) {
      setStatus('token_not_found');
    } else {
      setUser(response.payload);
      setStatus('loaded_token');
    }
  };

  const activate = async ({ password, passwordConfirmation }) => {
    setStatus('activating');

    const response = await client(
      'POST',
      `/api/user_activations`,
      {
        token: activationToken,
        password,
        passwordConfirmation,
      },
      { raiseError: false }
    );

    if (response.error) {
      setStatus('activation_failed');
    } else {
      dispatch(activateAccount(response.payload));
      setStatus('activated');
    }
  };

  const navigateToFrontpage = () => {
    history.push(frontpageRoute(customerAccount, seller, null));
  };

  useEffect(() => {
    if (!activationToken) {
      setStatus('token_not_found');
    } else {
      loadActivation();
    }
  }, []);

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: resolver(validateForm),
  });

  const submit = handleSubmit(activate);

  return { control, navigateToFrontpage, status, submit, email: user?.email };
};

export default useActivation;
