import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { FINANCING_REQUESTED_ALERT } from '../module';

class FinancedAlert extends Component {
  static i18nNamespace = 'app.invoiceSummary.financedAlert';

  static propTypes = {
    invoice: PropTypes.object.isRequired,
    showAlert: PropTypes.string,
  };

  render() {
    if (this.props.showAlert !== FINANCING_REQUESTED_ALERT) return null;

    return (
      <Alert type="info">
        <h3>{I18n.nt(this, 'title')}</h3>
        <br />
        {I18n.nt(this, 'bodyHtml')}
      </Alert>
    );
  }
}

export default FinancedAlert;
