export enum Billing {
  AFTER_DELIVERY = 'after_delivery',
  IN_INSTALLMENTS = 'in_installments',
  IN_ADVANCE = 'in_advance',
  VIA_SUBSCRIPTIONS = 'via_subscriptions',
  OTHER = 'other',
}

export enum Priority {
  AS_SOON_AS_POSSIBLE = 'as_soon_as_possible',
  WITHIN_ONE_WEEK = 'within_one_week',
  WITHIN_ONE_MONTH = 'within_one_month',
  MORE_THAN_ONE_MONTH = 'more_than_one_month',
}

export enum Purpose {
  WORKING_CAPITAL = 'working_capital',
  STOCK_DEBTORS = 'stock_debtors',
  GROWTH = 'growth',
  VEHICLES = 'vehicles',
  MACHINES_INVENTORY = 'machines_inventory',
  MORTGAGE = 'mortgage',
  REFINANCING = 'refinancing',
  ACQUISITIONS_REFINANCING = 'acquisitions_refinancing',
  FACTORING = 'factoring',
  OTHER = 'other',
}

export default interface CustomerSignup {
  entityKind: 'CustomerSignup';
  billing: Billing;
  businessActivities: string;
  existingDebtAmount: string;
  explanation: string;
  id?: number;
  priority: Priority;
  purpose: Purpose;
  soleFinancer: boolean;
}
