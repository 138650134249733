import { useSelector } from 'react-redux';
import { MainUser } from 'types';
import { mainUserSelector } from 'shared/selectors';

const useMainUser = (): MainUser => {
  const mainUser = useSelector(mainUserSelector);

  if (!mainUser) {
    throw new Error('Missing main user');
  }

  return mainUser;
};

export default useMainUser;
