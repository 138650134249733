import React from 'react';
import { TooltipInteraction, TooltipSize } from './types';
import useTooltip from './useTooltip';
import { TooltipContext } from './context';

interface Props {
  ariaLabel?: string;
  children: React.ReactNode;
  onDisplayTooltip?: () => void;
  tooltipInteraction?: TooltipInteraction;
  tooltipSize?: TooltipSize;
}

/* The Tooltip component displays a component with a tooltip. It is used together with the TooltipTrigger and TooltipContent components:

<Tooltip>
  <TooltipTrigger>
    trigger content
  </<TooltipTrigger>
  <TooltipContent>tooltip text</TooltipContent>
</Tooltip>

* The contents of TooltipTrigger can be a component. Same for TooltipContent.
* The tooltip is displayed when the trigger is hovered or has the focus. This behaviour can be changed with the tooltipInteraction parameter.
* ariaLabel: optional aria label text for the trigger
* onDisplayTooltip: optional callback that is called when the tooltip is displayed
* tooltipInteraction:
  * 'hover' (default): display the tooltip when the trigger is hovered or has the focus
  * 'click': display the tooltip when the trigger is clicked
* tooltipSize: size of the tooltip. Default is 'xs'. Values are 'xs' | 'md' | 'xl'.
*/
const Tooltip = ({
  ariaLabel,
  children,
  onDisplayTooltip,
  tooltipInteraction = 'hover',
  tooltipSize = 'xs',
}: Props) => {
  const tooltip = useTooltip({
    ariaLabel,
    onDisplayTooltip,
    tooltipInteraction,
    tooltipSize,
  });

  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
};

export default Tooltip;
