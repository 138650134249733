import React from 'react';
import { Button } from 'shared/bootstrap';
import Remote from 'types/Remote';
import { DeliveryRequestItem } from './types';
import StreamlineIcon from './StreamlineIcon';

interface Props extends DeliveryRequestItem {
  disabled?: boolean;
  onDelete: () => void;
}

const DeleteButton = (props: {
  onClick: () => void;
  status: Remote<boolean>;
}) => {
  switch (props.status.type) {
    case 'not_asked':
      return (
        <Button onClick={props.onClick} className="tw-text-red-600">
          <StreamlineIcon
            icon="Bin"
            className="tw-h-4 tw-w-4"
            strokeWidth={1.5}
          />
        </Button>
      );
    case 'pending':
      return (
        <Button disabled onClick={props.onClick} className="tw-text-red-600">
          <StreamlineIcon
            icon="Bin"
            className="tw-h-4 tw-w-4"
            strokeWidth={1.5}
          />
        </Button>
      );
    default:
      return <></>;
  }
};

const DeliveryItem = (props: Props) => {
  const isDisabled = !!props.disabled;
  return (
    <li className="tw-space-y-2 tw-p-3" data-testid="delivery-item">
      <div className="tw-flex tw-items-center tw-gap-3">
        <StreamlineIcon
          icon="Common File Text Check"
          className="tw-h-5 tw-w-5 tw-text-floryn-green"
          strokeWidth={1.5}
        />
        <div className="tw-flex-1">
          {props.url ? (
            <a href={props.url} target="_blank">
              {props.filename}
            </a>
          ) : (
            <span>{props.filename}</span>
          )}
        </div>
        {isDisabled || (
          <DeleteButton
            status={props.deletionStatus}
            onClick={props.onDelete}
          />
        )}
      </div>
    </li>
  );
};

export default DeliveryItem;
