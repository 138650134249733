import React from 'react';
import { Button, Modal } from 'shared/bootstrap';
import { label } from 'shared/utils/I18n';
import CheckboxToggleInput from 'hookForm/CheckboxToggleInput';
import * as I18n from 'shared/utils/I18n';
import ServerError from 'shared/components/ServerError';
import { User } from 'types';
import useChangeUserPermissionsForm from './useChangeUserPermissionsForm';

interface Props {
  afterSavePermissions: (user: User) => void;
  onHide: () => void;
  user: User;
}

const ChangeUserPermissionsForm = ({
  afterSavePermissions,
  onHide,
  user,
}: Props) => {
  const { control, serverError, submit } = useChangeUserPermissionsForm({
    afterSavePermissions,
    user,
  });

  return (
    <form onSubmit={submit} className="ChangeUserPermissionsForm">
      <Modal.Body>
        {serverError && <ServerError />}
        <table>
          <tbody>
            <tr>
              <td>
                <CheckboxToggleInput
                  control={control}
                  label={label('user', 'canWithdraw')}
                  name="canWithdraw"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>
          {I18n.nt('charlie.users.change_permissions', 'cancel')}
        </Button>
        <Button type="submit" className="btn-primary">
          {I18n.nt('charlie.users.change_permissions', 'confirm')}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default ChangeUserPermissionsForm;
