import { useForm } from 'react-hook-form';
import { validateEmail, validatePresence } from 'shared/utils/validation';
import resolver from 'hookForm/resolver';

export interface FormValues {
  email: string;
  password: string;
}

const validate = ({ email, password }: FormValues) => ({
  email: validateEmail(email),
  password: validatePresence(password),
});

const useLoginForm = (login: (values: FormValues) => void) => {
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: resolver(validate),
  });

  const submit = handleSubmit(login);

  return { control, submit };
};

export default useLoginForm;
