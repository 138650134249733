import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'shared/bootstrap';
import { Link } from 'react-router-dom';
import { compilePath } from 'shared/utils/routing';
import * as I18n from 'shared/utils/I18n';
import * as routes from 'app/routes';

const i18nNamespace = 'app.invoices.no_invoices_alert';

const NoInvoicesAlert = ({ selectedStatusFilter }) => (
  <Alert type="info" className="primary" style={{ marginTop: '16px' }}>
    {selectedStatusFilter === 'all'
      ? I18n.nt(i18nNamespace, 'no-invoices')
      : I18n.nt(i18nNamespace, 'no-invoices-status')}{' '}
    <Link to={compilePath(routes.INVOICE, { id: 'new' })}>
      {I18n.nt(i18nNamespace, 'upload-invoice')}
    </Link>
  </Alert>
);

NoInvoicesAlert.propTypes = {
  selectedStatusFilter: PropTypes.string,
};

export default NoInvoicesAlert;
