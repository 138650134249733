import React from 'react';

import * as I18n from 'shared/utils/I18n';
import LoadingAnimation from 'shared/components/LoadingAnimation';

import { LoginStatus as LoginStatusType } from 'PartnerSignup/util';

const ns = 'app.partner_signup';

interface Props {
  loginStatus: LoginStatusType;
}

const LoginStatus = ({ loginStatus }: Props) => {
  switch (loginStatus) {
    case 'error':
      return <div className="text-danger">{I18n.nt(ns, 'error')}</div>;
    case 'expired_token':
      return <div className="text-danger">{I18n.nt(ns, 'expired_token')}</div>;
    case 'invalid_token':
      return <div className="text-danger">{I18n.nt(ns, 'invalid_token')}</div>;
    case 'loading':
      return <LoadingAnimation />;
    default:
      return null;
  }
};

export default LoginStatus;
