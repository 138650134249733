import React from 'react';
import { Alert } from 'shared/components/tailwind/Alert';

interface Props {
  className?: string;
  generalErrors: string[];
}

const GeneralErrors = ({ className, generalErrors }: Props) => {
  if (!generalErrors.length) {
    return null;
  }

  return (
    <div className={className}>
      <Alert type="danger">
        <ul className="tw-ml-0 tw-list-none">
          {generalErrors.map((error, index) => (
            <li key={`error-${index}`}>{error}</li>
          ))}
        </ul>
      </Alert>
    </div>
  );
};

export default GeneralErrors;
