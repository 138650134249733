export type InstantPaymentAvailability =
  | { type: 'not_enabled' }
  | { type: 'enabled'; amount: number };

export function notEnabled(): InstantPaymentAvailability {
  return { type: 'not_enabled' };
}

export function enabled(amount: number): InstantPaymentAvailability {
  return { type: 'enabled', amount };
}

export function isEnabled(
  instantPaymentAvailability: InstantPaymentAvailability
): boolean {
  return instantPaymentAvailability.type === 'enabled';
}

export function enabledWithinDayLimit(
  instantPaymentAvailability: InstantPaymentAvailability,
  amount: number
) {
  return (
    instantPaymentAvailability.type === 'enabled' &&
    amount <= instantPaymentAvailability.amount
  );
}

export function enabledBeyondDayLimit(
  instantPaymentAvailability: InstantPaymentAvailability,
  amount: number
) {
  return (
    instantPaymentAvailability.type === 'enabled' &&
    amount > instantPaymentAvailability.amount
  );
}

export default InstantPaymentAvailability;
