import React from 'react';
import { Glyphicon } from 'shared/bootstrap';
import { CandidateCompany, Company } from 'types';
import CompanyInfo from 'shared/components/CompanyInfo';

interface Props {
  company: CandidateCompany | Company;
  onDeselect: () => void;
}

const CompanyDetails = ({ company, onDeselect }: Props) => (
  <li className="Company tw-block">
    <Glyphicon
      className="deselect tw-p-[16px] tw-text-white"
      glyph="remove-circle"
      onClick={onDeselect}
    />
    <CompanyInfo company={company} />
  </li>
);

export default CompanyDetails;
