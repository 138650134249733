import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { camelize } from 'inflected';
import resolver from 'hookForm/resolver';
import { validatePresence } from 'shared/utils/validation';
import client from 'shared/utils/client';
import useLoginSuccess from './useLoginSuccess';

type OtpErrorStatus =
  | 'error'
  | 'expiredOtp'
  | 'invalidIntermediaryOtp'
  | 'invalidOtp'
  | 'tooManyOtpAttempts';

export type OtpStatus = '' | 'submitting' | OtpErrorStatus;

export interface FormValues {
  otp: string;
}

const validate = ({ otp }: FormValues) => ({
  otp: validatePresence(otp),
});

const useOtp = (intermediaryToken: string) => {
  const handleSuccessfulLogin = useLoginSuccess();

  const { control, handleSubmit, reset } = useForm<FormValues>({
    resolver: resolver(validate),
  });

  const [status, setStatus] = useState<OtpStatus>('');

  const submitOtp = async ({ otp }: FormValues) => {
    if (!intermediaryToken) {
      return;
    }

    setStatus('submitting');

    const response = await client(
      'POST',
      '/api/session_v2/validate_otp',
      {
        otp,
        otpIntermediaryToken: intermediaryToken,
      },
      { raiseError: false }
    );
    const { payload } = response;

    if (response.error) {
      reset();

      if (response.statusCode === 401) {
        setStatus(camelize(payload.name, false) as OtpErrorStatus);
      } else {
        setStatus('error');
      }
    } else {
      handleSuccessfulLogin(response.payload);
    }
  };

  return {
    control,
    submit: handleSubmit(submitOtp),
    status,
  };
};

export default useOtp;
