export const calculatePercentage = (
  availableBalance: number,
  creditLimit: number
): number => {
  if (creditLimit <= 0) {
    return 0;
  } else {
    return availableBalance / creditLimit;
  }
};
