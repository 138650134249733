import { useState } from 'react';
import * as remote from 'types/Remote';
import { nanoid } from 'nanoid';
import { Transfer } from './types';
import Remote from 'types/Remote';

function itemsFromFileList(value: FileList): Transfer[] {
  return Array.from(value).map((f) => ({
    status: remote.notAsked(),
    file: f,
    id: nanoid(),
  }));
}

function updateById(
  coll: Transfer[],
  id: string,
  fun: (t: Transfer) => Transfer
): Transfer[] {
  return coll.map((el) => {
    if (el.id === id) {
      return fun(el);
    } else {
      return el;
    }
  });
}

function addNewById(coll1: Transfer[], coll2: Transfer[]): Transfer[] {
  return coll2.reduce((acc, el) => {
    const exists = acc.some((a) => {
      return a.id === el.id;
    });
    if (!exists) {
      return acc.concat([el]);
    } else {
      return acc;
    }
  }, coll1);
}

function remoteFromResult(result): Remote<boolean> {
  if (result.error) {
    return remote.failureFromError(result);
  } else {
    return remote.success(true);
  }
}

interface Props {
  disabled: boolean;
  onUpload: (file: File) => Promise<Remote<boolean>>;
}

function useFileList(props: Props) {
  const [files, setFiles] = useState<Transfer[]>([]);
  const [dragCounter, setDragCounter] = useState<number>(0);

  const addFiles = async (fileList) => {
    const files = itemsFromFileList(fileList);
    setFiles((currentFiles) => addNewById(currentFiles, files));

    for await (const file of files) {
      setFiles((currentFiles) => {
        return updateById(currentFiles, file.id, (f) => ({
          ...f,
          status: remote.pending(),
        }));
      });

      const newStatus = remoteFromResult(await props.onUpload(file.file));

      setFiles((currentFiles) => {
        return updateById(currentFiles, file.id, (f) => ({
          ...f,
          status: newStatus,
        }));
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.files) {
      addFiles(e.target.files);
    }
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    if (props.disabled) {
      return;
    }
    setDragCounter((n) => n + 1);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setDragCounter((n) => n - 1);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!props.disabled && e.dataTransfer.files) {
      addFiles(e.dataTransfer.files);
    }
    setDragCounter(0);
  };

  const isDragging = dragCounter > 0;

  return {
    isDragging,
    handleChange,
    files,
    dragHandlers: {
      onDragEnter: handleDragEnter,
      onDragLeave: handleDragLeave,
      onDragOver: handleDragOver,
      onDrop: handleDrop,
    },
  };
}

export default useFileList;
