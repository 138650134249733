import React from 'react';
import classNames from 'classnames';
import DeliveryItems from './DeliveryItems';
import useFileList from './useFileList';
import { nt } from 'shared/utils/I18n';
import Remote from 'types/Remote';
import { DeliveryRequestItem } from './types';

const ns = 'form.attachmentsInput';

interface Props {
  disabled: boolean;
  onUpload: (file: File) => Promise<Remote<boolean>>;
  onDelete: (file: DeliveryRequestItem) => void;
  items: DeliveryRequestItem[];
}

const FileList = (props: Props) => {
  const { isDragging, files, dragHandlers, handleChange } = useFileList({
    disabled: props.disabled,
    onUpload: props.onUpload,
  });
  const hasFiles = props.items.length > 0 || files.length > 0;
  return (
    <div className="tw-w-full tw-gap-6 tw-space-y-6 tw-rounded-b-md tw-border-t tw-bg-gray-50 tw-p-6 md:tw-flex md:tw-space-y-0">
      {hasFiles && (
        <div className="tw-flex-1">
          <DeliveryItems
            items={props.items}
            newItems={files}
            onDelete={props.onDelete}
            disabled={props.disabled}
          />
        </div>
      )}
      {!props.disabled && (
        <div
          data-testid="upload-container"
          className={classNames(
            'tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center tw-rounded-lg tw-border-4 tw-p-6',
            {
              'tw-border-dashed': !isDragging,
              'tw-border-solid tw-border-green-200 tw-bg-green-100': isDragging,
            }
          )}
          {...dragHandlers}
        >
          <div className="tw-mb-0 tw-text-center tw-font-heading tw-text-lg tw-font-semibold tw-text-gray-600">
            {nt(ns, 'drop_info_plural')}
          </div>
          <div className="tw-flex tw-flex-row">
            <hr className="tw-border-1 tw-w-36 tw-grow tw-border-solid tw-border-gray-400 tw-align-middle" />
            <h3 className="tw-my-auto tw-px-2 tw-text-lg tw-font-normal tw-text-gray-300">
              {nt(ns, 'or')}
            </h3>
            <hr className="tw-border-1 tw-w-36 tw-grow tw-border-solid tw-border-gray-400 tw-align-middle" />
          </div>
          <label
            className={classNames(
              'btn btn-primary-inverted btn-primary tw-border-2',
              {
                'btn-disabled': props.disabled,
              }
            )}
          >
            <input
              type="file"
              onChange={handleChange}
              multiple={true}
              className="tw-sr-only"
              disabled={props.disabled}
            />
            {nt(ns, 'select_file_plural')}
          </label>
        </div>
      )}
    </div>
  );
};

export default FileList;
