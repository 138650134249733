import { useSelector } from 'react-redux';
import { referrerSelector } from 'shared/selectors';
import { mustDisplayPartnerContactInformation } from 'shared/utils/referrerHelpers';
import { nt } from 'shared/utils/I18n';

const useContactInformation = () => {
  const referrer = useSelector(referrerSelector);
  const displayPartnerContactInformation =
    mustDisplayPartnerContactInformation(referrer);

  return {
    displayPartnerContactInformation,
    phoneLink: displayPartnerContactInformation
      ? nt('app.contact.phone_link', 'partners')
      : nt('app.contact.phone_link', 'default'),

    phoneNumber: displayPartnerContactInformation
      ? nt('app.contact.phone_number', 'partners')
      : nt('app.contact.phone_number', 'default'),
  };
};

export default useContactInformation;
