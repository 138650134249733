import React from 'react';

import { Col, Row } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import InformationField from 'shared/components/InformationField';
import ChangePasswordRequest from 'app/containers/profile/ChangePasswordRequest';
import CompanyInformation from './CompanyInformation';
import ChangeEmailRequest from './ChangeEmailRequest';
import ChangeFlorynInvoiceCcEmail from './ChangeFlorynInvoiceCcEmail';
import TwoFactorSettings from './TwoFactorSettings';
import FinanceAllInvoices from './FinanceAllInvoices';
import ProfileContract from './ProfileContract';
import { isAlfa } from 'models/Seller';
import { AlfaSeller, Seller } from 'types';
import AlfaRates from './AlfaRates';
import useAccountInformation from './useAccountInformation';

const ns = 'app.profilePage.contract';

interface Props {
  sellers: Seller[];
}

const AccountInformation = ({ sellers }: Props) => {
  const { currentSeller, signedContract, user } = useAccountInformation();
  const { fullName, email } = user;

  return (
    <div className="c-account-information">
      <h3>{I18n.nt('app.profilePage', 'profile')}</h3>
      <div className="profile-page-section">
        {sellers.map((seller) => (
          <CompanyInformation key={seller.id} seller={seller} />
        ))}
      </div>

      <div className="profile-page-section">
        <h4>{I18n.nt('app.profile', 'userFields')}</h4>
        <Row>
          <Col lg={4}>
            <InformationField field="full_name" value={fullName} model="user" />
          </Col>
          <Col lg={4}>
            <InformationField field="email" value={email} model="user" />
          </Col>
          <Col lg={4}>
            <InformationField field="phone" value={user.phone} model="user" />
          </Col>
        </Row>
      </div>

      {currentSeller.productVersion === 'alfa' && (
        <AlfaRates seller={currentSeller as AlfaSeller} />
      )}

      <div className="profile-page-section">
        <Row>
          {signedContract ? (
            <Col lg={6}>
              <ProfileContract />
            </Col>
          ) : (
            <Col lg={6} className="tw-my-auto">
              <h4>{I18n.nt(ns, 'title')}</h4>
              <p>{I18n.nt('app.profilePage.contract', 'no_contract_found')}</p>
            </Col>
          )}
          {isAlfa(currentSeller) && (
            <Col lg={6}>
              <FinanceAllInvoices />
            </Col>
          )}
        </Row>
      </div>

      <div className="profile-page-section two-factor">
        <Row>
          <ChangePasswordRequest />
          <TwoFactorSettings
            enabled={user.twoFactorEnabled}
            phone={user.phone}
          />
        </Row>
      </div>

      <div className="profile-page-section">
        <Row>
          <ChangeEmailRequest />
          <ChangeFlorynInvoiceCcEmail />
        </Row>
      </div>
    </div>
  );
};

export default AccountInformation;
