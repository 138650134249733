import React from 'react';
import StatusFilters from './StatusFilters';
import FinancingFilters from './FinancingFilters';
import { invoiceCounts } from 'types/AppState';

interface Props {
  invoiceCounts: invoiceCounts;
  mustDisplayFinancingTitle?: boolean;
  selectedFinancingFilter: string;
  selectedStatusFilter: string;
  selectFinancingFilter: (filter) => (dispatch) => void;
  selectStatusFilter: (filter) => (dispatch) => void;
}

const InvoiceFilters = ({
  invoiceCounts,
  mustDisplayFinancingTitle = true,
  selectedFinancingFilter,
  selectedStatusFilter,
  selectFinancingFilter,
  selectStatusFilter,
}: Props) => {
  return (
    <div className="invoice-filters">
      <StatusFilters
        invoiceCounts={invoiceCounts}
        selectStatusFilter={selectStatusFilter}
        selectedStatusFilter={selectedStatusFilter}
      />

      <FinancingFilters
        mustDisplayTitle={mustDisplayFinancingTitle}
        selectedFinancingFilter={selectedFinancingFilter}
        selectFinancingFilter={selectFinancingFilter}
      />
    </div>
  );
};

export default InvoiceFilters;
