import React from 'react';
import Button from 'shared/components/tailwind/Button';
import Modal from 'shared/components/tailwind/Modal';
import AttachmentsInput from 'hookForm/AttachmentsInput';
import useExampleInvoicesModal from './useExampleInvoicesModal';
import { useCurrentSeller } from 'namespaces/shared/hooks';

interface Props {
  t: (key: string, options?) => string;
  onHide: () => void;
}

const ExampleInvoicesModal = ({ onHide, t }: Props) => {
  const seller = useCurrentSeller();
  const { control, handleSubmit, isSubmittable } =
    useExampleInvoicesModal(onHide);

  return (
    <form className="ExampleInvoicesModal" onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>{t('modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tw-pb-6">{t('modal_intro')}</div>
        <AttachmentsInput
          control={control}
          model="seller"
          modelId={seller.id}
          name="exampleInvoices"
          label={t('title')}
          required={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn-primary" disabled={!isSubmittable}>
          {t('submit')}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default ExampleInvoicesModal;
