import React from 'react';
import { useSelector } from 'react-redux';
import * as I18n from 'shared/utils/I18n';
import { dateFormat } from 'shared/utils/Helpers';
import { BaseReview } from 'types/Review';
import { currentSellerSelector } from 'shared/selectors';
import { MinimalSeller, ReduxState } from 'types';

const ns = 'charlie.frontpage.warnings';

interface Props {
  reviewInfo: BaseReview;
}

const ReviewPeriodMessage = ({ reviewInfo }: Props) => {
  const seller = useSelector(
    (state: ReduxState) => currentSellerSelector(state) as MinimalSeller
  );

  const message = I18n.nt(
    ns,
    seller.hasLoan ? 'review_period_loan' : 'review_period_credit',
    {
      requestedStartDate: dateFormat(reviewInfo.requestedStartDate),
      requestedEndDate: dateFormat(reviewInfo.requestedEndDate),
    }
  );

  return <span>{message}</span>;
};

export default ReviewPeriodMessage;
