import React from 'react';
import { useForm } from 'react-hook-form';

import { Button, Modal } from 'shared/bootstrap';
import AttachmentsInput from 'hookForm/AttachmentsInput';
import CurrencyInput from 'hookForm/CurrencyInput';
import DatePickerInput from 'hookForm/DatePickerInput';
import { label, translate } from 'shared/utils/I18n';
import { Task, validate } from './modules/DebtorList';
import resolver from 'hookForm/resolver';

const t = translate('review_wizard.debtor_list_form');

interface Props {
  reviewId: number;
  sellerId: number;
  show: boolean;
  onHide: () => void;
  onSubmit: (values: Task) => void;
  initialValues: Partial<Task>;
}

export default ({ show, onHide, reviewId, initialValues, onSubmit }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Task>({
    defaultValues: initialValues,
    resolver: resolver(validate),
    mode: 'onSubmit',
  });

  return (
    <Modal show={show} onHide={onHide} data-testid="debtor-list-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('intro')}</p>
          <AttachmentsInput
            hideLabel
            label={label('review', 'debtor_lists')}
            model="review"
            modelId={reviewId}
            name="debtorLists"
            control={control}
          />
          <div className="rw-switcher">
            <DatePickerInput
              name="debtorListDate"
              control={control}
              label={label('review', 'debtor_list_date')}
            />
            <CurrencyInput
              control={control}
              name="debtorListTotal"
              label={label('review', 'debtor_list_total')}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-default" onClick={onHide}>
            {t('close')}
          </Button>
          <Button className="btn-primary" type="submit" disabled={isSubmitting}>
            {t('submit')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
