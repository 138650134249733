import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import InputWrapper from 'hookForm/InputWrapper';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import SearchInput from './SearchInput';
import useCompanyInput from './useCompanyInput';
import CompanyList from './CompanyList';
import CompanyDetails from './CompanyDetails';
import { nt } from 'shared/utils/I18n';
import LoadingError from 'shared/components/LoadingError';
import ErrorMessage from 'hookForm/ErrorMessage';

const ns = 'form.companyInput';

interface Props<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  label: string;
  name: Path<TFieldValues>;
  readOnly?: boolean;
  required?: boolean;
}

const CompanyInput = <TFieldValues extends FieldValues>({
  control,
  label,
  name,
  readOnly,
  required,
}: Props<TFieldValues>) => {
  const {
    error,
    errorMessageId,
    hasFocus,
    handleBlur,
    handleChange,
    handleDeselectCompany,
    handleFocus,
    handleSelectCompany,
    inputId,
    invalid,
    ref,
    searchResult,
    status,
    value,
  } = useCompanyInput(control, name);

  return (
    <InputWrapper
      id={inputId}
      invalid={invalid && !hasFocus}
      label={label}
      name={name}
      required={required}
      type="company"
    >
      <SearchInput
        errorMessageId={errorMessageId}
        invalid={invalid}
        id={inputId}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        readOnly={readOnly}
        inputRef={ref}
      />
      {status === 'searching' && <LoadingAnimation />}
      {status === 'searchDone' && value && (
        <CompanyDetails company={value} onDeselect={handleDeselectCompany} />
      )}
      {status === 'searchDone' && !value && searchResult && (
        <CompanyList
          onSelect={handleSelectCompany}
          searchResult={searchResult}
        />
      )}
      {status === 'noResults' && (
        <div className="no-results">
          {nt(ns, 'noResults')} {nt(ns, 'contactUs')}
        </div>
      )}
      {status === 'error' && <LoadingError />}
      <ErrorMessage error={error} id={errorMessageId} />
    </InputWrapper>
  );
};

export default CompanyInput;
