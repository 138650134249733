import React from 'react';
import * as I18n from 'shared/utils/I18n';
import { Consent, Consents, Remote } from 'types';
import withAisRemote from 'shared/utils/withAisRemote';
import { compact, intersectionBy } from 'lodash';
import { addDays, isAfter, parseISO } from 'date-fns';
import { IbanInfo } from './IbanInfoList';

interface Props {
  requiredIbans: IbanInfo[];
  remoteData: Remote<Consents>;
}

/*
 * Check if a value is at least a given number of days in the future.
 */
const isAtLeastDaysInTheFuture = (date: Date, days: number): boolean => {
  return isAfter(date, addDays(new Date(), days));
};

/*
 * Check if an expiration date is not considered "soon". Soon would the time by
 * which we would start nagging customers to refresh their consents.
 */
const isNotExpiringSoon = (date: Date): boolean => {
  return isAtLeastDaysInTheFuture(date, 14);
};

/*
 * Check if any consent is valid for long enough for us to not start naggin
 * customers yet. If a consent is required and valid for a while the customer
 * might be confused about why it is "required".
 */
const someRequiredConsentNotExpiringSoon = (
  consents: Consent[],
  requiredIbans: IbanInfo[]
) => {
  return compact(
    intersectionBy<Consent, IbanInfo>(consents, requiredIbans, 'iban').map(
      (c) => c.expiresOn
    )
  )
    .map((d) => parseISO(d))
    .some(isNotExpiringSoon);
};

const ns = 'shared.required_ibans';

/*
 * Due to grace periods and a consistent cadence we will ask customers to
 * refresh their PSD2 consents even when they are not expiring soon yet. This
 * might confuse people so we include a note here explaining why we do that.
 */
const EarlyRefreshExplanation = ({ requiredIbans, remoteData }: Props) => {
  if (
    remoteData.type === 'success' &&
    remoteData.response.type === 'combined' &&
    someRequiredConsentNotExpiringSoon(
      remoteData.response.consents,
      requiredIbans
    )
  ) {
    return <p>{I18n.nt(ns, 'early_refresh_explanation')}</p>;
  } else {
    return null;
  }
};

export default withAisRemote(EarlyRefreshExplanation, '/v2/user/consents');
