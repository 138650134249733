import React from 'react';
import { Table } from 'shared/bootstrap';
import classNames from 'classnames';
import { numberFormat } from 'shared/utils/Helpers';
import { translate } from 'shared/utils/I18n';
import Section from './Section';
import { Bucket, Periods } from './TransactionInsights';

const t = translate('insights.bucket_table');

interface CellProps {
  value: number | string;
  cellType: string;
  swatch?: string;
}

const Cell = ({ value, cellType, swatch }: CellProps) => {
  switch (cellType) {
    case 'label':
      if (swatch) {
        return (
          <th scope="row">
            <span className="ti-swatch" style={{ backgroundColor: swatch }} />
            {value}
          </th>
        );
      } else {
        return <th scope="row">{value}</th>;
      }
    case 'percentage':
      return (
        <td className="text-right">
          {numberFormat(value, 0)}
          {t('percentage')}
        </td>
      );
    default:
      return <td className="text-right">{numberFormat(value, 0)}</td>;
  }
};

const cellType = (bucket, columnIndex) => {
  if (columnIndex === 0) {
    return 'label';
  }

  if (columnIndex === 1 && bucket.showPercentage) {
    return 'percentage';
  }

  return 'currency';
};

const PropertyRows = ({ bucket }) => {
  let propertyNames = ['ratioForeign', 'counterpartyCount', 'transactionCount'];

  if (['cash', 'internal'].includes(bucket.key)) {
    propertyNames = ['minDebit', 'maxCredit', 'transactionCount'];
  } else if (['overview', 'revenue'].includes(bucket.key)) {
    propertyNames = [];
  } else if (bucket.key === 'vat') {
    propertyNames = ['totalPaidVat', 'totalExpectedVatDue'];
  } else if (bucket.key === 'vat_expected') {
    propertyNames = [];
  } else if (bucket.key === 'wage_taxes') {
    propertyNames = [
      'paidWageTaxes',
      'expectedPayments',
      'expectedDifferencePaid',
    ];
  }

  return (
    <>
      {propertyNames.map((propertyName) => {
        const property = bucket.properties[propertyName];
        const valueType =
          propertyName === 'ratioForeign' ? 'percentage' : 'number';
        return (
          <tr key={propertyName} className="odd:tw-bg-gray-50">
            <Cell value={property.label} cellType={'label'} />
            <Cell value={property.value} cellType={valueType} />
          </tr>
        );
      })}
    </>
  );
};

interface Props {
  title: string;
  periods: Periods;
  bucket: Bucket;
  swatches?: (string | undefined)[];
}

const BucketTable = ({ title, periods, bucket, swatches }: Props) => {
  return (
    <Section title={title}>
      {!bucket.hasData ? (
        <div className="ti-p-10">{t('no_data')}</div>
      ) : (
        <Table responsive className="ti-bucket-table">
          <thead>
            <tr>
              <th></th>
              {bucket.showPercentage && (
                <th className="text-right">{t('percentage')}</th>
              )}
              <th className="text-right">{t('total')}</th>
              {periods.map((p) => (
                <th key={p} className="text-right">
                  {p}
                </th>
              ))}
              {bucket.showCreditDebit && (
                <>
                  <th className="text-right">{t('total_credit')}</th>
                  <th className="text-right">{t('total_debit')}</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {bucket.rows.map((row, i) => (
              <tr
                key={row[0]}
                className={classNames({
                  'odd:tw-bg-gray-50': true,
                  summary: i === bucket.rows.length - 1,
                })}
              >
                {row.map((col, j) => (
                  <Cell
                    key={j}
                    value={col}
                    cellType={cellType(bucket, j)}
                    swatch={swatches ? swatches[i] : undefined}
                  />
                ))}
              </tr>
            ))}
            <PropertyRows bucket={bucket} />
          </tbody>
        </Table>
      )}
    </Section>
  );
};

export default BucketTable;
