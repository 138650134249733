import { differenceInDays, parseISO } from 'date-fns';

const parseISODate = (dateString) => (dateString ? parseISO(dateString) : null);
const daysBetween = (date1, date2) => differenceInDays(date2, date1);
const getRates = (invoice, seller) => {
  const source = invoice.state === 'draft' ? seller : invoice;
  const {
    advanceRate: advance,
    fixedRate: fixed,
    variableRate: variable,
  } = source;

  return { advance, fixed, variable };
};

const VAT = 0.21;

const today = () => new Date();

const calculateDaysLeftInPaymentPeriod = ({
  dateSent,
  financingDate,
  paymentPeriod,
}) =>
  Math.max(paymentPeriod - daysBetween(dateSent, financingDate || today()), 0);

const calculateDaysOpen = ({
  daysLeftInpaymentPeriod,
  startDate,
  financingFinishedDate,
}) =>
  Math.min(
    daysLeftInpaymentPeriod + 45,
    daysBetween(startDate, financingFinishedDate || today())
  );

const includingVat = (v) => (1 + VAT) * v;

const calculateCosts = ({ amountNotG, daysLeftInpaymentPeriod, rates }) => {
  const calculateCostValues = (costRate, settlementRate) => ({
    costAmount: costRate * amountNotG,
    rate: costRate,
    settlementAmount: (1 - rates.advance - settlementRate) * amountNotG,
    vatAmount: costRate * VAT * amountNotG,
  });

  const calculateRate = (days) => rates.fixed + rates.variable * (days / 30);
  const originalCostRate = calculateRate(daysLeftInpaymentPeriod);
  const advanceAmount =
    (rates.advance - includingVat(originalCostRate)) * amountNotG;

  return {
    advanceAmount,
    ...calculateCostValues(originalCostRate, 0),
  };
};

/* Calculate the rates for the invoice. Also calculate derived numbers like the costs and the advance amount.
  The rate is based on the number of days left in the payment period.

  Example:
  A draft invoice has a payment period of 60 days and is sent 15 days ago. The number of days left is then 45 days.
*/

const costs = (invoice, seller) => {
  const amount = Number(invoice.amount);
  const gAmount = Number(invoice.gAmount);
  const amountNotG = amount - gAmount;

  const dateSent = parseISODate(invoice.dateSent);
  const financingDate = parseISODate(invoice.financingDate);
  const financingFinishedDate = parseISODate(invoice.financingFinishedDate);
  const startDate = financingDate || dateSent;

  const daysLeftInpaymentPeriod = calculateDaysLeftInPaymentPeriod({
    dateSent,
    financingDate,
    paymentPeriod: invoice.paymentPeriod,
  });
  const daysOpen = calculateDaysOpen({
    daysLeftInpaymentPeriod,
    startDate,
    financingFinishedDate,
  });
  const rates = getRates(invoice, seller);

  const costsResult = calculateCosts({
    amountNotG,
    daysLeftInpaymentPeriod,
    daysOpen,
    rates,
  });

  return {
    amount,
    gAmount,
    ...costsResult,
  };
};

export default costs;
