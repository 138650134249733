import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccountStep from 'signup/components/seller/AccountStep';
import AdditionalQuestionsStep from './AdditionalQuestionsStep';
import BankStatementsStep from 'namespaces/signup/components/seller/BankStatementsStep';
import { RouteForSeller } from 'shared/utils/routing';
import {
  SELLER_SIGNUP_ACCOUNT_STEP,
  SELLER_SIGNUP_ADDITIONAL_QUESTIONS_STEP,
  SELLER_SIGNUP_BANK_STATEMENTS_STEP,
  SELLER_SIGNUP_BASE,
  SELLER_SIGNUP_UBO_IDENTIFICATION_STEP,
} from 'app/routes';
import {
  useCanAccessAdditionalQuestionsRoute,
  useCanAccessUboIdentificationRoute,
} from 'shared/hooks/useCanAccessRoutes';
import UboIdentificationStep from 'signup/components/seller/UboIdentificationStep';

const CurrentStep = () => {
  const canAccessAdditionalQuestionsRoute =
    useCanAccessAdditionalQuestionsRoute();
  const canAccessUBOIdentificationRoute = useCanAccessUboIdentificationRoute();

  return (
    <Switch>
      <Route
        path={[SELLER_SIGNUP_BASE, SELLER_SIGNUP_ACCOUNT_STEP]}
        exact
        component={AccountStep}
      />
      <Route
        path={SELLER_SIGNUP_BANK_STATEMENTS_STEP}
        component={BankStatementsStep}
      />
      <RouteForSeller
        path={SELLER_SIGNUP_ADDITIONAL_QUESTIONS_STEP}
        component={AdditionalQuestionsStep}
        condition={() => canAccessAdditionalQuestionsRoute}
      />
      <RouteForSeller
        path={SELLER_SIGNUP_UBO_IDENTIFICATION_STEP}
        component={UboIdentificationStep}
        condition={() => canAccessUBOIdentificationRoute}
      />
      <Route
        path={[SELLER_SIGNUP_BASE, SELLER_SIGNUP_ACCOUNT_STEP]}
        component={AccountStep}
      />
    </Switch>
  );
};

export default CurrentStep;
