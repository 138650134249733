import React from 'react';
import DeliveriesPage from './DeliveriesPage';
import { translate } from 'shared/utils/I18n';

const t = translate('deliveries_page.remote_status');

const RemoteStatus = (props) => {
  switch (props.deliveryRequests.type) {
    case 'not_asked':
    case 'pending':
      return (
        <div
          className="tw-text-center tw-text-lg tw-text-gray-500"
          data-testid="deliveries-status"
        >
          <p>{t('loading')}</p>
        </div>
      );
      break;
    case 'success':
      return (
        <DeliveriesPage
          deliveryRequests={props.deliveryRequests.response}
          onDelete={props.onDelete}
          onUpload={props.onUpload}
        />
      );
      break;
    default:
      return (
        <div
          className="tw-text-center tw-text-red-500"
          data-testid="deliveries-status"
        >
          <p>{t('error')}</p>
        </div>
      );
      break;
  }
};

export default RemoteStatus;
