import React from 'react';
import OnboardingProgress from './OnboardingProgress';
import Support from 'shared/components/DefaultSidebar/Support';

const OnboardingSidebar = () => (
  <>
    <OnboardingProgress />
    <div className="tw-mt-4 md:tw-hidden">
      <Support />
    </div>
  </>
);

export default OnboardingSidebar;
