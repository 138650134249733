import { useContext, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';

import resolver from 'hookForm/resolver';
import { trackFormSubmit } from 'shared/utils/tracker';
import {
  validateEmail,
  validateInclusion,
  validatePresence,
} from 'shared/utils/validation';
import { NewInvoiceContact } from './types';
import { ContactsInputContext } from './ContactsInputContext';

interface FormValues {
  email: string;
  fullName: string;
  locale: string;
}

const validateAddContactForm = ({
  email: emailField,
  fullName,
  locale,
}: FormValues): FormValues => {
  return {
    email: validatePresence(emailField) || validateEmail(emailField),
    fullName: validatePresence(fullName),
    locale: validateInclusion('nl', 'en')(locale),
  };
};

interface Props {
  addContact: (contact: NewInvoiceContact) => void;
}

const useAddNewContact = ({ addContact }: Props) => {
  const contactsInputContext = useContext(ContactsInputContext);
  const { control, getValues, handleSubmit, reset, trigger } =
    useForm<FormValues>({
      defaultValues: {
        email: '',
        fullName: '',
        locale: 'nl',
      },
      resolver: resolver(validateAddContactForm),
    });

  const submitAddContractForm = ({ email, fullName, locale }: FormValues) => {
    trackFormSubmit('contacts-input');

    const contact: NewInvoiceContact = {
      email,
      entityKind: 'NewInvoiceContact',
      fullName,
      id: nanoid(),
      locale,
    };
    addContact(contact);

    reset();
  };

  const submit = handleSubmit(submitAddContractForm);

  const handleSubmitOfParentForm = useCallback(async () => {
    const { email, fullName } = getValues();

    if (!(email?.trim() || fullName?.trim())) {
      return true;
    }

    const valid = await trigger();
    if (!valid) {
      return false;
    }

    await submit();
    return true;
  }, [submit]);

  useEffect(() => {
    contactsInputContext?.addOnSubmit(handleSubmitOfParentForm);

    return () => {
      contactsInputContext?.removeOnSubmit(handleSubmitOfParentForm);
    };
  }, [submit]);

  return {
    control,
    submit,
  };
};

export default useAddNewContact;
