import React from 'react';

import { Modal } from 'shared/bootstrap';
import { UltimateBeneficialOwner } from 'types';
import PersonaliaStep from './PersonaliaStep';
import InvitationStep from './InvitationStep';
import { Action } from '../useUltimateBeneficialOwners';

interface Props {
  addUboToState: (ubo: UltimateBeneficialOwner) => void;
  currentAction: Action;
  onHide: () => void;
  removeUboFromState: (ubo: UltimateBeneficialOwner) => void;
  resetActionAndUbo: () => void;
  show: boolean;
  ubo: UltimateBeneficialOwner | null;
  updateUboInState: (ubo: UltimateBeneficialOwner) => void;
}

export default ({
  addUboToState,
  currentAction,
  onHide,
  resetActionAndUbo,
  show,
  ubo,
  updateUboInState,
  removeUboFromState,
}: Props) => (
  <Modal className="UboForm" show={show} onHide={onHide}>
    {currentAction !== 'invite' && (
      <PersonaliaStep
        addUboToState={addUboToState}
        ubo={ubo}
        updateUboInState={updateUboInState}
        removeUboFromState={removeUboFromState}
      />
    )}
    {currentAction === 'invite' && ubo && (
      <InvitationStep
        ubo={ubo}
        updateUboInState={updateUboInState}
        resetActionAndUbo={resetActionAndUbo}
      />
    )}
  </Modal>
);
