import React, { forwardRef, HTMLProps } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import { useTooltipContext } from './context';

const TooltipTrigger = forwardRef<
  HTMLElement,
  HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      })
    );
  }

  return (
    <button
      aria-label={context.ariaLabel}
      className="tw-leading-none"
      ref={ref}
      data-state={context.open ? 'open' : 'closed'}
      onClick={(e) => e.preventDefault()}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});

export default TooltipTrigger;
