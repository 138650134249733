import React from 'react';
import { Modal, Button } from 'shared/bootstrap';
import PropTypes from 'prop-types';
import * as I18n from 'shared/utils/I18n';
import * as Helpers from 'shared/utils/Helpers';

const t = (...translation) =>
  I18n.nt('app.invoiceSummary.invoiceActions', ...translation);

class InvoiceActions extends React.Component {
  state = {
    open: false,
  };

  static propTypes = {
    company: PropTypes.object.isRequired,
    invoice: PropTypes.object.isRequired,
    onInvoiceAction: PropTypes.func,
    onRequestFinancing: PropTypes.func.isRequired,
    performingAction: PropTypes.bool.isRequired,
    seller: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.invoice.state !== 'paid' &&
      this.props.invoice.state === 'paid'
    ) {
      this.setState({ open: false });
    }
  }

  onWithdraw = (e) => {
    e.preventDefault();

    const { onInvoiceAction } = this.props;

    if (!confirm(t('confirmWithdraw'))) return;

    onInvoiceAction('withdraw');
  };

  onToggleModal = () =>
    this.setState((state) => ({
      open: !state.open,
    }));

  onRequestFinancing = () => {
    const {
      seller: { sendInvoices },
      invoice: { invoiceContacts },
    } = this.props;

    const messageKey =
      sendInvoices && invoiceContacts.length > 0
        ? 'confirmRequestFinancing.withEmail'
        : 'confirmRequestFinancing.withoutEmail';

    if (confirm(t(messageKey))) {
      this.props.onRequestFinancing();
    }
  };

  getRequestPaymentOfCostsText(requestedPayment) {
    const {
      company: { name: companyName },
      seller: { clientBankAccount },
    } = this.props;

    const interpolations = {
      account: clientBankAccount,
      amount: Helpers.toEuro(requestedPayment),
      companyName: companyName,
    };

    if (clientBankAccount) {
      return t('requestPaymentOfCostsClientAccount', interpolations);
    } else {
      return t('requestPaymentOfCostsGeneralAccount', interpolations);
    }
  }

  renderModal() {
    const { invoice, onInvoiceAction, performingAction } = this.props;
    const requestedPayment = Number(invoice.estimatedCosts);

    return (
      <Modal
        show={this.state.open}
        onHide={this.onToggleModal}
        className="invoice-actions-modal"
      >
        <Modal.Header>
          <h3 className="modal-title">{t('indirectlyPaid')}</h3>
        </Modal.Header>
        <Modal.Body>
          <p>{t('invoicePaid')}</p>

          {requestedPayment > 0 && (
            <p>{this.getRequestPaymentOfCostsText(requestedPayment)}</p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="buttons">
            <Button
              className="btn-light"
              disabled={performingAction}
              onClick={this.onToggleModal}
            >
              {t('cancel')}
            </Button>
            <Button
              className="btn-primary"
              disabled={performingAction}
              onClick={() => onInvoiceAction('process_paid')}
            >
              {t('submit')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { invoice } = this.props;
    const allowedActionsSet = new Set(invoice.allowedActions);

    return (
      <div className="Sidebars">
        {this.renderModal()}

        {allowedActionsSet.size !== 0 && (
          <div className="InvoiceActions">
            <ul>
              {allowedActionsSet.has('process_paid') && (
                <li>
                  <a onClick={this.onToggleModal} className="indirectly-paid">
                    <img src="/images/indirectbetaald.svg" />
                    <strong>{t('indirectlyPaid')}</strong>
                  </a>
                </li>
              )}

              {allowedActionsSet.has('withdraw') && (
                <li>
                  <a onClick={this.onWithdraw} className="withdraw" href="#">
                    <img src="/images/delete_blue.svg" />
                    <strong>{t('remove')}</strong>
                  </a>
                </li>
              )}

              {allowedActionsSet.has('request_financing') && (
                <li>
                  <a
                    onClick={this.onRequestFinancing}
                    className="request-financing"
                  >
                    <img src="/images/financieringaanvragen.svg" />
                    <strong>{t('requestFinancing')}</strong>
                  </a>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default InvoiceActions;
