import { useSelector } from 'react-redux';
import {
  currentSellerSelector,
  customerAccountSelector,
} from 'shared/selectors';
import { isSignupCompleted } from 'models/CustomerAccount';

export const useCanAccessSignupRoute = () => {
  const seller = useSelector(currentSellerSelector);
  const customerAccount = useSelector(customerAccountSelector);

  if (!seller || !customerAccount) {
    return false;
  }

  switch (seller.status) {
    case 'draft':
      return true;

    case 'submitted':
      // The customer can access the additional questions or ubo identification steps if the signup is not completed.
      return !isSignupCompleted(customerAccount);

    default:
      return false;
  }
};

export const useCanAccessAdditionalQuestionsRoute = () => {
  const seller = useSelector(currentSellerSelector);
  const customerAccount = useSelector(customerAccountSelector);

  if (!seller || !customerAccount) {
    return false;
  }

  switch (seller.status) {
    case 'draft':
      // Draft sellers cannot access the additional questions step.
      return false;

    case 'submitted':
      // The customer can access the additional questions step if the step is not completed.
      return !isSignupCompleted(customerAccount);

    default:
      return false;
  }
};

export const useCanAccessUboIdentificationRoute = () => {
  const seller = useSelector(currentSellerSelector);
  const customerAccount = useSelector(customerAccountSelector);

  if (!seller || !customerAccount) {
    return false;
  }

  switch (seller.status) {
    case 'draft':
      // Draft sellers cannot access the additional questions step.
      return false;

    case 'submitted':
      // The customer can access the ubo identification step if the step is not completed.
      return !isSignupCompleted(customerAccount);

    default:
      return false;
  }
};

export const useCanAccessOnboardingRoute = () => {
  const seller = useSelector(currentSellerSelector);
  const customerAccount = useSelector(customerAccountSelector);

  if (!seller || !customerAccount) {
    return false;
  }

  switch (seller.status) {
    case 'draft':
      return false;

    case 'submitted':
      // The customer can access the onboarding if the signup is completed.
      return isSignupCompleted(customerAccount);

    default:
      return false;
  }
};
