export function monthsBefore(date1: Date, n: number): Date {
  const date2 = new Date(date1.getTime());
  date2.setMonth(date1.getMonth() - n);
  date2.setDate(1);
  return date2;
}

export function monthsAgo(n: number): Date {
  return monthsBefore(new Date(), n);
}

export function toUTC(date: Date): number {
  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
}

export function days(n = 1) {
  return hours(24) * n;
}

export function hours(n = 1) {
  return minutes(60) * n;
}

export function minutes(n = 1) {
  return seconds(60) * n;
}

export function seconds(n = 1) {
  return 1000 * n;
}

export function toISODateString(date: Date): string {
  return date.toISOString().substring(0, 10);
}

/*
 * Build an array of dates, one for each date between two given dates.
 */
export function fillDates(from: Date, to: Date): Date[] {
  const output: number[] = [];
  const utcTo = toUTC(to);
  let current = toUTC(from);
  while (current <= utcTo) {
    output.push(current);
    current += days(1);
  }
  return output.map((d) => new Date(d));
}

export { dateFormat } from 'shared/utils/Helpers';
