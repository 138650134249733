import React, { useState } from 'react';
import * as I18n from 'shared/utils/I18n';
import { trackEvent } from 'shared/utils/tracker';
import { isAndroidOrIOS } from 'shared/utils/Helpers';
import PdfPopup from './PdfPopup';

const ns = 'shared.psd2_banks.other_bank_button';

interface Props {
  enabled: boolean;
  selectPdfUpload: () => void;
}

const OtherBankButton = ({ enabled, selectPdfUpload }: Props) => {
  const [pdfPopup, setPdfPopup] = useState<boolean>(false);

  const handleClickOtherBank = (e) => {
    e.preventDefault();

    if (enabled) {
      if (isAndroidOrIOS()) {
        trackEvent('Mobile pdf warning: display');
        setPdfPopup(true);
      } else {
        selectPdfUpload();
      }
    }
  };

  const handlePopupClose = () => {
    trackEvent('Mobile pdf warning: go back');
    setPdfPopup(false);
  };

  const handleSelectPdfUpload = () => {
    trackEvent('Mobile pdf warning: continue');
    selectPdfUpload();
  };

  return (
    <>
      <div className="Bank tw-mt-6">
        <div className="OtherBank__lines">
          <div>
            <strong>{I18n.nt(ns, 'title')}</strong>
          </div>
          <div>
            <a
              onClick={handleClickOtherBank}
              className="tw-text-black tw-underline"
              href="#"
            >
              {I18n.nt(ns, 'message')}
            </a>
          </div>
        </div>
      </div>
      <PdfPopup
        onContinue={handleSelectPdfUpload}
        onHide={handlePopupClose}
        show={pdfPopup}
      />
    </>
  );
};

export default OtherBankButton;
