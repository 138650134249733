import { asyncAction } from 'shared/utils/Helpers';
import { createLoadReducer } from 'shared/utils/reducers';
import { loadAction } from 'shared/actionCreators';
import { currentSellerSelector } from 'shared/selectors';
import { MinimalSeller } from 'types';

const LOAD_SELLER = asyncAction('floryn.general.LOAD_SELLER');

export const loadSellerReducer = createLoadReducer(LOAD_SELLER);

export const loadSeller = () => (dispatch, getState) => {
  const sellerId = (currentSellerSelector(getState()) as MinimalSeller).id;

  return loadAction({
    type: LOAD_SELLER,
    normalize: true,
    url: `/api/sellers/${sellerId}`,
  })(dispatch);
};
