import React from 'react';
import { BaseReview } from 'types/Review';
import { BaseSeller } from 'types';
import { ReviewStatusForProduct } from 'charlie/components/ReactivationModal/useReviewStatusForProduct';
import { dateFormat } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import { isAlfa, isDelta } from 'models/Seller';

const ns = 'charlie.frontpage.warnings';

interface Props {
  reviewInfo: BaseReview;
  seller: BaseSeller;
  status: ReviewStatusForProduct;
}

const ReviewMessage = ({ reviewInfo, seller, status }: Props) => {
  switch (status) {
    case 'finished':
      return null;

    case 'open':
      return I18n.nt(
        ns,
        seller.hasLoan ? 'review_alert_loan' : 'review_alert_credit',
        {
          requestedStartDate: dateFormat(reviewInfo.requestedStartDate),
          requestedEndDate: dateFormat(reviewInfo.requestedEndDate),
        }
      );

    case 'received':
      return I18n.nt(ns, 'received');

    case 'incomplete':
      return I18n.nt(ns, 'incomplete');

    case 'expired': {
      const requestedStartDate = dateFormat(reviewInfo.requestedStartDate);
      const requestedEndDate = dateFormat(reviewInfo.requestedEndDate);

      return (
        <div>
          {I18n.nt(ns, isAlfa(seller) ? 'expired_1_alfa' : 'expired_1_charlie')}{' '}
          {I18n.nt(ns, 'expired_2', { requestedStartDate, requestedEndDate })}
        </div>
      );
    }

    case 'expiredAndReceived':
      return I18n.nt(ns, isDelta(seller) ? 'received' : 'expired_and_received');

    default:
      return null;
  }
};

export default ReviewMessage;
