import React, { ReactNode } from 'react';
import InputWrapper from './InputWrapper';
import classNames from 'classnames';

interface Props {
  children: ReactNode;
  hideLabel?: boolean;
  horizontal?: boolean;
  label: string;
  name?: string;
  required?: boolean;
  hint?: string;
}

const RadioButtons = ({
  hint,
  children,
  hideLabel,
  horizontal,
  label,
  name,
  required,
}: Props) => (
  <InputWrapper
    hideLabel={hideLabel}
    label={label}
    name={name}
    required={required}
    type="radio"
  >
    {hint && <p>{hint}</p>}
    <div className={classNames('radiobuttons', horizontal && 'horizontal')}>
      {children}
    </div>
  </InputWrapper>
);

export default RadioButtons;
