import client from 'shared/utils/client';

export const loadAction =
  ({ type, url, normalize = false, ...rest }) =>
  (dispatch) => {
    dispatch({ type: type.REQUEST, ...rest });

    return client('GET', url).then(
      (response) =>
        dispatch({ type: type.SUCCESS, normalize, ...rest, ...response }),
      (error) => dispatch({ type: type.FAILURE, error: true, ...error })
    );
  };
