import React, { InputHTMLAttributes, RefObject, useState } from 'react';
import { isUndefined } from 'lodash';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { generateHtmlElementId } from 'shared/utils/Helpers';
import ErrorMessage from './ErrorMessage';
import InputWrapper from './InputWrapper';
import useSharedRef from './useSharedRef';

interface Props<TFieldValues extends FieldValues>
  extends InputHTMLAttributes<HTMLInputElement> {
  action?: {
    fn?: () => void;
    hint?: string;
    text?: string;
  };
  addOn?: string;
  control: Control<TFieldValues>;
  hideLabel?: boolean;
  hint?: string;
  includeLayoutClass?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  label: string;
  name: Path<TFieldValues>;
}

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const AddOnWrapper = ({ addOn, children }) => (
  <div className="input-group">
    <div className="input-group-addon">{addOn}</div>
    {children}
  </div>
);

const TextInput = <TFieldValues extends FieldValues>({
  action,
  addOn,
  control,
  hideLabel,
  hint,
  includeLayoutClass = true,
  inputRef,
  label,
  name,
  required,
  type,
  ...inputAttributes
}: Props<TFieldValues>) => {
  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error, invalid },
  } = useController<TFieldValues>({ control, name });

  const activeRef = useSharedRef(ref, inputRef);

  const [id] = useState(generateHtmlElementId);
  const [errorMessageId] = useState(generateHtmlElementId);
  const [hasFocus, setHasFocus] = useState(false);

  const handleBlur = () => {
    setHasFocus(false);
    onBlur();
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  return (
    <InputWrapper
      action={action}
      id={id}
      invalid={Boolean(error) && !hasFocus}
      hideLabel={hideLabel}
      hint={hint}
      includeLayoutClass={includeLayoutClass}
      label={label}
      name={name}
      required={required}
      type={type}
    >
      <ConditionalWrapper
        condition={addOn}
        wrapper={(children) => (
          <AddOnWrapper addOn={addOn}>{children}</AddOnWrapper>
        )}
      >
        <input
          aria-errormessage={invalid ? errorMessageId : undefined}
          aria-invalid={invalid ? true : undefined}
          className="form-control"
          id={id}
          name={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={onChange}
          ref={activeRef}
          required={required}
          type={type}
          value={isUndefined(value) ? '' : value}
          {...inputAttributes}
        />
      </ConditionalWrapper>
      <ErrorMessage error={error} id={errorMessageId} />
    </InputWrapper>
  );
};

export default TextInput;
