import React from 'react';
import { Button } from 'shared/bootstrap';

import { nt } from 'shared/utils/I18n';
import LoadingError from 'shared/components/LoadingError';
import useNotConnected from './useNotConnected';

const ns = 'app.profilePage.exact_online_connection.not_connected';

const NotConnected = () => {
  const { redirectToAuthorisationPage, status } = useNotConnected();

  return (
    <>
      <div className="intro">
        <div>{nt(ns, 'intro')}</div>
        <ul>
          <li>{nt(ns, 'bullet1')}</li>
          <li>{nt(ns, 'bullet2')}</li>
        </ul>
      </div>
      <div className="not-connected">
        {status === 'error' && <LoadingError />}
        <Button
          className="btn-default"
          onClick={redirectToAuthorisationPage}
          disabled={status === 'loading'}
        >
          <div>
            <img src="/images/exact-small.svg" />
          </div>
          <div>{nt(ns, 'create_connection')}</div>
        </Button>
      </div>
    </>
  );
};

export default NotConnected;
