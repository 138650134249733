import React, { useRef } from 'react';
import { Alert, Button, Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import TextInput from 'hookForm/TextInput';
import SignProcessDescription from './SignProcessDescription';
import useMandateStart from 'signup/components/seller/useMandateStart';
import LoadingAnimation from 'shared/components/LoadingAnimation';

const ns = 'app.profile.onboardingStep.modals.mandateStart';

interface Props {
  onClose: () => void;
}

const MandateStart = ({ onClose }: Props) => {
  const { control, handleSubmit, isSubmitting, isValid, submitStatus, watch } =
    useMandateStart();
  const ibanValue = watch('iban');

  const ibanRef = useRef<HTMLInputElement>(null);

  const description = () => {
    if (submitStatus === 'loading') {
      return <LoadingAnimation />;
    } else if (submitStatus === 'error') {
      return <Alert type="danger">{I18n.nt(ns, 'twikey_error')}</Alert>;
    } else if (isValid) {
      return <SignProcessDescription iban={ibanValue} />;
    }
    return null;
  };

  return (
    <Modal
      className="MandateStart"
      initialFocus={ibanRef}
      onHide={onClose}
      show
    >
      <form onSubmit={handleSubmit} autoComplete="off">
        <Modal.Header closeButton>
          <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            autoFocus
            control={control}
            inputRef={ibanRef}
            label={I18n.nt(ns, 'iban_description')}
            name="iban"
          />
          {description()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-primary"
            disabled={isSubmitting || !isValid}
            type="submit"
          >
            {I18n.nt(ns, 'submit')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default MandateStart;
