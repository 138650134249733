import React, { FocusEvent, RefObject } from 'react';
import useBaseCurrencyInput from './useBaseCurrencyInput';

interface Props {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  errorMessageId?: string;
  id?: string;
  inputRef?: RefObject<HTMLInputElement>;
  invalid?: boolean;
  maxLength?: number;
  name: string;
  numberOfDecimals?: number;
  onBlur?: (value: number | null) => void;
  onChange: (value: number | null) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  value?: string | number;
}

const BaseCurrencyInput = ({
  autoFocus,
  className,
  disabled,
  errorMessageId,
  id,
  inputRef,
  invalid,
  maxLength,
  name,
  numberOfDecimals,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  readOnly,
  value,
}: Props) => {
  const {
    displayValue,
    handleBlur,
    handleChange,
    handleFocus,
    handleKeyPress,
  } = useBaseCurrencyInput({
    formatOptions: {
      displayCurrenySymbol: false,
      numberOfDecimals: numberOfDecimals ?? 2,
    },
    onBlur,
    onChange,
    onFocus,
    value,
  });

  return (
    <div className="input-group">
      <div className="input-group-addon">€</div>
      <input
        aria-errormessage={invalid ? errorMessageId : undefined}
        aria-invalid={invalid ? true : undefined}
        autoFocus={autoFocus}
        className={className}
        disabled={disabled}
        id={id}
        inputMode="decimal"
        maxLength={maxLength}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={inputRef}
        type="text"
        value={displayValue}
      />
    </div>
  );
};

export default BaseCurrencyInput;
