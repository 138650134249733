import React from 'react';
import { Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { formatIban } from 'shared/utils/Helpers';
import { usesClientBankAccount } from 'charlie/model';
import { toEuro } from 'shared/utils/Helpers';
import useRepayment from './useRepayment';
import LoadingAnimation from 'shared/components/LoadingAnimation';

const ns = 'delta.frontpage.repayment_modal';

interface Props {
  parentPath: string;
  amountInEuros: number;
}

const DeltaRepaymentModal = ({ parentPath, amountInEuros }: Props) => {
  const { florynIban, transferReference, close, loadingStatus, seller } =
    useRepayment(parentPath);

  return (
    <Modal show onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'pay_back_arrear')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tw-pb-8">
          <>
            <div className="tw-mb-5">
              {I18n.nt(ns, 'liable_message', {
                liableAmount: toEuro(amountInEuros, 2),
              })}
            </div>
            <div className="tw-mb-5 tw-rounded-sm tw-border-2 tw-border-[#E3E3E3] tw-bg-[#F5F5F5] tw-p-3 sm:tw-p-5">
              <dl className="tw-mb-0 tw-flex tw-flex-wrap">
                <dt className="tw-w-[37%] tw-font-normal sm:tw-w-[22%]">
                  {I18n.nt(ns, 'iban')}:
                </dt>
                <dd className="tw-w-[63%]	tw-font-bold sm:tw-w-[78%]">
                  {formatIban(
                    usesClientBankAccount(seller)
                      ? seller.clientBankAccount
                      : florynIban
                  )}
                </dd>
                <dt className="tw-w-[37%] tw-font-normal sm:tw-w-[22%]">
                  {I18n.nt(ns, 'to_the_attention_of')}:
                </dt>
                <dd className="tw-w-[63%]	tw-font-bold sm:tw-w-[78%]">
                  {usesClientBankAccount(seller)
                    ? seller.companyName
                    : I18n.nt(ns, 'foundation_floryn_payments')}
                </dd>
                <dt className="tw-w-[37%] tw-font-normal sm:tw-w-[22%]">
                  {I18n.nt(ns, 'description')}:
                </dt>
                <dd className="tw-w-[63%]	tw-font-bold sm:tw-w-[78%]">
                  {I18n.nt(ns, 'reference', { reference: transferReference })}
                </dd>
              </dl>
            </div>
            <div>
              <div>{I18n.nt(ns, 'pay_description')}</div>
            </div>
          </>
          <div>{loadingStatus === 'loading' && <LoadingAnimation />}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeltaRepaymentModal;
