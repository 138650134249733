import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  children: ReactNode;
  className?: string;
}

const Well = ({ children, className }: Props) => (
  <div className={classNames('well', className)}>{children}</div>
);

export default Well;
