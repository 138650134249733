import React from 'react';
import { Link } from 'react-router-dom';
import * as I18n from 'shared/utils/I18n';
import * as routes from 'app/routes';

const ns = 'signup.onboarding.financial_dashboard.onboarding_link';

const OnboardingLink = () => (
  <div className="OnboardingLink">
    <div className="OnboardingLinkContainer">
      <h5>{I18n.nt(ns, 'title')}</h5>
      <Link to={routes.SELLER_ONBOARDING} className="btn btn-primary-inverted">
        {I18n.nt(ns, 'finish_submission')}
      </Link>
    </div>
    <div className="OnboardingLinkImage">
      <Link to={routes.SELLER_ONBOARDING}>
        <img src="/images/onboarding.png" />
      </Link>
    </div>
  </div>
);

export default OnboardingLink;
