import {
  validateAll,
  validateAmountGreaterThan,
  validateGreaterThanOrEqualTo,
  validateAmountLessThan,
  validateNumericality,
  validatePresence,
} from 'shared/utils/validation';

const MAX_AMOUNT = 100_000_000;
export const MINIMUM_EXPECTED_REVENUE_AMOUNT = 100_000;

export const validateAmount = (value: string | null) =>
  validateAll(
    validatePresence,
    validateNumericality,
    validateAmountGreaterThan(0),
    validateAmountLessThan(MAX_AMOUNT)
  )(value);

export const validateExpectedRevenueAmount = (value: string | null) =>
  validateAll(
    validateAmount,
    validateGreaterThanOrEqualTo(MINIMUM_EXPECTED_REVENUE_AMOUNT)
  )(value);
