import React, { useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

import {
  DateStringPicker,
  dateToString,
} from 'shared/components/DateStringPicker';
import { generateHtmlElementId } from 'shared/utils/Helpers';
import InputWrapper from './InputWrapper';
import Icon from 'hookForm/Icon';

interface Props<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  hint?: string;
  label: string;
  maxDate?: Date;
  name: Path<TFieldValues>;
  readOnly?: boolean;
}

export default <TFieldValues extends FieldValues>({
  control,
  hint,
  label,
  maxDate,
  name,
  readOnly,
}: Props<TFieldValues>) => {
  const {
    field: { onBlur, onChange, value },
    fieldState: { error },
  } = useController<TFieldValues>({ control, name });
  const [id] = useState(generateHtmlElementId);
  const [hasFocus, setHasFocus] = useState(false);

  const handleFocus = () => setHasFocus(true);
  const handleBlur = () => {
    setHasFocus(false);
    onBlur();
  };

  return (
    <div className="DateRangePicker">
      <InputWrapper
        hint={hint}
        id={id}
        invalid={Boolean(error) && !hasFocus}
        label={label}
        name={name}
      >
        <div className="tw-flex">
          <Icon name="calendar" />
          <DateStringPicker
            className="form-control tw-rounded-bl-none tw-rounded-tl-none"
            dateFormat="dd-MM-yyyy"
            disabled={readOnly}
            disabledKeyboardNavigation
            id={id}
            maxDate={maxDate ? dateToString(maxDate) : undefined}
            onBlur={handleBlur}
            onChange={onChange}
            onFocus={handleFocus}
            selected={value}
            selectsStart
          />
        </div>
      </InputWrapper>
    </div>
  );
};
