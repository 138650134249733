import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Activation from 'app/components/guest/Activation';
import * as routes from 'app/routes';
import NavigationBar from 'app/components/NavigationBar';
import EmailChangeConfirmation from 'app/components/guest/EmailChangeConfirmation';
import Login from 'app/components/guest/Login';
import NotFound from 'app/components/NotFound';
import RequestResetPassword from 'app/components/guest/RequestResetPassword';
import ResetPassword from 'app/components/guest/ResetPassword';
import Signup from 'signup/containers/GuestSignup';
import { GuestOnlyRoute } from 'shared/utils/routing';

const Guest = () => (
  <div className="GuestRoot">
    <NavigationBar />
    <Switch>
      <GuestOnlyRoute
        exact
        path={routes.LOGIN}
        component={Login}
        supportRedirectParam
      />
      <GuestOnlyRoute path={routes.SIGNUP_REFERRER} component={Signup} />
      <GuestOnlyRoute path={routes.SIGNUP} component={Signup} />
      <Route
        exact
        path={routes.REQUEST_RESET_PASSWORD}
        component={RequestResetPassword}
      />
      <Route exact path={routes.RESET_PASSWORD} component={ResetPassword} />
      <Route exact path={routes.ACTIVATION} component={Activation} />
      <Route
        exact
        path={routes.CHANGE_EMAIL}
        component={EmailChangeConfirmation}
      />
      <Route component={NotFound} />
    </Switch>
  </div>
);

export default Guest;
