import React from 'react';
import classNames from 'classnames';
import * as I18n from 'shared/utils/I18n';
import {
  referrerMessage,
  showReferrerLogo,
} from 'shared/utils/referrerHelpers';

interface Props {
  referrer: string;
}

const ns = 'app.nav.referrer_banner';

interface LogoProps {
  referrer: string;
}

const ImageLogo = ({ referrer }: LogoProps) => (
  <img
    className="banner-logo"
    src={`/images/referrers/${referrer}.png`}
    title={referrer}
  />
);

const TextLogo = ({ referrer }: LogoProps) => (
  <span className="banner-logo-text">
    {I18n.nt(ns, `referrers.${referrer}`)}
  </span>
);

const ReferrerBanner = ({ referrer }: Props) => {
  const readMoreUrl = `https://www.floryn.com/${referrer}`;

  return (
    <div className={classNames('ReferrerBanner', `banner-${referrer}`)}>
      <div className="container">
        <a className="banner-link" href={readMoreUrl} target="_blank">
          <span>{referrerMessage(referrer)}</span>
          {showReferrerLogo(referrer) ? (
            <ImageLogo referrer={referrer} />
          ) : (
            <TextLogo referrer={referrer} />
          )}
          <span className="banner-read-icon icon icon-arrow-right" />
        </a>
      </div>
    </div>
  );
};

export default ReferrerBanner;
