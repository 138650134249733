import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  compact?: boolean;
}

const Layout = ({ compact = false, children }: Props) => (
  <div className="c-overviewpage c-ti">
    <div className={compact ? 'ti-compact' : 'overviewpage-container'}>
      {children}
    </div>
  </div>
);

export default Layout;
