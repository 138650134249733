import { hasType, AvailabilityPeriods } from 'models/AvailabilityPeriods';

export type DeliveryType = 'unknown' | 'psd2' | 'pdf' | 'both';

/**
 * Given one or more availability periods for an account, determine the
 * canonical delivery type for that account.
 */
export function fromAvailabilityPeriods(
  availabilityPeriods: AvailabilityPeriods
): DeliveryType {
  if (
    hasType(availabilityPeriods, 'psd2') &&
    hasType(availabilityPeriods, 'pdf')
  ) {
    return 'both';
  } else if (hasType(availabilityPeriods, 'psd2')) {
    return 'psd2';
  } else if (hasType(availabilityPeriods, 'pdf')) {
    return 'pdf';
  } else {
    throw new Error(
      `unexpected availability period type in ${[...availabilityPeriods]}`
    );
  }
}

/*
 * Given two delivery types, determine the correct combination of the two.  If
 * one of the two is `unknown`, the other is used. If they are different, `both`
 * is used.
 */
export function combine(a: DeliveryType, b: DeliveryType): DeliveryType {
  if (a === b) {
    return a;
  }

  if (a === 'unknown') {
    return b;
  }

  if (b === 'unknown') {
    return a;
  }

  return 'both';
}

export function isBoth(delivery: DeliveryType): boolean {
  return delivery === 'both';
}

export function isPdf(delivery: DeliveryType): boolean {
  return delivery === 'pdf' || isBoth(delivery);
}

export function isPsd2(delivery: DeliveryType): boolean {
  return delivery === 'psd2' || isBoth(delivery);
}
