import React, { InputHTMLAttributes, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { isUndefined } from 'lodash';
import { generateHtmlElementId } from 'shared/utils/Helpers';
import InputWrapper from './InputWrapper';
import ErrorMessage from './ErrorMessage';

interface Props<TFieldValues extends FieldValues>
  extends InputHTMLAttributes<HTMLInputElement> {
  control: Control<TFieldValues>;
  label: string;
  mask: string;
  name: Path<TFieldValues>;
}

const MaskedTextInput = <TFieldValues extends FieldValues>({
  control,
  label,
  mask,
  name,
  required,
  ...inputAttributes
}: Props<TFieldValues>) => {
  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error, invalid },
  } = useController<TFieldValues>({ control, name });

  const [id] = useState(generateHtmlElementId);
  const [errorMessageId] = useState(generateHtmlElementId);
  const [hasFocus, setHasFocus] = useState(false);

  const handleBlur = () => {
    setHasFocus(false);
    onBlur();
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  return (
    <InputWrapper
      id={id}
      invalid={Boolean(error) && !hasFocus}
      label={label}
      name={name}
      required={required}
    >
      <PatternFormat
        {...inputAttributes}
        aria-errormessage={invalid ? errorMessageId : undefined}
        aria-invalid={invalid ? true : undefined}
        className="form-control"
        defaultValue={undefined}
        format={mask}
        getInputRef={ref}
        id={id}
        mask="_"
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
        required={required}
        type="text"
        value={isUndefined(value) ? '' : value}
      />
      <ErrorMessage error={error} id={errorMessageId} />
    </InputWrapper>
  );
};

export default MaskedTextInput;
