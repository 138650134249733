import React from 'react';
import { nt } from 'shared/utils/I18n';

import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { Seller } from 'types';
import { toPercentage } from 'shared/utils/Helpers';

const ns = 'signup.onboarding.interest_info';

const InterestInfo = () => {
  const seller = useCurrentSeller() as Seller;
  const creditDecision = seller.creditDecision;

  const baseInterestRate = creditDecision.baseInterestRate
    ? parseFloat(creditDecision.baseInterestRate)
    : 0.0;

  if (baseInterestRate === 0) {
    return null;
  }

  const euriborRate = creditDecision.euriborRate;

  return (
    <div className="CostExplanation">
      <h4>{nt(ns, 'title')}</h4>
      <ul>
        {creditDecision.hasEuribor ? (
          <li>
            {nt(ns, 'explanation1_with_euribor_html', {
              percentage: toPercentage(baseInterestRate),
              euribor_percentage: toPercentage(euriborRate),
            })}
          </li>
        ) : (
          <li>
            {nt(ns, 'explanation1_without_euribor_html', {
              percentage: toPercentage(baseInterestRate),
            })}
          </li>
        )}
        <li>{nt(ns, 'explanation2')}</li>
        {creditDecision.hasEuribor && (
          <li>{nt(ns, 'euribor_explanation_html')}</li>
        )}
      </ul>
    </div>
  );
};

export default InterestInfo;
