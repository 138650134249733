import { connect } from 'react-redux';
import {
  DashboardMutation,
  loadMoreMutations,
} from 'charlie/modules/dashboardSlice';
import { currentSellerSelector } from 'shared/selectors';
import Mutations, { DashboardMutationWithExtraInfo } from './Component';
import { ExtendedProductVersion, MinimalSeller, ReduxState } from 'types';

const mapStateToProps = (state: ReduxState) => {
  const dashboard = state.charlie.dashboard;

  const seller = currentSellerSelector(state) as MinimalSeller;
  const productVersion: ExtendedProductVersion = seller.hasLoan
    ? 'loan'
    : seller.productVersion;

  const mutations = new Map<string, DashboardMutationWithExtraInfo>();
  (dashboard.payload?.mutations ?? []).forEach(
    (mutation: DashboardMutation) => {
      const key =
        mutation.mutationType === 'credit_limit'
          ? `credit-limit-${mutation.id}`
          : `${mutation.id}`;

      const createdAtDate =
        mutation.createdAt !== null ? Date.parse(mutation.createdAt) : null;
      const isPending =
        mutation.mutationType?.startsWith('pending') ||
        mutation.mutationType?.startsWith('requested');

      mutations.set(key, {
        ...mutation,
        createdAtDate,
        identifier: key,
        isPending,
      });
    }
  );

  return {
    productVersion: productVersion,
    isLoaded: dashboard.mutationsStatus === 'LOADED',
    lastPage: dashboard.payload?.lastPage ?? true,
    mutations,
    searching: dashboard.searching,
    searchQuery: dashboard.searchQuery,
    today: dashboard.payload?.today ?? '',
    latestImport: dashboard.payload?.latestImport ?? '',
  };
};

export default connect(mapStateToProps, { loadMoreMutations })(Mutations);
