import React from 'react';
import GuaranteeForm from 'shared/components/GuaranteeForm';
import useGuaranteeModal from 'Onboarding/components/Guarantee/useGuaranteeModal';
import { nt } from 'Onboarding/components/utils';
import Modal from 'shared/components/tailwind/Modal';

const t = nt('signup.onboarding.guarantee');

const GuaranteeModal = () => {
  const { guarantee, onSubmit, sellerEmail } = useGuaranteeModal();

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GuaranteeForm
          guarantee={guarantee}
          onSubmit={onSubmit}
          sellerEmail={sellerEmail}
        />
      </Modal.Body>
    </>
  );
};

export default GuaranteeModal;
