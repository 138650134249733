import React from 'react';
import classNames from 'classnames';
import { translate } from 'shared/utils/I18n';
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from 'shared/components/Tooltip';

import Panel from './Panel';

const HEALTH_RATINGS = ['a', 'b', 'c', 'd', 'e'];
const t = translate('insights.health_rating');

interface HealthRatingCategoryProps {
  active: boolean;
  label: string;
}

const HealthRatingCategory = ({
  active,
  label,
  ...props
}: HealthRatingCategoryProps) => (
  <div
    className={classNames(
      'ti-health-rating__category',
      `ti-health-rating__category__${label}`,
      { 'ti-health-rating__category__active': active }
    )}
    {...props}
  >
    <h4>{label.toUpperCase()}</h4>
  </div>
);

const HealthRatingPanel = ({ healthRating }) => (
  <Panel className="ti-health-rating" title={t('title')}>
    <div className="ti-health-rating__chart">
      {HEALTH_RATINGS.map((categoryScore) =>
        categoryScore === healthRating ? (
          <Tooltip key={categoryScore}>
            <TooltipTrigger>
              <HealthRatingCategory label={categoryScore} active={true} />
            </TooltipTrigger>
            <TooltipContent>{t(`scores.${categoryScore}`)}</TooltipContent>
          </Tooltip>
        ) : (
          <HealthRatingCategory
            key={categoryScore}
            label={categoryScore}
            active={false}
          />
        )
      )}
    </div>
  </Panel>
);

export default HealthRatingPanel;
