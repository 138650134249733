import React from 'react';
import IconTooltip from 'shared/components/IconTooltip';
import { dateFormat, formatIban } from 'shared/utils/Helpers';
import { MissingPeriod } from 'types/Review';
import * as I18n from 'shared/utils/I18n';

export interface IbanInfo {
  iban: string;
  missingPeriods: MissingPeriod[];
  uploaded: boolean;
}

interface Props {
  ibanInfoList: IbanInfo[];
}

const ns = 'shared.iban_info_list';

const formatPeriod = (period: MissingPeriod): string =>
  I18n.nt(ns, 'period', {
    startDate: dateFormat(period.startDate),
    endDate: dateFormat(period.endDate),
  });

const ibanCheckboxClass = ({ missingPeriods, uploaded }: IbanInfo): string => {
  if (!uploaded) {
    return 'IbanCheckbox requested';
  }

  if (missingPeriods.length === 0) {
    return 'IbanCheckbox finished';
  } else {
    return 'IbanCheckbox warning';
  }
};

const IbanInfoList = ({ ibanInfoList }: Props) => {
  if (ibanInfoList.length === 0) {
    return null;
  }

  return (
    <div className="IbanInfoList">
      <ul>
        {ibanInfoList.map((ibanInfo) => (
          <li key={ibanInfo.iban}>
            <div className="ibanInfo">
              <div className="checkbox-and-iban">
                <span className={ibanCheckboxClass(ibanInfo)} />
                <span className="Iban">{formatIban(ibanInfo.iban)}</span>
              </div>
            </div>
            {ibanInfo.uploaded && ibanInfo.missingPeriods.length !== 0 && (
              <div className="MissingPeriods">
                <span className="title">
                  {I18n.nt(ns, 'missing_periods_intro')}
                </span>
                <IconTooltip
                  tooltipText={I18n.nt(ns, 'missing_period_tooltip')}
                  iconClassName="tw-ml-2"
                />
                {ibanInfo.missingPeriods.map((missingPeriod) => (
                  <div key={missingPeriod.startDate}>
                    {formatPeriod(missingPeriod)}
                  </div>
                ))}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IbanInfoList;
