export type HttpError =
  | { type: 'network_error' }
  | { type: 'bad_status'; status: number }
  | { type: 'bad_body'; message: string };

/*
 * Remote can be used to switch between possible states of loading remote data.
 * This allows you to distinguish between not having any remote data because
 * there is none, because you are waiting for some, or because there was an HTTP
 * error.
 */
type FailureRemote = { type: 'failure'; error: HttpError; response?: unknown };

type Remote<T> =
  | { type: 'not_asked' }
  | { type: 'pending' }
  | { type: 'success'; response: T }
  | FailureRemote;

export function notAsked(): Remote<never> {
  return { type: 'not_asked' };
}

export function pending(): Remote<never> {
  return { type: 'pending' };
}

export function success<T>(response: T): Remote<T> {
  return {
    type: 'success',
    response: response,
  };
}

export function failure(error: HttpError, response?: unknown): FailureRemote {
  return {
    type: 'failure',
    error,
    response,
  };
}

/*
 * Take any error object and transform it into a valid Remote failure with an
 * appropriate HttpError.
 *
 * This deals with:
 *
 * * Syntax errors in the JSON response body
 * * Bad status code (any non-2xx)
 *
 * Anything else becomes a "network error".
 */
export function failureFromError(error: any): FailureRemote {
  if (error instanceof SyntaxError) {
    return failure({ type: 'bad_body', message: error.message });
  } else if (error.statusCode && error.statusCode > 299) {
    return failure(
      { type: 'bad_status', status: error.statusCode },
      error.payload
    );
  } else {
    return failure({ type: 'network_error' });
  }
}

/*
 * Apply a callback function to a success response, returning just the same
 * response if it is not a success value.
 *
 * Example:
 *
 *     map(success(2), n => n * 2)
 *     // { type: 'success', response: 4 }
 *
 *     map(failure({ type: 'network_error' }), n => n * 2)
 *     // { type: 'failure', error: { type: 'network_error' } }
 *
 */
export function map<T, K>(remote: Remote<T>, fun: (value: T) => K): Remote<K> {
  if (remote.type === 'success') {
    return { type: 'success', response: fun(remote.response) };
  } else {
    return remote;
  }
}

export default Remote;
