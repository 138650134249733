import React, { useEffect, useState } from 'react';
import { aisRequestRemote } from 'shared/utils/aisClient';
import { Remote } from 'types';

export interface RemoteProps<T> {
  remoteData: Remote<T>;
}
/**
 * Higher-order component for loading some remote data and receiving the result
 * as a prop. Data will be loaded once, when first rendering.
 *
 * Note: this could be made generic for any remote data source by replacing
 * the string argument with `withAisRemote` with a function that returns a
 * `Promise<Remote<K>>`. Then, instead of calling `aisRequestRemote` in its
 * `useEffect`, `withAisRemote` can invoke that function.
 *
 * Example usage:
 *
 *   function MyComponent({ remoteData }) {
 *     switch(remoteData.type) {
 *       case 'success':
 *         return <p>{data.response}</p>;
 *       case 'pending':
 *         return <p>loading...</p>;
 *       case 'failure':
 *         return <p>something failed</p>;
 *       case 'not_asked':
 *         return <></>;
 *     }
 *   }
 *   // use `aisRequestRemote` to fetch `/api/users` and render
 *   // MyComponent with the results
 *   export withAisRemote(MyComponent, '/api/users');
 */
function withAisRemote<K, T extends RemoteProps<K>>(
  WrappedComponent: React.ComponentType<T>,
  path: string
) {
  return function (props) {
    const [data, setData] = useState<Remote<K>>({
      type: 'pending',
    });

    const finallySetData = (remote: Remote<K>) => setData(remote as Remote<K>);

    useEffect(() => {
      aisRequestRemote<K>(path, props.seller.id).then(
        finallySetData,
        finallySetData
      );
    }, []);

    return <WrappedComponent remoteData={data} {...props} />;
  };
}

export default withAisRemote;
