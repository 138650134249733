import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Button } from 'shared/bootstrap';
import TextInput from 'hookForm/TextInput';
import * as I18n from 'shared/utils/I18n';
import {
  validatePresence,
  validateTwoFactorToken,
} from 'shared/utils/validation';
import resolver from 'hookForm/resolver';
import client from 'shared/utils/client';
import { trackFormSubmit } from 'shared/utils/tracker';

interface Props {
  goToNextStep: () => void;
}

interface FormValues {
  twoFactorToken: string;
}

const ns = 'app.profilePage.twoFactorSettings.enableModal';

const validate = ({ twoFactorToken }: FormValues) => ({
  twoFactorToken:
    validatePresence(twoFactorToken) || validateTwoFactorToken(twoFactorToken),
});

const getErrorMessage = (error: string) => {
  switch (error) {
    case 'invalid_two_factor_token':
      return I18n.nt(ns, 'step3.invalidToken');

    case 'too_many_failed_attempts':
      return I18n.nt(ns, 'step3.tooManyFailedAttempts');

    default:
      return I18n.nt('common', 'server_error');
  }
};

const TwoFactorTokenForm = ({ goToNextStep }: Props) => {
  const [serverError, setServerError] = useState<string | null>(null);

  const submit = async ({ twoFactorToken }) => {
    const response = await client(
      'POST',
      '/api/users/validate_two_factor',
      {
        user: { twoFactorToken },
      },
      { raiseError: false }
    );

    if (response.error) {
      setServerError(getErrorMessage(response.payload.name as string));
    } else {
      trackFormSubmit('enter-two-factor-token');
      goToNextStep();
    }
  };

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: resolver(validate),
  });

  return (
    <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
      {serverError && <Alert type="danger">{serverError}</Alert>}
      <TextInput
        control={control}
        name="twoFactorToken"
        autoFocus
        label={I18n.label('user', 'two_factor_token')}
      />
      <div className="inner-modal-footer">
        <Button type="submit" className="btn btn-primary">
          {I18n.nt(ns, 'continue')}
        </Button>
      </div>
    </form>
  );
};

export default TwoFactorTokenForm;
