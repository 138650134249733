import React from 'react';
import { Modal } from 'shared/bootstrap';
import { useSelector } from 'react-redux';

import { t } from './helpers';
import { Bank, MinimalSeller } from 'types';
import ModalBody from './ModalBody';
import { mainSellerSelector } from 'shared/selectors';

interface Props {
  onHide: () => void;
  isVisible: boolean;
  onRefresh: (bankName: string) => void;
  onAddAccount: () => void;
  banks: Bank[];
  isDisabled: boolean;
}

const ConsentsModal = ({
  onHide,
  isVisible,
  onRefresh,
  onAddAccount,
  banks,
  isDisabled,
}: Props) => {
  const mainSeller = useSelector(mainSellerSelector) as MinimalSeller;

  return (
    <Modal className="ConsentsModal" show={isVisible} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('title')}</Modal.Title>
      </Modal.Header>
      <ModalBody
        banks={banks}
        introTextKey={mainSeller.hasLoan ? 'intro_loan' : 'intro_credit'}
        isDisabled={isDisabled}
        onHide={onHide}
        onRefresh={onRefresh}
        onAddAccount={onAddAccount}
      />
    </Modal>
  );
};

export default ConsentsModal;
