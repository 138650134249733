import React from 'react';
import { FieldError } from 'react-hook-form';

interface Props {
  error?: FieldError;
  id: string;
}

const ErrorMessage = ({ error, id }: Props) => {
  if (!error?.message) {
    return null;
  }

  return (
    <div className="tw-mt-2 tw-text-red-dark" id={id}>
      {error.message}
    </div>
  );
};

export default ErrorMessage;
