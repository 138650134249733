import React from 'react';
import { useSelector } from 'react-redux';
import * as I18n from 'shared/utils/I18n';
import { ReduxState } from 'types';
import ReviewIntroductionMessage from './ReviewIntroductionMessage';
import InstructionsLink from './InstructionsLink';
import ReviewPeriodMessage from './ReviewPeriodMessage';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';

const ns = 'charlie.frontpage.review_form';

interface Props {
  hideInstructionsLink?: boolean;
}

const ReviewStatusMessage = ({ hideInstructionsLink }: Props) => {
  const review = useSelector(
    (state: ReduxState) => state.charlie.review.payload
  );
  const seller = useCurrentSeller();

  if (!review) {
    return null;
  }
  switch (review.status) {
    case 'received':
      return <p>{I18n.nt(ns, 'message_received')}</p>;

    case 'expired':
      return (
        <div>
          {I18n.nt(
            'charlie.frontpage.warnings',
            `review_introduction_periodical_${
              seller.hasLoan ? 'loan' : 'credit'
            }`,
            { count: review.periodicReviewInterval }
          )}{' '}
          <ReviewPeriodMessage reviewInfo={review} />
          {hideInstructionsLink ? null : <InstructionsLink />}
        </div>
      );

    default:
      return (
        <div className="introduction-message">
          <ReviewIntroductionMessage
            reviewInfo={review}
            showInstructions={!hideInstructionsLink}
          />
        </div>
      );
  }
};

export default ReviewStatusMessage;
