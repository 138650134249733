import AnimatedNumber from 'shared/components/AnimatedNumber';

export default () => {
  // Tests work better without animations.
  // https://trello.com/c/51mKe2fb

  const showAnimation = window.RAILS_ENV !== 'test';

  AnimatedNumber.defaultProps.animation = showAnimation;
};
