import React from 'react';
import classNames from 'classnames';

import * as I18n from 'shared/utils/I18n';
import { isExactInvoice } from 'shared/utils/invoice';
import { isOverdue } from 'models/Invoice';
import { Invoice, InvoiceContact, MinimalSeller, Seller } from 'types';

import Assessment from './InvoiceStatus/Assessment';
import Draft from './InvoiceStatus/Draft';
import Finished from './InvoiceStatus/Finished';
import Open from './InvoiceStatus/Open';

const ns = 'app.invoiceStatus';

interface Props {
  invoice: Invoice;
  invoiceContacts: InvoiceContact[];
  seller: MinimalSeller | Seller;
  verificationState: string;
}

interface SuperstatusBlockProps {
  activeSuperstatus: string;
  invoice: Invoice;
  invoiceContacts: InvoiceContact[];
  invoiceState: string;
  seller: MinimalSeller | Seller;
  superstatus: string;
  verificationState: string;
}

const SuperstatusBlock = ({
  activeSuperstatus,
  invoice,
  invoiceContacts,
  invoiceState,
  seller,
  superstatus,
  verificationState,
}: SuperstatusBlockProps) => {
  const active = activeSuperstatus === superstatus;
  const className = classNames({
    active,
    isExactHeading:
      active && superstatus === 'draft' && isExactInvoice(invoice),
    superstatus: true,
  });

  return (
    <div key={superstatus} className={className}>
      <div className={`status-border status-border-${invoiceState}`} />
      <h4>{I18n.nt(ns, `superstatus.${superstatus}`)}</h4>
      {active && activeSuperstatus === 'draft' && (
        <Draft
          isExact={isExactInvoice(invoice)}
          lastActionAt={invoice.lastActionAt}
        />
      )}
      {active && activeSuperstatus === 'assessment' && (
        <Assessment
          lastActionAt={invoice.lastActionAt}
          isSendable={seller.sendInvoices && invoiceContacts.length > 0}
        />
      )}
      {active && activeSuperstatus === 'open' && (
        <Open
          invoice={invoice}
          verificationState={verificationState}
          invoiceContacts={invoiceContacts}
        />
      )}
      {active && activeSuperstatus === 'finished' && (
        <Finished
          lastActionAt={invoice.lastActionAt}
          invoiceState={invoice.state}
        />
      )}
    </div>
  );
};

const InvoiceStatus = ({
  invoice,
  invoiceContacts,
  seller,
  verificationState,
}: Props) => {
  let invoiceState = invoice.state;
  if (invoiceState === 'open') {
    if (isOverdue(invoice)) {
      invoiceState = 'overdue';
    } else if (invoice.financingState === 'rejected') {
      invoiceState = 'rejected';
    } else {
      invoiceState = 'open';
    }
  }

  let activeSuperstatus = '';
  if (invoiceState === 'draft') {
    activeSuperstatus = 'draft';
  } else if (invoiceState === 'submitted') {
    activeSuperstatus = 'assessment';
  } else if (invoiceState === 'open') {
    activeSuperstatus = 'open';
  } else if (
    invoiceState === 'rejected' ||
    invoiceState === 'withdrawn' ||
    invoiceState === 'paid'
  ) {
    activeSuperstatus = 'finished';
  }

  const superStatuses = ['draft', 'assessment', 'open', 'finished'];

  return (
    <div className="Sidebars">
      <div className="InvoiceStatus">
        {superStatuses.map((superstatus) => (
          <SuperstatusBlock
            activeSuperstatus={activeSuperstatus}
            invoice={invoice}
            invoiceContacts={invoiceContacts}
            invoiceState={invoiceState}
            key={superstatus}
            seller={seller}
            superstatus={superstatus}
            verificationState={verificationState}
          />
        ))}
      </div>
    </div>
  );
};

export default InvoiceStatus;
