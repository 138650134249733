import React from 'react';
import { Modal } from 'shared/bootstrap';
import Button from 'shared/components/tailwind/Button';
import * as I18n from 'shared/utils/I18n';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import { PaymentStatus } from './PaymentStatus';

const ns = 'charlie.frontpage.repayment_status_notification';

interface Props {
  status: PaymentStatus;
  close: () => void;
  retry: () => void;
}

const Notification = ({ status, close, retry }: Props) => {
  let content;

  if (status === PaymentStatus.SETTLEMENT_COMPLETED) {
    content = (
      <>
        <div className="tw-mb-2 tw-flex tw-flex-row tw-items-center tw-gap-3">
          <span className="icon icon-checkmark tw-text-2xl tw-text-green-500" />
          <h4 className="tw-m-0">
            {I18n.nt(ns, 'settlement_completed.sub_title')}
          </h4>
        </div>
        <div>{I18n.nt(ns, 'settlement_completed.description')}</div>
      </>
    );
  } else if (status === PaymentStatus.OPEN) {
    content = (
      <>
        <div>{I18n.nt(ns, 'open.sub_title')}</div>
        {status === PaymentStatus.OPEN ? (
          <div className="tw-mt-10 tw-items-center">
            <LoadingAnimation />
          </div>
        ) : null}
      </>
    );
  } else if (
    status === PaymentStatus.ERROR ||
    status === PaymentStatus.EXPIRED ||
    status === PaymentStatus.CANCELLED
  ) {
    content = (
      <>
        <div className="tw-mb-2 tw-flex tw-flex-row tw-items-center tw-gap-3">
          <span className="icon icon-cross1 tw-text-lg tw-text-red-500" />
          <h4 className="tw-m-0">
            {I18n.nt(ns, `${status.toLowerCase()}.sub_title`)}
          </h4>
        </div>
        <div>{I18n.nt(ns, `${status.toLowerCase()}.description`)}</div>
      </>
    );
  }

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer className="tw-flex">
        {status === PaymentStatus.ERROR ||
        status === PaymentStatus.CANCELLED ||
        status === PaymentStatus.EXPIRED ? (
          <>
            <Button onClick={close} className="btn-light-inverted tw-mr-auto">
              {I18n.nt(ns, 'close')}
            </Button>
            <Button onClick={retry} className="btn-primary tw-ml-auto">
              {I18n.nt(ns, 'try_again')}
            </Button>
          </>
        ) : (
          <Button onClick={close} className="btn-primary tw-ml-auto">
            {I18n.nt(ns, 'close')}
          </Button>
        )}
      </Modal.Footer>
    </div>
  );
};

export default Notification;
