import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  description: ReactNode;
  finishedStep: number;
  lastStep: number;
  stepIndex: number;
  title: string;
}

const OnboardingProgressStep = ({
  description,
  finishedStep,
  lastStep,
  stepIndex,
  title,
}: Props) => {
  const done = stepIndex <= finishedStep;

  return (
    <>
      <div className="tw-relative tw--mt-[4px]">
        <div
          className={classNames(
            'tw-z-10 tw-flex tw-h-[34px] tw-w-[34px] tw-items-center tw-justify-center tw-rounded-[50%] tw-border-[3px] tw-border-solid',
            done
              ? 'tw-border-floryn-green tw-bg-floryn-green'
              : 'tw-border-gray-400 tw-bg-white'
          )}
        >
          <div
            className={classNames(
              'tw-font-bold',
              done ? 'tw-text-white' : 'tw-text-gray-500'
            )}
          >
            {stepIndex}
          </div>
        </div>
        {stepIndex < lastStep && (
          <div
            className={classNames(
              'tw-absolute tw-left-[16px] tw-h-[100%] tw-w-[3px]',
              stepIndex < finishedStep ? 'tw-bg-floryn-green' : 'tw-bg-gray-400'
            )}
          />
        )}
      </div>
      <div>
        <div className="tw-mb-2 tw-font-bold tw-text-black">{title}</div>
        <div>{description}</div>
      </div>
    </>
  );
};

export default OnboardingProgressStep;
