import React, { useEffect, ReactNode } from 'react';
import { Alert } from 'shared/bootstrap';
import { useLocation } from 'react-router-dom';

import { Translator } from 'types';
import { trackCrossPlatformEvent } from 'shared/utils/tracker';
import {
  ConsentFeedbackStatus,
  getStatusFromQueryStringOrPath,
} from './FeedbackHelpers';

interface Props {
  children?: ReactNode;
  t: Translator;
  showSuccess?: boolean;
}

const Feedback = ({ children, t, showSuccess = true }: Props) => {
  const location = useLocation();

  useEffect(() => {
    const status = getStatusFromQueryStringOrPath(location);

    if (status === ConsentFeedbackStatus.Success) {
      trackCrossPlatformEvent('psd2 consent succeeded');
    } else if (
      status === ConsentFeedbackStatus.Rejected ||
      status === ConsentFeedbackStatus.Failed
    ) {
      trackCrossPlatformEvent('psd2 consent failed');
    }
  }, []);

  switch (getStatusFromQueryStringOrPath(location)) {
    case ConsentFeedbackStatus.Rejected:
      return <Alert type="info">{t('rejected_html')}</Alert>;
    case ConsentFeedbackStatus.Success:
      if (!showSuccess) {
        return <></>;
      }
      return children ? (
        <>{children}</>
      ) : (
        <Alert type="success">{t('success_html')}</Alert>
      );
    case ConsentFeedbackStatus.None:
      return <></>;
    default:
      return <Alert type="danger">{t('failed_html')}</Alert>;
  }
};

export default Feedback;
