import { useEffect, useState } from 'react';
import { formatISO, isAfter, subMonths, parseISO } from 'date-fns';
import UAParser from 'ua-parser-js';

export default () => {
  const localStorageKey = 'closedMobileAppBanner';

  const markAsClosed = () => {
    localStorage?.setItem(localStorageKey, formatISO(new Date()));
  };

  const closedRecently = (): boolean => {
    const currentValue = localStorage?.getItem(localStorageKey);

    if (!currentValue) {
      return false;
    }

    // We used to store a '1' to mark as closed, let's migrate to timestamps.
    if (currentValue === '1') {
      markAsClosed();
      return true;
    }

    const oneMonthAgo = subMonths(new Date(), 1);
    return isAfter(parseISO(currentValue), oneMonthAgo);
  };

  const [closed, setClosed] = useState(closedRecently);
  const [operatingSystem, setOperatingSystem] = useState<string | null>(null);

  useEffect(() => {
    const os = new UAParser().getOS();

    if (os.name) {
      setOperatingSystem(os.name.toLowerCase());
    }
  }, []);

  const dismiss = () => {
    setClosed(true);
    markAsClosed();
  };

  return {
    closed,
    dismiss,
    markAsClosed,
    operatingSystem,
  };
};
