import React from 'react';

import { ContactsInputValue } from './types';
import { isContactSelected } from './util';
import ContactListItem from './ContactListItem';

interface Props {
  allContacts: ContactsInputValue[];
  selectedContacts: ContactsInputValue[];
  toggleContact: (contact: ContactsInputValue) => void;
}

const ContactsList = ({
  allContacts,
  selectedContacts,
  toggleContact,
}: Props) => (
  <div className="list-group">
    {allContacts.map((contact) => {
      const key = `contact-${contact.id}-${contact.email}`;

      return (
        <ContactListItem
          contact={contact}
          key={key}
          name={key}
          selected={isContactSelected(selectedContacts, contact)}
          toggleContact={toggleContact}
        />
      );
    })}
  </div>
);

export default ContactsList;
