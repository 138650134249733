import { Middleware } from 'redux';
import { LOAD } from 'shared/redux/modules/session';
import * as tracker from 'shared/utils/tracker';
import * as abtest from 'shared/utils/ABTest';

const reportABTestsToTrackerMiddleware: Middleware =
  () => (next) => (action) => {
    const result = next(action);

    if ((action as any).type === LOAD) {
      // LOAD is the first action that is dispatched by the application
      tracker.addUserTraits(abtest.getPrefixedTestVariants());
    }
    return result;
  };

export default reportABTestsToTrackerMiddleware;
