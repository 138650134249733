import React from 'react';

import CurrencyInput from 'hookForm/CurrencyInput';
import GeneralErrors from 'hookForm/GeneralErrors';
import RadioButtons from 'hookForm/RadioButtons';
import RadioInput from 'hookForm/RadioInput';
import Select from 'hookForm/Select';
import TextArea from 'hookForm/TextArea';

import DontWorryMessage from 'shared/components/DontWorryMessage';
import Button from 'shared/components/tailwind/Button';
import * as I18n from 'shared/utils/I18n';
import * as Consent from 'shared/components/Consent';

import useAdditionalQuestionsStep from './useAdditionalQuestionsStep';

const ns = 'app.profile.additionalQuestionsStep';
const nt = (ns) => (key, options?) => I18n.nt(ns, key, options);

const AdditionalQuestionsStep = () => {
  const {
    billingOptions,
    control,
    goToPreviousStep,
    isSubmitting,
    priorityOptions,
    purposeOptions,
    serverErrorMessages,
    submit,
  } = useAdditionalQuestionsStep();

  return (
    <div className="lg:tw-pr-16">
      <Consent.Feedback t={nt(`${ns}.consentFeedback`)} />
      <h1 className="tw-mb-4 tw-text-xl md:tw-mt-0 md:tw-text-bootstrap-h3">
        {I18n.nt(ns, 'title')}
      </h1>
      <p className="tw-mb-8">{I18n.nt(ns, 'intro')}</p>
      <div className="fieldset tw-mb-8">
        <GeneralErrors generalErrors={serverErrorMessages} />
        <TextArea
          autoFocus
          control={control}
          label={I18n.nt(ns, 'labels.explanation')}
          name="explanation"
        />
        <TextArea
          control={control}
          label={I18n.nt(ns, 'labels.business_activities')}
          name="businessActivities"
        />
        <CurrencyInput
          control={control}
          label={I18n.nt(ns, 'labels.existing_debt_amount')}
          name="existingDebtAmount"
        />
        <div className="tw-mb-6">
          <Select
            control={control}
            includeEmpty
            includeLayoutClass={false}
            label={I18n.nt(ns, 'labels.purpose')}
            name="purpose"
            options={purposeOptions}
          />
        </div>
        <RadioButtons
          horizontal
          label={I18n.nt(ns, 'labels.sole_financer')}
          name="soleFinancer"
        >
          <RadioInput
            control={control}
            label={I18n.ut('common.true')}
            name="soleFinancer"
            value="true"
          />
          <RadioInput
            control={control}
            label={I18n.ut('common.false')}
            name="soleFinancer"
            value="false"
          />
        </RadioButtons>
        <div className="tw-mb-6">
          <Select
            control={control}
            includeEmpty
            includeLayoutClass={false}
            label={I18n.nt(ns, 'labels.billing')}
            name="billing"
            options={billingOptions}
          />
        </div>
        <div className="tw-mb-6">
          <Select
            control={control}
            includeEmpty
            includeLayoutClass={false}
            label={I18n.nt(ns, 'labels.priority')}
            name="priority"
            options={priorityOptions}
          />
        </div>
      </div>
      <div className="tw-flex">
        <Button
          className="btn-light-inverted sm:tw-w-auto"
          onClick={goToPreviousStep}
        >
          {I18n.ut('common.goBack')}
        </Button>
        <Button
          type="submit"
          className="btn-primary tw-grow sm:tw-grow-0"
          disabled={isSubmitting}
          onClick={submit}
        >
          {I18n.nt(ns, 'submit')}
        </Button>
      </div>
      <DontWorryMessage />
    </div>
  );
};

export default AdditionalQuestionsStep;
