/* Layout container using tailwind css.

* In xs and md the container has a fixed widths. It uses the container class from tailwind.
* In sm and xs the container does not have fixed width.
* The breakpoints and the container settings are defined in the tailwind configuration.
*/
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const Container = ({ children }: Props) => (
  <div className="md:px-0 tw-px-4 md:tw-container sm:tw-px-8">{children}</div>
);

export default Container;
