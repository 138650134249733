import React from 'react';
import { Link } from 'react-router-dom';

import * as I18n from 'shared/utils/I18n';
import * as routes from 'app/routes';
import ContentWithSidebar from 'shared/components/tailwind/ContentWithSidebar';
import DefaultSidebar from 'shared/components/DefaultSidebar';
import MandateReturnMessage from './MandateReturnMessage';
import { TwikeyStatus } from 'types/TwikeyStatus';

const ns = 'app.mandate_return';

const title = (twikeyStatus?: TwikeyStatus) => {
  const key =
    twikeyStatus && Object.values(TwikeyStatus).includes(twikeyStatus)
      ? twikeyStatus
      : 'default';

  return I18n.nt(ns, `title.${key}`);
};

interface Props {
  iban?: string;
  twikeyStatus?: TwikeyStatus;
}

const MandateReturn = ({ iban, twikeyStatus }: Props) => (
  <ContentWithSidebar sidebarChildren={<DefaultSidebar />}>
    <h3>{title(twikeyStatus)}</h3>
    <MandateReturnMessage iban={iban} status={twikeyStatus} />
    <Link className="btn btn-primary tw-mt-4" to={routes.SIGNUP}>
      {I18n.ut('common.goForward')}
    </Link>
  </ContentWithSidebar>
);

export default MandateReturn;
