import React from 'react';
import { translate } from 'shared/utils/I18n';
import EmptyPage from './EmptyPage';

const t = translate('insights.not_found');

const NotFound = () => (
  <EmptyPage cover>
    <h3>{t('title')}</h3>
    <p>{t('message')}</p>
  </EmptyPage>
);

export default NotFound;
