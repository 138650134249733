import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LOGOUT } from 'app/routes';
import { ReduxState } from 'types';

const CheckExpiredSession = () => {
  const history = useHistory();

  const sessionExpired = useSelector(
    (state: ReduxState) => state.session.expired
  );

  useEffect(() => {
    if (sessionExpired) {
      history.push(LOGOUT);
    }
  }, [sessionExpired]);

  return null;
};

export default CheckExpiredSession;
