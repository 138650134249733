import {
  SET_BANK_STATEMENT_UPLOAD_TYPE,
  TRANSITION_SELLER,
  UPDATE_BANK_STATEMENT_ATTACHMENTS,
  UPDATE_SELLER,
} from 'signup/actions/SellerSignup';
import { UPDATE_CUSTOMER_SIGNUP } from 'signup/actions/CustomerSignup';
import { UPDATE_UBOS_IDENTIFICATION } from 'signup/actions/UboIdentification';

const initialState = {
  bankStatementUploadType: 'psd2',
  quickscanResult: {
    approved: true,
    showRejection: false,
  },
  transitioning: false,
  updating: false,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_BANK_STATEMENT_UPLOAD_TYPE:
      return {
        ...state,
        bankStatementUploadType: action.payload,
      };

    case UPDATE_SELLER.REQUEST:
      return {
        ...state,
        updating: true,
      };

    case UPDATE_SELLER.SUCCESS:
      return {
        ...state,
        updating: false,
      };

    case UPDATE_SELLER.FAILURE:
      return {
        ...state,
        updating: false,
      };

    case UPDATE_BANK_STATEMENT_ATTACHMENTS.REQUEST:
      return {
        ...state,
        updating: true,
      };

    case UPDATE_BANK_STATEMENT_ATTACHMENTS.SUCCESS:
      return {
        ...state,
        updating: false,
      };

    case UPDATE_BANK_STATEMENT_ATTACHMENTS.FAILURE:
      return {
        ...state,
        updating: false,
      };

    case TRANSITION_SELLER.REQUEST:
      return {
        ...state,
        transitioning: true,
      };

    case TRANSITION_SELLER.SUCCESS:
      return {
        ...state,
        transitioning: false,
      };

    case TRANSITION_SELLER.FAILURE:
      return {
        ...state,
        transitioning: false,
      };

    case UPDATE_CUSTOMER_SIGNUP.REQUEST:
      return {
        ...state,
        updating: true,
      };

    case UPDATE_CUSTOMER_SIGNUP.SUCCESS:
      return {
        ...state,
        updating: false,
      };

    case UPDATE_CUSTOMER_SIGNUP.FAILURE:
      return {
        ...state,
        updating: false,
      };

    case UPDATE_UBOS_IDENTIFICATION.SUCCESS:
      return {
        ...state,
        updating: false,
      };

    case UPDATE_UBOS_IDENTIFICATION.FAILURE:
      return {
        ...state,
        updating: false,
      };

    default:
      return state;
  }
};

export default reducer;
