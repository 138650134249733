import React from 'react';

interface Props {
  children: React.ReactNode;
  icon: 'info' | 'lock';
}

const BankStatementInfo = ({ icon, children }: Props) => {
  let iconElement;
  switch (icon) {
    case 'info':
      iconElement = (
        <div className="icon icon-info tw-pt-[2px] tw-text-[18px]" />
      );
      break;
    case 'lock':
      iconElement = <div className="icon icon-lock tw-text-[18px]" />;
  }

  return (
    <div className="bank-statement-info tw-mt-4 tw-flex tw-text-sm tw-text-gray-500">
      <span className="bank-statement-info-image tw-mr-3">{iconElement}</span>
      {children}
    </div>
  );
};

export default BankStatementInfo;
