import updateUserInState from 'shared/modules/updateUserInState';

const INITIAL_STATE = {
  loading: false,
  error: {},
  passwordChangeRequested: false,
  requestPasswordLoading: false,
  emailChangeRequested: false,
  emailChangeLoading: false,
};

export const REQUEST_PASSWORD_RESET = 'if.profilePage.REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET_SUCCESS =
  'if.profilePage.REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAILURE =
  'if.profilePage.REQUEST_PASSWORD_RESET_FAILURE';

export const UNMOUNT = 'if.profilePage.UNMOUNT';

export const profilePageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_PASSWORD_RESET:
      return {
        ...state,
        error: {},
        requestPasswordLoading: true,
        passwordChangeRequested: false,
      };

    case REQUEST_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        requestPasswordLoading: false,
        passwordChangeRequested: true,
      };

    case REQUEST_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        error: action.payload,
        requestPasswordLoading: false,
        passwordChangeRequested: false,
      };

    case UNMOUNT:
      return {
        ...state,
        passwordChangeRequested: false,
        requestPasswordLoading: false,
        emailChangeRequested: false,
        emailChangeLoading: false,
      };

    default:
      return state;
  }
};

export const resetState = () => {
  return {
    type: UNMOUNT,
  };
};

export const requestReset = (email, form) => ({
  type: [
    REQUEST_PASSWORD_RESET,
    REQUEST_PASSWORD_RESET_SUCCESS,
    REQUEST_PASSWORD_RESET_FAILURE,
  ],
  form,
  normalize: false,
  promise: (client) =>
    client('POST', `/api/user_reset_passwords/request`, { email }),
});

export const twoFactorEnabled = (phone) => (dispatch) =>
  dispatch(updateUserInState({ phone, twoFactorEnabled: true }));
