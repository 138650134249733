import { dateFormat } from 'shared/utils/Helpers';

/*
 * A period is a "range" of two dates.
 */
export type Period = {
  from: Date;
  to: Date;
};

/*
 * Construct a new period from two dates.
 */
export function build(from: Date, to: Date): Period {
  if (from > to) {
    return { from: to, to: from };
  } else {
    return { from, to };
  }
}

/*
 * Use the `dateFormat` helper function to format a `Period` as a string.
 */
export function format({ from, to }: Period): string {
  return `${dateFormat(from)} – ${dateFormat(to)}`;
}
