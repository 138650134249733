import { Seller } from '../types';
import React from 'react';
import { nt } from 'shared/utils/I18n';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';

const ns = 'app.profilePage.exact_online_connection.connected';

interface Props<TFieldValues extends FieldValues> {
  register: UseFormRegister<TFieldValues>;
  name: Path<TFieldValues>;
  seller: Seller;
}

const SelectDivision = <TFieldValues extends FieldValues>({
  register,
  name,
  seller,
}: Props<TFieldValues>) => {
  return (
    <div className="select-division">
      <select
        aria-label={nt(ns, 'administration')}
        className="form-control"
        defaultValue={seller.currentDivision || 'none'}
        {...register(name)}
      >
        <option key="none" value="none">
          {nt(ns, 'no_division_selected')}
        </option>
        {seller.divisions.map(({ code, description }) => (
          <option key={code} value={code}>
            {description}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectDivision;
