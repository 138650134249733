import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const ContainerWithoutPadding = ({ children }: Props) => (
  <div className="md:tw-container">{children}</div>
);

export default ContainerWithoutPadding;
