import { useCallback, useEffect, useRef, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { debounce, isString } from 'lodash';
import { generateHtmlElementId } from 'shared/utils/Helpers';
import client from 'shared/utils/client';
import { trackEvent } from 'shared/utils/tracker';
import { SearchResult } from './types';
import { CandidateCompany, Company } from 'types';
import useSharedRef from '../useSharedRef';

type Status = '' | 'searching' | 'searchDone' | 'noResults' | 'error';

function useCompanyInput<TFieldValues extends FieldValues>(
  control: Control<TFieldValues>,
  name: Path<TFieldValues>
) {
  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error },
  } = useController<TFieldValues>({ control, name });

  const [inputId] = useState(generateHtmlElementId);
  const [errorMessageId] = useState(generateHtmlElementId);
  const [hasFocus, setHasFocus] = useState(false);
  const [status, setStatus] = useState<Status>('');
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const activeRef = useSharedRef(ref, inputRef);

  const hasSingleResult = () => {
    return (
      searchResult &&
      searchResult.candidateCompanies.length + searchResult.companies.length ===
        1
    );
  };

  const handleBlur = useCallback(() => {
    if (value === null && searchResult && hasSingleResult()) {
      handleSelectCompany(
        searchResult.candidateCompanies[0] || searchResult.companies[0]
      );
    }

    setHasFocus(false);
    onBlur();
  }, [value, searchResult]);

  const search = async (query: string) => {
    if (!query || (isString(query) && !query.trim())) {
      return;
    }

    setStatus('searching');
    if (value) {
      setSearchResult(null);
    }
    onChange(null);

    const response = await client<SearchResult>(
      'GET',
      '/api/companies/search',
      { query },
      { raiseError: false }
    );

    if (response.error) {
      setStatus('error');
    } else if (!activeRef.current || activeRef.current.value === query) {
      const searchResult = response.payload;

      if (
        !searchResult.candidateCompanies.length &&
        !searchResult.companies.length
      ) {
        setSearchResult(null);
        setStatus('noResults');
      } else {
        setSearchResult(response.payload);
        setStatus('searchDone');
      }
    } else {
      // Ignore the result if the input has changed
    }
  };

  const handleChange = useCallback(debounce(search, 500), []);

  const handleFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  const handleDeselectCompany = useCallback(() => {
    onChange(null);
  }, []);

  const handleSelectCompany = useCallback(
    (company: CandidateCompany | Company) => {
      trackEvent('Select company');

      onChange(company);
    },
    []
  );

  useEffect(() => {
    if (value) {
      setStatus('searchDone');
    }
  }, []);

  return {
    error,
    errorMessageId,
    handleBlur,
    handleChange,
    handleDeselectCompany,
    handleFocus,
    handleSelectCompany,
    hasFocus,
    inputId,
    invalid: Boolean(error),
    ref: activeRef,
    searchResult,
    status,
    value,
  };
}

export default useCompanyInput;
