import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import resolver from 'hookForm/resolver';
import { email as validateEmail } from 'shared/utils/validation';
import client from 'shared/utils/client';

type Status = 'error' | 'requested' | 'requesting' | '';

interface FormValues {
  email: string;
}

interface RouteState {
  email?: string;
}

const validateForm = (values: FormValues) => ({
  email: validateEmail(values.email),
});

const useRequestResetPassword = () => {
  const [status, setStatus] = useState<Status>('');
  const [requestedEmail, setRequestedEmail] = useState('');
  const { state } = useLocation<RouteState>();
  const emailFromRoute = state?.email;

  const requestReset = async ({ email }) => {
    setStatus('requesting');

    const response = await client(
      'POST',
      `/api/user_reset_passwords/request`,
      { email },
      { raiseError: false }
    );

    if (response.error) {
      setStatus('error');
    } else {
      setStatus('requested');
      setRequestedEmail(email);
    }
  };

  useEffect(() => {
    if (emailFromRoute) {
      requestReset({ email: emailFromRoute });
    }
  }, []);

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { email: '' },
    resolver: resolver(validateForm),
  });

  const submit = handleSubmit(requestReset);

  return {
    control,
    handleSubmit,
    requestedEmail,
    status,
    submit,
  };
};

export default useRequestResetPassword;
