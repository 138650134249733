import { useSelector } from 'react-redux';
import { BaseSeller } from 'types';
import { currentSellerSelector } from 'shared/selectors';
import { getProduct } from 'models/Seller';
import { nt } from 'shared/utils/I18n';
import { updateCurrentSellerId } from 'shared/redux/modules/session';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import useSellers from 'shared/hooks/useSellers';

const ns = 'app.products';

const getProductName = (seller: BaseSeller): string =>
  nt(ns, getProduct(seller));

const useFrontpageTabs = () => {
  const currentSeller = useSelector(currentSellerSelector) as BaseSeller;

  const dispatch = useAppDispatch();

  const sellers = useSellers();

  const multipleCompanies =
    new Set(sellers.map((seller) => seller.company)).size > 1;

  const selectTab = (sellerId: number) => {
    dispatch(updateCurrentSellerId(sellerId));
  };

  const tabs = sellers.map((seller) => ({
    id: seller.id,
    title: multipleCompanies ? seller.companyName : getProductName(seller),
  }));

  return { currentSeller, selectTab, tabs };
};

export default useFrontpageTabs;
