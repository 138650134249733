import React from 'react';

import { nt } from 'shared/utils/I18n';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import useIsComplexAccount from 'shared/hooks/useIsComplexAccount';
import useMobileAppBanner from './useMobileAppBanner';
import { ProductVersion, Status } from 'types/Seller';

const ns = 'app.mobileAppBanner';

const MobileAppBanner = () => {
  const seller = useCurrentSeller();
  const complexAccount = useIsComplexAccount();
  const { closed, dismiss, markAsClosed, operatingSystem } =
    useMobileAppBanner();

  if (seller.status !== Status.APPROVED) {
    return null;
  }

  const supportedProduct = !(
    complexAccount ||
    seller.hasLoan ||
    seller.productVersion !== ProductVersion.CHARLIE
  );

  if (closed || !supportedProduct) {
    return null;
  }

  if (operatingSystem !== 'android' && operatingSystem !== 'ios') {
    return null;
  }

  const downloadUrl =
    operatingSystem === 'android'
      ? 'https://play.google.com/store/apps/details?id=com.floryn.android'
      : 'https://apps.apple.com/nl/app/floryn/id1623589282?itsct=apps_box_link&itscg=30200';

  const handleInstall = () => {
    markAsClosed();
  };

  return (
    <div className="MobileAppBanner">
      <div className="app">
        <button type="button" className="close" onClick={dismiss}>
          <span aria-hidden="true">&times;</span>
          <span className="sr-only">{nt('common', 'close')}</span>
        </button>
        <img src="/images/app_icon_80x80.png" title={nt(ns, 'app')} />
        <div className="title-and-description">
          <div className="title">{nt(ns, 'app')}</div>
          <div className="description">{nt(ns, 'description')}</div>
        </div>
      </div>
      <a className={operatingSystem} href={downloadUrl} onClick={handleInstall}>
        {nt(ns, 'install')}
      </a>
    </div>
  );
};

export default MobileAppBanner;
