import React from 'react';
import { Link } from 'react-router-dom';

import * as I18n from 'shared/utils/I18n';
import GuestModal from 'shared/components/tailwind/GuestModal';
import { APP_ROOT } from 'app/routes';

const NotFound = () => (
  <GuestModal>
    <div className="tw-text-center">
      <img
        className="tw-mx-auto tw-mb-6 tw-w-[60px]"
        aria-label="floryn error logo"
        src="/images/broken_link.png"
      />
      <h1 className="tw-mb-4 tw-text-2xl md:tw-text-bootstrap-h3">
        {I18n.t('ui.app.not_found.header')}
      </h1>
      <div className="tw-mb-4">
        <p>{I18n.t('ui.app.not_found.does_not_exist')}</p>
      </div>
      <Link to={APP_ROOT}>{I18n.t('ui.app.not_found.go_back')}</Link>
    </div>
  </GuestModal>
);

export default NotFound;
