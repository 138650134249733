import * as remote from 'types/Remote';
import { DeliveryRequest, DeliveryRequestItem } from './types';
import { DeliveryRequestStatus } from './types';
import * as d from './decoding';

function parseDeliveryRequestStatus(
  value: string
): d.Decoder<DeliveryRequestStatus> {
  switch (value) {
    case 'open':
    case 'ignored':
    case 'deferred':
    case 'expired':
    case 'received':
    case 'planned':
    case 'accepted':
    case 'rejected':
      return d.succeed(value);
    default:
      return d.fail(`Invalid request status value ${value}`);
  }
}

function parseDate(value: string): d.Decoder<Date> {
  const i = Date.parse(value);
  if (isNaN(i) || i === 0) {
    return d.fail(`Value ${value} is not a valid date`);
  } else {
    return d.succeed(new Date(i));
  }
}

function createDeliveryRequestItem(
  id,
  companyName,
  filename,
  deliveryRequestId,
  url
): DeliveryRequestItem {
  return {
    id,
    companyName,
    filename,
    deliveryRequestId,
    url,
    deletionStatus: remote.notAsked(),
  };
}

function decodeDeliveryRequestItem(id): d.Decoder<DeliveryRequestItem> {
  return d.mapN(
    createDeliveryRequestItem,
    d.field('id', d.number()),
    d.optional(d.field('companyName', d.string())),
    d.optional(d.field('filename', d.string())),
    d.succeed(id),
    d.field('downloadUrl', d.string())
  );
}

function createDeliveryRequest(
  id,
  title,
  items,
  status,
  requestTypes,
  introduction,
  deadline,
  customerFeedback,
  currentDecisionCreatedAt,
  plannedAt,
  companyName
): DeliveryRequest {
  return {
    id,
    title,
    items,
    status,
    requestTypes,
    introduction,
    deadline,
    customerFeedback,
    currentDecisionCreatedAt,
    plannedAt,
    companyName,

    // TODO: to be filled in once the server knows these values
    closedDate: null,
  };
}

function decodeDeliveryRequest(): d.Decoder<DeliveryRequest> {
  return d.mapN(
    createDeliveryRequest,
    d.field('id', d.number()),
    d.field('title', d.optional(d.string())),
    d.andThen(d.field('id', d.number()), (id) =>
      d.field('visibleItems', d.array(decodeDeliveryRequestItem(id)))
    ),
    d.field('status', d.andThen(d.string(), parseDeliveryRequestStatus)),
    d.field('requestTypes', d.array(d.string())),
    d.optional(d.field('introduction', d.string())),
    d.field('deadline', d.andThen(d.string(), parseDate)),
    d.optional(d.field('customerFeedback', d.string())),
    d.field('currentDecisionCreatedAt', d.andThen(d.string(), parseDate)),
    d.field('plannedAt', d.andThen(d.string(), parseDate)),
    d.field('companyName', d.string())
  );
}

function compareByDate(a, b) {
  if (a.plannedAt < b.plannedAt) {
    return -1;
  } else if (a.plannedAt > b.plannedAt) {
    return 1;
  } else {
    return 0;
  }
}

export function decode(data: any): DeliveryRequest[] {
  return d
    .orThrow(d.decode(data, d.array(decodeDeliveryRequest())))
    .sort(compareByDate)
    .reverse();
}
