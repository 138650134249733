import React from 'react';
import { Attachment } from './types';
import UploadedFile from './UploadedFile';
import { translate } from 'shared/utils/I18n';

const t = translate('review_wizard.uploaded_files');

interface Props {
  onDeleteAttachment: (id: number) => void;
  uploadedFiles: Attachment[];
}

const UploadedFiles = ({ onDeleteAttachment, uploadedFiles }: Props) =>
  uploadedFiles.length === 0 ? null : (
    <div className="rw-uploaded-files rw-text-sm">
      <h5 className="rw-h">{t('title', { count: uploadedFiles.length })}</h5>
      <ul className="rw-space-y-3">
        {uploadedFiles.map((attachment: Attachment) => (
          <UploadedFile
            key={attachment.id}
            onDeleteAttachment={onDeleteAttachment}
            uploadedFile={attachment}
          />
        ))}
      </ul>
    </div>
  );

export default UploadedFiles;
