import { chain } from 'lodash';
import { period, AvailabilityPeriod } from './AvailabilityPeriod';
import { Period } from './Period';

/*
 * `AvailabilityPeriods` is a collection of `AvailabilityPeriod`s.
 */
export type AvailabilityPeriods = AvailabilityPeriod[];

function types(availabilityPeriods: AvailabilityPeriods): Set<string> {
  return new Set(availabilityPeriods.map((p) => p.type));
}

/*
 * Check whether any availability period has the given `type`.
 */
export function hasType(
  availabilityPeriods: AvailabilityPeriods,
  type: string
): boolean {
  return types(availabilityPeriods).has(type);
}

/*
 * Find the period that is the latest to end.
 */
export function latestPeriod(
  availabilityPeriods: AvailabilityPeriods
): Period | undefined {
  return chain(availabilityPeriods).map(period).maxBy('to').value();
}
