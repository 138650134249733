import React from 'react';

import { differenceInDays, parseISO } from 'date-fns';
import * as I18n from 'shared/utils/I18n';
import * as Helpers from 'shared/utils/Helpers';
import ReminderAlerts from '../ReminderAlerts';
import { Invoice, InvoiceContact } from 'types';

const ns = 'app.invoiceStatus';

interface Props {
  invoice: Invoice;
  invoiceContacts: InvoiceContact[];
  verificationState: string;
}

interface PaymentProps {
  invoice: Invoice;
  sent: boolean;
}

const PaymentState = ({ invoice, sent }: PaymentProps) => {
  const today = new Date();
  const daysRemaining = differenceInDays(parseISO(invoice?.dueDate), today);
  const daysRemainingText = I18n.ut('common.days', {
    count: Math.abs(daysRemaining),
  });
  let bodyHtml;

  if (daysRemaining > 0) {
    bodyHtml = 'dueInFuture';
  } else if (daysRemaining === 0) {
    bodyHtml = 'dueToday';
  } else {
    bodyHtml = 'dueInPast';
  }

  return (
    <p>
      {sent &&
        I18n.nt(ns, 'open.paymentState.sent', {
          dateSent: Helpers.dateFormat(invoice?.dateSent),
        })}
      {I18n.nt(ns, `open.paymentState.${bodyHtml}`, {
        days: daysRemainingText,
        dueDate: Helpers.dateFormat(invoice?.dueDate),
      })}
    </p>
  );
};

const Open = ({ invoice, verificationState, invoiceContacts }: Props) => {
  const formattedLastActionAt = Helpers.dateFormat(invoice.lastActionAt);

  return (
    <div>
      <PaymentState invoice={invoice} sent={verificationState !== 'not_sent'} />
      <p>{I18n.nt(ns, `open.financingState.${invoice.financingState}`)}</p>
      <ReminderAlerts invoice={invoice} invoiceContacts={invoiceContacts} />
      <p className="lastActionAt">
        {I18n.nt(ns, 'lastChange', { date: formattedLastActionAt })}
      </p>
    </div>
  );
};

export default Open;
