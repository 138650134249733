import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { trackEvent } from 'shared/utils/tracker';
import { ReduxState } from 'types';
import usePollFinancialDashboard from 'FinancialDashboard/usePollFinancialDashboard';

const StatusPoller = ({ children }) => {
  const loadCounter = useSelector(
    (state: ReduxState) => state.financialDashboard.loadCounter
  );
  const pollFinancialDashboard = usePollFinancialDashboard();

  useEffect(() => {
    let isCancelled = false;

    pollFinancialDashboard(() => isCancelled).then(
      (status) => {
        trackEvent(`Dashboard status ${status}`);
      },
      (error) => {
        Sentry.captureMessage(JSON.stringify(error));
        trackEvent(`Dashboard error ${error}`);
      }
    );

    return () => {
      isCancelled = true;
    };
  }, [loadCounter]);

  return children;
};

export default StatusPoller;
