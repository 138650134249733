import React from 'react';
import { Table } from 'shared/bootstrap';
import { dateFormat, toEuro } from 'shared/utils/Helpers';
import { translate } from 'shared/utils/I18n';
import Section from './Section';

const t = translate('insights.transactions_table');

const TransactionsTable = ({ title, transactions }) => {
  return (
    <Section title={title}>
      {transactions.length === 0 ? (
        <div className="ti-p-10">{t('no_transactions')}</div>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>{t('recent_transactions')}</th>
              <th>{t('counterparty')}</th>
              <th>{t('amount')}</th>
              <th>{t('date')}</th>
              <th>{t('description')}</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, i) => (
              <tr key={i} className="odd:tw-bg-gray-50">
                <td>{transaction.counterpartyName}</td>
                <td>{transaction.counterpartyIban}</td>
                <td>{toEuro(transaction.amount)}</td>
                <td>{dateFormat(transaction.transactionDate)}</td>
                <td>{transaction.description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Section>
  );
};

export default TransactionsTable;
