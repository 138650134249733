import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import client from 'shared/utils/client';
import { User } from 'types';
import { trackFormSubmit } from 'shared/utils/tracker';
import resolver from 'hookForm/resolver';
import {
  validateConfirmation,
  validatePassword,
} from 'shared/utils/validation';

type Status =
  | ''
  | 'loading_user'
  | 'ready_for_reset'
  | 'reset_done'
  | 'resetting'
  | 'reset_failed'
  | 'token_not_found';

interface FormValues {
  password: string;
  passwordConfirmation: string;
}

interface RouteParams {
  resetToken?: string;
}

const validateForm = ({ password, passwordConfirmation }: FormValues) => ({
  password: validatePassword(password),
  passwordConfirmation:
    validatePassword(passwordConfirmation) ||
    validateConfirmation(password, passwordConfirmation),
});

const useResetPassword = () => {
  const params = useParams<RouteParams>();
  const resetToken = params?.resetToken;
  const [user, setUser] = useState<User | null>(null);
  const [status, setStatus] = useState<Status>('');

  const loadUser = async () => {
    setStatus('loading_user');

    const response = await client<User>(
      'GET',
      `/api/user_reset_passwords/${resetToken}`,
      {},
      { raiseError: false }
    );

    if (response.error) {
      setStatus('token_not_found');
    } else {
      setUser(response.payload);
      setStatus('ready_for_reset');
    }
  };

  useEffect(() => {
    if (resetToken) {
      loadUser();
    } else {
      setStatus('token_not_found');
    }
  }, []);

  const resetPassword = async ({ password, passwordConfirmation }) => {
    trackFormSubmit('resetpassword');
    setStatus('resetting');

    const response = await client(
      'POST',
      `/api/user_reset_passwords`,
      {
        token: resetToken,
        password,
        passwordConfirmation,
      },
      { raiseError: false }
    );

    if (response.error) {
      setStatus('reset_failed');
    } else {
      setStatus('reset_done');
    }
  };

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: resolver(validateForm),
  });

  const submit = handleSubmit(resetPassword);

  return {
    control,
    email: user?.email,
    status,
    submit,
  };
};

export default useResetPassword;
