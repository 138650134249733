import { Restriction } from './Restriction';

interface RestrictionStep {
  step: number;
  limit: number;
  date?: string;
}

const calculateRestrictionSteps = (
  restrictionInfo: Restriction
): RestrictionStep[] => {
  const {
    creditLimit,
    restriction,
    restrictionFrequency,
    restrictionStepAmount,
    restrictionTarget,
    restrictionStartDate,
  } = restrictionInfo;

  if (
    !restriction ||
    !restrictionFrequency ||
    !restrictionStepAmount ||
    !restrictionTarget
  ) {
    return [];
  }

  const steps: RestrictionStep[] = [];
  let currentLimit = creditLimit;
  let stepCount = 0;
  const date = restrictionStartDate
    ? new Date(restrictionStartDate)
    : new Date();

  while (currentLimit > restrictionTarget) {
    stepCount++;
    currentLimit -= restrictionStepAmount;

    if (restrictionFrequency === 'month') {
      date.setMonth(date.getMonth() + 1);
      date.setDate(1);
      while (date.getDay() !== 3) {
        date.setDate(date.getDate() + 1);
      }
    } else {
      date.setDate(date.getDate() + 7);
    }

    steps.push({
      step: stepCount,
      limit: Math.max(currentLimit, restrictionTarget),
      date: date.toISOString().split('T')[0],
    });
  }

  return steps;
};

export default calculateRestrictionSteps;
