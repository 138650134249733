import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { map } from 'lodash';

import * as routes from 'app/routes';
import InactiveSeller from 'signup/components/InactiveSeller';
import Reactivation from 'signup/components/reactivation';
import Mandate from 'signup/containers/Mandate';
import MandateReturn from 'signup/containers/MandateReturn';
import NotFound from 'app/components/NotFound';
import ProfilePage from 'app/components/seller/ProfilePage';
import SellerRoot from 'app/components/seller/SellerRoot';
import Signup from 'signup/containers/SellerSignup';
import Onboarding from 'Onboarding';
import FinancialDashboard from 'FinancialDashboard';
import getProductConfig from 'productConfigs';
import { isActive } from 'models/Seller';
import { currentSellerSelector } from 'shared/selectors';
import useSession from 'shared/hooks/useSession';
import { getQueryParam } from 'shared/utils/Helpers';
import { MinimalSeller } from 'types';
import { Status } from 'types/Seller';
import RedirectToFrontpage from 'shared/utils/routing/RedirectToFrontpage';
import useFrontpageRoute from 'shared/utils/routing/useFrontpageRoute';
import {
  useCanAccessSignupRoute,
  useCanAccessOnboardingRoute,
} from 'shared/hooks/useCanAccessRoutes';

const Routes = () => {
  const seller = useSelector(currentSellerSelector) as MinimalSeller;
  const location = useLocation();
  const productConfig = getProductConfig(seller.productVersion);
  const canAccessSignupRoute = useCanAccessSignupRoute();
  const canAccessOnboardingRoute = useCanAccessOnboardingRoute();
  const session = useSession();
  const getFrontpageRoute = useFrontpageRoute();

  const getFinancialDashboardRedirectUrl = () => {
    const statusFromAis = getQueryParam(location.search, 'status');
    const isRedirectedFromAis = Boolean(statusFromAis);

    if (!isRedirectedFromAis) {
      return undefined;
    }

    if (seller.status === 'draft') {
      return `${routes.SELLER_SIGNUP_BANK_STATEMENTS_STEP}?status=${statusFromAis}`;
    }

    const redirectUrl = getFrontpageRoute(false);
    return `${redirectUrl}?status=${statusFromAis}`;
  };

  const financialDashboardRedirectUrl = getFinancialDashboardRedirectUrl();
  const approvedOrCancelled =
    seller.status === Status.APPROVED || seller.status === Status.CANCELLED;

  return (
    <Switch>
      <Route path={[routes.PROFILE_PAGE_TAB, routes.PROFILE_PAGE]}>
        {approvedOrCancelled ? <ProfilePage /> : <RedirectToFrontpage />}
      </Route>
      <Route path={routes.DASHBOARD}>
        {seller.canViewFinancialDashboard && approvedOrCancelled ? (
          <FinancialDashboard.ForCharlie />
        ) : (
          <RedirectToFrontpage />
        )}
      </Route>
      <Route path={routes.SELLER_ONBOARDING}>
        {canAccessOnboardingRoute ? <Onboarding /> : <RedirectToFrontpage />}
      </Route>
      <Route path={routes.FINANCIAL_DASHBOARD}>
        {financialDashboardRedirectUrl ? (
          <Redirect to={financialDashboardRedirectUrl} />
        ) : (
          <FinancialDashboard.ForSignup />
        )}
      </Route>
      {session.authenticationMethod !== 'token' && (
        <Route path={routes.SELLER_SIGNUP_STEP}>
          {canAccessSignupRoute ? <Signup /> : <RedirectToFrontpage />}
        </Route>
      )}
      <Route exact path={routes.MANDATE}>
        <Mandate />
      </Route>
      <Route
        exact
        path={routes.MANDATE_RETURN}
        component={MandateReturn}
      ></Route>
      {map(productConfig.routes, (routeProps, index) => (
        <Route key={index} path={routeProps.path} exact={routeProps.exact}>
          {approvedOrCancelled ? (
            <routeProps.component />
          ) : (
            <RedirectToFrontpage />
          )}
        </Route>
      ))}
      <Route component={NotFound} />
    </Switch>
  );
};

const Sellers = () => {
  const seller = useSelector(currentSellerSelector) as MinimalSeller;
  const productConfig = getProductConfig(seller.productVersion);

  return (
    <SellerRoot navBarLinks={productConfig.navBarLinks} seller={seller}>
      <Switch>
        <Route path={routes.INACTIVE}>
          {seller?.status == Status.INACTIVE ? (
            <InactiveSeller />
          ) : (
            <RedirectToFrontpage />
          )}
        </Route>
        <Route path={routes.REACTIVATION}>
          {seller?.status == Status.LOST ||
          seller?.status == Status.REJECTED ? (
            <Reactivation />
          ) : (
            <RedirectToFrontpage />
          )}
        </Route>
        <Route path={[routes.CUSTOMER_APP_ROOT, routes.OVERVIEW_PAGE_TAB]}>
          {isActive(seller) ? <Routes /> : <RedirectToFrontpage />}
        </Route>
      </Switch>
    </SellerRoot>
  );
};

export default Sellers;
