import React from 'react';
import StatusPoller from './StatusPoller';

const Page = ({ children }) => (
  <div className="Profile">
    <StatusPoller>{children}</StatusPoller>
  </div>
);

export default Page;
