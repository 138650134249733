import React from 'react';

import Button from 'shared/components/tailwind/Button';
import * as I18n from 'shared/utils/I18n';
import TextArea from 'hookForm/TextArea';
import useContactPage from './useContactPage';
import Select from 'hookForm/Select';

const ns = 'app.profilePage.contactForm';

const ContactPage = () => {
  const { control, categoryOptions, isSubmitting, handleSubmit } =
    useContactPage();

  return (
    <div className="md:tw-w-2/3">
      <h3>{I18n.nt(ns, 'title')}</h3>
      <form onSubmit={handleSubmit}>
        <p>{I18n.nt(ns, 'intro')}</p>
        <div className="tw-mb-6">
          <Select
            control={control}
            label={I18n.nt(ns, 'category')}
            name="category"
            options={categoryOptions}
          />
        </div>
        <TextArea
          autoFocus
          control={control}
          label={I18n.nt(ns, 'explanation')}
          name="explanation"
        />
        <Button type="submit" className="btn-primary" disabled={isSubmitting}>
          {I18n.nt(ns, 'submit')}
        </Button>
      </form>
    </div>
  );
};

export default ContactPage;
