import client from 'shared/utils/client';
import updateEntities from 'shared/modules/updateEntities';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import { sessionExpired } from 'shared/redux/modules/session';

/* Load full sellers for a customer account
 * Returns a function that loads the full sellers
 * Assumes an customer account id
 * Updates the sellers entity
 */
const useLoadFullSellersForCustomerAccount = (customerAccountId) => {
  const dispatch = useAppDispatch();

  const loadFullSellers = async () => {
    if (!customerAccountId) {
      throw new Error('customerAccountId: must have a customer account id');
    }

    const response = await client(
      'GET',
      `/api/customer_accounts/${customerAccountId}`,
      {},
      { raiseError: false }
    );

    if (response.error) {
      if (response.statusCode === 401) {
        dispatch(sessionExpired());
      }
      return false;
    }

    dispatch(updateEntities(response.payload));
    return true;
  };

  return loadFullSellers;
};

export default useLoadFullSellersForCustomerAccount;
