import { useForm } from 'react-hook-form';
import resolver from 'hookForm/resolver';
import { validatePresence } from 'shared/utils/validation';
import * as I18n from 'shared/utils/I18n';

interface FormValues {
  explanation: string;
  category: string;
}

const useContactPage = () => {
  const validate = ({ explanation, category }: FormValues) => ({
    explanation: validatePresence(explanation),
    category: validatePresence(category),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: resolver(validate),
  });

  const submit = async () => {};

  const contactOptions = [
    'change_address',
    'change_business_legal_form',
    'change_iban',
    'change_name',
  ];

  const categoryOptions: [string, string][] = contactOptions.map((key) => [
    key,
    I18n.nt('app.profilePage.contactForm.categories', key),
  ]);

  return {
    control,
    isSubmitting,
    handleSubmit: handleSubmit(submit),
    categoryOptions,
  };
};

export default useContactPage;
