import React, { ReactNode } from 'react';
import * as I18n from 'shared/utils/I18n';
import * as ibanValidation from 'models/Iban';

const ns = 'app.profile.onboardingStep.modals.mandateStart.signDescription';

interface DescProps {
  contentKey: string;
}

const Desc = ({ contentKey }: DescProps) => (
  <li
    className="tw-ml-6 tw-mt-3 only:tw-ml-0 only:tw-list-none"
    dangerouslySetInnerHTML={{ __html: I18n.nt(ns, contentKey) }}
  />
);

interface SignStepsProps {
  children?: ReactNode;
}

const SignSteps = ({ children }: SignStepsProps) => (
  <ol className="mandate-sign-description tw-list-decimal tw-p-0">
    {children}
  </ol>
);

const Opening = () => <Desc contentKey="opening" />;

const SendFilledOutForm = () => <Desc contentKey="sendFilledOutForm" />;

const SendConfirmationCopy = () => <Desc contentKey="sendConfirmationCopy" />;

const GenericAnalogBankDescription = () => (
  <SignSteps>
    <Opening />
    <SendFilledOutForm />
    <SendConfirmationCopy />
  </SignSteps>
);

const BunqDescription = () => (
  <SignSteps>
    <Opening />
    <Desc contentKey="sendFilledOutFormBunq" />
    <Desc contentKey="bunq" />
  </SignSteps>
);

const KnabDescription = () => (
  <SignSteps>
    <Opening />
    <Desc contentKey="knab" />
    <SendConfirmationCopy />
  </SignSteps>
);

const DigitalBankDescription = () => (
  <SignSteps>
    <Desc contentKey="digitalBank.0" />
    <Desc contentKey="digitalBank.1" />
  </SignSteps>
);

const FallbackDescription = () => (
  <SignSteps>
    <Desc contentKey="fallback" />
  </SignSteps>
);

const descriptionByBankCode = {
  BUNQ: BunqDescription, // NL23BUNQ0123456789
  KNAB: KnabDescription, // NL68KNAB0123456789
  SNSB: GenericAnalogBankDescription, // NL12SNSB0123456789
  TRIO: GenericAnalogBankDescription, // NL70TRIO0123456789
  ABNA: DigitalBankDescription, // NL02ABNA0123456789
  INGB: DigitalBankDescription, // NL69INGB0123456789
  RABO: DigitalBankDescription, // NL44RABO0123456789
};

interface SignProcessDescription {
  iban: string;
}

const SignProcessDescription = ({ iban }: SignProcessDescription) => {
  const bankCodeFromIban = ibanValidation.bankCode(iban);
  const Component =
    descriptionByBankCode[bankCodeFromIban] || FallbackDescription;

  return <Component />;
};

export default SignProcessDescription;
