import { createSelector } from 'reselect';
import qs from 'qs';
import { isValidStep, SignupStep } from 'models/SignupOrder';
import { SELLER_SIGNUP_STEP } from 'app/routes';
import { paramValueFromUrl } from 'shared/utils/routing/routeHelpers';
import { isCorrectReferrer } from 'shared/utils/referrerHelpers';
import {
  Attachment,
  CampaignCompany,
  Company,
  Contract,
  CustomerAccount,
  CustomerSignup,
  MainUser,
  MinimalSeller,
  Partner,
  ReduxState,
  Seller,
  Review,
} from 'types';
import * as InstantPaymentAvailability from 'types/InstantPaymentAvailability';

export interface CampaignInfo {
  campaignCompany: CampaignCompany | null;
  code?: string;
  email?: string;
  status: 'loading' | 'loaded' | 'error';
}

export interface PayOffInfo {
  currentBalance: string | undefined;
  florynIban: string | undefined;
  maxRemainingExtraRepaymentAmount?: string;
}

export const userSelector = (state: ReduxState) => {
  const userId = customerAccountSelector(state)?.user;

  if (!userId) {
    return undefined;
  }

  return state.entities.users[userId];
};

export const currentSellerSelector = (
  state: ReduxState
): MinimalSeller | Seller | undefined => {
  const currentSellerId = state.session.currentSellerId;

  if (currentSellerId) {
    return state.entities.sellers[currentSellerId];
  }

  return undefined;
};

export const currentReviewSelector = (
  state: ReduxState
): Review | undefined => {
  return state.charlie.review.payload || undefined;
};

export const customerAccountSelector = (
  state: ReduxState
): CustomerAccount | undefined => {
  const customerAccountId = state.session.customerAccount;

  if (!customerAccountId) {
    return undefined;
  }

  return state.entities.customerAccounts[customerAccountId];
};

export const customerSignupSelector = (
  state: ReduxState
): CustomerSignup | undefined => customerAccountSelector(state)?.customerSignup;

export const mainUserSelector = (state: ReduxState): MainUser | undefined =>
  customerAccountSelector(state)?.mainUser;

export const mainSellerSelector = (
  state: ReduxState
): MinimalSeller | Seller | undefined => {
  const mainSellerId = customerAccountSelector(state)?.mainSellerId;

  if (mainSellerId) {
    return state.entities.sellers[mainSellerId];
  }

  return undefined;
};

export const companySelector = (state: ReduxState) => {
  const currentSeller = currentSellerSelector(state);
  if (currentSeller) {
    return state.entities.companies[currentSeller.company];
  } else {
    return undefined;
  }
};

export const sellerIdsSelector = (state: ReduxState) =>
  customerAccountSelector(state)?.sellers;

export const companyWithIdSelector = (
  state: ReduxState,
  id?: number
): Company | undefined => (id ? state.entities.companies[id] : undefined);

export const campaignSelector: (state: ReduxState) => CampaignInfo | null =
  createSelector(
    (state: ReduxState) => state.entities.campaignCompanies,
    (state: ReduxState) => state.signup.campaign,
    (campaignCompanies, campaign) => {
      if (!campaign) {
        return null;
      }

      const { campaignCompanyId, code, email, status } = campaign;

      const campaignCompany = campaignCompanyId
        ? campaignCompanies[campaignCompanyId]
        : null;
      const campaignCode = campaignCompany
        ? campaignCompany.campaignCode
        : code;

      return {
        campaignCompany,
        code: campaignCode,
        email,
        status,
      };
    }
  );

export const currentStepSelector = (
  state: ReduxState
): SignupStep | undefined => {
  const stepFromUrl = paramValueFromUrl(
    state.router.location?.pathname || '',
    SELLER_SIGNUP_STEP,
    'currentStep'
  );

  if (isValidStep(stepFromUrl)) {
    return stepFromUrl as SignupStep;
  } else {
    return undefined;
  }
};

export const referrerSelector = (state: ReduxState): string | undefined => {
  let referrer: string | undefined;

  if (state.signup.referrer) {
    referrer = state.signup.referrer;
  } else {
    const seller = currentSellerSelector(state);
    if (seller?.entityKind === 'Seller') {
      referrer = seller.referrer;
    }
  }

  if (referrer && isCorrectReferrer(referrer)) {
    return referrer;
  } else {
    return undefined;
  }
};

export const featureFlagsSelector = (state) =>
  state.session && state.session.featureFlags;

export const isFeatureFlagEnabledSelector = (state, name) =>
  state.session &&
  state.session.featureFlags &&
  state.session.featureFlags[name];

export const isDeltaSelector = (state) =>
  currentSellerSelector(state)?.productVersion === 'delta';

export const contractSelector = (state: ReduxState): Contract | undefined => {
  const seller = currentSellerSelector(state);

  if (!seller) {
    return undefined;
  }

  const contractId = (seller as Seller).contract;

  if (!contractId) {
    return undefined;
  }

  return state.entities.contracts[contractId];
};

export const attachmentSelector = (
  state: ReduxState,
  attachmentId
): Attachment | undefined => state.entities.attachments[attachmentId];

export const initialProductPreferenceSelector = (state: ReduxState) => {
  const search = state.router.location?.search;

  return search ? qs.parse(search.substring(1)).productvoorkeur : null;
};

export const instantPaymentAvailabilitySelector: (
  state: ReduxState
) => InstantPaymentAvailability.InstantPaymentAvailability = createSelector(
  (state: ReduxState) => currentSellerSelector(state),
  (seller) => {
    if (seller?.instantPaymentEnabled === true) {
      return InstantPaymentAvailability.enabled(
        seller.instantPaymentAvailableAmount
      );
    } else {
      return InstantPaymentAvailability.notEnabled();
    }
  }
);

export const aBTestSelector =
  (testId: string) =>
  (state: ReduxState): boolean => {
    const seller = currentSellerSelector(state);

    return seller?.aBTests[testId] === true;
  };

export const isInternalAccountDetectionEnabled = aBTestSelector(
  'internalAccountDetection'
);

export const improvedCustomerContractsEnabled = aBTestSelector(
  'improved_customer_contracts'
);

export const partnerSelector = (state: ReduxState): Partner | null => {
  return state.partnerSignup?.partner ?? null;
};
