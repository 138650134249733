import React from 'react';

import SignupContainer from 'signup/components/SignupContainer';
import CurrentStep from './seller/CurrentStep';
import { SignupStep } from 'models/SignupOrder';

interface Props {
  currentStep: SignupStep;
}

const SellerSignup = ({ currentStep }: Props) => {
  const includeRequestSummary =
    currentStep === 'bankgegevens' ||
    currentStep === 'aanvullende-gegevens' ||
    currentStep === 'ubo-identificatie';
  return (
    <SignupContainer includeRequestSummary={includeRequestSummary}>
      <CurrentStep />
    </SignupContainer>
  );
};

export default SellerSignup;
