import { useState } from 'react';
import client from 'shared/utils/client';

const useContract = (sellerId: number, openLinkOnNewPage = false) => {
  const [requestStatus, setRequestStatus] = useState<string>('');

  const redirectToSignUrl = async () => {
    if (requestStatus === 'loading') {
      return;
    }

    setRequestStatus('loading');

    const response = await client(
      'GET',
      `/api/sellers/${sellerId}/contract`,
      {},
      { raiseError: false }
    );

    if (response.error) {
      setRequestStatus('error');
    } else {
      const { status, url } = response.payload;

      if (status === 'ok') {
        setRequestStatus('loaded');

        if (openLinkOnNewPage) {
          const newTab = window.open(url, '_blank', 'noopener,noreferrer');
          if (newTab) {
            newTab.opener = null;
          }
        } else {
          location.assign(url);
        }
      } else {
        setRequestStatus('error');
      }
    }
  };

  return {
    redirectToSignUrl,
    requestStatus,
  };
};

export default useContract;
