import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { TooltipSize } from 'shared/components/Tooltip';
export type { TooltipSize } from 'shared/components/Tooltip';
import IconTooltip from 'shared/components/IconTooltip';

interface Props {
  hint: ReactNode;
  iconClassName?: string;
  tooltipSize?: TooltipSize;
}

const HintPopover = ({ hint, iconClassName, tooltipSize = 'xs' }: Props) => {
  return (
    <IconTooltip
      iconClassName={classNames('tw-ml-[6px]', iconClassName)}
      tooltipSize={tooltipSize}
      tooltipText={hint}
    />
  );
};

export default HintPopover;
