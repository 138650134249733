import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import * as I18n from 'shared/utils/I18n';
import { formatAmount, formatDate } from '../charts/LabelFormatters';
import CustomTooltip from './CustomTooltip';

const ns = 'signup.onboarding.financial_dashboard.bank_balance_chart';

const Chart = ({ data, colors }) => {
  if (data.length === 0) {
    return null;
  }
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          axisLine={true}
          dataKey="date"
          tickLine={true}
          ticks={data
            .map((point) => point.date)
            .filter((date) => date.endsWith('-01'))} // assume the first of the month is always present
        />
        <YAxis
          axisLine={true}
          allowDecimals={false}
          interval={0}
          tickFormatter={formatAmount}
        />
        <Tooltip
          wrapperStyle={{ zIndex: 1 }}
          labelFormatter={formatDate}
          content={<CustomTooltip overrideNameLabel={I18n.nt(ns, 'balance')} />}
          cursor={{ fill: 'rgba(0, 0, 0, .05)' }}
        />
        <Line
          type="monotone"
          dataKey="amount"
          stroke={colors[0]}
          dot={false}
          strokeWidth={3}
          isAnimationActive={false}
        />
        <ReferenceLine y={0} stroke="red" strokeDasharray="3 3" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
