import React from 'react';
import { Alert, Button, Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import useFlorynPayFailedConsent from './useFlorynPayFailedConsent';
import Well from 'shared/components/tailwind/Well';
import { formatIban, toEuro } from 'shared/utils/Helpers';
import ProcessingStep from 'charlie/components/PayModal/ProcessingStep';

const ns = 'charlie.frontpage.floryn_pay_failed_consent';

interface Props {
  parentPath: string;
}

const FlorynPayFailedConsent = ({ parentPath }: Props) => {
  const {
    buttonsDisabled,
    close,
    payment,
    restart,
    revoke,
    serverError,
    step,
  } = useFlorynPayFailedConsent(parentPath);

  const consentGivenWarning =
    payment &&
    (payment.status === 'consent_given' || payment.status === 'paid');
  const paymentPendingWarning =
    payment &&
    (payment.status === 'pending' ||
      payment.status === 'transferring' ||
      payment.status === 'consent_pending');

  const form = (
    <>
      <Modal.Body>
        {serverError && <Alert type="danger">{serverError}</Alert>}
        {consentGivenWarning && (
          <Alert type="warning">{I18n.nt(ns, 'consent_given')}</Alert>
        )}
        {paymentPendingWarning && (
          <Alert type="warning">{I18n.nt(ns, 'payment_pending')}</Alert>
        )}
        {payment && payment.status === 'consent_failed' && (
          <div className="tw-mb-4">{I18n.nt(ns, 'intro')}</div>
        )}
        <Well>
          <ul className="tw-m-0 tw-list-none">
            <li>
              <label>{I18n.nt(ns, 'details_name')}:</label>{' '}
              {payment?.creditorName}
            </li>
            <li>
              <label>{I18n.nt(ns, 'details_iban')}:</label>{' '}
              {formatIban(payment?.creditorIban)}
            </li>
            {payment?.paymentReference && (
              <li>
                <label>{I18n.nt(ns, 'details_payment_reference')}:</label>{' '}
                {payment?.paymentReference}
              </li>
            )}
            {payment?.description && (
              <li>
                <label>{I18n.nt(ns, 'details_description')}:</label>{' '}
                {payment?.description}
              </li>
            )}
            <li>
              <label>{I18n.nt(ns, 'details_amount')}:</label>{' '}
              {toEuro(payment?.amount)}
            </li>
          </ul>
        </Well>
        <div className="tw-mb-4">{I18n.nt(ns, 'action')}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-primary-inverted"
          onClick={revoke}
          disabled={buttonsDisabled}
        >
          {I18n.nt(ns, 'revoke_button')}
        </Button>
        <Button
          className="btn-primary"
          onClick={restart}
          disabled={buttonsDisabled}
        >
          {I18n.nt(ns, 'restart_button')}
        </Button>
        <div className="tw-mt-5 tw-flex tw-text-left tw-text-gray-600">
          <span className="icon icon-lock tw-mr-2 tw-mt-1" />
          <span>{I18n.nt(ns, 'next_step')}</span>
        </div>
      </Modal.Footer>
    </>
  );

  return (
    <Modal show onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
      </Modal.Header>
      {payment && step === 'processing' ? (
        <Modal.Body>
          <div className="tw-mb-6 md:tw-mb-8">
            <ProcessingStep florynPayPaymentId={payment.id} />
          </div>
        </Modal.Body>
      ) : (
        form
      )}
    </Modal>
  );
};

export default FlorynPayFailedConsent;
