export const createLoadReducer = (actions) => {
  const initialState = {
    payload: null,
    status: 'empty',
  };

  return (state = initialState, action = {}) => {
    switch (action.type) {
      case actions.REQUEST:
        return {
          ...state,
          status: 'loading',
        };

      case actions.SUCCESS:
        return {
          ...state,
          payload: action.payload,
          status: 'loaded',
        };

      case actions.FAILURE:
        return {
          ...state,
          status: 'error',
        };

      default:
        return state;
    }
  };
};
