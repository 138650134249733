import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import resolver from 'hookForm/resolver';
import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';
import {
  validateEmail,
  validatePhone,
  validatePresence,
} from 'shared/utils/validation';
import { CampaignInfo } from 'shared/selectors';
import { getFirstName, getLastName } from 'shared/utils/Helpers';
import { getQueryParam } from 'shared/utils/Helpers';
import { signup } from 'signup/actions/GuestSignup';
import { scrollToTop } from 'shared/utils/scroll';
import { CandidateCompany, Company, ReduxState } from 'types';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import {
  validateAmount,
  validateExpectedRevenueAmount,
} from 'signup/shared/validations';

export interface FormData {
  acceptsPromoEmail: string;
  company: CandidateCompany | Company | null;
  email: string;
  expectedFinancingAmount: string | null;
  expectedRevenue: string | null;
  firstName: string;
  lastName: string;
  phone: string;
}

const selectInitialCompany = (
  state: ReduxState,
  campaign: CampaignInfo | null
): Company | undefined => {
  if (campaign?.campaignCompany) {
    return state.entities.companies[campaign.campaignCompany.company];
  } else {
    return undefined;
  }
};

export const validate = ({
  acceptsPromoEmail,
  company,
  email: emailField,
  expectedFinancingAmount,
  expectedRevenue,
  firstName,
  lastName,
  phone,
}: FormData) => ({
  firstName: validatePresence(firstName),
  lastName: validatePresence(lastName),
  email: validatePresence(emailField) || validateEmail(emailField),
  phone: validatePhone(phone),
  company: validatePresence(company),
  acceptsPromoEmail: validatePresence(acceptsPromoEmail),
  expectedFinancingAmount: validateAmount(expectedFinancingAmount),
  expectedRevenue: validateExpectedRevenueAmount(expectedRevenue),
});

const useSignupForm = (campaign: CampaignInfo | null) => {
  const location = useLocation();
  const referrer = useSelector((state: ReduxState) => state.signup.referrer);
  const initialCompany = useSelector((state: ReduxState) =>
    selectInitialCompany(state, campaign)
  );
  const dispatch = useAppDispatch();
  const [duplicateEmail, setDuplicateEmail] = useState<string | null>(null);
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);

  const getInitialValues = () => {
    const initialValues = {
      acceptsPromoEmail: 'false',
      expectedFinancingAmount: '50000',
    };

    if (campaign?.campaignCompany) {
      const {
        campaignCompany: { contactName, phoneNumber },
        email,
      } = campaign;

      return {
        ...initialValues,
        company: initialCompany,
        email,
        firstName: getFirstName(contactName),
        lastName: getLastName(contactName),
        phone: phoneNumber,
      };
    } else {
      const email = getQueryParam(location.search, 'email') || '';

      return {
        ...initialValues,
        email,
        referrer,
      };
    }
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: getInitialValues(),
    resolver: resolver(validate),
  });

  const submit = async (values: FormData) => {
    setDuplicateEmail(null);

    const action = await dispatch(signup(values));

    if (action.error) {
      scrollToTop();

      const generalErrorMessages = handleFormErrorsFromResponse(
        action,
        setError
      );
      setServerErrorMessages(generalErrorMessages);

      if (action.statusCode === 422 && action.payload?.validationErrors) {
        const validationErrors = action.payload.validationErrors;

        if (
          validationErrors._error &&
          validationErrors._error.length !== 0 &&
          validationErrors._error[0] === 'email_already_used'
        ) {
          setDuplicateEmail(values.email);
        }
      }
    }
  };

  const onSubmit = handleSubmit(submit);

  return {
    control,
    duplicateEmail,
    isSubmitting,
    onSubmit,
    serverErrorMessages,
  };
};

export default useSignupForm;
