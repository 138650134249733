import { useSelector } from 'react-redux';

import { ReduxState } from 'types';
import { isAlfa } from 'models/Seller';
import useCurrentSeller from './useCurrentSeller';

const useReviewInfo = () => {
  const seller = useCurrentSeller();
  return useSelector((state: ReduxState) =>
    isAlfa(seller)
      ? state.app.invoices.dashboard.reviewInfo
      : state.charlie.dashboard.payload?.reviewInfo
  );
};

export default useReviewInfo;
