import React from 'react';
import * as I18n from 'shared/utils/I18n';
import useContactInformation from 'shared/hooks/useContactInformation';

const i18nNamespace = 'app.sidebars.support';

const Support = () => {
  const { displayPartnerContactInformation, phoneLink, phoneNumber } =
    useContactInformation();

  return (
    <div className="tw-flex tw-flex-col">
      <img
        src={
          displayPartnerContactInformation
            ? '/images/staff_partners.png'
            : '/images/staff.png'
        }
        className="tw-mb-4 tw-max-h-[100%] tw-max-w-[246px] sm:tw-mb-8"
        alt={I18n.nt(i18nNamespace, 'staff')}
      />
      <span className="tw-text-base tw-font-bold">
        {I18n.nt(i18nNamespace, 'title')}
      </span>
      <span>{I18n.nt(i18nNamespace, 'call_us')}</span>
      <a
        className="tw-mt-1 tw-font-heading tw-text-[24px] tw-font-bold tw-text-floryn-green hover:tw-text-floryn-green"
        href={phoneLink}
      >
        {phoneNumber}
      </a>
    </div>
  );
};

export default Support;
