import React from 'react';
import * as I18n from 'shared/utils/I18n';
import Button from 'shared/components/tailwind/Button';
import BankStatementInfo from 'shared/components/UploadBankStatements/BankStatementInfo';
import UploadStatusInfo from 'shared/components/UploadBankStatements/UploadStatusInfo';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import { bankStatementStartDate } from 'shared/utils/bankStatementStartDate';
import RequiredIbans from 'shared/components/UploadBankStatements/RequiredIbans';
import UploadStatementsWarning from 'shared/components/UploadBankStatements/UploadStatementsWarning';
import BankstatementUploadInput from 'hookForm/BankstatementUploadInput';
import DontWorryMessage from 'shared/components/DontWorryMessage';
import useDocumentUpload from './useDocumentUpload';

const ns = 'app.profile.bankStatementsStep.documentUpload';

const DocumentUpload = () => {
  const {
    attachmentsWithWarning,
    bankStatementAttachments,
    control,
    goToPreviousStep,
    handleBankConnectionLink,
    removeInvalidAttachments,
    seller,
    setUploading,
    status,
    submit,
    warnings,
  } = useDocumentUpload();

  if (status === 'loading') {
    return <LoadingAnimation />;
  }

  return (
    <div>
      <div className="fieldset tw-mb-8 tw-rounded-lg tw-border-2 tw-p-6 tw-shadow-md">
        <div className="tw-flex tw-flex-row">
          <div className="tw-mb-6 tw-grow tw-text-right tw-text-sm">
            <a
              className="tw-font-bold tw-leading-none tw-text-gray-600"
              onClick={handleBankConnectionLink}
              href="#"
            >
              {I18n.nt(ns, 'bank_connection_link')}
            </a>
            <p className="tw-text-floryn-green">
              {I18n.nt(ns, 'bank_connection_endorsement_html')}
            </p>
          </div>
          <button
            type="button"
            className="close tw-ml-2 tw-flex tw-flex-col tw-items-start"
            onClick={handleBankConnectionLink}
          >
            <span aria-hidden="true" className="tw--mt-1 tw-leading-none">
              ×
            </span>
            <span className="sr-only">Close</span>
          </button>
        </div>
        <h4>{I18n.attribute('seller', 'bankStatementAttachments')}</h4>
        <p className="DocumentUploadInstructions">
          {I18n.nt(ns, 'download_bank_statements_start', {
            date: bankStatementStartDate(new Date()),
          })}
          &nbsp;
          {I18n.nt(ns, 'download_bank_statements')}
          &nbsp;
          <a
            href="https://www.floryn.com/nl/bankafschriften-downloaden"
            rel="noopener noreferrer"
            target="_blank"
            className="tw-underline"
          >
            {I18n.nt(ns, 'download_bank_statements_hint')}
          </a>
        </p>
        <div className="tw-mb-4">
          <RequiredIbans bankStatementAttachments={bankStatementAttachments} />
        </div>
        <BankstatementUploadInput
          control={control}
          label={I18n.attribute('seller', 'bankStatementAttachments')}
          model="seller"
          modelId={seller.id}
          name="bankStatementAttachments"
          readOnly={status === 'saving'}
          setUploading={setUploading}
        />
        <UploadStatusInfo
          bankStatementCount={seller.bankStatementAttachments.length}
          saving={status === 'saving'}
          warnings={warnings}
        />
        <UploadStatementsWarning
          attachmentsWithWarning={attachmentsWithWarning}
          removeInvalidAttachments={removeInvalidAttachments}
          disabled={status === 'saving'}
        />
      </div>
      <div className="tw-flex">
        <Button
          className="btn-light-inverted sm:tw-w-auto"
          onClick={goToPreviousStep}
        >
          {I18n.ut('common.goBack')}
        </Button>
        <Button
          type="submit"
          className="btn-primary tw-grow sm:tw-grow-0"
          disabled={status === 'saving'}
          onClick={submit}
        >
          {I18n.nt(ns, 'submit')}
        </Button>
      </div>
      <DontWorryMessage />
      <BankStatementInfo icon="lock">
        {I18n.nt(ns, 'statements_info')}
      </BankStatementInfo>
    </div>
  );
};

export default DocumentUpload;
