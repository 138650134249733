import React from 'react';
import { hasAddress, formatHouseNumber } from './utils';
import { CandidateCompany, Company } from 'types';
import * as I18n from 'shared/utils/I18n';

interface Props {
  company: CandidateCompany | Company;
}

const CompanyInfo = ({ company }: Props) => {
  if (hasAddress(company)) {
    return (
      <div className="CompanyInfo">
        {company.name}
        <br />
        {company.street} {formatHouseNumber(company)}
        <br />
        {company.postalCode} {company.city}
        <br />
        {I18n.attribute('company', 'kvk')} {company.kvk}
      </div>
    );
  } else {
    return (
      <div className="CompanyInfo">
        {company.name}
        <br />
        {I18n.attribute('company', 'kvk')} {company.kvk}
      </div>
    );
  }
};

export default CompanyInfo;
