import React from 'react';

import SignupContainer from 'signup/components/SignupContainer';
import AccountStep from 'signup/components/guest/AccountStep';
import LoadingAnimation from 'shared/components/LoadingAnimation';

interface Props {
  loading: boolean;
}

const GuestSignup = ({ loading }: Props) => {
  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <SignupContainer>
      <AccountStep />
    </SignupContainer>
  );
};

export default GuestSignup;
