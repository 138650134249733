import React from 'react';
import * as I18n from 'shared/utils/I18n';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { Seller } from 'types';
import { toEuro } from 'shared/utils/Helpers';

const ns = 'app.sidebars.request_summary';

const RequestSummary = () => {
  const { companyName, expectedFinancingAmount } = useCurrentSeller() as Seller;
  const formattedFinancingAmount = toEuro(expectedFinancingAmount, 0);

  return (
    <div className="tw-mb-4 tw-flex tw-flex-col tw-gap-4 tw-rounded-lg tw-border-2 tw-border-floryn-green tw-p-4 sm:tw-flex-row sm:tw-gap-8 md:tw-flex-col lg:tw-p-8">
      <div>
        <label className="tw-text-sm tw-font-normal md:tw-text-base">
          {I18n.nt(ns, 'signup_for')}
        </label>
        <div className="tw-mt-2 tw-font-heading tw-font-semibold tw-text-floryn-green sm:tw-text-xl md:tw-text-2xl">
          {companyName}
        </div>
      </div>
      <div>
        <label className="tw-text-sm tw-font-normal md:tw-text-base">
          {I18n.nt(ns, 'expected_financing')}
        </label>
        <div className="tw-mt-2 tw-font-heading tw-font-semibold tw-text-floryn-green sm:tw-text-lg md:tw-text-2xl">
          {formattedFinancingAmount}
        </div>
      </div>
    </div>
  );
};

export default RequestSummary;
