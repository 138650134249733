import React from 'react';

const VIDEOS = {
  generalIntroduction:
    'https://3333532.fs1.hubspotusercontent-na1.net/hubfs/3333532/Final%20versie%20Reinier%20aanmeldflow.mp4',
  platformWalkthrough:
    'https://3333532.fs1.hubspotusercontent-na1.net/hubfs/3333532/Digitale%20omgeving%20subs%20S.mp4',
  generalIntroductionAlfa:
    'https://3333532.fs1.hubspotusercontent-na1.net/hubfs/3333532/_LANDINGPAGES/Anne%20factoring%20video.mp4',
};

interface Props {
  kind:
    | 'generalIntroduction'
    | 'platformWalkthrough'
    | 'generalIntroductionAlfa';
}

export default ({ kind }: Props) => {
  if (window.RAILS_ENV === 'test') {
    return null;
  }

  const url = VIDEOS[kind];
  if (!url) {
    return null;
  }

  return (
    <video
      controls
      className="tw-rounded-md tw-shadow-[0_0_6px_4px_rgba(0,0,0,0.07)]"
      aria-label="video"
    >
      <source src={url} type="video/mp4" />
    </video>
  );
};
