import React from 'react';
import { Route } from 'react-router-dom';

import Action, { ActionButton, ActionClosingModal } from '../Action';
import WithdrawDialog from './WithdrawDialog';
import { OnboardingActionStatus, Seller } from 'types';
import { financeConditions } from 'models/Seller';
import { nt } from '../utils';
import { toEuro } from 'shared/utils/Helpers';

const t = nt('signup.onboarding.withdrawal');

interface Props {
  basePath: string;
  seller: Seller;
  status: OnboardingActionStatus;
}

const Withdrawal = ({ basePath, seller, status }: Props) => {
  const withdrawalPath = `${basePath}/geld-opnemen`;

  const conditions = financeConditions(seller);
  const { currentBalance } = conditions;
  const hasWithdrawal = currentBalance > 0;

  const Button = () => (
    <ActionButton to={withdrawalPath}>
      {hasWithdrawal
        ? t('button.with_withdrawal')
        : t('button.without_withdrawal')}
    </ActionButton>
  );

  const title = hasWithdrawal
    ? t('title.with_withdrawal', { amount: toEuro(currentBalance) })
    : t('title.without_withdrawal');

  const description = hasWithdrawal
    ? t('description.with_withdrawal')
    : t('description.without_withdrawal');

  return (
    <>
      <Action
        button={Button}
        description={description}
        path={withdrawalPath}
        status={status}
        title={title}
      />
      <Route
        path={withdrawalPath}
        render={() => (
          <ActionClosingModal basePath={basePath}>
            <WithdrawDialog basePath={basePath} />
          </ActionClosingModal>
        )}
      />
    </>
  );
};

export default Withdrawal;
