import { useEffect, useState } from 'react';
import useLoadFullSeller from './useLoadFullSeller';
import useCurrentSeller from './useCurrentSeller';

type Status = '' | 'loading' | 'loaded' | 'error';

/* Ensures a `Seller` entity exists. Loads a `Seller` from the server if necessary.
- A seller must exist (MinimalSeller or Seller).
- returns a seller and a status.
*/
const useEnsureFullSeller = () => {
  const loadFullSeller = useLoadFullSeller();
  const [status, setStatus] = useState<Status>('');
  const sellerEntity = useCurrentSeller();
  const isSellerLoaded = sellerEntity.entityKind === 'Seller';

  const handle = async () => {
    setStatus('loading');
    const result = await loadFullSeller();
    if (result) {
      setStatus('loaded');
    } else {
      setStatus('error');
    }
  };

  useEffect(() => {
    if (isSellerLoaded) {
      setStatus('loaded');
    } else {
      handle();
    }
  }, []);

  const seller = isSellerLoaded ? sellerEntity : undefined;

  return { seller, status };
};

export default useEnsureFullSeller;
