import React, { ReactNode } from 'react';
import { formatAmount } from '../charts/LabelFormatters';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Panel from './Panel';
import CustomTooltip from './CustomTooltip';
import { Category, Data } from './charts';

interface Props {
  children?: ReactNode;
  onExpand?: () => void;
  data: Data;
  categories: Category[];
  title: string;
  stacked?: boolean;
  legend?: boolean;
}

const GenericChart = ({
  onExpand,
  data,
  categories,
  children,
  title,
  stacked,
  legend,
}: Props) => {
  return (
    <Panel title={title} onExpand={onExpand}>
      <div className="ti-p-n">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="date" />
            <YAxis tickFormatter={formatAmount} />
            {legend && <Legend />}
            <Tooltip
              wrapperStyle={{ zIndex: 1 }}
              content={<CustomTooltip />}
              cursor={{ fill: 'rgba(0, 0, 0, .05)' }}
            />
            {categories.map(({ key, name, color }, i) => (
              <Bar
                key={i}
                dataKey={key}
                fill={color}
                name={name}
                stackId={stacked ? '1' : i}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
      {children}
    </Panel>
  );
};

export default GenericChart;
