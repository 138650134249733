import React from 'react';
import { I18n, Dict, Scope, TranslateOptions } from 'i18n-js';
import { endsWith, mapValues } from 'lodash';
import { underscore } from 'inflected';
import escapeTextContentForBrowser from './escapeTextContentForBrowser';
import translations from 'shared/railsTranslations.json';

const i18n = new I18n(translations);
i18n.defaultLocale = 'nl';
i18n.locale = 'nl';

const us = (str: string) => underscore(str);

// Use the t() and l() functions directly from I18nJS
export const t = (scope: Scope, options?: TranslateOptions) =>
  i18n.t(scope, options);

export const l = (
  type: string,
  value: string | number | Date | null | undefined,
  options?: Dict
) => i18n.l(type, value, options);

// A translation function wrapped can allow HTML in translations
// The return type is set to 'string' to fix type issues. In reality the type is a string or a React node.
export const wrappedT = (
  scope: string,
  interpolatedValues = {},
  options: TranslateOptions = {}
): string => {
  if (
    options.allowHtml !== true &&
    !endsWith(scope, 'Html') &&
    !endsWith(scope, '_html')
  ) {
    return t(scope, { ...interpolatedValues, ...options });
  }

  // We first manually escape the interpolatedValues, and then send back the
  //  translation string without React HTML escaping
  const escapedInterpolatedValues = mapValues(
    interpolatedValues,
    escapeTextContentForBrowser
  );
  const translation = t(scope, { ...escapedInterpolatedValues, ...options });

  if (translation === undefined || translation === null || translation === '') {
    return translation;
  }

  return (<span dangerouslySetInnerHTML={{ __html: translation }} />) as any;
};

// Translation helpers for all kinds of different 'components'
export const model = (modelName: string, options: TranslateOptions = {}) =>
  wrappedT(
    `activerecord.models.${us(modelName)}`,
    {},
    { defaultValue: modelName, ...options }
  );

export const attribute = (
  modelName: string,
  attributeName: string,
  options: TranslateOptions = {}
) =>
  wrappedT(
    `activerecord.attributes.${us(modelName)}.${us(attributeName)}`,
    {},
    { defaultValue: attributeName, ...options }
  );

export const label = (
  modelName: string,
  fieldName: string,
  options: TranslateOptions = {}
) =>
  wrappedT(
    `helpers.label.${us(modelName)}.${us(fieldName)}`,
    {},
    {
      defaults: [
        { scope: `activerecord.attributes.${us(modelName)}.${us(fieldName)}` },
      ],
      defaultValue: fieldName,
      allowHtml: true,
      ...options,
    }
  );

export const placeholder = (
  modelName: string,
  fieldName: string,
  options: TranslateOptions = {}
) =>
  wrappedT(
    `helpers.placeholder.${us(modelName)}.${us(fieldName)}`,
    {},
    { defaultValue: '', ...options }
  );

export const hint = (
  modelName: string,
  fieldName: string,
  options: TranslateOptions = {}
) =>
  wrappedT(
    `helpers.hint.${us(modelName)}.${us(fieldName)}`,
    {},
    { defaultValue: '', allowHtml: true, ...options }
  );

export const secondaryHint = (
  modelName: string,
  fieldName: string,
  options: TranslateOptions = {}
) =>
  wrappedT(
    `helpers.secondaryHint.${us(modelName)}.${us(fieldName)}`,
    {},
    { defaultValue: '', allowHtml: true, ...options }
  );

// UI translation
export const ut = (
  scope: string,
  interpolatedValues = {},
  options: TranslateOptions = {}
) => wrappedT(`ui.${scope}`, interpolatedValues, options);

// Namespaced UI translation
export const nt = (
  componentOrNamespace,
  key: string,
  interpolatedValues: Record<string, unknown> = {},
  options: TranslateOptions = {}
) => {
  let namespace;
  if (componentOrNamespace.isReactComponent) {
    namespace =
      componentOrNamespace.constructor.i18nNamespace ||
      componentOrNamespace.constructor.displayName;
  } else {
    namespace = componentOrNamespace;
  }

  return wrappedT(`ui.${namespace}.${key}`, interpolatedValues, options);
};

export const translate =
  (ns: string) =>
  (key: string, options: TranslateOptions = {}) =>
    nt(ns, key, options);

export const setLocale = (locale: string) => {
  i18n.locale = locale;
};
