import { useEffect, useState } from 'react';
import Remote, * as remote from 'types/Remote';
import { Uploader, DeliveryRequest, DeliveryRequestItem } from './types';
import { decode } from './decode';
import client from 'shared/utils/client';

async function fetchDeliveryDeliveryRequests() {
  const response = await client(
    'GET',
    '/api/delivery_requests',
    {},
    { raiseError: false }
  );

  if (response.error) {
    return remote.failureFromError(response);
  } else {
    try {
      return remote.success(decode(response.payload));
    } catch (e) {
      return remote.failure({ type: 'bad_body', message: e.message });
    }
  }
}

async function deleteDeliveryRequestItem(item: DeliveryRequestItem) {
  const response = await client(
    'DELETE',
    `/api/delivery_requests/${item.deliveryRequestId}/delivery_items/${item.id}`,
    {},
    { raiseError: false }
  );

  if (response.error) {
    return remote.failureFromError(response);
  } else {
    return remote.success(true);
  }
}

async function uploadNewDeliveryRequestItem(
  deliveryRequestId: number,
  file: File
): Promise<Remote<boolean>> {
  const data = new FormData();
  data.append('deliveries_document[files][]', file);
  const response = await client(
    'POST',
    `/api/delivery_requests/${deliveryRequestId}/delivery_items`,
    data,
    { raiseError: false }
  );

  if (response.error) {
    return remote.failureFromError(response);
  } else {
    return remote.success(true);
  }
}

/**
 * Update a `DeliveryRequestItem` value nested inside a list of `DeliveryRequest` values by ID using an
 * updater function.
 *
 * Example:
 *
 *     updateDeliveryRequestItem(
 *       [{ items: [{ id: 1, name: 'foo' }] }],
 *       1,
 *       (f) => { f.name += '!'; return f; }
 *     )
 *     // [{ items: [{ id: 1, name: 'foo!' }] }]
 *
 */
export function updateDeliveryRequestItem(
  deliveryRequests: DeliveryRequest[],
  fileId: string | number,
  fun: (f: DeliveryRequestItem) => DeliveryRequestItem
): DeliveryRequest[] {
  return deliveryRequests.map((req) => {
    req.items.map((f) => {
      if (f.id === fileId) {
        fun(f);
      }
      return f;
    });
    return req;
  });
}

/**
 * Remove a `DeliveryRequestItem` value nested inside a list of `DeliveryRequest` values by ID.
 *
 * Example:
 *
 *     removeDeliveryRequestItem(
 *       [{ items: [{ id: 1, name: 'foo' }] }],
 *       1,
 *     )
 *     // [{ items: [] }]
 *
 */
export function removeDeliveryRequestItem(
  deliveryRequests: DeliveryRequest[],
  fileId: string | number
) {
  return deliveryRequests.map((req) => {
    req.items = req.items.filter((f) => f.id !== fileId);
    return req;
  });
}

/**
 * Create a deletion function using two callbacks to set state and run the
 * request. This returns a function that can be passed to components to be
 * invoked as an event handler.
 *
 * The idea here is provide a deletion function that takes a file and updates
 * the the list of delivery requests accordingly to indicate the delete request
 * is underway, then run the actual HTTP request, and then update the file in in
 * question with the result of that request -- deleting the file from the data
 * structure in case of remote.success or storing the error so the UI could decide to
 * show it.
 *
 * Note that this function uses a callback function to set state using another
 * callback function, rather than passing in a new value directly. Since the
 * DELETE request might take a while to complete, the current state might be
 * changed so we always want to update the _current_ state, not the reference of
 * the state we had at the time of starting the request.
 *
 * @param {function} callback - to be called to update some state. Typically
 *   a `setState` function returned by `React.useState`.
 * @param {function} makeRequest - actually run an HTTP request.
 */
export function createOnDelete(
  callback: (
    fn: (currentState: Remote<DeliveryRequest[]>) => Remote<DeliveryRequest[]>
  ) => any,
  makeRequest: (file: DeliveryRequestItem) => Promise<Remote<boolean>>
): (file: DeliveryRequestItem) => void {
  return async (file: DeliveryRequestItem) => {
    callback((currentDeliveryRequests) => {
      return remote.map(currentDeliveryRequests, (r) => {
        return updateDeliveryRequestItem(r, file.id, (f) => {
          f.deletionStatus = remote.pending();
          return f;
        });
      });
    });
    const response = await makeRequest(file);
    switch (response.type) {
      case 'success':
        callback((currentDeliveryRequests) => {
          return remote.map(currentDeliveryRequests, (r) => {
            return removeDeliveryRequestItem(r, file.id);
          });
        });
        break;
      default:
        callback((currentDeliveryRequests) => {
          return remote.map(currentDeliveryRequests, (r) => {
            return updateDeliveryRequestItem(r, file.id, (f) => {
              f.deletionStatus = response;
              return f;
            });
          });
        });
    }
  };
}

interface Props {
  deliveryRequests: Remote<DeliveryRequest[]>;
  onDelete: (file: DeliveryRequestItem) => void;
  onUpload: Uploader;
}

const useDeliveries = (): Props => {
  const [deliveryRequests, setDeliveryRequests] = useState<
    Remote<DeliveryRequest[]>
  >(remote.notAsked());

  const fetchAndUpdateState = async () => {
    setDeliveryRequests(remote.pending());
    const result = await fetchDeliveryDeliveryRequests();
    setDeliveryRequests(result);
  };

  const onDelete = createOnDelete(
    setDeliveryRequests,
    deleteDeliveryRequestItem
  );

  useEffect(() => {
    fetchAndUpdateState();
  }, []);

  return { deliveryRequests, onDelete, onUpload: uploadNewDeliveryRequestItem };
};

export default useDeliveries;
