import { addDays, differenceInDays, endOfMonth, parseISO } from 'date-fns';

export const daysToEndOfMonth = (
  invoiceDateString: string,
  paymentPeriod: number
): number => {
  const invoiceDate = parseISO(invoiceDateString);
  const endOfMonthDate = endOfMonth(addDays(invoiceDate, paymentPeriod));
  return differenceInDays(endOfMonthDate, invoiceDate);
};
