import { Middleware } from 'redux';
import { LOCATION_CHANGE } from 'redux-first-history';
import * as tracker from 'shared/utils/tracker';
import { UPDATE_IBAN } from 'namespaces/Onboarding/module';
import { mainSellerSelector } from 'shared/selectors';

import {
  ACTIVATE_ACCOUNT,
  AUTHENTICATE_SUCCESS,
  LOAD_SUCCESS,
} from 'shared/redux/modules/session';
import { SIGNUP } from 'signup/actions/GuestSignup';

import {
  companySelector,
  featureFlagsSelector,
  mainUserSelector,
  userSelector,
} from 'shared/selectors';
import { Company, MainUser, MinimalSeller, ReduxState } from 'types';
import * as reviewWizard from 'shared/modules/reviewWizardSlice';

const hsq = () => (window as any)._hsq || [];

const crossPlatformPages: Record<string, string> = {
  '/g/inloggen': 'login',
  '/g/wachtwoord-herstellen': 'forgot-password',
  '/klant/account/rekeningen': 'connected-accounts',
  '/klant/geld-opnemen': 'withdraw',
};

const checkCrossPlatformPage = (page: string) => {
  if (page in crossPlatformPages) {
    tracker.trackCrossPlatformPage(crossPlatformPages[page]);
  }
};

const trackHubspotPageView = (path) => {
  hsq().push(['setPath', path]);
  hsq().push(['trackPageView']);
};

const trackerMiddleware: Middleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);

    switch ((action as any).type) {
      case ACTIVATE_ACCOUNT:
      case AUTHENTICATE_SUCCESS:
      case LOAD_SUCCESS:
      case SIGNUP.SUCCESS: {
        const state = getState() as ReduxState;
        const user = userSelector(state);

        if (user) {
          const company = companySelector(state) as Company;
          const seller = mainSellerSelector(state) as MinimalSeller;
          const mainUser = mainUserSelector(state) as MainUser;
          const featureFlags = featureFlagsSelector(state);
          tracker.identifySeller({ company, mainUser, seller, featureFlags });
        }

        if ((action as any).type === ACTIVATE_ACCOUNT) {
          tracker.trackEvent('Set password');
        }
        break;
      }

      case LOCATION_CHANGE: {
        const path = (action as any).payload.location.pathname as string;
        tracker.trackPage(path);
        checkCrossPlatformPage(path);
        trackHubspotPageView(path);
        break;
      }

      case UPDATE_IBAN:
        tracker.trackEvent('Verify bank account', {
          digitalBank: (action as any).digitalBank,
        });
        break;

      default:
        reviewWizard.trackAction(action);
    }

    return result;
  };
};
export default trackerMiddleware;
