import React from 'react';

type StreamlineIconName =
  | 'Common File Text Check'
  | 'Common File Text Upload'
  | 'Common File Text Clock'
  | 'Common File Text Warning'
  | 'Bin'
  | 'Message Bubble Square Warning'
  | 'Messages Bubble Typing 1';

interface Props {
  icon: StreamlineIconName;
  className?: string;
  strokeWidth?: number;
}

const StreamlineIcon = (props: Props) => {
  const strokeWidth = props.strokeWidth || 2;
  const className = props.className || 'tw-w-5 tw-h-5';

  switch (props.icon) {
    case 'Messages Bubble Typing 1':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="-1 -1 24 24"
          className={className}
        >
          <path
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.678333333333333 0.6875a9.596583333333333 9.596583333333333 0 0 0 -8.405833333333334 4.938083333333333 9.617666666666667 9.617666666666667 0 0 0 0.23833333333333334 9.7515L0.6875 21.3125l5.928999999999999 -2.827a9.613083333333334 9.613083333333334 0 0 0 14.328416666666666 -5.5385 9.634166666666665 9.634166666666665 0 0 0 -1.5803333333333331 -8.4425A9.619499999999999 9.619499999999999 0 0 0 11.678333333333333 0.6875v0Z"
            strokeWidth={strokeWidth}
          />
          <path
            stroke="currentcolor"
            d="M11.6875 10.65625a0.34375 0.34375 0 0 1 0 -0.6875"
            strokeWidth={strokeWidth}
          />
          <path
            stroke="currentcolor"
            d="M11.6875 10.65625a0.34375 0.34375 0 0 0 0 -0.6875"
            strokeWidth={strokeWidth}
          />
          <path
            stroke="currentcolor"
            d="M7.5625 10.65625a0.34375 0.34375 0 0 1 0 -0.6875"
            strokeWidth={strokeWidth}
          />
          <path
            stroke="currentcolor"
            d="M7.5625 10.65625a0.34375 0.34375 0 0 0 0 -0.6875"
            strokeWidth={strokeWidth}
          />
          <g>
            <path
              stroke="currentcolor"
              d="M15.8125 10.65625a0.34375 0.34375 0 0 1 0 -0.6875"
              strokeWidth={strokeWidth}
            />
            <path
              stroke="currentcolor"
              d="M15.8125 10.65625a0.34375 0.34375 0 0 0 0 -0.6875"
              strokeWidth={strokeWidth}
            />
          </g>
        </svg>
      );
      break;
    case 'Message Bubble Square Warning':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="-1 -1 24 24"
          className={className}
        >
          <path
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.9375 17.1875h-9.625l-5.5 4.125v-4.125h-2.75a1.375 1.375 0 0 1 -1.375 -1.375v-13.75a1.375 1.375 0 0 1 1.375 -1.375h17.875a1.375 1.375 0 0 1 1.375 1.375v13.75a1.375 1.375 0 0 1 -1.375 1.375Z"
            strokeWidth={strokeWidth}
          />
          <path
            stroke="currentcolor"
            d="M11.6875 13.75a0.34375 0.34375 0 0 1 0 -0.6875"
            strokeWidth={strokeWidth}
          />
          <path
            stroke="currentcolor"
            d="M11.6875 13.75a0.34375 0.34375 0 0 0 0 -0.6875"
            strokeWidth={strokeWidth}
          />
          <path
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.6875 10.3125v-5.5"
            strokeWidth={strokeWidth}
          />
        </svg>
      );
      break;
    case 'Bin':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="-1 -1 24 24"
          className={className}
        >
          <path
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.553166666666666 20.519583333333333H5.446833333333333a1.5858333333333332 1.5858333333333332 0 0 1 -1.5858333333333332 -1.58675V4.6539166666666665h14.278v14.278916666666666a1.5858333333333332 1.5858333333333332 0 0 1 -1.5858333333333332 1.5858333333333332Z"
            strokeWidth={strokeWidth}
          />
          <path
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.620333333333333 15.759333333333332V9.414166666666667"
            strokeWidth={strokeWidth}
          />
          <path
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.379666666666667 15.759333333333332V9.414166666666667"
            strokeWidth={strokeWidth}
          />
          <path
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M0.6875 4.6539166666666665h20.625"
            strokeWidth={strokeWidth}
          />
          <path
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.379666666666667 1.4804166666666665H8.620333333333333a1.5858333333333332 1.5858333333333332 0 0 0 -1.5858333333333332 1.58675v1.5858333333333332h7.931916666666667v-1.5858333333333332a1.5858333333333332 1.5858333333333332 0 0 0 -1.5858333333333332 -1.5858333333333332Z"
            strokeWidth={strokeWidth}
          />
        </svg>
      );
      break;
    case 'Common File Text Warning':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-1 -1 24 24"
          className={className}
        >
          <defs />
          <title>{'common-file-text-upload'}</title>
          <path
            d="M10.3125 15.8125a5.5 5.5 0 1 0 11 0 5.5 5.5 0 1 0 -11 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m15.8125 18.5625 0 -5.5"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="M15.8125 13.0625 13.75 15.125"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m15.8125 13.0625 2.0625 2.0625"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m3.4375 6.1875 9.625 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m3.4375 10.3125 5.5 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m3.4375 14.4375 3.4375 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="M6.875 18.5625H2.0625a1.375 1.375 0 0 1 -1.375 -1.375V2.0625a1.375 1.375 0 0 1 1.375 -1.375h9.74325a1.375 1.375 0 0 1 0.9716666666666667 0.40241666666666664l2.6326666666666663 2.6326666666666663a1.375 1.375 0 0 1 0.40241666666666664 0.9716666666666667V6.875"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
        </svg>
      );
      break;
    case 'Common File Text Clock':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-1 -1 24 24"
          className={className}
        >
          <defs />
          <title>{'common-file-text-upload'}</title>
          <path
            d="M10.3125 15.8125a5.5 5.5 0 1 0 11 0 5.5 5.5 0 1 0 -11 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m15.8125 18.5625 0 -5.5"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="M15.8125 13.0625 13.75 15.125"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m15.8125 13.0625 2.0625 2.0625"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m3.4375 6.1875 9.625 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m3.4375 10.3125 5.5 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m3.4375 14.4375 3.4375 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="M6.875 18.5625H2.0625a1.375 1.375 0 0 1 -1.375 -1.375V2.0625a1.375 1.375 0 0 1 1.375 -1.375h9.74325a1.375 1.375 0 0 1 0.9716666666666667 0.40241666666666664l2.6326666666666663 2.6326666666666663a1.375 1.375 0 0 1 0.40241666666666664 0.9716666666666667V6.875"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
        </svg>
      );
      break;
    case 'Common File Text Upload':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-1 -1 24 24"
          className={className}
        >
          <defs />
          <title>{'common-file-text-upload'}</title>
          <path
            d="M10.3125 15.8125a5.5 5.5 0 1 0 11 0 5.5 5.5 0 1 0 -11 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m15.8125 18.5625 0 -5.5"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="M15.8125 13.0625 13.75 15.125"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m15.8125 13.0625 2.0625 2.0625"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m3.4375 6.1875 9.625 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m3.4375 10.3125 5.5 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m3.4375 14.4375 3.4375 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="M6.875 18.5625H2.0625a1.375 1.375 0 0 1 -1.375 -1.375V2.0625a1.375 1.375 0 0 1 1.375 -1.375h9.74325a1.375 1.375 0 0 1 0.9716666666666667 0.40241666666666664l2.6326666666666663 2.6326666666666663a1.375 1.375 0 0 1 0.40241666666666664 0.9716666666666667V6.875"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
        </svg>
      );
      break;
    case 'Common File Text Check':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-1 -1 24 24"
          className={className}
        >
          <defs />
          <title>{'common-file-text-check'}</title>
          <path
            d="M10.3125 15.8125a5.5 5.5 0 1 0 11 0 5.5 5.5 0 1 0 -11 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m18.263666666666666 14.213833333333334 -2.6629166666666664 3.55025a0.6893333333333334 0.6893333333333334 0 0 1 -1.03675 0.07425l-1.375 -1.375"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m3.4375 6.1875 9.625 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m3.4375 10.3125 5.5 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="m3.4375 14.4375 3.4375 0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="M6.875 18.5625H2.0625a1.375 1.375 0 0 1 -1.375 -1.375V2.0625a1.375 1.375 0 0 1 1.375 -1.375h9.74325a1.375 1.375 0 0 1 0.9716666666666667 0.40241666666666664l2.6326666666666663 2.6326666666666663a1.375 1.375 0 0 1 0.40241666666666664 0.9716666666666667V6.875"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
        </svg>
      );
      break;
    default:
      return null;
  }
};

export default StreamlineIcon;
