import { ChangeEvent, FocusEvent, useState } from 'react';
import { isNil, isNumber, round } from 'lodash';
import { numberFormat, toEuro } from 'shared/utils/Helpers';

interface FormatOptions {
  displayCurrenySymbol: boolean;
  numberOfDecimals: number;
}

const convertToDisplay = (
  value: number | string | undefined,
  { displayCurrenySymbol, numberOfDecimals }: FormatOptions
): string => {
  if (value === '' || isNil(value)) {
    return '';
  }

  if (displayCurrenySymbol) {
    return toEuro(value, numberOfDecimals);
  } else {
    return numberFormat(value, numberOfDecimals);
  }
};

const convertValueToInternalValue = (
  value: number | string | undefined,
  numberOfDecimals: number
): string => {
  const formatOptions = { displayCurrenySymbol: false, numberOfDecimals };

  if (isNil(value) || value === '') {
    return '';
  } else if (isNumber(value)) {
    return convertToDisplay(`${value}`, formatOptions);
  } else {
    const numberValue = parseFloat(value);
    return convertToDisplay(`${numberValue}`, formatOptions);
  }
};

export const convertToFormValue = (value: string, numberOfDecimals: number) => {
  if (!value) {
    return null;
  } else {
    return round(
      Number(value.replace(/\./g, '').replace(',', '.')),
      numberOfDecimals
    );
  }
};

interface Props {
  formatOptions: FormatOptions;
  onBlur?: (value: number | null) => void;
  onChange?: (value: number | null) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  value?: string | number;
}

const useBaseCurrencyInput = ({
  formatOptions,
  onBlur,
  onChange,
  onFocus,
  value,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [internalValue, setInternalValue] = useState<string>(() =>
    convertValueToInternalValue(value, formatOptions.numberOfDecimals)
  );

  const handleBlur = () => {
    if (onBlur) {
      onBlur(convertToFormValue(internalValue, formatOptions.numberOfDecimals));
    }

    setEditing(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const setValue = (value: string) => {
    setInternalValue(value);

    if (onChange) {
      onChange(convertToFormValue(value, formatOptions.numberOfDecimals));
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(e);
    }

    setInternalValue(
      convertValueToInternalValue(value, formatOptions.numberOfDecimals)
    );
    setEditing(true);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isDigit = e.key.length === 1 && e.key >= '0' && e.key <= '9';
    const isComma = e.key === ',';
    const isEnter = e.key === 'Enter';

    if (!(isEnter || isComma || isDigit)) {
      e.preventDefault();
    }
  };

  const displayValue = editing
    ? internalValue
    : convertToDisplay(value, formatOptions);

  return {
    displayValue,
    handleBlur,
    handleChange,
    handleFocus,
    handleKeyPress,
    setValue,
  };
};

export default useBaseCurrencyInput;
