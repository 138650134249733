import { useMemo, useRef, useState } from 'react';
import {
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useTransitionStyles,
  arrow,
  offset,
  shift,
} from '@floating-ui/react';
import { TooltipInteraction, TooltipSize } from './types';

interface Params {
  ariaLabel?: string;
  onDisplayTooltip?: () => void;
  tooltipInteraction: TooltipInteraction;
  tooltipSize: TooltipSize;
}

const useTooltip = ({
  ariaLabel,
  onDisplayTooltip,
  tooltipInteraction,
  tooltipSize,
}: Params) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (value: boolean) => {
    if (onDisplayTooltip && value && !open) {
      onDisplayTooltip();
    }

    setOpen(value);
  };

  const arrowRef = useRef(null);

  const data = useFloating({
    open: open,
    onOpenChange: handleOpenChange,
    middleware: [
      shift(),
      offset(11),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const context = data.context;

  const click = useClick(context, { enabled: tooltipInteraction === 'click' });
  const dismiss = useDismiss(context, {
    enabled: tooltipInteraction === 'click',
  });
  const focus = useFocus(context, { enabled: tooltipInteraction === 'hover' });
  const hover = useHover(context, { enabled: tooltipInteraction === 'hover' });

  const interactions = useInteractions([click, dismiss, focus, hover]);

  const { isMounted, styles } = useTransitionStyles(context);

  return useMemo(
    () => ({
      ariaLabel,
      arrowRef,
      isMounted,
      open,
      setOpen,
      tooltipSize,
      transitionStyles: styles,
      ...interactions,
      ...data,
    }),
    [open, setOpen, data, interactions, isMounted, styles]
  );
};

export default useTooltip;
