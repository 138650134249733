import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useCurrentSeller } from 'namespaces/shared/hooks';
import client from 'shared/utils/client';
import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';
import { CONFIRM_UBOS } from 'Onboarding/module';
import { SELLER_ONBOARDING } from 'app/routes';
import { Seller } from 'types';
import { trackFormSubmit } from 'shared/utils/tracker';

export interface FormValues {
  name: string;
}

const useConfirmForm = () => {
  const seller = useCurrentSeller() as Seller;
  const dispatch = useDispatch();
  const history = useHistory();
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    setError,
  } = useForm<FormValues>({
    defaultValues: { name: seller.ubosConfirmedBy || '' },
  });

  const name = useWatch({ control, name: 'name' });

  useEffect(() => {
    reset({ name: seller.ubosConfirmedBy || '' });
  }, [seller.ubosConfirmedBy]);

  const submit = async (values: FormValues) => {
    const response = await client(
      'PUT',
      `/api/sellers/${seller.id}/confirm_ultimate_beneficial_owners`,
      values,
      { raiseError: false }
    );

    trackFormSubmit('ubo-confirm-form', !response.error);

    if (response.error) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        response,
        setError
      );
      setServerErrorMessages(generalErrorMessages);
    } else {
      dispatch({
        type: CONFIRM_UBOS,
        normalize: true,
        ...response,
      });

      history.push(SELLER_ONBOARDING);
    }
  };

  return {
    control,
    isSubmitting,
    name,
    serverErrorMessages,
    submit: handleSubmit(submit),
  };
};

export default useConfirmForm;
