import { useSelector } from 'react-redux';
import { ReduxState } from 'types';

const useSelectInvoice = (stringId: string) => {
  const allInvoices = useSelector(
    (state: ReduxState) => state.entities.invoices
  );

  const id = parseInt(stringId, 10);
  return allInvoices[id];
};

export default useSelectInvoice;
