import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import client from 'shared/utils/client';
import { trackEvent } from 'shared/utils/tracker';
import { IdentifyingPerson, UltimateBeneficialOwner } from 'types';
import { currentSellerSelector } from 'shared/selectors';
import { OnfidoStatus } from 'shared/components/OnfidoModal';

interface Parameters {
  ubo: UltimateBeneficialOwner;
  updateUboInState: (ubo: UltimateBeneficialOwner) => void;
  resetActionAndUbo: () => void;
}

export default ({ ubo, updateUboInState, resetActionAndUbo }: Parameters) => {
  const [status, setStatus] = useState<OnfidoStatus>('loading');
  const [identifyingPerson, setIdentifyingPerson] =
    useState<IdentifyingPerson>();
  const seller = useSelector(currentSellerSelector);
  const phone = seller?.phone;

  const loadIdentifyingPerson = async () => {
    const { payload: identifyingPerson } = await client(
      'GET',
      `/api/identifying_people/${ubo.identifyingPersonId}`
    );

    if (identifyingPerson) {
      setIdentifyingPerson(identifyingPerson);
      setStatus('ready');
      trackEvent('Onfido ID verification: initiated');
    } else {
      setStatus('loading');
    }
  };

  const completeSubmission = async () => {
    await client(
      'POST',
      `/api/identifying_people/${ubo.identifyingPersonId}/complete_file_submission`
    );
    setStatus('complete');
    trackEvent('Onfido ID verification: completed');
  };

  const onClose = () => {
    if (status === 'complete') {
      updateUboInState({ ...ubo, onfidoIdentificationStatus: 'done' });
      resetActionAndUbo();
    }
  };

  useEffect(() => {
    loadIdentifyingPerson();
  }, []);

  return { completeSubmission, onClose, phone, identifyingPerson, status };
};
