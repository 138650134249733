import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { getQueryParam } from 'shared/utils/Helpers';

type IdentificationState = 'error' | 'success';

const useFlorynPayIdentificationStatus = (parentPath: string) => {
  const history = useHistory();
  const [status, setStatus] = useState<IdentificationState>('success');

  const location = useLocation();
  const error = getQueryParam(location.search, 'error');

  useEffect(() => {
    if (error) {
      setStatus('error');
    }
  }, []);

  const close = () => history.push(parentPath);

  return {
    close,
    status,
  };
};

export default useFlorynPayIdentificationStatus;
