import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';
import qs from 'qs';
import { Location } from 'history';
import { isString } from 'lodash';
import {
  currentSellerSelector,
  customerAccountSelector,
} from 'shared/selectors';
import { frontpageRoute } from 'shared/routes';

const parseRedirectUrl = (location: Location) => {
  const match = qs.parse(location.search.substring(1)).redirect;

  if (isString(match)) {
    return match;
  } else {
    return undefined;
  }
};

interface Props {
  component: React.ComponentType;
  exact?: boolean;
  path: string;
  supportRedirectParam?: boolean;
}

const GuestOnlyRoute = ({
  component,
  exact,
  path,
  supportRedirectParam,
}: Props) => {
  const location = useLocation();
  const seller = useSelector(currentSellerSelector);
  const customerAccount = useSelector(customerAccountSelector);

  const getNextUrl = () => {
    const redirectUrl = parseRedirectUrl(location);

    if (supportRedirectParam && redirectUrl) {
      return redirectUrl;
    } else {
      return frontpageRoute(customerAccount, seller, null);
    }
  };

  const render = () => {
    if (!seller) {
      return React.createElement(component);
    } else {
      return <Redirect to={getNextUrl()} />;
    }
  };

  return <Route exact={exact} path={path} render={render} />;
};

export default GuestOnlyRoute;
