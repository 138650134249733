import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Glyphicon, Row } from 'shared/bootstrap';
import Button from 'shared/components/tailwind/Button';
import { Translator } from 'types';
import { isCancelled } from 'models/Seller';
import Feedback from 'shared/components/Consent/Feedback';
import { MinimalSeller, Seller } from 'types/Seller';

interface Props {
  children: ReactNode;
  t: Translator;
  connectPath: string;
  seller: MinimalSeller | Seller;
}

const Layout = ({ t, children, connectPath, seller }: Props) => {
  const history = useHistory();

  const titleKey = seller.aisConnected ? 'accounts' : 'connect_accounts';
  const subTitleKey = seller.aisConnected ? 'manageAtBank' : 'psd2_intro_html';

  return (
    <div>
      <Feedback t={(key) => t(`consentFeedback.${key}`)} />
      <Row>
        <Col md={8}>
          <h3>{t(titleKey)}</h3>
          <p>{t(subTitleKey)}</p>
        </Col>
        <Col md={4} className="text-right">
          <Button
            className="btn btn-primary"
            onClick={() => history.push(connectPath)}
            disabled={isCancelled(seller)}
          >
            <Glyphicon glyph="plus" /> {t('addAccount')}
          </Button>
        </Col>
      </Row>
      <hr />
      {children}
    </div>
  );
};

export default Layout;
