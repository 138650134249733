import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import client from 'shared/utils/client';
import updateEntities from 'shared/modules/updateEntities';
import { userSelector } from 'shared/selectors';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import { User } from 'types';

interface RouteParams {
  newEmailToken: string;
}

type Status = '' | 'updating' | 'updated' | 'error';

interface UserChangeEmailPayload {
  newEmail: string;
}

const useEmailChangeConfirmation = () => {
  const [status, setStatus] = useState<Status>('');
  const { newEmailToken } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const user = useSelector(userSelector) as User;

  const changeEmail = async () => {
    setStatus('updating');

    const response = await client<UserChangeEmailPayload>(
      'PUT',
      `/api/user_change_emails/${newEmailToken}`,
      {},
      {
        raiseError: false,
      }
    );

    if (response.error) {
      setStatus('error');
    } else {
      setStatus('updated');

      const { newEmail } = response.payload;
      dispatch(updateEntities({ ...user, email: newEmail }));
    }
  };

  useEffect(() => {
    changeEmail();
  }, []);

  return { status };
};

export default useEmailChangeConfirmation;
