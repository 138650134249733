import * as Sentry from '@sentry/browser';
import { nanoid } from 'nanoid';
import { format as datefnsFormat, parse, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import UAParser from 'ua-parser-js';

import * as I18n from 'shared/utils/I18n';

/*
 * Extract a query parameter from a query string.
 *
 * This is a helper function so it might easily be implemented
 * with another library.
 */
export const getQueryParam = (queryString: string, key: string) => {
  return new URLSearchParams(queryString).get(key);
};

export const capitalizeFirstLetter = (val: string) =>
  val.charAt(0).toUpperCase() + val.slice(1);

export const toEuro = (
  number: string | number | null | undefined,
  digits = 2
) =>
  new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(toFloat(number) || 0);

export const toFloat = (input: any) => {
  return typeof input === 'string' ? parseFloat(input) : input;
};

export const parseFloatOrUndefined = (value: any) => {
  if (!value) {
    return null;
  } else {
    return parseFloat(value);
  }
};

export const numberFormat = (
  number,
  minimumFractionDigits = 2,
  fixedNumberOfFractionDigits = true,
  maximumFractionDigits = 20
) => {
  // 20 is the maximum value in Intl.NumberFormat

  return new Intl.NumberFormat('nl-NL', {
    minimumFractionDigits,
    maximumFractionDigits: fixedNumberOfFractionDigits
      ? minimumFractionDigits
      : maximumFractionDigits,
  }).format(number);
};

export const toPercentage = (
  floatNumber: number | string | null,
  numberOfFractionDigits = 2
) =>
  `${numberFormat(
    toFloat(floatNumber) * 100,
    numberOfFractionDigits,
    false,
    10
  )}%`;

// Format a date using a predefined format. E.g. 'long' or 'short'
export const dateFormat = (
  date: string | Date | null | undefined,
  format = 'long'
) => {
  if (!date) return '';

  format = `date.formats.${format}`;
  return I18n.l(format, date);
};

// Format a datetime using a predefined format. E.g. 'long' or 'short'
export const datetimeFormat = (datetime: string | Date, format = 'long') => {
  if (!datetime) return '';

  format = `datetime.formats.${format}`;
  return I18n.l(format, datetime);
};

// Format a time using a predefined format. E.g. 'long' or 'short'
export const timeFormat = (time: string | Date | null, format = 'long') => {
  if (!time) return '';

  format = `time.format.${format}`;
  return I18n.l(format, time);
};

/* Format a date using an explicit format; e.g. 'd MMMM yyyy'. Uses date-fns.
Uses the correct locale.
*/
export const explicitDateFormat = (date: number | Date, format: string) =>
  datefnsFormat(date, format, { locale: nl });

export const fromDutchDate = (dateString: string) => {
  const date = parse(dateString, 'dd-MM-yyyy', new Date());
  return explicitDateFormat(date, 'yyyy-MM-dd');
};

export const toDutchDate = (dateString: string) => {
  const date = parseISO(dateString);
  return explicitDateFormat(date, 'dd-MM-yyyy');
};

export const asyncAction = (base) => ({
  REQUEST: base,
  SUCCESS: `${base}_SUCCESS`,
  FAILURE: `${base}_FAILURE`,
});

export function setSentryUserContext({ id, email }) {
  Sentry.getCurrentScope().setUser({
    id,
    email,
  });
}

export function removeSentryUserContext() {
  Sentry.getCurrentScope().setUser({});
}

export const formatIban = (iban: string | null | undefined) => {
  if (!iban) return '';

  return iban.match(/.{1,4}/g)?.join(' ');
};

/* Calculate percentage part / total.
 * The result is a number in the range [0, 100].
 * Invalid for negative numbers.
 * Special case: 0 / 0 = 100
 */
export const calculatePercentage = (part: number, total: number) => {
  if (part < 0 || total < 0) {
    return 0;
  }

  if (part === 0 && total === 0) {
    return 100;
  } else {
    return Math.min((part * 100) / total, 100);
  }
};

export const sum = (values) =>
  values
    .map((value) => Number.parseFloat(value))
    .filter((value) => !Number.isNaN(value))
    .reduce((sum, value) => sum + value, 0);

export const capitalize = (string: string) => {
  if (string.length === 0) return string;

  return string[0].toUpperCase() + string.substring(1);
};

export const getFirstName = (fullName: string) => {
  return fullName.match(/[\w*]*(?!( {2}))/u)?.[0];
};

export const getLastName = (fullName: string) => {
  return fullName.replace(/[\w*]*(?!( {2}))/u, '').trim();
};

// Generate a valid id for an html element. NB. It should not start with a digit.
export const generateHtmlElementId = () => `id-${nanoid()}`;

export const isAndroidOrIOS = () => {
  const osName = new UAParser().getOS().name?.toLocaleLowerCase();

  return osName === 'ios' || osName === 'android';
};

/*
 * Mimick Rails' `simple_format` helper that transforms plain text to HTML using
 * a few simple rules:
 *
 * 1. Paragraphs of text are divided by two consecutive newlines and are
 *    wrapped in `<p>` tags.
 * 2. Other newlines are treated as line breaks and replaced with `<br>`
 *    elements.
 */
export const simpleFormat = (text: string): string => {
  return text
    .trim()
    .split('\n\n')
    .filter((s) => s !== '')
    .map((para) => {
      const wrappedPara = para.replace(/\n/g, '<br>\n');
      return `<p>${wrappedPara}</p>`;
    })
    .join('\n\n');
};
