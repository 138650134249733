export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const poll = async (
  loadData: () => any,
  isReady: (payload) => boolean
) => {
  for (let i = 0; ; i++) {
    const { payload } = await loadData();

    if (isReady(payload)) {
      return payload;
    } else {
      // Delay is exponential; starting with 1 second and with a maximum of 8 seconds.
      // In seconds: 1, 2, 4, 8, 8
      const ms = Math.min(2 ** i, 8) * 1000;

      await sleep(ms);
    }
  }
};
