import React, { ReactNode } from 'react';
import { Button } from 'shared/bootstrap';
import classNames from 'classnames';

const WarningIcon = () => {
  return (
    <svg
      className="warning__icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 32 32"
    >
      <title>warning</title>
      <path d="M16 2.899l13.409 26.726h-26.819l13.409-26.726zM16 0c-0.69 0-1.379 0.465-1.903 1.395l-13.659 27.222c-1.046 1.86-0.156 3.383 1.978 3.383h27.166c2.134 0 3.025-1.522 1.978-3.383h0l-13.659-27.222c-0.523-0.93-1.213-1.395-1.903-1.395v0z" />
      <path d="M18 26c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z" />
      <path d="M16 22c-1.105 0-2-0.895-2-2v-6c0-1.105 0.895-2 2-2s2 0.895 2 2v6c0 1.105-0.895 2-2 2z" />
    </svg>
  );
};

interface WarningMessageProps {
  children: ReactNode;
  title: string;
}

const WarningMessage = ({ children, title }: WarningMessageProps) => {
  return (
    <div className="warning__message">
      <h4 className="warning__title">
        <WarningIcon />
        {title}
      </h4>
      <div className="warning__text">{children}</div>
    </div>
  );
};

interface WarningButtonProps {
  children: ReactNode;
  className: string;
  isDisabled: boolean;
  onClick: (args?: any) => any;
}

const WarningButton = ({
  children,
  className = 'warning',
  isDisabled = false,
  onClick,
}: WarningButtonProps) => {
  return (
    <Button
      className={classNames(className, 'warning__button')}
      disabled={isDisabled}
      onClick={onClick}
      type="submit"
    >
      {children}
    </Button>
  );
};

interface WarningProps {
  children: ReactNode;
  className?: string;
}

const Warning = ({ children, className }: WarningProps) => {
  return <div className={classNames('warning', className)}>{children}</div>;
};

export { WarningButton, WarningMessage };
export default Warning;
