import React from 'react';
import { formatAmount } from '../charts/LabelFormatters';

interface Props {
  active?: boolean;
  overrideNameLabel?: string;
  payload?: {
    fill?: string;
    stroke?: string;
    name: string;
    value: string | number;
  }[];
  label?: string;
}
function CustomTooltip({ active, overrideNameLabel, payload, label }: Props) {
  if (active && payload && payload.length) {
    return (
      <div className="ti-chart-tooltip">
        <div className="ti-chart-tooltip__header">{label}</div>
        <div className="ti-chart-tooltip__body">
          {payload.map((category, i) => (
            <React.Fragment key={i}>
              <div className="ti-chart-tooltip__name">
                <span
                  className="ti-swatch"
                  style={{ backgroundColor: category.stroke || category.fill }}
                />
                {overrideNameLabel ?? category.name}
              </div>
              <div className="ti-chart-tooltip__value">
                {formatAmount(category.value)}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default CustomTooltip;
