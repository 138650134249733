import React, { useEffect, useRef } from 'react';
import { Modal, Button } from 'shared/bootstrap';

import * as I18n from 'shared/utils/I18n';

import WithdrawDialogForm from 'charlie/components/WithdrawDialog/WithdrawDialogForm';
import useWithdrawalDialog from 'charlie/components/WithdrawDialog/useWithdrawDialog';
import WithdrawIntroduction from './WithdrawIntroduction';

const ns = 'charlie.frontpage.reactivation_modal.withdraw_step';

interface Props {
  close: () => void;
  finishStep: () => void;
  requireBankStatements: boolean;
  requirePsd2Refresh: boolean;
}

const WithdrawStep = ({
  close,
  finishStep,
  requireBankStatements,
  requirePsd2Refresh,
}: Props) => {
  const {
    availableBalance,
    control,
    generalErrors,
    handleSubmit,
    isSubmitting,
    summaryProperties,
    withdrawAll,
  } = useWithdrawalDialog(finishStep);

  const amountInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    amountInputRef.current?.focus();
  }, []);

  return (
    <div className="WithdrawStep">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WithdrawIntroduction
          requireBankStatements={requireBankStatements}
          requirePsd2Refresh={requirePsd2Refresh}
        />
        <h5 className="withdraw-title">{I18n.nt(ns, 'withdraw_title')}</h5>
        <WithdrawDialogForm
          amountInputRef={amountInputRef}
          availableBalance={availableBalance}
          control={control}
          generalErrors={generalErrors}
          handleSubmit={handleSubmit}
          summaryProperties={summaryProperties}
          withdrawAll={withdrawAll}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-light-inverted" onClick={close}>
          {I18n.nt(ns, 'close')}
        </Button>
        <Button
          className="btn-primary"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {I18n.nt(ns, 'withdraw')}
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default WithdrawStep;
