import { normalize } from 'normalizr';
import { merge } from 'lodash';
import { Middleware } from 'redux';
import * as definedSchemas from '../schema';
import konsole from 'shared/utils/konsole';

function arrayify(item) {
  return item instanceof Array ? item : [item];
}

export const getSchemaEntityKeys = (schema) => {
  if (schema instanceof Array) {
    schema = schema[0];
  }

  const ownKey = schema.key;
  const childKeys = Object.keys(schema.schema).map(
    (key) => schema.schema[key].key
  );

  return [ownKey, ...childKeys];
};

// Make sure that the normalized payload contains all keys of nested entities
//  in the schema, even if there are no resources in the original payload
export const ensureSchemaEntityKeys = (schema, payload) => {
  const schemaKeys = getSchemaEntityKeys(schema);
  schemaKeys.forEach((key) => {
    payload.entities[key] = payload.entities[key] || {};
  });
};

const normalizeMiddleware: Middleware = () => (next) => (action) => {
  const { error, payload, ...rest } = action as any;

  if (!payload || !(action as any).normalize || error) {
    return next(action);
  }

  let entities = payload.entities ? payload.entities : payload;
  const isArray = entities instanceof Array;

  // In the case of an empty collection result, we cannot infer
  // the used schema, so we just exit early
  if (isArray && entities.length === 0) {
    return next({
      ...rest,
      payload: {
        ...payload,
        result: [],
        entities: {},
      },
    });
  }

  function normalizeEntity(entity, isArray) {
    if (!('entityKind' in entity)) {
      konsole.warn('No entityKind found in object, not normalizing:', entity);
      return next(action);
    }

    // Warn when we can't find the schema the server indicated
    const schemaName = entity.entityKind.replace('Minimal', '');
    const schema = definedSchemas[schemaName];

    if (!schema) {
      konsole.warn('schema not defined, not normalizing', schemaName);
      return next(action);
    }

    const normalizedEntities = normalize(entity, schema);
    ensureSchemaEntityKeys(schema, normalizedEntities);

    if (isArray) {
      return {
        ...normalizedEntities,
        result: arrayify(normalizedEntities.result),
      };
    } else {
      return normalizedEntities;
    }
  }

  entities = isArray ? entities : [entities];
  let normalizedEntities = entities.map((entity) =>
    normalizeEntity(entity, isArray)
  );

  normalizedEntities = normalizedEntities.reduce((acc, normalEntity) => {
    const normalEntityAttrs = normalEntity;

    if (acc.result) {
      const results = arrayify(acc.result);
      const normalResults = arrayify(normalEntity.result);
      const newResult = results.concat(normalResults);

      normalEntityAttrs.result = newResult;
    }

    return merge(acc, normalEntityAttrs);
  });

  const newPayload = payload.entities
    ? { ...payload, ...normalizedEntities }
    : normalizedEntities;

  // Warn on schema missing from server response
  return next({ ...rest, payload: newPayload });
};

export default normalizeMiddleware;
