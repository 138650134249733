export type Generic = 'bunq' | 'knab';

export type Psd2 = 'ing' | 'rabobank' | 'abnamro';

export type Unknown = 'unknown';

export type Any = Generic | Psd2 | Unknown;

/**
 * Take any value and turn it into a known valid bank type. Any invalid value
 * will become the `Unknown` bank type.
 */
export function parse(bank: any): Any {
  switch (bank) {
    case 'bunq':
    case 'knab':
    case 'ing':
    case 'rabobank':
    case 'abnamro':
      return bank;
    default:
      return 'unknown';
  }
}

/**
 * Extract a bank type from an existing IBAN by reading the bank code.
 * This is made to (mostly) mirror the implementation in `app/lib/iban.rb`.
 */
export function fromIban(iban: any): Any {
  if (typeof iban !== 'string') {
    return 'unknown';
  }

  switch (iban.toLowerCase().slice(4, 8)) {
    case 'abna':
      return 'abnamro';
    case 'bunq':
      return 'bunq';
    case 'ingb':
      return 'ing';
    case 'rabo':
      return 'rabobank';
    case 'knab':
      return 'knab';
    default:
      return 'unknown';
  }
}
