import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import client from 'shared/utils/client';
import { nt } from 'shared/utils/I18n';
import useLoadFullSeller from 'shared/hooks/useLoadFullSeller';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { trackEvent } from 'shared/utils/tracker';
import { Attachment, Seller } from 'types';
import Question from './question';
import { ns } from './utils';
import { get } from 'lodash';
import { QuestionKind } from './QuestionsAction';

interface AnswerQuestionParams {
  answer?: string;
  answerFiles?: Attachment[];
  id: number;
}

interface RouteParams {
  kind: QuestionKind;
}

export type ReopenQuestion = (id: number) => void;

export type AnswerQuestion = ({
  answer,
  answerFiles,
  id,
}: AnswerQuestionParams) => Promise<void>;

const useQuestions = () => {
  const loadFullSeller = useLoadFullSeller();
  const [status, setStatus] = useState<string>('');
  const [questions, setQuestions] = useState<Question[]>([]);
  const seller = useCurrentSeller() as Seller;

  const { kind } = useParams<RouteParams>();

  const loadQuestions = async () => {
    const response = await client<Question[]>(
      'GET',
      '/api/questions',
      {},
      { raiseError: false }
    );

    if (response.error) {
      setStatus('error');
    } else {
      const loadedQuestions = response.payload;

      setQuestions(
        loadedQuestions.map((question) => ({
          ...question,
          initialStatus: question.answeredAt ? 'answered' : 'open',
        }))
      );
      setStatus('loaded');
    }
  };

  const reOpenQuestion: ReopenQuestion = (id: number) => {
    setQuestions(
      questions.map((question) =>
        question.id === id ? { ...question, reOpened: true } : question
      )
    );
  };

  const answerQuestion: AnswerQuestion = async ({
    answer,
    answerFiles,
    id,
  }) => {
    const attachmentIds = (answerFiles || []).map((a) => a.id);

    const response = await client('PUT', `/api/questions/${id}`, {
      answer,
      answerFiles: attachmentIds,
    });

    const updatedQuestion = response.payload as Question;

    await loadFullSeller();

    trackEvent('Answer question', { questionId: id });

    setQuestions(
      questions.map((question) =>
        question.id === updatedQuestion.id
          ? { ...updatedQuestion, initialStatus: question.initialStatus }
          : question
      )
    );
  };

  const answeredQuestions = questions.filter(
    (question) =>
      question.initialStatus === 'answered' && question.kind === kind
  );
  const openQuestions = questions.filter(
    (question) => question.initialStatus === 'open' && question.kind === kind
  );

  const getIntroductionText = () => {
    const statistics = get(seller.questions, `${kind}`);

    switch (statistics.status) {
      case 'all_questions_answered':
        return nt(ns, 'introduction.all_questions_answered');
      case 'unanswered_questions':
        return (
          nt(ns, `introduction.unanswered_questions.${kind}`, {
            count: statistics.unansweredCount,
          }) +
          ' ' +
          nt(ns, 'introduction.unanswered_questions.general')
        );
      case 'no_questions':
      default:
        return nt(ns, `introduction.no_questions.${kind}`);
    }
  };

  useEffect(() => {
    loadQuestions();
  }, []);

  return {
    answeredQuestions,
    answerQuestion,
    introductionText: getIntroductionText(),
    openQuestions,
    kind,
    reOpenQuestion,
    status,
  };
};

export default useQuestions;
