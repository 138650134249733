import React, { useEffect, useState } from 'react';
import client from 'shared/utils/client';
import { Alert } from 'shared/bootstrap';

/*
 * `keys` is a list of announcement keys to show.
 */
interface Props {
  keys: string[];
}

/*
 * An announcement is a message site admins can post to inform users about
 * current issues.
 *
 * The `key` represents a "location" in the application so admins can post
 * messages to specific parts of the user interface.
 *
 * See Api::AnnouncementsController#index
 */
interface Announcement {
  id: number;
  key: string;
  body: string;
  severity: 'info' | 'warning';
}

/*
 * Fetch announcements from the `/api/announcements` API.
 */
async function getAnnouncements(): Promise<Announcement[]> {
  const response = await client(
    'GET',
    '/api/announcements',
    {},
    { raiseError: false }
  );

  if (response.error) {
    // Display nothing when the announcements cannot be loaded.
    return [];
  } else {
    return response.payload as Announcement[];
  }
}

/*
 * Match a list of announcements to a whitelist of keys. Filter out those
 * that do not match.
 */
function matching(announcements: Announcement[], keys: string[]) {
  return announcements.filter(({ key }) => {
    return keys.indexOf(key) >= 0;
  });
}

/*
 * `DisplayAnnouncements` lists admin announcement messages. It may show multiple
 * messages, but usually shows only one.
 *
 * Messages are shown as a Bootstrap danger alert.
 *
 * Note that no effort is made to request announcements only once and then cache
 * the result for as long as possible. The request should be quick and the
 * contents might change on a moment's notice; it's probably better to make a
 * few too many requests when there is nothing new, than it is to make too few
 * and miss an important message.
 */
const DisplayAnnouncements = ({ keys }: Props) => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  useEffect(() => {
    getAnnouncements().then((payload) => {
      setAnnouncements(matching(payload, keys));
    });
  }, []);

  return (
    <div>
      {announcements.map(({ id, body, severity }) => (
        <Alert key={id} type={severity} className="tw-whitespace-pre-wrap">
          {body}
        </Alert>
      ))}
    </div>
  );
};

export default DisplayAnnouncements;
