import { combineReducers } from 'redux';

import commonReducers from 'shared/redux/commonReducers';

import { invoicesReducer } from './modules/invoices';
import onboarding from 'Onboarding/reducer';
import profile from 'signup/reducers/SellerSignup';
import signup from 'signup/reducers/index';

import { invoiceReducer } from './components/seller/Invoice/module';

import { profilePageReducer } from './modules/profilePage';

import charlie from 'charlie/module';
import partnerSignup from 'PartnerSignup/modules/partnerSignupSlice';
import exactOnlineConnection from 'app/components/seller/profile/ExactOnlineConnection/exactOnlineConnectionSlice';
import financialDashboard from 'FinancialDashboard/financialDashboardSlice';

export default {
  app: combineReducers({
    profilePage: profilePageReducer,
    invoices: invoicesReducer,
    invoice: invoiceReducer,
    profile,
  }),
  charlie,
  exactOnlineConnection,
  financialDashboard,
  onboarding,
  partnerSignup,
  ...commonReducers,
  ...signup,
};
