import cookie from 'js-cookie';
import * as I18n from 'shared/utils/I18n';

export const getSignupReferrer = () => cookie.get('signupReferrer');

export const setSignupReferrer = (referrer: string) =>
  cookie.set('signupReferrer', referrer, { expires: 100 });

const correctReferrers = [
  'auxilium',
  'bol',
  'debiteurnet',
  'effectconnect',
  'financefinder',
  'fivex',
  'multicultinetwork',
  'payt',
  'stichting-bedrijfsfinanciering',
  'technieknederland',
  'worldfirst',
  'yezzer',
];

export const isCorrectReferrer = (referrer: string): boolean =>
  correctReferrers.includes(referrer);

export const showReferrerLogo = (referrer: string): boolean =>
  referrer !== 'bol';

export const referrerMessage = (referrer: string) =>
  I18n.nt(
    'app.nav.referrer_banner',
    referrer === 'bol' ? 'for_partners_of' : 'in_cooperation_with'
  );

export const mustDisplayPartnerContactInformation = (
  referrer: string | undefined
) => referrer && referrer !== 'bol';
