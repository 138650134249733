import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import client from 'shared/utils/client';
import { useEffect, useState } from 'react';
import { trackEvent, trackFormSubmit } from 'shared/utils/tracker';
import { useSelector } from 'react-redux';
import { currentSellerSelector } from 'shared/selectors';

// eslint-disable-next-line
interface FormValues {}

type PayFeatureStatus = 'error' | 'initial' | 'success';

const usePayFeatureInfoModal = (parentPath: string) => {
  const seller = useSelector(currentSellerSelector);
  const [status, setStatus] = useState<PayFeatureStatus>('initial');
  const history = useHistory();

  useEffect(() => {
    trackEvent('Floryn Pay feature info dialog: loaded', {
      sellerId: seller?.id,
    });
  }, []);

  const close = () => history.push(parentPath);

  const submit = async () => {
    const response = await client(
      'POST',
      `/api/floryn_pay_signups`,
      {},
      { raiseError: false }
    );

    setStatus(response.error ? 'error' : 'success');
    trackFormSubmit('floryn-pay-feature-info-dialog');
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  return {
    close,
    control,
    handleSubmit: handleSubmit(submit),
    isSubmitting,
    status,
  };
};

export default usePayFeatureInfoModal;
