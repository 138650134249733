import React from 'react';
import useRefreshSignStatus from './useRefreshSignStatus';
import DisplayLoginStatus from './DisplayLoginStatus';
import DisplaySignStatus from './DisplaySignStatus';
import { getSignStatus } from './util';

const Signed = () => {
  const { guarantee, loginStatus } = useRefreshSignStatus();

  if (loginStatus === 'logged_in' && guarantee) {
    const signStatus = getSignStatus(guarantee);

    return (
      <DisplaySignStatus
        coSignerEmail={guarantee.coSignerEmail || ''}
        signStatus={signStatus}
      />
    );
  } else {
    return <DisplayLoginStatus loginStatus={loginStatus} />;
  }
};

export default Signed;
