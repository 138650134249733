import { parseISO } from 'date-fns';
import { explicitDateFormat, toEuro } from 'shared/utils/Helpers';

export const formatAmount = (amount: number | string): string => {
  const decimals = Number(amount) % 1 === 0 ? 0 : 2; // Only show decimals when the amount has decimals

  return toEuro(amount, decimals);
};

export const formatDate = (dateString: string): string =>
  explicitDateFormat(parseISO(dateString), 'd-M-yyy');
