import React from 'react';
import { Seller } from 'types';
import Video from '../Video';
import ProductVideo from '../ProductVideo';

interface Props {
  seller: Seller;
}

const SubmittedVideo = ({ seller }: Props) => {
  if (seller.showProposalSummary) {
    return <ProductVideo seller={seller} />;
  } else {
    return <Video kind="generalIntroduction" />;
  }
};

export default SubmittedVideo;
