import React from 'react';
import { nt } from 'shared/utils/I18n';
import { SearchResult } from './types';
import CompanyListItem from './CompanyListItem';
import { CandidateCompany, Company } from 'types';

const ns = 'form.companyInput';

interface Props {
  onSelect: (company: CandidateCompany | Company) => void;
  searchResult: SearchResult;
}

const CompanyList = ({ onSelect, searchResult }: Props) => (
  <>
    {searchResult.companies.length > 0 && (
      <div className="Companies">
        <label>{nt(ns, 'existingCompanies')}:</label>
        <div className="list-group">
          {searchResult.companies.map((company) => (
            <CompanyListItem
              key={company.id}
              company={company}
              onSelect={onSelect}
            />
          ))}
        </div>
      </div>
    )}
    {searchResult.candidateCompanies.length > 0 && (
      <div className="Companies">
        <label className="tw-font-heading tw-font-medium">
          {nt(ns, 'candidateCompanies')}:
        </label>
        <div className="list-group">
          {searchResult.candidateCompanies.map((company) => (
            <CompanyListItem
              key={company.key}
              company={company}
              onSelect={onSelect}
            />
          ))}
        </div>
      </div>
    )}
  </>
);

export default CompanyList;
