import { isNumber, mergeWith, max, min, pick } from 'lodash';
import { toISODateString, fillDates } from 'shared/utils/date';

const adder = (obj, src) => (isNumber(obj) ? obj + src : src);

const toArray = (closingBalances): { date: string; amount: number }[] =>
  Object.entries(closingBalances).map(([date, amount]) => ({
    date: date as string,
    amount: amount as number,
  }));

export const combineBalances = (balances) =>
  mergeWith({}, ...Object.values(balances), adder);

const filterBalances = (balances, accounts) => {
  const ibans = accounts.map((account) => account.id);

  return pick(balances, ibans);
};

const trimCombinedBalances = (combinedBalances, accounts) => {
  if (accounts.length === 0) {
    return {};
  }

  const from: Date | undefined = min(
    accounts.map((account) => new Date(account.beginDate))
  );
  const till: Date | undefined = max(
    accounts.map((account) => new Date(account.endDate))
  );

  if (!from || !till) {
    return {};
  }

  const relevantDates = fillDates(from, till).map(toISODateString);

  return pick(combinedBalances, relevantDates);
};

export function prepareBalances(balances, accounts) {
  const combinedBalances = combineBalances(filterBalances(balances, accounts));

  return toArray(trimCombinedBalances(combinedBalances, accounts));
}
