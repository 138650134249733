import { useForm } from 'react-hook-form';
import resolver from 'hookForm/resolver';
import {
  validateDate,
  validateDateIsInPast,
  validateEmail,
  validatePresence,
} from 'shared/utils/validation';
import { fromDutchDate, toDutchDate } from 'shared/utils/Helpers';
import Guarantee from 'types/Guarantee';
import { nt } from 'Onboarding/components/utils';
import { FormValues } from './GuaranteeForm';
import { useState } from 'react';

const t = nt('shared.guarantee_form');

interface Props {
  guarantee: Guarantee;
  onSubmit: (values: FormValues) => Promise<void>;
  sellerEmail: string;
}

const validateSignupEmail = (
  coSignerEmail: string,
  sellerEmail: string
): string | null => {
  return coSignerEmail === sellerEmail ? t('same_partner_email') : null;
};

const defaultValues = (guarantee: Guarantee): Partial<FormValues> => {
  const defaultGuaranteeValues: Partial<FormValues> = {
    ...guarantee,
    guaranteeRequiresCoSigner:
      guarantee.guaranteeRequiresCoSigner?.toString() as
        | 'true'
        | 'false'
        | undefined,
  };

  // Guarantee might contain null values. These are not accepted by hookform
  Object.keys(defaultGuaranteeValues).forEach((key: string) => {
    if (defaultGuaranteeValues[key] === null) {
      delete defaultGuaranteeValues[key];
    }
  });

  if (defaultGuaranteeValues.dateOfBirth) {
    defaultGuaranteeValues.dateOfBirth = toDutchDate(
      defaultGuaranteeValues.dateOfBirth
    );
  }

  return defaultGuaranteeValues;
};

const useGuaranteeForm = ({ guarantee, onSubmit, sellerEmail }: Props) => {
  const [error, setError] = useState<string | null>(null);

  const validate = ({
    city,
    confirmNoPartner,
    coSignerEmail,
    coSignerName,
    dateOfBirth,
    fullName,
    guaranteeRequiresCoSigner,
    houseNumber,
    placeOfBirth,
    postalCode,
    street,
  }: FormValues) => ({
    city: validatePresence(city),
    confirmNoPartner:
      guaranteeRequiresCoSigner === 'false'
        ? validatePresence(confirmNoPartner)
        : null,
    coSignerEmail:
      guaranteeRequiresCoSigner === 'true'
        ? validateEmail(coSignerEmail) ||
          validateSignupEmail(coSignerEmail, sellerEmail)
        : null,
    coSignerName:
      guaranteeRequiresCoSigner === 'true'
        ? validatePresence(coSignerName)
        : null,
    dateOfBirth:
      validatePresence(dateOfBirth) ||
      validateDate(dateOfBirth) ||
      validateDateIsInPast(dateOfBirth),
    fullName: validatePresence(fullName),
    guaranteeRequiresCoSigner: validatePresence(guaranteeRequiresCoSigner),
    houseNumber: validatePresence(houseNumber),
    placeOfBirth: validatePresence(placeOfBirth),
    postalCode: validatePresence(postalCode),
    street: validatePresence(street),
  });

  const submit = async (values: FormValues) => {
    const submitValue: FormValues = {
      ...values,
      dateOfBirth: fromDutchDate(values.dateOfBirth),
    };

    try {
      await onSubmit(submitValue);
    } catch (error) {
      setError(error);
    }
  };

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    defaultValues: defaultValues(guarantee),
    resolver: resolver<FormValues>(validate),
  });

  return {
    control,
    error,
    handleSubmit: handleSubmit(submit),
    isSubmitting,
    watch,
  };
};

export default useGuaranteeForm;
