import { useSelector } from 'react-redux';
import { customerAccountSelector } from 'shared/selectors';

const useIsComplexAccount = () => {
  const customerAccount = useSelector(customerAccountSelector);

  if (!customerAccount) {
    return false;
  } else {
    return customerAccount.sellers.length > 1;
  }
};

export default useIsComplexAccount;
