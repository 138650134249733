import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';

import InputWrapper from 'hookForm/InputWrapper';
import * as I18n from 'shared/utils/I18n';
import { InvoiceContact } from 'types';
import ContactsList from './ContactsList';
import AddContactForm from './AddContactForm';
import useContactsInput from './useContactsInput';

interface Props<TFieldValues extends FieldValues> {
  allContacts: InvoiceContact[];
  control: Control<TFieldValues>;
  hint?: string;
  label: string;
  name: Path<TFieldValues>;
}

const ContactsInput = <TFieldValues extends FieldValues>({
  allContacts: initialAllContacts,
  control,
  hint,
  name,
  label,
}: Props<TFieldValues>) => {
  const { addContact, allContacts, selectedContacts, toggleContact } =
    useContactsInput({
      control,
      initialAllContacts,
      name,
    });

  return (
    <InputWrapper label={label} name={name} type="contacts">
      {hint && (
        <p key="hint" className="hint tw-mb-4">
          {hint}
        </p>
      )}
      <ContactsList
        allContacts={allContacts}
        selectedContacts={selectedContacts}
        toggleContact={toggleContact}
      />
      <p className="tw-mt-4">{I18n.hint('invoice', 'add_new_contact')}</p>
      <AddContactForm addContact={addContact} />
    </InputWrapper>
  );
};

export default ContactsInput;
