import React from 'react';
import useSelectorWithShallowCompare from 'shared/hooks/useSelectorWithShallowCompare';
import { ReduxState } from 'types';
import { calculatePercentage } from './utils';
import SummaryLayout from './DashboardSummaryLayout';
import AmountField from './AmountField';
import IconTooltip from 'shared/components/IconTooltip';
import * as I18n from 'shared/utils/I18n';

const ns = 'app.mutations';

const AlfaSummary = () => {
  const { dashboard } = useSelectorWithShallowCompare((state: ReduxState) => ({
    dashboard: state.charlie.dashboard.payload,
  }));
  if (!dashboard) {
    return null;
  }

  const { availableBalance, creditLimit, currentBalance } = dashboard;

  const chartPercentage = calculatePercentage(
    parseFloat(availableBalance),
    parseFloat(creditLimit)
  );

  const topLeft = {
    amount: parseFloat(availableBalance),
    translationKey: 'available_balance',
  };

  const renderCurrentBalance = () => {
    return (
      <AmountField
        amount={parseFloat(currentBalance)}
        translationKey="current_balance"
      >
        <IconTooltip
          tooltipText={I18n.nt(ns, 'popover_html')}
          iconClassName="tw-ml-[6px]"
        />
      </AmountField>
    );
  };

  const renderMaxLimit = () => {
    return (
      <div className="tw-text-right">
        <AmountField
          amount={parseFloat(creditLimit)}
          translationKey="max_limit"
        />
      </div>
    );
  };

  return (
    <SummaryLayout
      chartPercentage={chartPercentage}
      renderBottomLeft={renderCurrentBalance}
      renderBottomRight={renderMaxLimit}
      topLeft={topLeft}
    />
  );
};

export default AlfaSummary;
