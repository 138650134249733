import React from 'react';
import { Modal } from 'shared/bootstrap';

import WithdrawStep from './WithdrawStep';
import useReactivationModal from './useReactivationModal';
import * as I18n from 'shared/utils/I18n';
import UploadBankstatementLink from '../DashboardAlerts/UploadBankstatementLink';

const ns = 'charlie.frontpage.reactivation_modal';

interface Props {
  onHideModal: () => void;
  onShowModal: () => void;
}

const ReactivationModal = ({ onHideModal, onShowModal }: Props) => {
  const {
    currentStep,
    hideModal,
    isLoaded,
    onClickWithdrawStep,
    requireBankStatements,
    requirePsd2Refresh,
    visible,
  } = useReactivationModal(onHideModal, onShowModal);

  const withdrawStep = (
    <WithdrawStep
      close={hideModal}
      finishStep={onClickWithdrawStep}
      requireBankStatements={requireBankStatements}
      requirePsd2Refresh={requirePsd2Refresh}
    />
  );

  const reviewStep = (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'review_step.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{I18n.nt(ns, 'review_step.open_review_html')}</p>
        <div className="inner-modal-footer">
          <UploadBankstatementLink asButton={true} />
        </div>
      </Modal.Body>
    </>
  );

  if (!isLoaded) {
    return null;
  }

  return (
    <Modal className="ReactivationModal" show={visible} onHide={hideModal}>
      {currentStep === 'welcome' && withdrawStep}
      {currentStep === 'review' && reviewStep}
    </Modal>
  );
};

export default ReactivationModal;
