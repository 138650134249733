import { includes } from 'lodash';

const DUTCH_IBAN_REGEX = /^\s*(nl\d{2}[a-z]{4}\d{10})\s*$/i;

const A = 'A'.charCodeAt(0);

// Calculate the modulo of the number in `value` (which is a string) by 97. This is a recursive function that repeatedly
// replaces the 10 leftmost digits in `value` with the modulo 97 thereof, because the number in `value` can be
// greater than Number.MAX_SAFE_INTEGER (ie. 2^53 - 1).
const modulo97 = (value) =>
  value.length <= 2
    ? parseInt(value, 10) % 97
    : modulo97(`${parseInt(value.substr(0, 10), 10) % 97}${value.substr(10)}`);

const ibanToNumber = (iban) =>
  [...iban.substr(4), ...iban.substr(0, 4)]
    .map((char) => {
      if (char >= 'A' && char <= 'Z') {
        return (10 + char.charCodeAt(0) - A).toString();
      }
      return char;
    })
    .join('');

// https://en.wikipedia.org/wiki/International_Bank_Account_Number#Validating_the_IBAN
const ibanMod97Check = (iban) => {
  return modulo97(ibanToNumber(iban.toUpperCase())) === 1;
};

export const isValidDutchIban = (value) => {
  const [match, iban] = DUTCH_IBAN_REGEX.exec(value) || [];
  return (match && ibanMod97Check(iban)) || false;
};

export const bankCode = (value) =>
  (isValidDutchIban(value) && value.substr(4, 4).toUpperCase()) || '';

const digitalBankCodes = ['ABNA', 'INGB', 'RABO'];

export const isDigitalBank = (iban) =>
  includes(digitalBankCodes, bankCode(iban));

export const cleanIban = (iban) => iban.replace(/[^a-zA-Z0-9]/g, '');
