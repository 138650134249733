import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deauthenticate } from 'shared/redux/modules/session';
import NavigationBarDraftAndSubmitted from 'app/components/NavigationBar/NavigationBarDraftAndSubmitted';
import { ReduxState } from 'types/ReduxState';
import * as I18n from 'shared/utils/I18n';
import { LOGIN } from 'app/routes';
import Container from 'shared/components/tailwind/Container';
import useAppDispatch from 'shared/hooks/useAppDispatch';

const ns = 'app.logout';

const Logout = () => {
  const dispatch = useAppDispatch();
  const session = useSelector((state: ReduxState) => state.session);
  const isLoggedIn = Boolean(session.customerAccount);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(deauthenticate());
    }
  }, []);

  return (
    <>
      <NavigationBarDraftAndSubmitted />
      <Container>
        <div className="tw-pt-6">
          {session.deauthenticating && <p>{I18n.nt(ns, 'logging_out')}</p>}
          {!isLoggedIn && (
            <>
              <h4 className="tw-mb-4">{I18n.nt(ns, 'logged_out')}</h4>
              <Link to={LOGIN}>{I18n.nt(ns, 'log_back_in')}</Link>
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default Logout;
