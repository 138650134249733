import React from 'react';

interface Props {
  companyName: string;
}

const NavigationBar = ({ companyName }: Props) => (
  <>
    <div className="NavigationBarPartnerSignup">
      <div className="floryn-container">
        <div className="navigation-bar-content">
          <span className="company-name">{companyName}</span>
        </div>
      </div>
    </div>
  </>
);

export default NavigationBar;
