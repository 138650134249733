import React from 'react';

interface Props {
  name?: string;
  text?: string;
}

export default ({ name, text }: Props) => (
  <div className="tw-flex tw-min-w-[42px] tw-items-center tw-justify-center tw-rounded-bl-sm tw-rounded-tl-sm tw-border tw-border-r-0 tw-border-[#ccc] tw-bg-gray-200">
    {name && (
      <div className={`icon icon-${name} tw-text-[16px] tw-text-[#555555]`} />
    )}
    {text && text}
  </div>
);
