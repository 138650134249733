export type Status =
  | 'completed'
  | 'warnings'
  | 'pending'
  | 'expiresSoon'
  | 'unrecognised';

/*
 * Given two statuses, return a new status value that is the combination
 * of the two.
 *
 * This considers the combination to be either completed, expiresSoon, warnings
 * or pending, in that other, if either of the two values is.
 */
export function combine(a: Status, b: Status): Status {
  if (a === 'expiresSoon' || b === 'expiresSoon') {
    return 'expiresSoon';
  } else if (a === 'completed' || b === 'completed') {
    return 'completed';
  } else if (a === 'warnings' || b === 'warnings') {
    return 'warnings';
  } else if (a === 'unrecognised' && b !== 'unrecognised') {
    return b;
  } else if (b === 'unrecognised' && a !== 'unrecognised') {
    return a;
  } else {
    return 'pending';
  }
}
