import client, { Result } from 'shared/utils/client';
import { asyncAction } from 'shared/utils/Helpers';
import { currentSellerSelector } from 'shared/selectors';
import { MinimalSeller } from 'types';

const UPDATE_SELLER = asyncAction('if.profile.UPDATE_SELLER');

/*
 * PUT seller data
 */
export const updateSeller = (params) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_SELLER.REQUEST });

  const seller = currentSellerSelector(getState()) as MinimalSeller;

  let response: Result | null = null;
  try {
    response = await client('PUT', `/api/sellers/${seller.id}`, params);
  } catch (e) {
    dispatch({ type: UPDATE_SELLER.FAILURE, error: true, ...e });
  }

  const successAction = dispatch({
    type: UPDATE_SELLER.SUCCESS,
    normalize: true,
    ...response,
  });

  return successAction;
};
