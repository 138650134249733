import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
}

const LoadingAnimation = ({ className }: Props) => (
  <div className={classNames('LoadingAnimation', className)}>
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
);

export default LoadingAnimation;
