import React from 'react';
import * as I18n from 'shared/utils/I18n';
import { dateFormat } from 'shared/utils/Helpers';
import { BaseReview } from 'types/Review';
import InstructionsLink from './InstructionsLink';
import ReviewPeriodMessage from './ReviewPeriodMessage';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';

const ns = 'charlie.frontpage.warnings';

interface Props {
  reviewInfo: BaseReview;
  showInstructions?: boolean;
}

const ReviewIntroductionMessage = ({
  reviewInfo,
  showInstructions = false,
}: Props) => {
  const seller = useCurrentSeller();
  const { deadlineOn, periodicReviewInterval, reviewType } = reviewInfo;

  if (reviewType === 'manual') {
    return (
      <div>
        <ReviewPeriodMessage reviewInfo={reviewInfo} />
        {showInstructions && <InstructionsLink />}
      </div>
    );
  } else {
    return (
      <div>
        <p>
          {periodicReviewInterval !== 0 && (
            <span>
              {I18n.nt(
                ns,
                `review_introduction_periodical_${
                  seller.hasLoan ? 'loan' : 'credit'
                }`,
                {
                  count: periodicReviewInterval,
                }
              )}{' '}
            </span>
          )}
          <ReviewPeriodMessage reviewInfo={reviewInfo} />
        </p>
        {showInstructions && <InstructionsLink />}
        {!seller.hasLoan && (
          <p>
            {I18n.nt(ns, 'deadline_credit', {
              deadlineOn: dateFormat(deadlineOn),
            })}
          </p>
        )}
      </div>
    );
  }
};

export default ReviewIntroductionMessage;
