import React from 'react';
import { Col, Row } from 'shared/bootstrap';
import { AlfaSeller } from 'types';
import { toPercentage } from 'shared/utils/Helpers';
import IconTooltip from 'shared/components/IconTooltip';
import InformationField from 'shared/components/InformationField';
import * as I18n from 'shared/utils/I18n';

interface Props {
  seller: AlfaSeller;
}

const AlfaRates = ({ seller }: Props) => (
  <div className="profile-page-section">
    <div className="tw-mb-4 tw-flex tw-gap-1">
      <h4 className="tw-my-0">{I18n.nt('app.profilePage', 'rates')}</h4>
      <IconTooltip
        iconClassName="tw-text-gray-500"
        tooltipText={I18n.nt('app.profilePage', 'rates_info')}
      />
    </div>

    <Row>
      <Col lg={4}>
        <InformationField
          field="advance_rate"
          value={toPercentage(seller.advanceRate, 0)}
          model="seller"
        />
      </Col>
      <Col lg={4}>
        <InformationField
          field="fixed_rate"
          value={toPercentage(seller.fixedRate)}
          model="seller"
        />
      </Col>
      <Col lg={4}>
        <InformationField
          field="variable_rate"
          value={toPercentage(seller.variableRate)}
          model="seller"
        />
      </Col>
    </Row>
  </div>
);

export default AlfaRates;
