import { sortBy, sumBy } from 'lodash';
import { asyncAction } from 'shared/utils/Helpers';
import client from 'shared/utils/client';

export const LOAD_INVOICES = asyncAction('if.invoices.LOAD_INVOICES');
const LOAD_COUNTS = 'if.invoices.LOAD_COUNTS';
const LOAD_DASHBOARD = asyncAction('if.invoices.LOAD_DASHBOARD');
const SELECT_FINANCING_FILTER = 'if.invoices.SELECT_FINANCING_FILTER';
const SELECT_STATUS_FILTER = 'if.invoices.SELECT_FILTER';
const REQUEST_FINANCING = asyncAction('if.invoices.REQUEST_FINANCING');

const initialState = {
  dashboard: {
    status: 'empty',
  },

  invoiceCounts: {},

  initialInvoicesLoaded: false,
  invoices: [],
  invoicesLoading: false,
  invoicesLoaded: false,
  invoicesTotalAmount: 0,

  selectedStatusFilter: 'open',
  selectedFinancingFilter: 'all',
};

const calculateInvoicesTotalAmount = (invoices) =>
  sumBy(invoices, (invoice) => parseFloat(invoice.amount));

const STATUS_ORDER = [
  'draft',
  'assessment',
  'open',
  'overdue',
  'paid',
  'withdrawn',
];

// Sort invoice by primarily by status ASC, then by debtor name ASC
const sortInvoices = (invoices) =>
  sortBy(invoices, (invoice) => [
    STATUS_ORDER.indexOf(invoice.filterState),
    invoice.debtor,
  ]);

export const invoicesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_INVOICES.REQUEST:
      return {
        ...state,
        invoicesLoading: true,
        invoicesLoaded: false,
      };

    case LOAD_INVOICES.SUCCESS: {
      const invoices = action.payload;

      return {
        ...state,
        initialInvoicesLoaded: true,
        invoices: sortInvoices(invoices),
        invoicesLoading: false,
        invoicesLoaded: true,
        invoicesTotalAmount: calculateInvoicesTotalAmount(invoices),
      };
    }

    case LOAD_INVOICES.FAILURE:
      return {
        ...state,
        invoices: [],
        invoicesTotalAmount: 0,
        invoicesLoading: false,
        invoicesLoaded: false,
      };

    case LOAD_COUNTS:
      return {
        ...state,
        invoiceCounts: action.payload,
      };

    case LOAD_DASHBOARD.REQUEST:
      return {
        ...state,
        dashboard: {
          status: 'loading',
        },
      };

    case LOAD_DASHBOARD.SUCCESS:
      return {
        ...state,
        dashboard: {
          status: 'loaded',
          ...action.payload,
        },
      };

    case LOAD_DASHBOARD.FAILURE:
      return {
        ...state,
        dashboard: {
          status: 'error',
        },
      };

    case SELECT_STATUS_FILTER:
      return {
        ...state,
        selectedStatusFilter: action.statusFilter,
      };

    case SELECT_FINANCING_FILTER:
      return {
        ...state,
        selectedFinancingFilter: action.financingFilter,
      };

    case REQUEST_FINANCING.REQUEST:
      return {
        ...state,
        performingAction: true,
      };

    case REQUEST_FINANCING.FAILURE:
      return {
        ...state,
        performingAction: false,
      };

    case REQUEST_FINANCING.SUCCESS: {
      const { allowedActions, id, financingState } = action.payload;
      const invoice = state.invoices.find((i) => i.id === id);
      invoice.financingState = financingState;
      invoice.allowedActions = allowedActions;

      return {
        ...state,
        performingAction: false,
      };
    }

    default:
      return state;
  }
};

const clientGetInvoices = (getState) => {
  const { selectedStatusFilter, selectedFinancingFilter } =
    getState().app.invoices;

  return client('GET', '/api/invoices', {
    financing_filter: selectedFinancingFilter,
    state_filter: selectedStatusFilter,
  });
};

const loadInvoices = () => async (dispatch, getState) => {
  dispatch({ type: LOAD_INVOICES.REQUEST });

  try {
    const invoicesResponse = await clientGetInvoices(getState);

    dispatch({ type: LOAD_INVOICES.SUCCESS, ...invoicesResponse });
  } catch (e) {
    dispatch({ type: LOAD_INVOICES.FAILURE, error: true, ...e });
  }
};

export const selectStatusFilter = (statusFilter) => (dispatch) => {
  dispatch({ type: SELECT_STATUS_FILTER, statusFilter });
  dispatch(loadInvoices());
};

export const selectFinancingFilter = (financingFilter) => (dispatch) => {
  dispatch({ type: SELECT_FINANCING_FILTER, financingFilter });
  dispatch(loadInvoices());
};

export const loadInitialInvoices = () => async (dispatch, getState) => {
  dispatch({ type: LOAD_INVOICES.REQUEST });

  const getSelectedStatusFilter = () =>
    getState().app.invoices.selectedStatusFilter;

  try {
    const { payload: counts } = await client('GET', '/api/invoices/count');
    dispatch({ type: LOAD_COUNTS, payload: counts });

    if (getSelectedStatusFilter() === 'open' && !counts.open) {
      dispatch(selectStatusFilter('all'));
    }

    // Please note that we may have switched the selected filter.
    // So we need to call getState() again to retrieve the current state.
    const invoicesResponse = await clientGetInvoices(getState);

    dispatch({ type: LOAD_INVOICES.SUCCESS, ...invoicesResponse });
  } catch (e) {
    dispatch({ type: LOAD_INVOICES.FAILURE, error: true, ...e });
  }
};

export const loadDashboard = () => ({
  type: LOAD_DASHBOARD,
  normalize: false,
  promise: (client) => client('GET', '/api/invoices/dashboard'),
});

export const requestFinancing = (invoiceId) => ({
  type: REQUEST_FINANCING,
  normalize: false,
  promise: (client) =>
    client('PUT', `/api/invoices/${invoiceId}/request_financing`),
});
