import React from 'react';
import { Col, Row } from 'shared/bootstrap';

import * as I18n from 'shared/utils/I18n';
import SignupForm from './SignupForm';

const ns = 'app.signup';

const AccountStep = () => (
  <div>
    <div className="tw-mb-4">
      <h1 className="tw-mb-4 tw-text-lg md:tw-text-bootstrap-h3">
        {I18n.nt(ns, 'title')}
      </h1>
      <Row>
        <Col lg={10}>
          <p>{I18n.nt(ns, 'intro')}</p>
        </Col>
      </Row>
    </div>
    <SignupForm />
  </div>
);

export default AccountStep;
