import React from 'react';
import ModalContext from './ModalContext';

interface Props {
  children?: React.ReactNode;
  closeButton: boolean;
  className?: string;
}

const ModalHeader = ({ children, closeButton, className, ...props }: Props) => {
  const { onHide } = React.useContext(ModalContext);
  return (
    <div {...props} className={['modal-header', className].join(' ')}>
      {closeButton && (
        <button type="button" className="close" onClick={onHide}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      )}
      {children}
    </div>
  );
};

export default ModalHeader;
