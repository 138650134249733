import React from 'react';
import SmallSignupProgressStep from './SmallSignupProgressStep';
import useSignupProgress from './useSignupProgress';

const SmallSignupProgress = () => {
  const { currentIndex, steps, stepsArray } = useSignupProgress();
  const percentage = ((currentIndex - 1) * 100) / (steps - 1);

  return (
    <div className="tw-relative tw-mb-2 tw-mt-0 tw-flex tw-justify-between">
      <div className="tw-absolute tw-left-0 tw-top-[14px] tw-z-[-2] tw-h-[4px] tw-w-[100%] tw-bg-gray-300">
        <div
          className="tw-absolute tw-z-[-1] tw-h-[4px] tw-bg-floryn-green"
          style={{ width: `${percentage}%` }}
        />
      </div>
      {stepsArray.map((i) => (
        <SmallSignupProgressStep
          key={i}
          done={currentIndex >= i}
          stepIndex={i}
        />
      ))}
    </div>
  );
};

export default SmallSignupProgress;
