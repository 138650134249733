import * as pathToRegexp from 'path-to-regexp';
import { push } from 'redux-first-history';
import { matchPath } from 'react-router-dom';
import { ReduxState } from 'types';

/* Compile pattern to path

compilePath('/user/:id', {id: 123}) => '/user/123'
*/
export const compilePath = (pattern: string, params: Record<string, unknown>) =>
  pathToRegexp.compile(pattern)(params);

/* Push action; accepts same params as compilePath

dispatch(
  pushToPath('/user/:id', {id: 123})
)
*/
export const pushToPath = (
  pattern: string,
  params: Record<string, unknown> = {}
) => push(compilePath(pattern, params));

/* Extract param from url

paramValueFromUrl('/user/123', '/user/:id', 'id') => '123'
*/
export const paramValueFromUrl = (
  url: string,
  pattern: string,
  paramName: string
) => {
  const match = matchPath(url, { path: pattern });

  return match && match.params[paramName];
};

export const selectPathname = (state: ReduxState) =>
  state.router.location?.pathname;
