import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import * as I18n from 'shared/utils/I18n';
import { MinimalSeller, Seller } from 'types';
import { FinancialDashboardStatus } from 'types';
import * as Consent from 'shared/components/Consent';

import Buttons from './Buttons';
import { LoadingInfo, MaybeLoading } from './Loading';
import BankTransactionsImportModal from 'Onboarding/components/BankTransactionsImportModal';
import Insights from '../Insights';

export const ns = 'signup.onboarding.financial_dashboard';

interface LoadingProps {
  seller: MinimalSeller | Seller;
  status: FinancialDashboardStatus;
}

const Loading = ({ seller, status }: LoadingProps) => {
  return (
    <LoadingInfo
      timedOut={status === 'timeout'}
      connectionMethod={seller.aisConnected ? 'psd2' : 'statements'}
    />
  );
};

interface InnerComponentProps {
  seller: MinimalSeller | Seller;
  status: FinancialDashboardStatus;
}

const InnerComponent = ({ seller, status }: InnerComponentProps) => {
  if (status === 'unavailable') {
    return (
      <>
        <p className="NoData">{I18n.nt(ns, 'no_data')}</p>
        <Buttons />
      </>
    );
  }

  const isLoading = status !== 'available' && status !== 'cancelled';

  const renderLoading = () => <Loading seller={seller} status={status} />;

  return (
    <>
      <MaybeLoading
        isLoading={isLoading}
        renderLoadingComponent={renderLoading}
      >
        <Insights
          type="onboarding"
          processing={isLoading}
          compact
          hideDefaultLoadingAnimation={isLoading}
          showHealthRating
        />
      </MaybeLoading>
      <Buttons />
    </>
  );
};

interface FinancialDashboardProps {
  status: FinancialDashboardStatus;
  seller: MinimalSeller | Seller;
}

const FinancialDashboard = ({ seller, status }: FinancialDashboardProps) => {
  const { path } = useRouteMatch();

  return (
    <div className="FinancialDashboard">
      <Consent.Feedback t={(key) => I18n.nt(`${ns}.consentFeedback`, key)} />
      <InnerComponent seller={seller} status={status} />
      <Route path={`${path}/koppel`} component={BankTransactionsImportModal} />
    </div>
  );
};

export default FinancialDashboard;
