import React from 'react';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { Seller } from 'types';
import { isAlfa, isCharlie, isDelta } from 'models/Seller';
import AlfaSummary from './AlfaSummary';
import CharlieSummary from './CharlieSummary';
import AutomatedOffer from './AutomatedOffer';

const ProposalSummary = () => {
  const seller = useCurrentSeller() as Seller;

  if (isDelta(seller)) {
    return null;
  }

  return (
    <div className="ProposalSummary">
      {seller.showAutomatedOffer && <AutomatedOffer />}
      {isAlfa(seller) && <AlfaSummary />}
      {isCharlie(seller) && <CharlieSummary />}
    </div>
  );
};

export default ProposalSummary;
