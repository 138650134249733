import React from 'react';
import NavigationBar from 'app/components/NavigationBar/NavigationBarDraftAndSubmitted';

const Page = ({ children }) => (
  <>
    <NavigationBar />
    {children}
  </>
);

export default Page;
