import React, { ReactNode } from 'react';
import classNames from 'classnames';
import {
  Tooltip,
  TooltipSize,
  TooltipTrigger,
  TooltipContent,
} from './Tooltip';

interface Props {
  icon?: string;
  iconClassName?: string;
  iconSize?: 'sm' | 'md';
  tooltipSize?: TooltipSize;
  tooltipText: ReactNode;
}

/* The IconTooltip component displays an icon with a toolip.
 * The default icon is the info icon.
 * The tooltip is displayed when the icon is hovered or has the focus.
 *
 * icon: icon name from the icomoon font. Default is 'info'
 * iconClassName: optional css class for the icon. E.g. to change the color.
 * iconSize: size of the icon ('sm' | 'md'). Default is 'md'.
 * tooltipSize: tooltip size. See `Tooltip` component. Default is 'xs'<div className=""></div>
 * tooltipText: tooltip text. Can also be a React node.
  
 <IconTooltip tooltipText="Test text for tooltip" />
 */
const IconTooltip = ({
  icon = 'info',
  iconClassName,
  iconSize = 'md',
  tooltipSize,
  tooltipText,
}: Props) => {
  const iconSizeClass = iconSize === 'sm' ? 'tw-text-[14px]' : 'tw-text-[18px]';

  return (
    <span
      className={classNames('tw-leading-none', iconSizeClass, iconClassName)}
    >
      <Tooltip tooltipSize={tooltipSize}>
        <TooltipTrigger>
          <span className="tw-align-middle tw-leading-none">
            <span className="sr-only">{tooltipText}</span>
            <span
              className={classNames('icon', `icon-${icon}`, iconSizeClass)}
            />
          </span>
        </TooltipTrigger>
        <TooltipContent>{tooltipText}</TooltipContent>
      </Tooltip>
    </span>
  );
};

export default IconTooltip;
