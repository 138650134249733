import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FinancialDashboardStatus from 'types/FinancialDashboardStatus';

export interface FinancialDashboardState {
  loadCounter: number;
  status: FinancialDashboardStatus;
}

const initialState: FinancialDashboardState = {
  loadCounter: 0,
  // TODO: having the default status as 'processing' causes a short flash of the loading screen
  // when opening the financial dashboard, even when the dashboard is already available.
  // It might be better to make the type of the `status` field `FinancialDashboardStatusType | null`,
  // where `null` indicates an unknown status.
  status: 'processing',
};

const financialDashboardSlice = createSlice({
  name: 'financialDashboard',
  initialState,
  reducers: {
    // Reload the financial dashboard. The loading is done in a component, e.g. StatusPoller.
    reload: (state) => {
      state.loadCounter += 1;
      state.status = 'processing';
    },
    updateStatus: (state, action: PayloadAction<FinancialDashboardStatus>) => {
      state.status = action.payload;
    },
  },
});

export const { reload, updateStatus } = financialDashboardSlice.actions;

export default financialDashboardSlice.reducer;
