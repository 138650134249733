import React from 'react';
import OverviewPage from 'charlie/components/OverviewPage';
import FrontpageTabs from './FrontpageTabs';

const Frontpage = () => {
  return (
    <>
      <FrontpageTabs />
      <OverviewPage />
    </>
  );
};

export default Frontpage;
