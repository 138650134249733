import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWatch } from 'react-hook-form';

import * as I18n from 'shared/utils/I18n';
import Button from 'shared/components/tailwind/Button';
import CompanyInput from 'hookForm/CompanyInput';
import RadioButtons from 'hookForm/RadioButtons';
import RadioInput from 'hookForm/RadioInput';
import TextInput from 'hookForm/TextInput';
import useSignupForm from './useSignupForm';
import {
  campaignSelector,
  initialProductPreferenceSelector,
} from 'shared/selectors';
import GeneralErrors from 'hookForm/GeneralErrors';
import { toEuro } from 'shared/utils/Helpers';
import { Alert } from 'shared/bootstrap';
import DuplicateEmailError from './DuplicateEmailError';
import ExpectedFinancingAmountInput from 'signup/shared/ExpectedFinancingAmountInput';
import ExpectedRevenueInput from 'signup/shared/ExpectedRevenueInput';
import { MINIMUM_EXPECTED_REVENUE_AMOUNT } from 'signup/shared/validations';
import { label } from 'shared/utils/I18n';
import DontWorryMessage from 'shared/components/DontWorryMessage';

const ns = 'app.signup';

const SignupForm = () => {
  const campaign = useSelector(campaignSelector);
  const initialProductPreference = useSelector(
    initialProductPreferenceSelector
  );
  const [rejected, setRejected] = useState(false);
  const {
    control,
    duplicateEmail,
    serverErrorMessages,
    isSubmitting,
    onSubmit,
  } = useSignupForm(campaign);

  const handleExpectedRevenueBlur = (value: string) => {
    setRejected(parseFloat(value) < MINIMUM_EXPECTED_REVENUE_AMOUNT);
  };

  const expectedRevenue = useWatch({ control, name: 'expectedRevenue' });

  const expectedFinancingAmountLabel = label(
    'seller',
    `expectedFinancingAmount${
      initialProductPreference === 'factoring' ? 'Factoring' : ''
    }`
  );

  useEffect(() => {
    if (
      rejected &&
      expectedRevenue !== null &&
      parseFloat(expectedRevenue) >= MINIMUM_EXPECTED_REVENUE_AMOUNT
    ) {
      setRejected(false);
    }
  }, [expectedRevenue]);

  return (
    <div className="lg:tw-pr-16">
      <GeneralErrors generalErrors={serverErrorMessages} />
      {duplicateEmail && <DuplicateEmailError email={duplicateEmail} />}
      <div className="tw-mb-6">
        <ExpectedFinancingAmountInput
          autoFocus
          control={control}
          label={expectedFinancingAmountLabel}
          name="expectedFinancingAmount"
        />
      </div>
      <ExpectedRevenueInput
        control={control}
        name="expectedRevenue"
        onBlur={handleExpectedRevenueBlur}
      />
      {rejected && (
        <Alert type="info">
          {I18n.nt(ns, 'rejected_html', {
            minimum_amount: toEuro(MINIMUM_EXPECTED_REVENUE_AMOUNT, 0),
          })}
        </Alert>
      )}
      <CompanyInput
        control={control}
        label={I18n.label('seller', 'company')}
        name="company"
        readOnly={rejected}
      />
      <div className="tw-grid-cols-2 tw-gap-x-10 md:tw-grid">
        <TextInput
          control={control}
          label={I18n.label('user', 'first_name')}
          name="firstName"
          readOnly={rejected}
        />
        <TextInput
          control={control}
          label={I18n.label('user', 'last_name')}
          name="lastName"
          readOnly={rejected}
        />
      </div>
      <TextInput
        control={control}
        label={I18n.label('user', 'email')}
        name="email"
        readOnly={rejected}
      />
      <TextInput
        control={control}
        label={I18n.label('seller', 'phone')}
        name="phone"
        type="tel"
        readOnly={rejected}
      />
      <RadioButtons
        label={I18n.label('seller', 'acceptsPromoEmail')}
        name="acceptsPromoEmail"
      >
        <RadioInput
          control={control}
          label={I18n.ut('common.true')}
          name="acceptsPromoEmail"
          readOnly={rejected}
          value="true"
        />
        <RadioInput
          control={control}
          label={I18n.ut('common.false')}
          name="acceptsPromoEmail"
          readOnly={rejected}
          value="false"
        />
      </RadioButtons>
      <div className="tw-flex">
        <Button
          className="btn-primary tw-grow sm:tw-grow-0"
          disabled={isSubmitting || rejected}
          onClick={onSubmit}
          type="submit"
        >
          {I18n.nt(ns, 'continueToProvideBankAccess')}
        </Button>
      </div>
      <DontWorryMessage />
      <div className="tw-mt-4 tw-flex tw-text-sm tw-text-gray-500">
        <div className="icon icon-info tw-mr-3 tw-inline tw-text-[18px]" />
        <div className="tw-inline">{I18n.nt(ns, 'nextStepExplanation')}</div>
      </div>
      <div className="tw-mt-4 tw-flex tw-text-sm tw-text-gray-500">
        <div className="icon icon-lock tw-mr-3 tw-inline tw-text-[18px] tw-text-gray-500" />
        <div className="tw-inline">
          {I18n.ut('common.secure_connection')}{' '}
          {I18n.nt(ns, 'acceptsConditionsHtml', {
            link_classes: 'tw-underline',
          })}
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
