import React from 'react';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import * as I18n from 'shared/utils/I18n';

const ns = 'shared.upload_status_info';

interface Props {
  bankStatementCount: number;
  saving: boolean;
  warnings: boolean;
}

const UploadStatusInfo = ({ bankStatementCount, saving, warnings }: Props) => {
  if (saving) {
    return (
      <div className="tw-mb-6 tw-mt-4">
        <LoadingAnimation className="inline" />
        <span>{I18n.nt(ns, 'saving')}</span>
      </div>
    );
  } else if (bankStatementCount !== 0) {
    return (
      <div className="tw-mt-4">
        <i className="icon-checkmark tw-pr-2 tw-text-lg tw-text-floryn-green" />
        <span>
          {I18n.nt(
            ns,
            `${warnings ? 'saved_with_warnings' : 'saved_without_warnings'}`
          )}
        </span>
      </div>
    );
  } else {
    return null;
  }
};

export default UploadStatusInfo;
