import React, { useEffect, useRef, ReactNode } from 'react';
import { Alert } from 'shared/bootstrap';
import { WarningMessage } from 'namespaces/shared/components/Warning';
import { translate } from 'shared/utils/I18n';
import { FailedSubmitReason } from './modules/submit';

const t = translate('review_wizard.overview.submit_warning');

interface Props {
  children: ReactNode;
  reason: FailedSubmitReason;
}

const getMessage = (reason: FailedSubmitReason): string => {
  switch (reason) {
    case 'iban_task':
      return t('iban_task_html');
    case 'iban_task_with_override':
      return t('iban_task_with_override_html');
    case 'debtor_list':
      return t('debtor_list');
    case 'iban_task_and_debtor_list':
      return t('iban_task_and_debtor_list_html');
    case 'server':
      return t('server');
    default:
      return t('other');
  }
};

const SubmitWarning = ({ reason, children }: Props) => {
  const warningRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (warningRef?.current) {
      warningRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <div ref={warningRef} className="rw-submit-warning">
      <Alert type="warning">
        <WarningMessage title={t('title')}>
          {getMessage(reason)}
          {reason === 'iban_task' && children}
        </WarningMessage>
      </Alert>
    </div>
  );
};

export default SubmitWarning;
