import React from 'react';

import Action, { ActionButton } from '../Action';
import { nt } from '../utils';
import { OnboardingActionStatus } from 'types';
import { Seller } from 'types';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { get } from 'lodash';

const t = nt('signup.onboarding.questions.action');

export type QuestionKind = 'inquiry' | 'requirement';

interface Props {
  basePath: string;
  status: OnboardingActionStatus;
  kind: QuestionKind;
}

const getDescription = (kind: QuestionKind, seller: Seller) => {
  const statistics = get(seller.questions, `${kind}`);

  switch (statistics.status) {
    case 'all_questions_answered':
      return t('description.all_questions_answered');
    case 'unanswered_questions':
      return t(`description.unanswered_questions.${kind}`, {
        count: statistics.unansweredCount,
      });
    case 'no_questions':
    default:
      return '';
  }
};

const QuestionsAction = ({ basePath, status, kind }: Props) => {
  const questionsPath = `${basePath}/questions/${kind}`;
  const seller = useCurrentSeller() as Seller;
  const Button = () => (
    <ActionButton to={questionsPath}>
      {t(status === 'Todo' ? 'button_answer' : 'button_view')}
    </ActionButton>
  );

  return (
    <Action
      button={Button}
      description={getDescription(kind, seller)}
      path={questionsPath}
      status={status}
      title={t(`title.${kind}`)}
    />
  );
};

export default QuestionsAction;
