import React from 'react';
import * as I18n from 'shared/utils/I18n';
import { SignStatus } from './util';

const ns = 'app.second_signer';

interface Props {
  coSignerEmail: string;
  signStatus: SignStatus;
}

const getMessage = ({ coSignerEmail, signStatus }: Props) => {
  switch (signStatus) {
    case 'needs_cosigner':
      return I18n.nt(ns, 'needs_cosigner', { email: coSignerEmail });
    case 'not_signed':
      return I18n.nt(ns, 'not_signed');
    case 'signed':
      return I18n.nt(ns, 'signed');
  }

  return '';
};

const DisplaySignStatus = ({ coSignerEmail, signStatus }: Props) => (
  <div>{getMessage({ coSignerEmail, signStatus })}</div>
);

export default DisplaySignStatus;
