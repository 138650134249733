import React, { useEffect, useState } from 'react';

import * as I18n from 'shared/utils/I18n';
import { Button, Modal } from 'shared/bootstrap';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import { IdentifyingPerson } from 'types';

const nt = (ns) => (key, options?) => I18n.nt(ns, key, options);
const t = nt('signup.onboarding.verify_identity.modal');

export type OnfidoStatus = 'loading' | 'ready' | 'complete';

interface Props {
  closeModal: () => void;
  status: string;
  identifyingPerson?: IdentifyingPerson;
  phone?: string;
  onComplete: (identifyingPerson: IdentifyingPerson) => void;
}

export default ({
  closeModal,
  identifyingPerson,
  status,
  phone,
  onComplete,
}: Props) => {
  const [initialized, setInitialized] = useState(false);
  const initializeOnfido = async () => {
    if (!identifyingPerson?.onfidoSdkToken) {
      return;
    }

    const { default: init } = await import('namespaces/Onfido');

    init({
      token: identifyingPerson.onfidoSdkToken,
      phoneNumber: phone,
      onComplete: () => onComplete(identifyingPerson),
    });
    setInitialized(true);
  };

  useEffect(() => {
    if (status === 'ready') {
      initializeOnfido();
    }
  }, [status]);

  return (
    <Modal className="VerifyIdentityModal" show onHide={closeModal}>
      <Modal.Header closeButton />
      <Modal.Body>
        {(status === 'loading' || !initialized) && <LoadingAnimation />}
        <div id="onfido-mount" />
      </Modal.Body>
      <Modal.Footer>
        {status === 'complete' && (
          <Button className="btn-primary" onClick={closeModal}>
            {t('submit')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
