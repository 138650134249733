import { useSelector } from 'react-redux';
import { customerAccountSelector } from 'shared/selectors';

/* Select the customer account.
- Assumes a customer account exists. Will throw an error otherwise.
*/
const useCustomerAccount = () => {
  const customerAccount = useSelector(customerAccountSelector);

  if (!customerAccount) {
    throw Error('useCustomerAccount: a customer account must exist');
  }

  return customerAccount;
};

export default useCustomerAccount;
