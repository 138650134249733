import React from 'react';

import * as I18n from 'shared/utils/I18n';

const ns = 'shared.dont_worry';

export default () => (
  <div className={`tw-mt-8 tw-flex tw-text-sm tw-text-floryn-green`}>
    <span className="icon icon-checkmark tw-mr-3 tw-text-[18px]" />
    {I18n.nt(ns, 'message')}
  </div>
);
