import React from 'react';
import PropTypes from 'prop-types';
import { toEuro } from 'shared/utils/Helpers';

const InvoiceCostLine = ({ amount, text }) => {
  return (
    <div>
      <span>{text}</span>
      <span className="amount">{toEuro(amount)}</span>
    </div>
  );
};

InvoiceCostLine.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
};

export default InvoiceCostLine;
