import React from 'react';
import { Alert, Well } from 'shared/bootstrap';
import { Route } from 'react-router-dom';

import LoadingAnimation from 'shared/components/LoadingAnimation';
import ConnectModal from 'shared/components/Consent/ConnectModal';
import ConsentsPageBanksList from './BanksList';
import Layout from './Layout';
import aisRedirector, {
  AisRedirectorProps,
} from 'shared/components/Consent/AisRedirector';

import * as I18n from 'shared/utils/I18n';
import useConsentsPage from './useConsentsPage';

const t = (key, options = {}) => I18n.nt('app.consentsPage', key, options);

const ConsentsPage = ({ onRefresh }: AisRedirectorProps) => {
  const {
    redirectPath,
    connectPath,
    connectRoute,
    mainSeller,
    closeModal,
    remoteConsents,
  } = useConsentsPage();

  const handleRefresh = (bank) => onRefresh(bank, redirectPath);

  let content: React.ReactNode;
  switch (remoteConsents.type) {
    case 'not_asked':
    case 'pending':
      content = <LoadingAnimation />;
      break;
    case 'failure':
      content = <Alert type="danger">{t('consentRequestFailed')}</Alert>;
      break;
    default:
      if (remoteConsents.response.consents.length === 0) {
        content = <Well>{t('noConsents')}</Well>;
      } else {
        content = (
          <ConsentsPageBanksList
            t={t}
            onRefresh={handleRefresh}
            consents={remoteConsents.response.consents}
          />
        );
      }
  }

  return (
    <Layout t={t} connectPath={connectPath} seller={mainSeller}>
      {content}
      <Route
        exact
        path={connectRoute}
        render={() => (
          <ConnectModal redirectPath={redirectPath} onClose={closeModal} />
        )}
      />
    </Layout>
  );
};

export default aisRedirector(ConsentsPage);
