import React from 'react';
import CharlieActions from './CharlieActions';
import DeltaActions from './DeltaActions';
import AlfaActions from './AlfaActions';
import LoanActions from './LoanActions';
import { useCurrentSeller } from 'shared/hooks';

const Actions = () => {
  const seller = useCurrentSeller();

  switch (seller.productVersion) {
    case 'alfa':
      return <AlfaActions />;
    case 'delta':
      return <DeltaActions />;
    default: {
      if (seller.hasLoan) {
        return <LoanActions />;
      } else {
        return <CharlieActions />;
      }
    }
  }
};

export default Actions;
