import React from 'react';
import { BankStatementUploadType } from 'types/BankStatementUploadType';
import ConnectWithBank from './ConnectWithBank';
import DocumentUpload from './DocumentUpload';
import * as I18n from 'shared/utils/I18n';
import * as Consent from 'shared/components/Consent';

const ns = 'app.profile.bankStatementsStep';

interface Props {
  bankStatementUploadType: BankStatementUploadType;
}

const BankStatementsStep = ({ bankStatementUploadType }: Props) => (
  <div className="BankStatementsStep lg:tw-pr-16">
    <Consent.Feedback
      t={(key: string): string => I18n.nt(`${ns}.consentFeedback`, key)}
    />
    <h1 className="tw-mt-8 tw-text-xl md:tw-mt-0 md:tw-text-bootstrap-h3">
      {I18n.nt(ns, 'heading')}
      <span className="icon icon-lock tw-ml-2 tw-text-floryn-green" />
    </h1>
    <p className="tw-mb-8">{I18n.nt(ns, 'intro.regular')}</p>
    {bankStatementUploadType === 'psd2' ? (
      <ConnectWithBank />
    ) : (
      <DocumentUpload />
    )}
  </div>
);

export default BankStatementsStep;
