/**
 * Decoding JSON values: take in any value, extract a particular shape of data
 * from it, without exceptions but with type safety.
 *
 * ## Example
 *
 * Consider the following input value:
 *
 *     [
 *       { id: 1, name: "John" },
 *       { id: 2, name: "Paul" }
 *     ]
 *
 * You could decode with a decoder like this:
 *
 *     array(
 *       mapN(
 *         (id, name) => ({ id, name }),
 *         number(),
 *         string()
 *       )
 *     );
 *
 * This will give you a `Result<{ id: number, name: string }>` value.  Any value
 * not conforming to the expected schema will generate an a failure value.
 *
 * ## Transforming values
 *
 * Simple values can be decoded easily but you can also transform values using
 * `map`:
 *
 *     decode(20, map(number(), n => n * 2))
 *     // { type: "success", value: 40 }
 *
 * Or you can switching decoding strategies based on the value itself:
 *
 *     let decoder =
 *       andThen(
 *         field("version", number()),
 *         (version) => {
 *           if(version === 1) {
 *             return field("name", string());
 *           } else if(version === 2) {
 *             return field("first_name", string());
 *           }
 *         }
 *       )
 *     decode( { version: 1, name: "John" }, decoder)
 *     // { type: "success", value: "John" }
 *     decode( { version: 2, first_name: "George" }, decoder)
 *     // { type: "success", value: "George" }
 *
 * ## Compared to schema validation
 *
 * One might also use something like JSON schema to declare the shape of data
 * and validate that a given value conforms to it. There are two main important
 * differences:
 *
 * 1. with schema validation, you take the entire input value, given that it
 *    passes the schema. You may take more than you want/need. With decoding,
 *    you pluck just the values you need out of a given value.
 * 2. with schema validation, you merely validate but not parse into domain
 *    types.  With a decoding approach you can parse scalars into domain types
 *    and have the decoding succeed or fail based on that operation. With
 *    validation, that remains a separate step.
 *
 * ## References
 *
 * The idea behind how these decoders work is based on the [Json.Decode][] package
 * for the [Elm][] programming language.
 *
 * [Elm]: https://elm-lang.org
 * [Json.Decode]: https://package.elm-lang.org/packages/elm/json/latest/Json-Decode
 */

// Result
// ======

/**
 * The result type indicates the outcome of a decoding operation: either it
 * successfully decoded some value, or it created an error message as a string.
 */
export type Result<T> =
  | { type: 'success'; value: T }
  | { type: 'failure'; msg: string };

export function isSuccess<T>(
  result: Result<T>
): result is { type: 'success'; value: T } {
  return result.type === 'success';
}

export function isFailure<T>(
  result: Result<T>
): result is { type: 'failure'; msg: string } {
  return !isSuccess(result);
}

export function success<T>(value: T): Result<T> {
  return { type: 'success', value };
}

export function failure<T>(msg: string): Result<T> {
  return { type: 'failure', msg };
}

/**
 * Return the success value from a result or use a default if the result is a
 * failure.
 *
 * ## Example
 *
 *     or(success(true), false) // true
 *     or(failure("some error"), false) // false
 */
export function or<T>(result: Result<T>, defaultValue: T): T {
  if (isSuccess(result)) {
    return result.value;
  } else {
    return defaultValue;
  }
}

/**
 * Unwrap a success value or throw an error using the failure message as error
 * message.
 *
 * ## Example
 *
 *     orThrow(success(true)) // true
 *     orThrow(failure("some error")) // new Error("some error")
 */
export function orThrow<T>(result: Result<T>): T {
  if (isSuccess(result)) {
    return result.value;
  } else {
    throw new Error(result.msg);
  }
}

/**
 * Apply a callback function `fun` to the error message in a given `Result`
 * value, if it is a failure. Success values are returned as-is.
 */
export function mapFailure<T>(
  result: Result<T>,
  fun: (msg: string) => string
): Result<T> {
  if (isFailure(result)) {
    return failure(fun(result.msg));
  } else {
    return result;
  }
}

// Decoding
// =================

export function decode<T>(value: any, decoder: Decoder<T>): Result<T> {
  return decoder(value);
}

/**
 * A decoder is a function that takes any value and produces a `Result` value of
 * some type.
 */
export type Decoder<T> = (value: any) => Result<T>;

// Simple decoders
// ----------------

/**
 * Create a decoder that always returns a fixed value.
 */
export function succeed<T>(value: T): Decoder<T> {
  return function () {
    return success(value);
  };
}

/**
 * Create a decoder that always fails with a fixed message.
 */
export function fail<T>(msg: string): Decoder<T> {
  return function () {
    return failure(msg);
  };
}

export function boolean(): Decoder<boolean> {
  return function (value) {
    if (value === true || value === false) {
      return success(value);
    } else {
      return failure(
        `Expected a boolean, got ${typeof value} ${JSON.stringify(value)}`
      );
    }
  };
}

export function string(): Decoder<string> {
  return function (value) {
    if (typeof value === 'string') {
      return success(value);
    } else {
      return failure(
        `Expected a string, got ${typeof value} ${JSON.stringify(value)}`
      );
    }
  };
}

export function number(): Decoder<number> {
  return function (value) {
    if (typeof value === 'number') {
      return success(value);
    } else {
      return failure(
        `Expected a number, got ${typeof value} ${JSON.stringify(value)}`
      );
    }
  };
}

export function nothing(): Decoder<null | undefined> {
  return function (value) {
    if (value === null || value === undefined) {
      return success(value);
    } else {
      return failure(
        `Expected null/undefined, got ${typeof value} ${JSON.stringify(value)}`
      );
    }
  };
}

// Composite value decoders
// ------------------------

/**
 * Decode a value from an object by its field name using a decoder.
 *
 * ## Example
 *
 *     decode({ id: 1 }, field("id", number()))
 *     // { type: "success", value: 1 }
 */
export function field<T>(name: string, decoder: Decoder<T>): Decoder<T> {
  return function (value) {
    if (value && Object.prototype.hasOwnProperty.call(value, name)) {
      return decoder(value[name]);
    } else {
      return failure(
        `Expected an object with field ${name}, got ${typeof value} ${JSON.stringify(
          value
        )}`
      );
    }
  };
}

/**
 * Decode an array and decode all its values with the given decoder. If any
 * element fails to decode, this returns an error.
 *
 * ## Example
 *
 *     decode([1, 2], array(number()))
 *     // { type: "success", value: [1, 2] }
 */
export function array<T>(decoder: Decoder<T>): Decoder<T[]> {
  return function (value) {
    if (Array.isArray(value)) {
      return value.reduce((acc: Result<T[]>, el: T, i: number) => {
        if (isSuccess(acc)) {
          const result = decoder(el);
          if (isSuccess(result)) {
            acc.value.push(result.value);
            return acc;
          } else {
            return mapFailure(result, (s) => `Error at array index ${i}: ${s}`);
          }
        } else {
          return acc;
        }
      }, success([]));
    } else {
      return failure(`Expected an array, got ${value}`);
    }
  };
}

/**
 * Decode a value as a dictionary with string keys and values of type `T`, using
 * a given `Decoder<T>`.
 *
 * ## Example
 *
 *     decode({ a: 1, b: 2 }, dict(number()))
 *     // { type: 'success', value: { a: 1, b: 2 } }
 *     decode({ a: '1', b: 2 }, dict(number()))
 *     // { type: 'failure', msg: '...' }
 */
export function dict<T>(decoder: Decoder<T>): Decoder<{ [k: string]: T }> {
  return function (value) {
    if (value && typeof value === 'object') {
      return Object.entries(value).reduce(
        (acc: Result<{ [k: string]: T }>, [key, objValue]) => {
          if (isSuccess(acc)) {
            const result = decoder(objValue);
            if (isSuccess(result)) {
              acc.value[key] = result.value;
              return acc;
            } else {
              return mapFailure(
                result,
                (msg) => `Error decoding dict key "${key}": ${msg}`
              );
            }
          } else {
            return acc;
          }
        },
        success({})
      );
    } else {
      return failure(`Expected an object, got ${typeof value}`);
    }
  };
}

// Flexible decoding
// -----------------

/**
 * Try a number of different decoders and use the first one that succeeds. This
 * returns a failure if none of them does.
 *
 * ## Examples
 *
 *     decode(123, oneOf([string(), number()]))
 *     // { type: "success", value: 123 }
 */
export function oneOf<T>(decoders: Decoder<T>[]): Decoder<T> {
  return function (value) {
    const result =
      decoders.map((d) => d(value)).find(isSuccess) ||
      failure('none of the decoders matched');
    return result;
  };
}

/**
 * Decode a value that might also be `null` or `undefined`.
 *
 * ## Example
 *
 *     decode({ id: 1 }, field("id", nullable(number())))
 *     // { type: "success", value: 1 }
 *     decode({ id: null }, field("id", nullable(number())))
 *     // { type: "success", value: null }
 *     decode({}, field("id", nullable(string())))
 *     // { type: "failure", value: "..." }
 */
export function nullable<T>(
  decoder: Decoder<T>
): Decoder<T | null | undefined> {
  return oneOf([decoder, nothing()]);
}

/**
 * Decode a value and fallback to `undefined` if it fails.
 *
 * ## Example
 *
 *     decode({ id: 1 }, field("id", optional(string())))
 *     // { type: "success", value: undefined }
 *     decode({ id: 1 }, optional(field("name", string())))
 *     // { type: "success", value: undefined }
 */
export function optional<T>(decoder: Decoder<T>): Decoder<T | undefined> {
  return oneOf([decoder, succeed(undefined)]);
}

// Transforming decoded values
// ---------------------------

/**
 * Apply a callback function `fun` on a decoded result. Failure values are
 * returned as-is.
 *
 * ## Example
 *
 *     decode([1, 2], array(map(number(), n => n * 2)))
 *     // { type: "success", value: [2, 4] }
 */
export function map<T, K>(decoder: Decoder<T>, fun: (val: T) => K): Decoder<K> {
  return function (value): Result<K> {
    const result = decoder(value);
    if (isSuccess(result)) {
      return success(fun(result.value));
    } else {
      return result;
    }
  };
}

/**
 * Take a decoded value and use it as input for another decoder.
 */
export function andThen<T, K>(
  decoder: Decoder<T>,
  fun: (val: T) => Decoder<K>
): Decoder<K> {
  return function (value): Result<K> {
    const result = decoder(value);
    if (isSuccess(result)) {
      return fun(result.value)(value);
    } else {
      return result;
    }
  };
}

// Decoding and then combinding multiple values
// --------------------------------------------
// These functions below might seem silly but are written with explicit
// arguments to maintain type checking.
//
// A different pattern for a variable number of arguments is possible using just
// a `map2` function, but that would requiring implementing some currying in
// order to maintain safe type checking. This is more but simpler code.

// Black magicks
type DecodedTypes<T> = T extends [Decoder<infer Head>]
  ? [Head]
  : T extends [Decoder<infer Head>, ...infer Tail]
    ? [Head, ...DecodedTypes<Tail>]
    : never;

function argsDecoder<T extends Decoder<unknown>[]>(
  decoders: T
): Decoder<DecodedTypes<T>> {
  return function (value) {
    const result = decoders.map((d) => d(value));
    // Array does not have a .partition function... :(
    const failure = result.find(isFailure);
    if (failure) {
      return failure;
    }
    return success(
      // ...we need to help the compiler a bit here, there is no way
      // result contains failures at this point in the code
      (result as { type: 'success'; value: unknown }[]).map(
        ({ value }) => value
      ) as DecodedTypes<T>
    );
  };
}

/**
 * Run multiple decoders and, if they all succeed, combine the results into a
 * single new output value using a `constructor` function.
 *
 * ## Example
 *
 *     decode(
 *       { x: 3, y: 7},
 *       mapN(
 *         (x, y) => ({ x, y }),
 *         field("x", number()),
 *         field("y", number())
 *       )
 *     )
 */
export function mapN<T extends Decoder<unknown>[], R>(
  constructor: (...args: DecodedTypes<T>) => R,
  ...decoders: T
): Decoder<R> {
  return map(argsDecoder(decoders), (args) => constructor(...args));
}
