import React from 'react';

import { AisRedirectorProps } from 'shared/components/Consent/AisRedirector';
import { Partner, Seller } from 'types';

import BankButton from './BankButton';
import OtherBankButton from './OtherBankButton';
import Psd2Announcement from './Psd2Announcement';
import usePsd2Banks from './usePsd2Banks';

export interface Props extends AisRedirectorProps {
  allowedPdfUpload: boolean;
  redirectPath: string;
  partner: Partner | null;
  seller: Seller;
  onSelectPdf: () => void;
}

const Psd2Banks = ({
  allowedPdfUpload,
  onSelectPdf,
  onRefresh,
  partner,
  redirectPath,
  seller,
}: Props) => {
  const { bank, chooseBank, redirectToBank, resetBank, state } = usePsd2Banks({
    onRefresh,
    redirectPath,
    seller,
  });

  return (
    <>
      <div className="Psd2Banks tw-flex tw-flex-col tw-gap-6 xs:tw-flex-row">
        {['abnamro', 'rabobank', 'ing'].map((bank) => (
          <BankButton
            key={bank}
            bank={bank}
            onSelect={() => chooseBank(bank)}
          />
        ))}

        <Psd2Announcement
          bank={bank}
          onHide={resetBank}
          onSubmit={redirectToBank}
          partner={partner}
          state={state}
        />
      </div>
      {allowedPdfUpload && (
        <OtherBankButton
          selectPdfUpload={onSelectPdf}
          enabled={state !== 'redirecting'}
        />
      )}
    </>
  );
};

export default Psd2Banks;
