import React from 'react';
import { nt } from 'shared/utils/I18n';
import { toEuro } from 'shared/utils/Helpers';
import MoreInfo from './MoreInfo';

interface Props {
  amount: string;
  message: string;
  settlementCalculated: boolean;
}

const ns = 'charlie.frontpage.account_summary';

const CurrentCommitmentPanel = ({
  amount,
  message,
  settlementCalculated,
}: Props) => (
  <div
    className="tw-flex tw-flex-col tw-items-end tw-gap-0 xs:tw-flex-row xs:tw-gap-1"
    id="current_commitment"
  >
    <span>
      {nt(ns, settlementCalculated ? 'settlement' : 'commitment')}
      {': '}
    </span>
    <span className="tw-flex tw-items-center tw-gap-1 tw-text-right">
      {toEuro(amount)} {message}
      <span className="tw-leading-none">
        <MoreInfo
          tooltip={nt(
            ns,
            settlementCalculated ? 'explain_settlement' : 'explain_commitment'
          )}
        />
      </span>
    </span>
  </div>
);

export default CurrentCommitmentPanel;
