import { dateFormat, timeFormat } from 'shared/utils/Helpers';
import { nt } from 'shared/utils/I18n';
import Question from './question';

export const ns = 'signup.onboarding.questions.page';

export const formatQuestionInfo = (question: Question) =>
  nt(ns, `question_info.${question.kind}`, {
    askedBy: question.askedBy,
    date: dateFormat(question.askedAt),
    time: timeFormat(question.askedAt, 'concise'),
  });

export const formatAnswerInfo = (question: Question) =>
  nt(ns, 'answer_info', {
    date: dateFormat(question.answeredAt),
    time: timeFormat(question.answeredAt, 'concise'),
  });
