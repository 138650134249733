import React from 'react';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { AlfaSeller } from 'types';
import { nt } from 'shared/utils/I18n';
import {
  parseFloatOrUndefined,
  toEuro,
  toPercentage,
} from 'shared/utils/Helpers';
import SecuritiesSummary from './SecuritiesSummary';

const ns = 'signup.onboarding.proposal_summary.alfa';

const AlfaSummary = () => {
  const seller = useCurrentSeller() as AlfaSeller;
  const creditLimit = parseFloatOrUndefined(seller.creditLimit);

  if (!creditLimit) {
    return null;
  }

  const advanceRate = parseFloatOrUndefined(seller.advanceRate);
  const fixedRate = parseFloatOrUndefined(seller.fixedRate);
  const variableRate = parseFloatOrUndefined(seller.variableRate);

  return (
    <>
      <div>
        {nt(ns, 'introduction_html', {
          limit: toEuro(creditLimit, 0),
        })}
      </div>
      <ul>
        <li>{nt(ns, 'limit_html', { amount: toEuro(creditLimit, 0) })}</li>
        {advanceRate && advanceRate > 0 && (
          <li>
            {nt(ns, 'advance_html', {
              percentage: toPercentage(advanceRate, 0),
            })}
          </li>
        )}
        {fixedRate && fixedRate > 0 && (
          <li>
            {nt(ns, 'fixed_rate_html', {
              percentage: toPercentage(fixedRate),
            })}
          </li>
        )}
        {variableRate && variableRate > 0 && (
          <li>
            {nt(ns, 'variable_rate_html', {
              percentage: toPercentage(variableRate),
            })}
          </li>
        )}
      </ul>
      <SecuritiesSummary />
    </>
  );
};

export default AlfaSummary;
