import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { actionCreators } from 'app/components/seller/Invoice/module';
import resolver from 'hookForm/resolver';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { validateAccepted } from 'shared/utils/validation';
import { isCancelled } from 'models/Seller';
import { ReduxState } from 'types';

interface FormValues {
  confirmation: boolean;
}

const useInvoiceSummary = () => {
  const dispatch = useDispatch();

  const validateForm = ({ confirmation }: FormValues) => ({
    confirmation: validateAccepted(confirmation),
  });

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: resolver(validateForm),
  });

  const { performingAction } = useSelector(
    (state: ReduxState) => state.app.invoice
  );

  const onMailInvoiceContact = (invoiceContact) => {
    dispatch(actionCreators.mailInvoiceContact(invoiceContact));
  };

  const submitInvoice = (invoiceId, params) =>
    handleSubmit(() => {
      onSubmitInvoice(invoiceId, params);
    })();

  const submitInvoiceWithoutValidation = (invoiceId, params) => {
    onSubmitInvoice(invoiceId, params);
  };

  const onSubmitInvoice = (invoiceId, params) => {
    dispatch(
      actionCreators.submitInvoice(invoiceId, {
        ...params,
      })
    ).then(window.scrollTo(0, 0));
  };

  const attachmentsMap = useSelector(
    (state: ReduxState) => state.entities.attachments
  );

  const companiesMap = useSelector(
    (state: ReduxState) => state.entities.companies
  );

  const seller = useCurrentSeller();

  const { mailContact, showAlert } = useSelector(
    (state: ReduxState) => state.app.invoice
  );

  const onShowForm = () => dispatch(actionCreators.showForm());

  const readonly = isCancelled(seller);

  return {
    attachmentsMap,
    companiesMap,
    control,
    mailContact,
    onMailInvoiceContact,
    performingAction,
    readonly,
    seller,
    showAlert,
    showForm: onShowForm,
    submitInvoice,
    submitInvoiceWithoutValidation,
  };
};

export default useInvoiceSummary;
