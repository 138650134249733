import React from 'react';
import PropTypes from 'prop-types';

export default class Attachments extends React.Component {
  renderAttachment(attachment) {
    return (
      <li key={attachment.id}>
        <a
          href={`/api/attachments/${attachment.id}/download`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {attachment.fileName}
        </a>
      </li>
    );
  }

  render() {
    const { attachmentsMap, attachmentIds } = this.props;

    return (
      <ul>
        {attachmentIds
          .map((id) => attachmentsMap[id])
          .map(this.renderAttachment)}
      </ul>
    );
  }
}
Attachments.propTypes = {
  attachmentIds: PropTypes.array.isRequired,
  attachmentsMap: PropTypes.object.isRequired,
};
