import React from 'react';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import * as I18n from 'shared/utils/I18n';
import { LoginStatus } from './util';

const ns = 'app.second_signer';

interface Props {
  loginStatus: LoginStatus;
}

const DisplayLoginStatus = ({ loginStatus }: Props) => {
  switch (loginStatus) {
    case 'error':
      return <div className="text-danger">{I18n.nt(ns, 'error')}</div>;
    case 'expired_token':
      return <div className="text-danger">{I18n.nt(ns, 'expired_token')}</div>;
    case 'loading':
      return <LoadingAnimation />;
    default:
      return null;
  }
};

export default DisplayLoginStatus;
