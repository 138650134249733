import React from 'react';
import AccountSummary from 'charlie/components/AccountSummary';
import Actions from 'charlie/components/Actions';
import Mutations from 'charlie/components/Mutations';
import ReactivationModal from 'charlie/components/ReactivationModal';
import DashboardAlerts from 'charlie/components/DashboardAlerts';
import RefreshModal from 'shared/components/Consent/RefreshModal';
import LoadingAnimation from 'shared/components/LoadingAnimation';

import { isAisConnected } from 'models/Seller';
import useOverviewPage from './useOverviewPage';

const OverviewPage = () => {
  const {
    isLoaded,
    onHideModal,
    onShowModal,
    seller,
    showReactivationPopup,
    showRefreshPopup,
  } = useOverviewPage();

  if (!isLoaded) {
    return <LoadingAnimation />;
  }

  return (
    <main className="c-overviewpage">
      <AccountSummary />
      <div className="overviewpage-container">
        {showReactivationPopup && (
          <ReactivationModal
            onHideModal={onHideModal}
            onShowModal={onShowModal}
          />
        )}
        {isAisConnected(seller) && (
          <RefreshModal showPopup={showRefreshPopup} seller={seller} />
        )}
        <DashboardAlerts />
        <Actions />
        <Mutations />
      </div>
    </main>
  );
};

export default OverviewPage;
