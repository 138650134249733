import tlds from 'tlds';

import * as I18n from 'shared/utils/I18n';
import { toEuro } from 'shared/utils/Helpers';
import { isValidDutchIban } from 'models/Iban';
import { isPast, isValid, parse } from 'date-fns';

const t = (scope, ...args) => I18n.t(`errors.messages.${scope}`, ...args);

export const minLength = (min) => (value) => {
  if (value && value.length < min) {
    return t('minimum_length', { min });
  }

  return null;
};

const isEmpty = (value) => !(value || typeof value === 'number');

export const validateNumericality = (value) =>
  isFinite(value) ? null : t('not_a_number');

export const validateAmountGreaterThan = (minValue) => (value) =>
  value > minValue ? null : t('greater_than', { count: toEuro(minValue, 0) });

export const validateGreaterThanOrEqualTo = (minValue) => (value) =>
  value >= minValue ? null : t('greater_than_or_equal_to', { count: minValue });

export const validateAmountLessThan = (maxValue) => (value) =>
  value < maxValue ? null : t('less_than', { count: toEuro(maxValue, 0) });

export const validateLessThanOrEqualTo = (maxValue) => (value) =>
  value <= maxValue ? null : t('less_than_or_equal_to', { count: maxValue });

export const validatePresence = (value) => {
  const message = t('blank');

  if (Array.isArray(value)) {
    return value.length === 0 ? message : null;
  }

  return isEmpty(value) ? message : null;
};

export const validateIban = (value) =>
  isValidDutchIban(value) ? null : t('invalid_iban');

export const validateAll =
  (...validators) =>
  (value) => {
    for (const validator of validators) {
      const validationMessage = validator(value);

      if (validationMessage) {
        return validationMessage;
      }
    }

    return null;
  };

const emailRegex = /^\s*([^@\s]{1,64})@((?:[-a-z0-9]+\.)+[a-z]{2,})\s*$/i;

export const validateEmail = (value) => {
  if (emailRegex.test(value)) {
    const tld = value.split('.').pop();

    if (tlds.includes(tld.toLowerCase().trim())) {
      return null;
    }
  }
  return t('invalid_email');
};

const twoFactorTokenRegex = /^[0-9]{6}$/;

export const validateTwoFactorToken = (value) => {
  if (twoFactorTokenRegex.test(value)) {
    return null;
  }

  return t('invalid_two_factor_token');
};

const phoneRegex = /(^\+31|^\+31\(0\)|^\(\+31\)\(0\)|^0031|^0)([0-9]{9}$)/;

export const validatePhone = (value) => {
  if (value) {
    const filteredNumber = value.replace(/[- ]/g, '');

    if (phoneRegex.test(filteredNumber)) {
      return null;
    }
  }

  return t('invalid_phone');
};

export const email = validateEmail;

export const validateConfirmation = (value, valueConfirmation) => {
  if (!isEmpty(valueConfirmation) && valueConfirmation !== value) {
    return t('confirmation');
  }
  return null;
};

export const validateAccepted = (value) =>
  value === true ? null : t('accepted');

export const passwordConfirmation = ({ password, passwordConfirmation }) => ({
  passwordConfirmation: validateConfirmation(password, passwordConfirmation),
});

export const validateInclusion =
  (...allowedValues) =>
  (value) =>
    allowedValues.includes(value) ? null : t('inclusion');

export const validateAmountGreaterThanOrEqualToGAmount = (amount, gAmount) =>
  amount >= gAmount ? null : t('g_amount_exceeds_amount');

export const validateDate = (value, format = 'dd-MM-yyyy') => {
  if (!value) return t('invalid_date');

  const date = parse(value, format, new Date());
  if (!isValid(date)) {
    return t('invalid_date');
  }

  return null;
};

export const validateDateIsInPast = (value, format = 'dd-MM-yyyy') => {
  const date = parse(value, format, new Date());
  if (!isPast(date)) {
    return t('date_not_in_past');
  }

  return null;
};

export const validatePaymentReference = (value) => {
  if (value.length < 7) {
    return t('payment_reference_too_short');
  } else if (value.length > 16) {
    return t('payment_reference_too_long');
  }

  return null;
};

export const validatePassword = validateAll(minLength(8), validatePresence);
