import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { validatePresence, validatePhone } from 'shared/utils/validation';
import resolver from 'hookForm/resolver';
import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import useUser from 'shared/hooks/useUser';
import { companySelector } from 'shared/selectors';
import { updateSeller } from 'signup/actions/SellerSignup';
import { Company, Seller } from 'types';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import {
  validateAmount,
  validateExpectedRevenueAmount,
} from 'signup/shared/validations';

interface FormData {
  firstName: string;
  lastName: string;
  phone: string;
  expectedFinancingAmount: string | null;
  expectedRevenue: string | null;
}

const validate = (values: FormData) => ({
  firstName: validatePresence(values.firstName),
  lastName: validatePresence(values.lastName),
  phone: validatePhone(values.phone),
  expectedFinancingAmount: validateAmount(values.expectedFinancingAmount),
  expectedRevenue: validateExpectedRevenueAmount(values.expectedRevenue),
});

const useAccountStep = () => {
  const dispatch = useAppDispatch();
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);

  const seller = useCurrentSeller() as Seller;
  const { phone, expectedRevenue, expectedFinancingAmount } = seller;
  const { firstName, lastName } = useUser();
  const defaultValues = {
    firstName,
    lastName,
    phone,
    expectedRevenue,
    expectedFinancingAmount,
  };

  const company = useSelector(companySelector) as Company;

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setError,
  } = useForm<FormData>({ defaultValues, resolver: resolver(validate) });

  const submit = async (values: FormData) => {
    const action = await dispatch(updateSeller(values));

    if (action.error) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        action,
        setError
      );
      setServerErrorMessages(generalErrorMessages);
    }
  };

  return {
    company,
    control,
    isSubmitting,
    serverErrorMessages,
    submit: handleSubmit(submit),
  };
};

export default useAccountStep;
