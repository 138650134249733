import { useForm } from 'react-hook-form';
import { updateSeller } from 'shared/actions/seller';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { validateEmail } from 'shared/utils/validation';
import resolver from 'hookForm/resolver';
import useMainUser from 'shared/hooks/useMainUser';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import { Seller } from 'types';
import { trackFormSubmit } from 'shared/utils/tracker';

interface FormData {
  florynInvoiceCcEmail: string;
}

const validate = ({ florynInvoiceCcEmail }: FormData) => ({
  florynInvoiceCcEmail:
    florynInvoiceCcEmail === '' ? null : validateEmail(florynInvoiceCcEmail),
});

const useChangeFlorynInvoiceCcEmail = () => {
  const dispatch = useAppDispatch();

  const submit = async (data: FormData) => {
    await dispatch(updateSeller(data));
    trackFormSubmit('floryn-invoice-cc-form');
  };

  const seller = useCurrentSeller() as Seller;
  const mainUser = useMainUser();

  const {
    control,
    formState: { isSubmitting, isSubmitSuccessful, isValid },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      florynInvoiceCcEmail: seller.florynInvoiceCcEmail || '',
    },
    resolver: resolver(validate),
  });

  return {
    control,
    emailOfMainUser: mainUser.email,
    isSubmitting,
    isSubmitSuccessful,
    isValid,
    submit: handleSubmit(submit),
  };
};

export default useChangeFlorynInvoiceCcEmail;
