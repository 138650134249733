import React from 'react';
import { DropdownButton } from 'shared/bootstrap';
import { translate } from 'shared/utils/I18n';
import { dateFormat, formatIban } from 'shared/utils/Helpers';
import classNames from 'classnames';

const t = translate('insights.accounts_dropdown');

function Account({ account, selected, disabled }) {
  const { name, iban, beginDate, endDate } = account;

  return (
    <div
      className={classNames('ti-account', { 'ti-account--disabled': disabled })}
      data-testid={`account-${iban}`}
    >
      <div>
        {selected && !disabled && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            role="checkmark"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        )}
      </div>
      <div>
        <div className="ti-account__iban">{formatIban(iban)}</div>
        <div>{name}</div>
        <div>
          {!disabled &&
            beginDate &&
            endDate &&
            t('date_range', {
              from: dateFormat(beginDate, 'concise'),
              to: dateFormat(endDate, 'concise'),
            })}
        </div>
        {disabled && <div>{t('no_transactions')}</div>}
      </div>
    </div>
  );
}

function AccountsDropdown({
  accounts,
  selectedAccounts,
  onToggle,
  isDisabled,
}) {
  const renderAccount = (account) => (
    <Account
      account={account}
      selected={selectedAccounts.includes(account)}
      disabled={isDisabled(account)}
    />
  );

  return (
    <DropdownButton
      id="accounts"
      datatestid="accounts-dropdown"
      title={
        selectedAccounts.length === accounts.length
          ? t('all_accounts')
          : t('nr_accounts', { count: selectedAccounts.length })
      }
      renderItem={renderAccount}
      onSelect={onToggle}
      items={accounts.map((account) => ({
        eventKey: account,
        disabled: isDisabled(account),
      }))}
    />
  );
}

export default AccountsDropdown;
