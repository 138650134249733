import React from 'react';
import { nt } from 'shared/utils/I18n';
import BaseCurrencyInput from 'hookForm/elements/BaseCurrencyInput';
import useWithdrawalCalculator from './useWithdrawalCalculator';
import Calculator from './Calculator';

const ns = 'signup.onboarding.withdrawal_calculator';

const WithdrawalCalculator = () => {
  const { amount, changeInputValue, error, seller, withdrawalInfo } =
    useWithdrawalCalculator();

  return (
    <div className="tw-flex tw-flex-col tw-gap-5">
      <h4>{nt(ns, 'title')}</h4>
      <div>{nt(ns, 'intro')}</div>
      <div className="tw-flex tw-flex-col tw-gap-2">
        <label htmlFor="amount" className="tw-mb-0">
          {nt(ns, `input_label`)}
        </label>
        <div className="tw-w-1/2 sm:tw-w-1/3">
          <BaseCurrencyInput
            className="form-control"
            id="amount"
            name="amount"
            onChange={changeInputValue}
            value={amount}
          />
        </div>
        {error && <div className="tw-text-red-dark">{error}</div>}
        <div className="tw-text-gray-600">{nt(ns, 'input_hint')}</div>
      </div>
      <Calculator seller={seller} withdrawalInfo={withdrawalInfo} />
    </div>
  );
};

export default WithdrawalCalculator;
