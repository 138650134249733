import React from 'react';

import { Alert } from 'shared/bootstrap';
import Button from 'shared/components/tailwind/Button';
import { dateFormat } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import useFinanceAllInvoices from './useFinanceAllInvoices';

const ns = 'app.profilePage.financeAllInvoices';

const FinanceAllInvoices = () => {
  const {
    financeAllInvoices,
    isSubmitSuccessful,
    isSubmitting,
    submitDisable,
    submitEnable,
  } = useFinanceAllInvoices();

  const FinanceAllInvoicesDisabled = () => (
    <form onSubmit={submitEnable}>
      {isSubmitSuccessful && (
        <div className="tw-mb-2">
          <Alert type="success">{I18n.nt(ns, 'disabled.submitted')}</Alert>
        </div>
      )}
      <p>{I18n.nt(ns, 'disabled.description')}</p>
      <Button type="submit" className="btn-primary" disabled={isSubmitting}>
        {I18n.nt(ns, 'disabled.button')}
      </Button>
    </form>
  );

  const FinanceAllInvoicesEnabled = () => (
    <form onSubmit={submitDisable}>
      {isSubmitSuccessful && (
        <div className="tw-mb-2">
          <Alert type="success">{I18n.nt(ns, 'enabled.submitted')}</Alert>
        </div>
      )}
      <p>
        {I18n.nt(ns, 'enabled.description_html', {
          date: dateFormat(financeAllInvoices),
        })}
      </p>
      <Button type="submit" className="btn-primary" disabled={isSubmitting}>
        {I18n.nt(ns, 'enabled.button')}
      </Button>
    </form>
  );

  return (
    <>
      <h4>{I18n.nt(ns, 'title')}</h4>
      {financeAllInvoices ? (
        <FinanceAllInvoicesEnabled />
      ) : (
        <FinanceAllInvoicesDisabled />
      )}
    </>
  );
};

export default FinanceAllInvoices;
