import { CustomerAccount } from 'types';

export const isSignupCompleted = (
  customerAccount: CustomerAccount
): boolean => {
  return (
    customerAccount.hasCompletedAdditionalQuestions &&
    customerAccount.hasCompletedUboIdentification
  );
};
