import React from 'react';
import { toPercentage } from 'shared/utils/Helpers';
import { trackEvent } from 'shared/utils/tracker';
import { nt } from 'shared/utils/I18n';
import MoreInfo from './MoreInfo';
import InterestSummaryTooltip from './InterestSummaryTooltip';

interface Props {
  baseInterestRate: string;
  euriborRate: string;
  interestRate: string;
  latestInterestChange?: {
    date: string;
    rateChange: string;
  };
}

const ns = 'charlie.frontpage.account_summary.interest_summary';

const InterestSummary = ({
  baseInterestRate,
  euriborRate,
  interestRate,
  latestInterestChange,
}: Props) => {
  const trackTooltip = () => {
    trackEvent('Display interest tooltip');
  };

  return (
    <div className="tw-flex tw-flex-col tw-items-end tw-gap-0 xs:tw-flex-row xs:tw-gap-1">
      <span>{nt(ns, 'interest')}:</span>
      <span className="tw-flex tw-items-center tw-gap-1 tw-text-right">
        {nt(ns, 'per_month', { rate: toPercentage(interestRate) })}
        <span className="tw-leading-none">
          <MoreInfo
            onDisplayTooltip={trackTooltip}
            tooltip={
              <InterestSummaryTooltip
                baseInterestRate={baseInterestRate}
                euriborRate={euriborRate}
                latestInterestChange={latestInterestChange}
              />
            }
          />
        </span>
      </span>
    </div>
  );
};

export default InterestSummary;
