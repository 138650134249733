import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { compilePath } from 'shared/utils/routing';
import * as routes from 'app/routes';
import useEnsureFullSeller from 'shared/hooks/useEnsureFullSeller';
import useHasSeenPopupToday, {
  touchLocalStorage,
} from 'shared/hooks/useHasSeenPopupToday';
import { getQueryParam } from 'shared/utils/Helpers';

import {
  loadInitialInvoices,
  loadDashboard,
  requestFinancing,
  selectFinancingFilter,
  selectStatusFilter,
} from 'app/modules/invoices';
import { ReduxState } from 'types';
import { isCancelled } from 'models/Seller';
import useIsFrontpage from 'shared/hooks/useIsFrontpage';

const useInvoices = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    dashboard,
    initialInvoicesLoaded,
    invoices,
    invoicesLoading,
    invoiceCounts,
    invoicesTotalAmount,
    selectedStatusFilter,
    selectedFinancingFilter,
  } = useSelector((state: ReduxState) => state.app.invoices);
  const { seller } = useEnsureFullSeller();
  const isFrontpage = useIsFrontpage(seller);

  const canUploadInvoice = !isCancelled(seller);

  const [popupsEnabled, setPopupsEnabled] = useState(true);
  const hasSeenPopupToday = useHasSeenPopupToday();
  const hasAisFeedback = !!getQueryParam(location.search, 'status');

  useEffect(() => {
    if (!seller) return;

    if (isFrontpage) {
      touchLocalStorage('lastSeenPopup');
      setPopupsEnabled(true);
    } else {
      setPopupsEnabled(false);
    }
  }, [seller]);

  const showRefreshPopup =
    popupsEnabled && isFrontpage && (!hasSeenPopupToday || hasAisFeedback);

  const gotoInvoice = (invoiceId) => {
    history.push(compilePath(routes.INVOICE, { id: invoiceId }));
  };

  const uploadInvoice = () => {
    history.push(compilePath(routes.INVOICE, { id: 'new' }));
  };

  const onRequestFinancing = (invoiceId) => {
    dispatch(requestFinancing(invoiceId));
  };

  useEffect(() => {
    dispatch(loadDashboard());
    dispatch(loadInitialInvoices());
  }, []);

  return {
    canUploadInvoice,
    dashboard,
    gotoInvoice,
    initialInvoicesLoaded,
    invoiceCounts,
    invoices,
    invoicesLoading,
    invoicesTotalAmount,
    onRequestFinancing,
    selectedStatusFilter,
    selectedFinancingFilter,
    selectFinancingFilter,
    selectStatusFilter,
    seller,
    showRefreshPopup,
    uploadInvoice,
  };
};

export default useInvoices;
