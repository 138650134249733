import React, { useMemo } from 'react';
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { nt } from 'shared/utils/I18n';
import { toEuro } from 'shared/utils/Helpers';
import { Payment } from './calculateWithdrawalInfo';

const ns = 'signup.onboarding.current_debt_chart';

interface ChartRow {
  currentDebt: number;
  week: number;
}

const generateChartData = (payments: Payment[]): ChartRow[] =>
  payments.map((payment, index) => ({
    currentDebt: payment.currentDebt,
    week: index + 1,
  }));

interface Props {
  payments: Payment[];
}

const CurrentDebtChart = ({ payments }: Props) => {
  const data = useMemo(() => generateChartData(payments), [payments]);

  return (
    <div className="tw-f-full tw-h-[300px]">
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{ top: 0, left: 0, bottom: 0, right: 10 }}
        >
          <XAxis
            dataKey="week"
            height={40}
            label={nt(ns, 'week')}
            tickFormatter={(value, index) =>
              index === data.length - 1 ? `${value}` : ''
            }
          />
          <YAxis width={60} tickFormatter={(value) => toEuro(value, 0)} />
          <Legend
            height={40}
            verticalAlign="top"
            formatter={() => nt(ns, 'current_debt')}
          />
          <Tooltip
            labelFormatter={(index) => `${nt(ns, 'week')} ${index}`}
            formatter={(value: number) => [
              toEuro(value),
              nt(ns, 'current_debt'),
            ]}
          />
          <Line type="monotone" dataKey="currentDebt" stroke="#00ABE9" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CurrentDebtChart;
