import React from 'react';
import classNames from 'classnames';
import { Account } from 'types';
import { t, almostExpiring, describeTimeUntilDate, isExpired } from './helpers';

interface Props {
  account: Account;
}

const expirationText = (consentExpiresAt: Date) => {
  const expired = isExpired(consentExpiresAt);
  const [date, distance] = describeTimeUntilDate(consentExpiresAt);

  if (expired) {
    return t('isExpired');
  } else if (distance) {
    return t('expiresAtRelative', { date: date, distance: distance });
  }
  return t('expiresAt', { date: date });
};

const AccountLine = ({ account }: Props) => {
  let isAlmostExpiring: boolean;

  switch (account.type) {
    case 'active':
      isAlmostExpiring = almostExpiring(account.expiresAt);

      return (
        <div>
          <h5 style={{ marginBottom: 0 }}>{account.iban}</h5>
          <p
            className={classNames({
              'text-muted': !isAlmostExpiring,
              'text-danger': isAlmostExpiring,
            })}
          >
            {expirationText(account.expiresAt)}
          </p>
        </div>
      );
    default:
      return (
        <div>
          <h5 style={{ marginBottom: 0 }}>{account.iban}</h5>
          <p className="text-danger">{t('isExpired')}</p>
        </div>
      );
  }
};

export default AccountLine;
