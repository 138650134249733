import React from 'react';
import PropTypes from 'prop-types';
import * as I18n from 'shared/utils/I18n';

export default class InformationField extends React.Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.string,
    model: PropTypes.string.isRequired,
  };

  static defaultProps = {
    value: '',
  };

  render() {
    const { model, field, value } = this.props;

    return (
      <div className={`InformationField ${field.replace('.', '_')}`}>
        <strong>{I18n.label(model, field)}</strong>
        <div className="information-field-text">{value}</div>
      </div>
    );
  }
}
