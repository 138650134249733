import React from 'react';
import { Alert } from 'shared/bootstrap';
import { isCancelled } from 'models/Seller';
import { nt } from 'shared/utils/I18n';
import { useCurrentSeller } from 'shared/hooks';

const ns = 'charlie.frontpage';

const CancelledAlert = () => {
  const currentSeller = useCurrentSeller();

  if (!isCancelled(currentSeller)) {
    return null;
  }

  return (
    <Alert type="success">
      <div className="content tw-flex tw-flex-row tw-gap-2">
        <div className="tw-grow">
          {nt(
            ns,
            'cancelled_alert_html',
            {},
            {
              phone:
                '<strong class="tw-text-black tw-font-bold">073 808 0221</strong>',
              mail: '<a href="mailto:klanten@floryn.com" class="tw-text-black tw-font-bold tw-underline">klanten@floryn.com</a>',
            }
          )}
        </div>
        <div className="tw-hidden sm:tw-flex">
          <img
            src="images/staff_relationship_management.png"
            className="tw-max-h-[100%] tw-max-w-[246px] tw-object-contain"
          />
        </div>
      </div>
    </Alert>
  );
};

export default CancelledAlert;
