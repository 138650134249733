import { CandidateCompany, Company } from 'types';

export const hasAddress = (company: CandidateCompany | Company) =>
  Boolean(company.street) ||
  Boolean(company.houseNumber) ||
  Boolean(company.houseNumberSuffix) ||
  Boolean(company.postalCode) ||
  Boolean(company.city);

export const formatHouseNumber = (company: CandidateCompany | Company) =>
  company.houseNumberSuffix
    ? `${company.houseNumber}-${company.houseNumberSuffix}`
    : company.houseNumber;
