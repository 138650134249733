/* Redux action to update entities manually.

* Entities are updated automatically when using redux. If you're not using redux, then use this function to update entities.
* Payload: response from the server containing entities.
*/
const updateEntities = (payload: any) => ({
  type: 'UPDATE_ENTITIES',
  normalize: true,
  payload,
});

export default updateEntities;
