import { get, intersection } from 'lodash';

export default (entitiesKey, state, action) => {
  const entities = get(action, `payload.entities.${entitiesKey}`);
  if (!entities) return state;

  // If we find entities that we already have, the properties are merged
  // This is needed in bankTransaction because we get MinimalBankTransactions with not enough data
  // that override full BankTransaction objects and loses data in the process
  // another discussion is what should happen with entityKind in this case,
  // as the object is now both Minimal and Full

  const overwrittenEntities = intersection(
    Object.keys(entities),
    Object.keys(state)
  );

  if (overwrittenEntities.length) {
    overwrittenEntities.forEach((id) => {
      if (
        entities[id].entityKind &&
        entities[id].entityKind.startsWith('Minimal')
      ) {
        // Note: this mutates entities[id]
        // if we are merging, don't change the entity type to minimal, because it still contains the full dataset

        entities[id] = {
          ...state[id],
          ...entities[id],
          entityKind: state[id].entityKind,
        };
      }
    });
  }

  return {
    ...state,
    ...entities,
  };
};
