import React from 'react';
import { concat, groupBy } from 'lodash';

import { OnboardingActionStatus } from 'types';

interface ActionStatus {
  status: OnboardingActionStatus;
}

interface ActionProps {
  props: ActionStatus;
}

const Actions = ({ children }) => {
  const array = React.Children.toArray(children);
  const grouped = groupBy(array, (child: ActionProps) => child.props.status);
  const ordered = concat(grouped.Todo, grouped.Pending, grouped.Done);

  return (
    <div className="SellerActions">
      <>{ordered}</>
    </div>
  );
};

export default Actions;
