import React from 'react';
import { nt } from 'shared/utils/I18n';
import useRestrictionInfo from './useRestrictionInfo';
import RestrictionChart from './RestrictionChart';
import { dateFormat, toEuro } from 'namespaces/shared/utils/Helpers';

const ns = 'signup.onboarding.restriction';

const RestrictionInfo = () => {
  const restrictionInfo = useRestrictionInfo();

  if (
    !restrictionInfo?.restriction ||
    restrictionInfo.restrictionType === 'floating'
  ) {
    return null;
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-5">
      <h4>{nt(ns, 'title')}</h4>
      <div>{nt(ns, 'intro')}</div>
      <div>
        {nt(ns, 'scheduled_restriction', {
          credit_limit: toEuro(restrictionInfo.creditLimit),
          step_amount: toEuro(restrictionInfo.restrictionStepAmount),
          target_amount: toEuro(restrictionInfo.restrictionTarget),
          start_date: dateFormat(restrictionInfo.restrictionStartDate),
          target_date: dateFormat(restrictionInfo.restrictionTargetEndDate),
          frequency: nt(
            ns,
            `frequency_${restrictionInfo.restrictionFrequency}`
          ),
        })}
      </div>
      <RestrictionChart restrictionInfo={restrictionInfo} />
    </div>
  );
};

export default RestrictionInfo;
