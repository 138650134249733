import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { Restriction } from './Restriction';
import { Seller } from 'types';
import { toFloat } from 'shared/utils/Helpers';

const useRestrictionInfo = () => {
  const seller = useCurrentSeller() as Seller;
  const {
    creditLimit,
    restriction,
    restrictionFrequency,
    restrictionStartDate,
    restrictionStepAmount,
    restrictionTarget,
    restrictionTargetEndDate,
    restrictionType,
  } = seller.creditDecision;
  const restrictionInfo: Restriction = {
    creditLimit: toFloat(creditLimit),
    restriction,
    restrictionFrequency,
    restrictionStartDate,
    restrictionStepAmount: toFloat(restrictionStepAmount),
    restrictionTarget: toFloat(restrictionTarget),
    restrictionTargetEndDate,
    restrictionType,
  };

  return restrictionInfo;
};

export default useRestrictionInfo;
