import { useState } from 'react';
import client from 'shared/utils/client';

type Status = '' | 'error' | 'loading' | 'loaded';

const useNotConnected = () => {
  const [status, setStatus] = useState<Status>('');

  const redirectToAuthorisationPage = async () => {
    setStatus('loading');

    const response = await client(
      'GET',
      '/api/exact_online/authorisation_url',
      {},
      { raiseError: false }
    );

    if (response.error) {
      setStatus('error');
      return;
    }

    const url = response.payload?.url;
    window.open(url, '_self');
  };

  return { redirectToAuthorisationPage, status };
};

export default useNotConnected;
