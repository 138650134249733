import client from 'shared/utils/client';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FlorynPayPayment } from '../PayModal/types';
import * as I18n from 'shared/utils/I18n';
import { trackFormSubmit } from 'shared/utils/tracker';

interface RouteParams {
  id: string;
}

type Step = 'form' | 'processing';

const useFlorynPayFailedConsent = (parentPath: string) => {
  const history = useHistory();
  const { id } = useParams<RouteParams>();
  const [payment, setPayment] = useState<FlorynPayPayment>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);
  const [step, setStep] = useState<Step>('form');

  const close = () => history.push(parentPath);

  const performAction = async (action: 'restart' | 'revoke') => {
    setIsSubmitting(true);
    const response = await client(
      'POST',
      `/api/floryn_pay_payments/${action}`,
      { id: id },
      { raiseError: false }
    );

    if (response.error) {
      setServerError(I18n.nt('common', 'server_error'));
    } else {
      trackFormSubmit(`floryn-pay-failed-consent-${action}`);
      setStep('processing');
    }
    setIsSubmitting(false);
  };

  const restart = async () => {
    await performAction('restart');
  };

  const revoke = async () => {
    await performAction('revoke');
  };

  const loadPayment = async () => {
    const response = await client(
      'GET',
      `/api/floryn_pay_payments/${id}`,
      {},
      { raiseError: false }
    );

    if (response.error) {
      setServerError(I18n.nt('common', 'server_error'));
      return;
    }

    setPayment(response.payload.florynPayPayment as FlorynPayPayment);
  };

  useEffect(() => {
    if (id) {
      loadPayment();
    }
  }, []);

  return {
    close,
    buttonsDisabled:
      isSubmitting ||
      serverError !== null ||
      !payment ||
      payment.status !== 'consent_failed',
    payment,
    restart,
    revoke,
    serverError,
    step,
  };
};

export default useFlorynPayFailedConsent;
