import { combineReducers } from 'redux';
import { reducer as review } from 'shared/modules/review';
import dashboard from './modules/dashboardSlice';
import reviewWizard from 'shared/modules/reviewWizardSlice';

const charlie = combineReducers({
  dashboard,
  review,
  reviewWizard,
});

export default charlie;
