import React from 'react';
import { Alert, Button, Col } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import TextInput from 'hookForm/TextInput';
import useChangeFlorynInvoiceCcEmail from './useChangeFlorynInvoiceCcEmail';

const ns = 'app.profilePage.changeFlorynInvoiceCcEmail';

const ChangeFlorynInvoiceCcEmail = () => {
  const {
    control,
    emailOfMainUser,
    isSubmitting,
    isSubmitSuccessful,
    isValid,
    submit,
  } = useChangeFlorynInvoiceCcEmail();

  return (
    <Col lg={6} id="cc-email">
      <form autoComplete="off">
        <h4>{I18n.nt(ns, 'title')}</h4>
        <p>{I18n.nt(ns, 'intro', { email: emailOfMainUser })}</p>
        <TextInput
          control={control}
          label={I18n.nt(ns, 'label')}
          name="florynInvoiceCcEmail"
          type="email"
        />
        <Button
          type="submit"
          className="btn-primary"
          disabled={isSubmitting || !isValid}
          onClick={submit}
        >
          {I18n.nt(ns, 'submit')}
        </Button>
        {isSubmitSuccessful && (
          <Alert type="success">{I18n.nt(ns, 'submitted')}</Alert>
        )}
      </form>
    </Col>
  );
};

export default ChangeFlorynInvoiceCcEmail;
