import { translate } from 'shared/utils/I18n';
import { Buckets, Periods } from './TransactionInsights';

const t = translate('insights.page');

/*
 * The colour palette used in the graphs. Since we will show at most 6 different
 * entries in a single graph (a top 5 and "other") we need six colors here. The
 * order is important!
 */
export const colors = [
  '#00ABE9',
  '#282f59',
  '#09b54e',
  '#F9B92C',
  '#DC5E0B',
  '#C0D2D8',
];

/*
 * The Data type describes the data that Recharts can render. Note that this
 * type is not ideal. What we want to describe is an object with one known
 * string value (date) and any number of other keys, which will all have number
 * values. We cannot express this with Typescript unless we explicitly type all
 * properties ahead of time.
 */
export type Data = { date: string; [index: string]: string | number }[];

/*
 * The category describes how to present a group of values in a chart. This is
 * aimed at use for a `Bar` in Recharts.
 */
export type Category = { key: string; name: string; color: string };

/*
 * An explicit type of the different lists of categories returned in this
 * module.
 */
type Categories = {
  cashflow: Category[];
  debtors: Category[];
  creditors: Category[];
  finance: Category[];
  taxes: Category[];
  wages: Category[];
};

/*
 * A named row is a map of string strings to arrays of numbers.
 */
type NamedRows = {
  [key: string]: number[];
};

/*
 * An assoc row is like a named row, but in associative array form: a 2-tuple
 * (still just an array) of name and array of numbers.
 */
type AssocRow = [string, number[]];

function toCategories(categories: NamedRows): Category[] {
  return Object.keys(categories).map((category, i) => ({
    key: category,
    name: t(`legend.${category}`, { defaultValue: category }),
    color: colors[i],
  }));
}

function buildChartEntries(periods: Periods, categories: NamedRows): Data {
  return periods.map((period, i) => {
    return Object.entries(categories).reduce(
      (acc, [category, row]) => {
        acc[category] = row[i];
        return acc;
      },
      { date: period }
    );
  });
}

function buildDataAndCategories(
  periods: Periods,
  categories: NamedRows
): [Data, Category[]] {
  return [buildChartEntries(periods, categories), toCategories(categories)];
}

/*
 * Get a single row of data by offset from a particular bucket. Negative offsets
 * are taken from the end of the array of rows.
 */
function row(buckets: Buckets, key: keyof Buckets, offset: number): number[] {
  if (offset < 0) {
    const rows = buckets[key].rows;
    return rows[rows.length + offset].slice(2) as number[];
  } else {
    return buckets[key].rows[offset].slice(2) as number[];
  }
}

/*
 * Get multiple rows of data by offsets from a particular bucket. Uses the same
 * arguments as `Array.prototype.slice`.
 */
function rows(
  buckets: Buckets,
  key: keyof Buckets,
  from: number,
  to: number
): AssocRow[] {
  return buckets[key].rows
    .slice(from, to)
    .map((row) => [row[0] as string, row.slice(2) as number[]]);
}

/*
 * Flip the values in a row: make negative values positive and positive values
 * negative.
 */
function flip(row: number[]): number[] {
  return row.map((i) => i * -1);
}

/*
 * Given an array of named rows, flip all rows.
 */
function flipAll(rows: AssocRow[]): AssocRow[] {
  return rows.map(([name, values]) => [name, flip(values)]);
}

/*
 * Transform a set of periods and buckets into data suitable for visualisation
 * with recharts.
 *
 * This will return a 2-tuple of data and categories. The data is a single
 * value that different charts can pluck different parts from. The categories
 * describe what values to pluck and how to visualise them.
 *
 * The data looks like this:
 *
 *     [{ date: "2021-07", foo: 1, bar: -25, baz: 100.33 }]
 *
 * While categories look like this:
 *
 *     [{ key: 'foo', name: 'Debiteuren', color: '#ff0000' }]
 */
export function getCharts(
  periods: Periods,
  buckets: Buckets
): [Data, Categories] {
  // Curry some functions using the periods and buckets for easier use further
  // down in this function.
  const _buildDataAndCategories = (categories) =>
    buildDataAndCategories(periods, categories);
  const _row = (key, offset) => row(buckets, key, offset);
  const _rows = (key, from, to) => rows(buckets, key, from, to);

  const [cashflowData, cashflow] = _buildDataAndCategories({
    debtors: _row('debtors', -1),
    creditors: flip(_row('creditors', -1)),
  });

  const [debtorsData, debtors] = _buildDataAndCategories(
    Object.fromEntries(_rows('debtors', 0, -1))
  );

  const [creditorsData, creditors] = _buildDataAndCategories(
    Object.fromEntries(flipAll(_rows('creditors', 0, -1)))
  );

  const [financeData, finance] = _buildDataAndCategories({
    financeWithdrawals: _row('financeWithdrawals', -1),
    financeInterest: flip(_row('financeInterestAndRepayments', -1)),
  });

  const [taxesData, taxes] = _buildDataAndCategories({
    vat: flip(_row('vat', 1)),
    additional: flip(_row('vat', 2)),
  });

  const [wagesData, wages] = _buildDataAndCategories({
    wages: flip(_row('wageTaxes', 0)),
    tax: flip(_row('wageTaxes', 2)),
  });

  return [
    periods
      .map((_, i) => ({
        ...cashflowData[i],
        ...debtorsData[i],
        ...creditorsData[i],
        ...financeData[i],
        ...taxesData[i],
        ...wagesData[i],
      }))
      .reverse(),

    {
      cashflow,
      debtors,
      creditors,
      finance,
      taxes,
      wages,
    },
  ];
}
