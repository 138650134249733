import * as routes from 'app/routes';

import client, { Result } from 'shared/utils/client';
import { asyncAction } from 'shared/utils/Helpers';
import { pushToPath } from 'shared/utils/routing';
import { customerSignupSelector } from 'shared/selectors';

export const UPDATE_CUSTOMER_SIGNUP = asyncAction(
  'if.profile.UPDATE_CUSTOMER_SIGNUP'
);

const callCreateCustomerSignup = (params) => {
  return client('POST', `/api/customer_signups`, params);
};

const callUpdateCustomerSignup = (id, params) => {
  return client('PATCH', `/api/customer_signups/${id}`, params);
};

export const updateCustomerSignup = (params) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_CUSTOMER_SIGNUP.REQUEST });

  const customer_signup = customerSignupSelector(getState());

  let response: Result | null = null;
  try {
    if (customer_signup?.id) {
      response = await callUpdateCustomerSignup(customer_signup.id, params);
    } else {
      response = await callCreateCustomerSignup(params);
    }
  } catch (e) {
    dispatch({ type: UPDATE_CUSTOMER_SIGNUP.FAILURE, error: true, ...e });
    return e;
  }

  const successAction = dispatch({
    type: UPDATE_CUSTOMER_SIGNUP.SUCCESS,
    normalize: true,
    ...response,
  });

  dispatch(pushToPath(routes.SELLER_ONBOARDING));

  return successAction;
};
