import { useParams } from 'react-router';

import { IdentifyingPerson } from 'types';
import { useResource } from 'namespaces/shared/hooks';

type OnboardingUserResponse = {
  company: string;
  identifyingPerson: IdentifyingPerson;
  mainApplicant: string;
  name: string;
};

type Result =
  | {
      status: 'not_asked' | 'pending';
    }
  | {
      status: 'success';
      payload: OnboardingUserResponse;
    }
  | {
      status: 'failure';
    }
  | {
      status: 'expired_token';
      canReset: boolean;
    };

export default (): Result => {
  const { token } = useParams<{ token: string }>();

  const response = useResource<OnboardingUserResponse>(
    `/api/onboarding_users/${token}`
  );

  const status = response.type;

  switch (status) {
    case 'not_asked':
    case 'pending':
      return { status };

    case 'failure': {
      if (
        response.error.type === 'bad_status' &&
        response.error.status === 401
      ) {
        const canReset = (response.response as any)?.canReset || false;
        return { status: 'expired_token', canReset };
      } else {
        return { status };
      }
    }

    case 'success':
      return { status, payload: response.response };

    default:
      return { status: 'failure' };
  }
};
