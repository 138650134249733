import setupSentry from './sentry';
import setupDropping from './dropping';
import setupHacks from './hacks';
import setupTesting from './testing';
import setupDatePicker from './reactDatePicker';

export default (config) => {
  [
    setupSentry,
    setupHacks,
    setupDropping,
    setupTesting,
    setupDatePicker,
  ].forEach((setupFn) => setupFn(config));
};
