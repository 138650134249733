import React from 'react';
import BankStatementInfo from 'shared/components/UploadBankStatements/BankStatementInfo';
import DnbDisclaimer from 'shared/components/DnbDisclaimer';
import Psd2Banks from 'shared/components/Psd2Banks';
import * as I18n from 'shared/utils/I18n';
import { PARTNER_SIGNUP_BANK_STATEMENTS_OVERVIEW } from 'app/routes';
import DontWorryMessage from 'shared/components/DontWorryMessage';

const ns = 'app.partner_signup.bank_statements_step.connect_with_bank';

interface Props {
  onSelectPdf: () => void;
}

const ConnectWithBank = ({ onSelectPdf }: Props) => (
  <div className="PartnerSignupConnectWithBank">
    <label>{I18n.nt(ns, 'connection_title')}</label>
    <Psd2Banks
      allowedPdfUpload
      onSelectPdf={onSelectPdf}
      redirectPath={PARTNER_SIGNUP_BANK_STATEMENTS_OVERVIEW}
    />
    <DontWorryMessage />
    <DnbDisclaimer includeTopMargin={false} />
    <BankStatementInfo icon="info">
      {I18n.nt(ns, 'multiple_banks_html')}
    </BankStatementInfo>
  </div>
);

export default ConnectWithBank;
