import { Attachment, ReduxState, Seller } from 'types';
import { Step } from './types';
import { DebtorList, ReviewSheet } from './modules';
import * as Bank from './Bank';
import { currentSellerSelector } from 'shared/selectors';
import { AisRedirectorProps } from 'shared/components/Consent/AisRedirector';
import * as AddStatementsStatus from './AddStatementsStatus';
import { REVIEW } from 'app/routes';
import { SubmitInput, SubmitStatus } from './modules/submit';

export type BasicProps = {
  step: Step;
  reviewId: number;
  reviewSheet: ReviewSheet.ReviewSheet;
  onConnectBank: (bank: Bank.Psd2, redirectPath: string) => void;
  seller: Seller;
  redirectPath: string;
  addStatementsStatus: AddStatementsStatus.AddStatementsStatus;
  sellerComment: string;
  debtorList: DebtorList.DebtorList;
  submitStatus: SubmitStatus;
  bankStatementAttachments: Attachment[];
};

export type DispatchProps = {
  onDeleteAttachment: (attachmentId: number) => void;
  onOpenDebtorList: () => void;
  onSellerCommentChange: (comment: string) => void;
  onSubmit: (data: SubmitInput) => any;
  onUpdateAddStatementsStatus: (
    status: AddStatementsStatus.AddStatementsStatus
  ) => void;
  setOverrideWarnings: (override: boolean) => void;
  toDebtorList: () => void;
  toOverview: () => void;
  onSubmitDebtorList: (values: any) => void;
};

export type WizardProps = BasicProps & DispatchProps;

const loadWizard = (
  state: ReduxState,
  { onRefresh }: AisRedirectorProps
): BasicProps => {
  const seller = currentSellerSelector(state) as Seller;
  return {
    ...state.charlie.reviewWizard,
    seller: seller,
    onConnectBank: onRefresh,
    redirectPath: `${REVIEW}?seller_id=${seller.id}`,
  };
};

export default loadWizard;
