import { useSelector } from 'react-redux';

import client from 'shared/utils/client';
import { useCurrentSeller } from 'shared/hooks';
import { Guarantee } from 'types';
import { contractSelector } from 'shared/selectors';
import { nt } from 'Onboarding/components/utils';

const t = nt('signup.onboarding.guarantee');

const useGuaranteeModal = () => {
  const seller = useCurrentSeller();
  const contract = useSelector(contractSelector);
  const guarantee = contract?.guarantee as Guarantee;

  const sellerEmail = seller.entityKind === 'Seller' ? seller.email : '';

  const onSubmit = (formData): Promise<void> =>
    client('PUT', '/api/guarantee', formData)
      .then((response) => {
        window.location.replace(response.payload.redirectUrl);
      })
      .catch(() => {
        throw t('docusign_failure_html');
      });

  return { guarantee, onSubmit, sellerEmail };
};

export default useGuaranteeModal;
