import React, { useState } from 'react';
import BanksList from 'shared/components/Consent/RefreshModal/BanksList';
import { transformAccountsIntoBanks } from 'shared/components/Consent/RefreshModal/helpers';
import { Consent, Translator } from 'types';
import PendingBanks from './PendingBanks';

interface Props {
  consents: Consent[];
  onRefresh: (bank: string) => void;
  t: Translator;
}

const ConsentsPageBanksList = ({ consents, onRefresh, t }: Props) => {
  const [isDisabled, setDisabled] = useState(false);
  const handleRefresh = (bank) => {
    setDisabled(true);
    onRefresh(bank);
  };

  const pendingConsents: Consent[] = consents.filter(
    ({ bank, iban }) => bank && !iban
  );

  return (
    <>
      {pendingConsents.length > 0 && (
        <PendingBanks t={t} consents={pendingConsents} />
      )}
      <BanksList
        banks={transformAccountsIntoBanks(consents)}
        isDisabled={isDisabled}
        onRefresh={handleRefresh}
      />
    </>
  );
};

export default ConsentsPageBanksList;
