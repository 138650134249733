import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { currentSellerSelector } from 'shared/selectors';
import { frontpageRoute } from 'shared/routes';
import ConditionalRedirectRoute from './ConditionalRedirectRoute';
import { BaseSeller } from 'types';
import { customerAccountSelector } from 'shared/selectors';

interface Props {
  component: React.ComponentType;
  condition: (seller: BaseSeller) => boolean;
  exact?: boolean;
  path: string | string[];
}

const RouteForSeller = ({ component, condition, exact, path }: Props) => {
  const seller = useSelector(currentSellerSelector);
  const customerAccount = useSelector(customerAccountSelector);
  const location = useLocation();

  if (seller && condition(seller)) {
    return (
      <ConditionalRedirectRoute
        component={component}
        exact={exact}
        path={path}
        redirectWhen={false}
      />
    );
  } else {
    const redirectTo = frontpageRoute(
      customerAccount,
      seller,
      location.pathname
    );
    return (
      <ConditionalRedirectRoute
        component={component}
        exact={exact}
        path={path}
        redirectWhen={true}
        redirectTo={redirectTo}
      />
    );
  }
};

export default RouteForSeller;
