import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import CurrencyInputWithSlider from 'hookForm/CurrencyInputWithSlider';

const steps = [
  10, 20, 30, 40, 50, 75, 100, 150, 200, 250, 300, 400, 500, 750, 1_000, 1_500,
  2_000, 3_000,
].map((v) => v * 1000);

interface Props<TFieldValues extends FieldValues> {
  autoFocus?: boolean;
  control: Control<TFieldValues>;
  label: string;
  name: Path<TFieldValues>;
}

const ExpectedFinancingAmountInput = <TFieldValues extends FieldValues>({
  autoFocus,
  control,
  label,
  name,
}: Props<TFieldValues>) => (
  <CurrencyInputWithSlider
    autoFocus={autoFocus}
    className="tw-h-auto tw-px-3 tw-pb-1 tw-pt-2 tw-font-heading tw-text-[24px] tw-font-bold tw-leading-none md:tw-px-6 md:tw-pb-0 md:tw-pt-1 md:tw-text-[48px]"
    control={control}
    label={label}
    name={name}
    steps={steps}
  />
);

export default ExpectedFinancingAmountInput;
