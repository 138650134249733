import React from 'react';

import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { Seller } from 'types';
import DebtCollectionInfoCharlie from './DebtCollectionInfoCharlie';

const DebtCollectionInfo = () => {
  const seller = useCurrentSeller() as Seller;

  const debtCollectionRate = seller.debtCollectionRate
    ? parseFloat(seller.debtCollectionRate)
    : 0.0;

  if (debtCollectionRate === 0) {
    return null;
  }

  return (
    <DebtCollectionInfoCharlie
      seller={seller}
      debtCollectionRate={debtCollectionRate}
    />
  );
};

export default DebtCollectionInfo;
