import { useHistory } from 'react-router-dom';
import { CUSTOMER_APP_ROOT, INVOICES } from 'app/routes';
import { isAlfa } from 'models/Seller';
import { MinimalSeller, Seller } from 'types';

const useIsFrontpage = (seller: MinimalSeller | Seller | undefined) => {
  const history = useHistory();
  const frontpage = isAlfa(seller) ? INVOICES : CUSTOMER_APP_ROOT;

  return seller && history.location.pathname === frontpage;
};

export default useIsFrontpage;
