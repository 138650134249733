import { useForm } from 'react-hook-form';
import resolver from 'hookForm/resolver';
import { validateIban } from 'shared/utils/validation';
import { ReduxState } from 'types/ReduxState';
import { currentSellerSelector } from 'shared/selectors';
import { useSelector } from 'react-redux';
import { trackEvent, trackFormSubmit } from 'shared/utils/tracker';
import { cleanIban } from 'models/Iban';
import client from 'shared/utils/client';
import { useState } from 'react';

interface FormValues {
  iban: string;
}

const ibanSelector = (state: ReduxState): string => {
  const seller = currentSellerSelector(state);
  const sellerIban =
    seller && seller.entityKind === 'Seller' ? seller.iban : '';

  return state.onboarding.iban || sellerIban || '';
};

type SubmitStatus = '' | 'error' | 'loading' | 'ok';

const useMandateStart = () => {
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>('');
  const iban = useSelector(ibanSelector);

  const validate = ({ iban }: FormValues) => {
    const cleanedIban = cleanIban(iban);

    return {
      iban: cleanedIban.length >= 18 ? validateIban(cleanedIban) : false,
    };
  };

  const submit = async (values: FormValues) => {
    setSubmitStatus('loading');
    trackFormSubmit('mandate-start');

    const response = await client(
      'GET',
      `/api/twikey_session/invite?iban=${cleanIban(values.iban)}`,
      {},
      { raiseError: false }
    );

    if (response.error) {
      setSubmitStatus('error');
    } else {
      const { status, url } = response.payload;

      if (status === 'ok') {
        setSubmitStatus('ok');
        trackEvent('Start direct debit mandate', {}, () => {
          window.location.href = url;
        });
      } else {
        setSubmitStatus('error');
      }
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      iban,
    },
    mode: 'onChange',
    resolver: resolver(validate),
  });

  return {
    control,
    handleSubmit: handleSubmit(submit),
    isSubmitting,
    isValid,
    submitStatus,
    watch,
  };
};

export default useMandateStart;
