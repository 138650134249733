import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { mainSellerSelector } from 'shared/selectors';
import { aisRequestRemote } from 'shared/utils/aisClient';
import { compilePath } from 'shared/utils/routing';
import {
  PROFILE_PAGE_CONSENTS,
  PROFILE_PAGE_CONSENTS_CONNECT,
} from 'app/routes';
import { Consents, MinimalSeller, Remote } from 'types';

type RemoteConsents = Remote<Consents>;

const useConsentsPage = () => {
  const history = useHistory();

  const [remoteConsents, setRemoteConsents] = useState<RemoteConsents>({
    type: 'not_asked',
  });

  const redirectPath = PROFILE_PAGE_CONSENTS;
  const connectPath = compilePath(PROFILE_PAGE_CONSENTS_CONNECT, {
    tabKey: 'rekeningen',
  });
  const connectRoute = PROFILE_PAGE_CONSENTS_CONNECT;

  const mainSeller = useSelector(mainSellerSelector) as MinimalSeller;

  const closeModal = () => history.push(PROFILE_PAGE_CONSENTS);

  const loadConsents = async () => {
    const remoteData = await aisRequestRemote<Consents>(
      '/v2/user/consents',
      mainSeller.id
    );
    setRemoteConsents(remoteData);
  };

  useEffect(() => {
    loadConsents();
  }, []);

  return {
    closeModal,
    connectPath,
    connectRoute,
    redirectPath,
    remoteConsents,
    mainSeller,
  };
};

export default useConsentsPage;
