import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Guarantee } from 'types';
import client from 'shared/utils/client';
import { LoginStatus } from './util';

interface RouteParams {
  token: string;
}

interface RefreshSignStatusResult {
  guarantee: Guarantee | null;
  loginStatus: LoginStatus;
}

const useRefreshSignStatus = (): RefreshSignStatusResult => {
  const { token } = useParams<RouteParams>();
  const [loginStatus, setLoginStatus] = useState<LoginStatus>('');
  const [guarantee, setGuarantee] = useState<Guarantee | null>(null);

  const updateSignStatus = async () => {
    setLoginStatus('loading');

    try {
      const { payload } = await client(
        'post',
        '/api/second_signers/refresh_sign_status',
        { token }
      );

      if (payload.status === 'ok') {
        setGuarantee(payload.guarantee);
        setLoginStatus('logged_in');
      } else if (payload.status === 'expired_token') {
        setLoginStatus('expired_token');
      } else {
        setLoginStatus('error');
      }
    } catch {
      setLoginStatus('error');
    }
  };

  useEffect(() => {
    updateSignStatus();
  }, []);

  return { guarantee, loginStatus };
};

export default useRefreshSignStatus;
