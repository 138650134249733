import React from 'react';
import { Well } from 'shared/bootstrap';
import { dateFormat, toEuro, toPercentage } from 'shared/utils/Helpers';
import {
  enabledBeyondDayLimit,
  enabledWithinDayLimit,
  InstantPaymentAvailability,
} from 'types/InstantPaymentAvailability';
import * as I18n from '../utils/I18n';

const ns = 'shared.withdraw_summary';

const t = (key, options?) => I18n.nt(ns, key, options);

interface DebtCollectionAmountProps {
  currentAmount: number;
  newAmount: number;
}

const DebtCollectionAmount = ({
  currentAmount,
  newAmount,
}: DebtCollectionAmountProps) => (
  <li>
    {currentAmount === newAmount
      ? t('same_debt_collection_amount_html', { amount: toEuro(currentAmount) })
      : t('new_debt_collection_amount_html', { amount: toEuro(newAmount) })}
  </li>
);

interface DebtCollectionDateProps {
  currentAmount: number;
  debtCollectionDate: Date;
  usesClientBankAccount: boolean;
}

const DebtCollectionDate = ({
  currentAmount,
  debtCollectionDate,
  usesClientBankAccount,
}: DebtCollectionDateProps) => {
  if (currentAmount === 0) {
    return (
      <li>
        {t('first_repayment_date_html', {
          date: dateFormat(debtCollectionDate),
        })}
      </li>
    );
  } else {
    const message = usesClientBankAccount
      ? 'changed_commitment_date_html'
      : 'changed_repayment_date_html';

    return <li>{t(message, { date: dateFormat(debtCollectionDate) })}</li>;
  }
};

interface Props {
  amount: number;
  closingCommissionAmount: number;
  currentDebtCollectionAmount: number;
  debtCollectionDate?: Date;
  interestRate: number;
  minimumWithdrawalAmount: number;
  newCurrentBalance?: number;
  newDebtCollectionAmount: number;
  serviceFeeRate: number;
  usesClientBankAccount: boolean;
  withdrawalFeeRate: number;
  instantPayment: InstantPaymentAvailability;
  withdrawalWillRequireApproval: boolean;
}

const WithdrawalSummary = ({
  amount,
  closingCommissionAmount,
  currentDebtCollectionAmount,
  debtCollectionDate,
  interestRate,
  minimumWithdrawalAmount,
  newCurrentBalance,
  newDebtCollectionAmount,
  serviceFeeRate,
  usesClientBankAccount,
  withdrawalFeeRate,
  instantPayment,
  withdrawalWillRequireApproval,
}: Props) => {
  const withdrawalFee = amount * withdrawalFeeRate;
  const amountMinusCosts = amount - withdrawalFee - closingCommissionAmount;

  return (
    <Well className="WithdrawalSummary">
      <h5>{t('title')}</h5>
      <ul>
        {amount < minimumWithdrawalAmount &&
          minimumWithdrawalAmount > closingCommissionAmount && (
            <li>
              {t('amount_smaller_than_minimum_html', {
                amount: toEuro(minimumWithdrawalAmount),
              })}
            </li>
          )}
        {amount >= minimumWithdrawalAmount && amountMinusCosts > 0 && (
          <li>
            {t('amount_html', { amountMinusCosts: toEuro(amountMinusCosts) })}{' '}
            {closingCommissionAmount > 0 &&
              t('closing_commission_html', {
                costs: toEuro(closingCommissionAmount),
              })}{' '}
            {withdrawalFee > 0 &&
              t('withdrawal_fee_html', { costs: toEuro(withdrawalFee) })}
          </li>
        )}
        {amount >= minimumWithdrawalAmount &&
          enabledWithinDayLimit(instantPayment, amount) &&
          !withdrawalWillRequireApproval && <li>{t('transfer_instant')}</li>}
        {amount >= minimumWithdrawalAmount &&
          enabledBeyondDayLimit(instantPayment, amount) && (
            <li>{t('transfer_non_instant')}</li>
          )}
        {amountMinusCosts <= 0 && closingCommissionAmount > 0 && (
          <li>
            {t('amount_smaller_than_closing_commission_html', {
              costs: toEuro(closingCommissionAmount),
            })}
          </li>
        )}
        {newCurrentBalance !== undefined && (
          <li>
            {t('balance_line_html', {
              newCurrentBalance: toEuro(newCurrentBalance),
            })}
          </li>
        )}
        <li>
          {t('interest_rate_html', { percentage: toPercentage(interestRate) })}
        </li>
        {serviceFeeRate !== 0 && (
          <li>
            {t('service_fee_rate_html', {
              percentage: toPercentage(serviceFeeRate),
            })}
          </li>
        )}
        {amount !== 0 && (
          <DebtCollectionAmount
            currentAmount={currentDebtCollectionAmount}
            newAmount={newDebtCollectionAmount}
          />
        )}
        {newDebtCollectionAmount !== currentDebtCollectionAmount &&
          debtCollectionDate && (
            <DebtCollectionDate
              currentAmount={currentDebtCollectionAmount}
              debtCollectionDate={debtCollectionDate}
              usesClientBankAccount={usesClientBankAccount}
            />
          )}
      </ul>
    </Well>
  );
};

export default WithdrawalSummary;
