import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { User } from 'types';
import client from 'shared/utils/client';

export interface FormValues {
  canWithdraw: boolean;
}

interface Params {
  afterSavePermissions: (user: User) => void;
  user: User;
}

const useChangeUserPermissionsForm = ({
  afterSavePermissions,
  user,
}: Params) => {
  const defaultValues = { canWithdraw: user.canWithdraw };

  const { control, handleSubmit } = useForm({ defaultValues });
  const [serverError, setServerError] = useState(false);

  const updatePermissions = async (values: FormValues) => {
    const response = await client<User>(
      'PUT',
      `/api/users/${user.id}/permissions`,
      {
        user: {
          ...values,
        },
      },
      {
        raiseError: false,
      }
    );

    if (response.error) {
      setServerError(true);
    } else {
      afterSavePermissions(response.payload);
    }
  };

  const submit = handleSubmit(updatePermissions);

  return { control, serverError, submit };
};

export default useChangeUserPermissionsForm;
