import React, { createContext, ReactNode, useState } from 'react';

type SubmitHandler = () => Promise<boolean>;

interface ContactsInputContextType {
  addOnSubmit: (onSubmit: SubmitHandler) => void;
  removeOnSubmit: (onSubmit: SubmitHandler) => void;
  submit: SubmitHandler;
}

export const ContactsInputContext =
  createContext<ContactsInputContextType | null>(null);

interface ContactsInputProviderProps {
  children: ReactNode;
}

export const ContactsInputProvider = ({
  children,
}: ContactsInputProviderProps) => {
  const [onSubmitEvents, setOnSubmitEvents] = useState<Set<SubmitHandler>>(
    new Set()
  );

  const addOnSubmit = (onSubmit: SubmitHandler) => {
    setOnSubmitEvents((events) => events.add(onSubmit));
  };

  const removeOnSubmit = (onSubmit: SubmitHandler) => {
    setOnSubmitEvents((events) => {
      events.delete(onSubmit);
      return events;
    });
  };

  const submit = async () => {
    for (const onSubmit of onSubmitEvents) {
      const valid = await onSubmit();

      if (!valid) {
        return false;
      }
    }

    return true;
  };

  return (
    <ContactsInputContext.Provider
      value={{ addOnSubmit, removeOnSubmit, submit }}
    >
      {children}
    </ContactsInputContext.Provider>
  );
};
