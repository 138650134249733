import React from 'react';
import { Link } from 'react-router-dom';
import * as I18n from 'shared/utils/I18n';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import { LOGIN, APP_ROOT } from 'app/routes';
import useEmailChangeConfirmation from './useEmailChangeConfirmation';

const ns = 'app.emailChangeConfirmation';

const EmailChangeConfirmation = () => {
  const { status } = useEmailChangeConfirmation();

  switch (status) {
    case 'error':
      return (
        <div className="container EmailChangeConfirmation">
          <h3>{I18n.nt(ns, 'error')}</h3>
          <div>
            <Link to={APP_ROOT} className="btn btn-primary">
              {I18n.nt(ns, 'mainpageButton')}
            </Link>
          </div>
        </div>
      );

    case 'updating':
      return (
        <div className="container EmailChangeConfirmation">
          <LoadingAnimation />
        </div>
      );

    case 'updated':
      return (
        <div className="container EmailChangeConfirmation">
          <h3>{I18n.nt(ns, 'email')}</h3>
          <div>{I18n.nt(ns, 'emailChangeSuccessText')}</div>
          <div>
            <Link to={LOGIN} className="btn btn-primary">
              {I18n.nt(ns, 'mainpageButton')}
            </Link>
          </div>
        </div>
      );
  }

  return null;
};

export default EmailChangeConfirmation;
