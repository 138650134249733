import React from 'react';
import PropTypes from 'prop-types';
import { Alert as AlertComponent } from 'shared/bootstrap';
import {
  addDays,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
  startOfDay,
} from 'date-fns';
import * as I18n from 'shared/utils/I18n';
import * as Helpers from 'shared/utils/Helpers';

const Alert = (props) => {
  const i18nNamespace = 'app.reminderAlerts';

  const {
    name,
    daysStart,
    daysEnd,
    invoice: { dueDate, emails, debtorManagement },

    invoiceContacts,
  } = props;

  const today = startOfDay(new Date());
  const startsOn = addDays(parseISO(dueDate), daysStart);
  const endsOn = addDays(parseISO(dueDate), daysEnd);

  const sentEmails = emails.filter(
    (c) => c.mailerMethod === `ContactMailer#${name}`
  );
  const sentEmail = sentEmails[0];

  const showAlert =
    debtorManagement &&
    isAfter(today, startsOn) &&
    (isBefore(today, endsOn) || isEqual(today, endsOn)) &&
    !sentEmail;

  if (showAlert) {
    const interpolations = {
      contactEmailAddresses: invoiceContacts.map((c) => c.email).join(', '),
      sendDate: Helpers.dateFormat(endsOn),
    };

    return (
      <AlertComponent className="invoice-reminder" type="info">
        {I18n.nt(i18nNamespace, `${name}.future`, interpolations)}
      </AlertComponent>
    );
  } else if (sentEmail) {
    const interpolations = {
      contactEmailAddresses: sentEmails
        .map((email) => email.toAddress)
        .join(', '),
      sendDate: Helpers.dateFormat(parseISO(sentEmail.sentAt)),
    };

    return (
      <AlertComponent className="invoice-reminder" type="warning">
        {I18n.nt(i18nNamespace, `${name}.past`, interpolations)}
      </AlertComponent>
    );
  } else {
    return null;
  }
};

Alert.propTypes = {
  name: PropTypes.string.isRequired,
  daysStart: PropTypes.number.isRequired,
  daysEnd: PropTypes.number.isRequired,
  invoice: PropTypes.object.isRequired,
  invoiceContacts: PropTypes.array.isRequired,
};

export { Alert };

const ReminderAlerts = (props) => {
  const notifications = [
    ['invoice_reminder_first', 0, 7],
    ['invoice_reminder_second', 7, 21],
    ['invoice_reminder_final', 21, 35],
  ];

  const alerts = notifications.map(([name, daysStart, daysEnd], index) => {
    return (
      <Alert
        daysEnd={daysEnd}
        daysStart={daysStart}
        key={index}
        name={name}
        {...props}
      />
    );
  });

  return <div>{alerts}</div>;
};

ReminderAlerts.propTypes = {
  invoice: PropTypes.object,
  invoiceContacts: PropTypes.array.isRequired,
};

export default ReminderAlerts;
