import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as I18n from 'shared/utils/I18n';
import { Well } from 'shared/bootstrap';
import { toEuro, numberFormat } from 'shared/utils/Helpers';
import costs from 'shared/utils/costs';
import InvoiceCostLine from './InvoiceCostLine';

import { isFinanced, isFinished, isOverdue } from 'models/Invoice';

export default class InvoiceCostsSummary extends Component {
  static i18nNamespace = 'app.invoiceCostsSummary';
  static propTypes = {
    invoice: PropTypes.object.isRequired,
    seller: PropTypes.object.isRequired,
  };

  get settlementAmountTranslation() {
    const { invoice } = this.props;

    if (isFinanced(invoice) && isOverdue(invoice)) {
      return 'settlementAmount.financedOverdue';
    }
    return 'settlementAmount.default';
  }

  get extraCostsTranslation() {
    const { invoice } = this.props;

    if (invoice.expired) return 'extraCosts.finishedDueToExpiration';
    if (isFinished(invoice)) {
      return 'extraCosts.finishedDueToPayment';
    }

    return 'extraCosts.default';
  }

  get advanceAmountTranslation() {
    const { invoice } = this.props;

    if (isFinanced(invoice) || isFinished(invoice)) {
      return 'advanceAmount.financed';
    }
    return 'advanceAmount.notFinanced';
  }

  render() {
    const { invoice, seller } = this.props;
    const {
      daysOverdue,
      financeable,
      extraCosts,
      extraVat,
      indirectlyPaid,
      financingState,
      state: invoiceState,
    } = invoice;
    const {
      advanceAmount,
      amount,
      gAmount,
      settlementAmount,
      rate,
      costAmount,
      vatAmount,
    } = costs(invoice, seller);

    if (financingState === 'not_requested' && !financeable) return null;
    if (financingState === 'rejected' || invoiceState === 'rejected') {
      return null;
    }

    const showExtraCosts =
      (isFinanced(invoice) || isFinished(invoice)) && daysOverdue > 0;

    return (
      <div className="fieldset lastfieldset costs">
        <h4>
          {I18n.nt(
            this,
            invoice.requestedFinancing ? 'title' : 'titleWithoutFinancing'
          )}
        </h4>

        <Well>
          <InvoiceCostLine
            text={I18n.nt(this, this.advanceAmountTranslation)}
            amount={advanceAmount}
          />

          {!indirectlyPaid && (
            <InvoiceCostLine
              text={I18n.nt(this, this.settlementAmountTranslation)}
              amount={
                settlementAmount -
                (parseFloat(extraCosts) + parseFloat(extraVat))
              }
            />
          )}

          {indirectlyPaid && (
            <InvoiceCostLine
              text={I18n.nt(this, 'amountIndirectlyPaid')}
              amount={advanceAmount}
            />
          )}

          <InvoiceCostLine
            text={I18n.nt(this, 'costs', {
              rate: numberFormat(rate * 100),
              amount: toEuro(amount - gAmount),
            })}
            amount={costAmount}
          />

          {showExtraCosts && (
            <InvoiceCostLine
              text={I18n.nt(this, this.extraCostsTranslation, { daysOverdue })}
              amount={extraCosts}
            />
          )}

          <InvoiceCostLine
            text={I18n.nt(this, 'btw')}
            amount={vatAmount + parseFloat(extraVat)}
          />
        </Well>
      </div>
    );
  }
}
