import React from 'react';
import classNames from 'classnames';

interface Props {
  leftDone: boolean;
  rightDone: boolean;
  stepIndex: number;
}

const MediumSignupProgressLine = ({
  leftDone,
  rightDone,
  stepIndex,
}: Props) => {
  const done = leftDone || rightDone;

  return (
    <div className="step-index tw-relative tw-flex tw-w-[100%] tw-justify-center">
      <div
        className={classNames(
          'tw-absolute tw-left-0 tw-top-[15px] tw-h-[3px] tw-w-[100%] tw-w-[50%]',
          leftDone ? 'tw-bg-floryn-green' : 'tw-bg-gray-400'
        )}
      />
      <div
        className={classNames(
          'tw-absolute tw-right-0 tw-top-[15px] tw-h-[3px] tw-w-[100%] tw-w-[50%]',
          rightDone ? 'tw-bg-floryn-green' : 'tw-bg-gray-400'
        )}
      />
      <div
        className={classNames(
          'tw-z-10 tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-[50%] tw-border-[3px] tw-border-solid tw-text-center tw-font-bold',
          done
            ? 'tw-border-floryn-green tw-bg-floryn-green tw-text-white'
            : 'tw-border-gray-300 tw-bg-white tw-text-gray-400 md:tw-border-gray-400'
        )}
      >
        {stepIndex}
      </div>
    </div>
  );
};

export default MediumSignupProgressLine;
