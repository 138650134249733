import * as Period from './Period';

/*
 * An availability period comes from AIS and specifies how and for what date
 * range a particular bank account has been provided bank statements for.
 */
export interface AvailabilityPeriod {
  type: 'psd2' | 'pdf' | 'both';
  period: string;
}

/*
 * Build a `Period` from the string-specified date range in the
 * availability period.
 */
export function period({
  period,
}: AvailabilityPeriod): Period.Period | undefined {
  if (period.indexOf('..')) {
    const dates = period.split('..').map((d) => new Date(d));
    if (dates.length === 2) {
      return Period.build(dates[0], dates[1]);
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}
