import React from 'react';
import classNames from 'classnames';
import { toEuro, toPercentage } from 'shared/utils/Helpers';
import YourFinanceLine from './YourFinanceLine';
import { nt } from '../utils';
import { hasDeterminedLimitsAndRates } from 'models/Seller';
import useEnsureFullSeller from 'shared/hooks/useEnsureFullSeller';

interface Props {
  className?: string;
}

const t = nt('signup.onboarding.your_finance');

const YourFinance = ({ className }: Props) => {
  const { seller } = useEnsureFullSeller();

  if (!seller) {
    return null;
  }

  const hasDeterminedCosts = hasDeterminedLimitsAndRates(seller);
  let financeLines;

  if (hasDeterminedCosts) {
    financeLines = (
      <>
        <YourFinanceLine
          label={t('credit_limit.fixed')}
          value={toEuro(seller.creditLimit, 0)}
        />
        <YourFinanceLine
          label={t('interest.fixed')}
          value={t('percentage_per_month', {
            percentage: toPercentage(parseFloat(seller.interestRate || '')),
          })}
        />
      </>
    );
  } else {
    financeLines = (
      <YourFinanceLine
        label={t('credit_limit.requested')}
        value={toEuro(seller.expectedFinancingAmount || '', 0)}
      />
    );
  }

  return (
    <div className={classNames('YourFinance', className)}>
      <h4>{t('title', { company: seller.companyName })}</h4>
      {financeLines}
    </div>
  );
};

export default YourFinance;
