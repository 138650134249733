import React, { useEffect, useRef, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { nanoid } from 'nanoid';

import { generateHtmlElementId } from 'shared/utils/Helpers';
import { nt } from 'shared/utils/I18n';
import client from 'shared/utils/client';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { MinimalSeller } from 'types';
import { BasicAttachment } from 'types/Attachment';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import {
  CREATE_ATTACHMENT,
  CREATE_ATTACHMENT_FAILURE,
  CREATE_ATTACHMENT_SUCCESS,
} from './module';

interface Params<TFieldValues extends FieldValues> {
  allowMultipleFiles: boolean;
  control: Control<TFieldValues>;
  model: string;
  modelId?: number;
  name: Path<TFieldValues>;
  readOnly?: boolean;
  setUploading?: (uploading: boolean) => void;
}

export type TransferStatus = 'uploading' | 'done' | 'notStarted';

interface Transfer {
  fileName: string;
  status: TransferStatus;
  uuid: string;
}

const createTransferFromFile = (file: File): Transfer => ({
  fileName: file.name,
  status: 'notStarted',
  uuid: nanoid(),
});

const useAttachmentsInput = <TFieldValues extends FieldValues>({
  allowMultipleFiles,
  control,
  model,
  modelId,
  name,
  readOnly,
  setUploading,
}: Params<TFieldValues>) => {
  const seller = useCurrentSeller() as MinimalSeller;
  const dispatch = useAppDispatch();
  const [id] = useState(generateHtmlElementId);
  const [transfers, setTransfers] = useState<Transfer[]>([]);
  const [serverError, setServerError] = useState<string | undefined>();
  const [dragCounter, setDragCounter] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    field: { onBlur, onChange, value },
    fieldState: { error },
  } = useController<TFieldValues>({ control, name });

  const updateTransferStatus = (uuid: string, status: TransferStatus) =>
    setTransfers((transfers) =>
      transfers.map((transfer) =>
        transfer.uuid === uuid ? { ...transfer, status } : transfer
      )
    );

  const removeAttachment = (attachmentId: number) => {
    if (readOnly) {
      return;
    }

    onBlur();

    const attachments = (value || []) as BasicAttachment[];
    onChange(
      attachments.filter((attachment) => attachment.id !== attachmentId)
    );
    setServerError(undefined);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleChange(e.target.files);

      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };

  const uploadFileToServer = async (file: File) => {
    const fileData = new FormData();
    fileData.append('file', file);
    fileData.append('record_type', model);
    fileData.append('attachment_name', name);
    fileData.append('record_id', `${modelId || 0}`);
    fileData.append('seller_id', `${seller.id}`);

    const response = await client<BasicAttachment>(
      'POST',
      '/api/attachments',
      fileData,
      {
        raiseError: false,
      }
    );
    return response;
  };

  const uploadFile = async (file: File) => {
    await dispatch({
      type: CREATE_ATTACHMENT,
      uuid: id,
      upload: file,
      fieldName: name,
    });

    const response = await uploadFileToServer(file);

    if (response.error) {
      await dispatch({
        type: CREATE_ATTACHMENT_FAILURE,
        payload: response.payload,
        uuid: id,
        upload: file,
        fieldName: name,
      });

      const validationErrors = response.payload?.validationErrors as
        | Record<string, string>
        | undefined;
      const errorMessage =
        validationErrors?.fileName ||
        nt('form.attachmentsInput', 'upload_error');
      setServerError(errorMessage);
      return null;
    }

    await dispatch({
      type: CREATE_ATTACHMENT_SUCCESS,
      payload: response.payload,
      uuid: id,
      upload: file,
      fieldName: name,
    });

    return response.payload;
  };

  const uploadFiles = async (files: File[]) => {
    const transfers: Transfer[] = [];
    const transfersPerFile = new Map<File, Transfer>();

    for (const file of files) {
      const transfer = createTransferFromFile(file);
      transfers.push(transfer);
      transfersPerFile.set(file, transfer);
    }

    setTransfers(transfers);

    const uploadedAttachments: BasicAttachment[] = [];

    for await (const file of files) {
      const transfer = transfersPerFile.get(file);
      if (transfer) {
        updateTransferStatus(transfer.uuid, 'uploading');
      }

      const attachment = await uploadFile(file);

      if (attachment) {
        uploadedAttachments.push(attachment);
      }

      if (transfer) {
        updateTransferStatus(transfer.uuid, 'done');
      }
    }

    setTransfers([]);
    onBlur();

    const existingAttachments: BasicAttachment[] = value ? [...value] : [];
    onChange([...existingAttachments, ...uploadedAttachments]);
  };

  const handleChange = (files: FileList) => {
    if (!files.length || readOnly) {
      return;
    }

    setServerError(undefined);

    if (allowMultipleFiles) {
      uploadFiles(Array.from(files));
    } else {
      onBlur();
      onChange([]);

      const file = files[0];
      uploadFiles([file]);
    }
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    handleChange(e.dataTransfer.files);
    setDragCounter((dragCounter) => dragCounter + 1);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();

    setDragCounter((dragCounter) => dragCounter - 1);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent) => {
    if (!readOnly) {
      handleChange(e.dataTransfer.files);
    }

    e.preventDefault();
    e.stopPropagation();

    setDragCounter(0);
  };

  useEffect(() => {
    if (setUploading) {
      setUploading(transfers.length !== 0);
    }
  }, [transfers]);

  const attachments = (value || []) as BasicAttachment[];

  const getErrors = () => {
    const errors: string[] = [];

    if (error?.message) {
      errors.push(error.message);
    }

    if (serverError) {
      errors.push(serverError);
    }

    return errors;
  };

  return {
    attachments,
    dropping: dragCounter !== 0,
    errors: getErrors(),
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handleInputChange,
    id,
    inputRef,
    removeAttachment,
    transfers,
  };
};

export default useAttachmentsInput;
