import React from 'react';

interface Props {
  info: string;
  text: string;
}

const QuotedText = ({ info, text }: Props) => (
  <div className="QuotedText">
    <div className="text-info">{info}</div>
    <div className="quoted-text">{text}</div>
  </div>
);

export default QuotedText;
