import _, { includes, isEqual, keyBy, keys, sample } from 'lodash';
import { useEffect, Children, useState } from 'react';
import Cookies from 'js-cookie';

import { trackEvent } from 'shared/utils/tracker';

const expectedTestVariants = {};

const prefixedName = (name) => `ab_test_${name}`;

const getVariantFromStorage = (name, variants) => {
  const storageKey = prefixedName(name);
  let variant = Cookies.get(storageKey);

  if (!includes(variants, variant)) {
    // no variant was found, or the variant is none of the test cases
    variant = sample(variants);
    Cookies.set(storageKey, variant || '', {
      expires: 1000 /* days */,
      secure: true,
    });
  }

  return variant || '';
};

export const getPrefixedTestVariants = () =>
  _.transform(
    expectedTestVariants,
    (acc, variants, key) =>
      (acc[prefixedName(key)] = getVariantFromStorage(key, variants)),
    {}
  );

// trackInSegment will only make a segment API call for a certain A/B test
// name if that test hasn't been tracked yet. We do this by making it a
// closure that closes over an object mapping test names to tracking statuses.
// If the name is not an object key, track the test. Otherwise, ignore the
// call.
const trackInSegment = (() => {
  const trackStateByName: {
    [key: string]: 'pending' | 'completed';
  } = {};

  return (name, variant) => {
    if (trackStateByName[name] === undefined) {
      trackStateByName[name] = 'pending';
      trackEvent(
        prefixedName(name),
        { variant },
        // There's no way of handling errors returned by the segment API.
        // Documentation for the callback argument: "A function that is
        // executed after a short timeout, giving the browser time to make
        // outbound requests first.", so no success/error case, just a
        // callback.
        // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
        // We'll simply mark it as 'completed' then
        () => (trackStateByName[name] = 'completed')
      );
    }
  };
})();

const isEqualSet = (xs, ys) => isEqual(xs.sort(), ys.sort());

export const ABTest = ({ children, name }) => {
  const childByVariantName = keyBy(
    Children.toArray(children),
    (child: any) => child?.props?.name || ''
  );

  const [variant, setVariant] = useState('');

  const variants = keys(childByVariantName);

  if (!isEqualSet(variants, expectedTestVariants[name])) {
    throw new Error(
      `Variants for '${name}' test are not the right ones. Found '${variants.join(
        "', '"
      )}', expected '${expectedTestVariants[name].join("', '")}'.`
    );
  }

  useEffect(() => {
    setVariant(getVariantFromStorage(name, variants));
  }, []);

  const testVariant = childByVariantName[variant];

  useEffect(() => {
    if (testVariant) {
      trackInSegment(name, variant);
    }
  }, [variant]);

  return testVariant || null;
};

export const Variant = (props) => {
  const { children, name } = props;
  if (!name) {
    throw new Error('variant attribute should be a non-empty string');
  }
  return children || null;
};
