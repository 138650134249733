import { Middleware } from 'redux';
import client from 'shared/utils/client';

const promiseMiddleware: Middleware = (store) => (next) => (action) => {
  const { promise, type, success, ...rest } = action as any;

  if (!promise) {
    return next(action);
  }

  let REQUEST, SUCCESS, FAILURE;
  if (Array.isArray(type)) {
    [REQUEST, SUCCESS, FAILURE] = type;
  } else {
    ({ REQUEST, SUCCESS, FAILURE } = type);
  }

  next({ ...rest, type: REQUEST });

  return promise(client, store).then(
    (response) => {
      const resultAction = store.dispatch({
        ...rest,
        ...response,
        type: SUCCESS,
      });

      if (success) {
        success(store.dispatch, store.getState);
      }

      return resultAction;
    },
    (response) =>
      store.dispatch({ ...rest, ...response, type: FAILURE, error: true })
  );
};

export default promiseMiddleware;
