import React from 'react';

import * as I18n from 'shared/utils/I18n';
import { formatIban } from 'shared/utils/Helpers';
import { Consent } from 'types';

const ns = 'app.partner_signup.overview_step';

interface Props {
  consent: Consent;
}

const BankAccount = ({ consent }: Props) => (
  <div className="Account">
    <div className="logo">
      <img src={`/images/banks/${consent.bank}.svg`} />
    </div>
    <div className="name">{I18n.nt(ns, 'bank_connection')}</div>
    <div className="iban">{formatIban(consent.iban)}</div>
  </div>
);

export default BankAccount;
