import React, { useState } from 'react';
import { monthsAgo } from 'shared/utils/date';
import { trackEvent } from 'shared/utils/tracker';
import { translate } from 'shared/utils/I18n';
import Chart from './Chart';
import { prepareBalances } from './balances';
import Panel from './Panel';
import AccountsDropdown from './AccountsDropdown';
import { TimeFilter } from './TimeFilterDropdown';
import { Account, ClosingBalancesPerAccount } from './TransactionInsights';

const t = translate('insights.accounts');

interface Props {
  accounts: Account[];
  closingBalances: ClosingBalancesPerAccount;
  onExpand?: () => void;
  months: TimeFilter;
  colors: string[];
  chart?: (props) => React.ReactElement | null;
}

function startDateFromTimefilter(months): Date {
  switch (months) {
    case '6':
      return monthsAgo(12);
    case '12':
      return monthsAgo(12);
    default:
      return new Date(1900, 0, 1);
  }
}

function omitValue(collection, element) {
  return collection.filter((e) => e !== element);
}

function omitValueUnlessItIsTheOnlyValue(collection, element) {
  if (collection.length === 1 && collection[0] === element) {
    return collection;
  } else {
    return omitValue(collection, element);
  }
}

const BalancePanel = ({
  months,
  accounts,
  closingBalances,
  onExpand,
  colors,
  chart: BalanceChart = Chart,
}: Props) => {
  const startDate = startDateFromTimefilter(months);
  const isDisabled = (account) => new Date(account.endDate) < startDate;
  const [selectedAccounts, setSelectedAccounts] = useState(
    accounts.filter((a) => !isDisabled(a))
  );
  const toggleAccount = (account) => {
    trackEvent('FinancialDashboard.ToggleAccount');
    if (!selectedAccounts.includes(account)) {
      setSelectedAccounts([account, ...selectedAccounts]);
    } else {
      setSelectedAccounts(
        omitValueUnlessItIsTheOnlyValue(selectedAccounts, account)
      );
    }
  };

  const dropdown = (
    <AccountsDropdown
      accounts={accounts}
      isDisabled={isDisabled}
      selectedAccounts={selectedAccounts}
      onToggle={toggleAccount}
    />
  );

  return (
    <Panel onExpand={onExpand} title={t('title')} headerChildren={dropdown}>
      <div className="ti-p-n">
        <BalanceChart
          data={prepareBalances(closingBalances, selectedAccounts)}
          colors={colors}
        />
      </div>
    </Panel>
  );
};

export default BalancePanel;
