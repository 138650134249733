import React from 'react';
import { Button } from 'shared/bootstrap';
import { translate } from 'shared/utils/I18n';
import classNames from 'classnames';

const t = translate('insights.panel');

interface Props {
  title: string;
  headerChildren?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  onExpand?: () => void;
}

function Panel({
  title,
  headerChildren,
  children,
  className,
  onExpand,
}: Props) {
  return (
    <div className={classNames('ti-panel', className)}>
      <div className="ti-panel__header">
        <h4>{title}</h4>
        <div className="ti-panel__header-controls">
          {headerChildren}
          {onExpand && (
            <Button onClick={onExpand} className="ti-panel__expand btn-link">
              {t('details')}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ti-icon"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Button>
          )}
        </div>
      </div>
      <div className="ti-panel__body">{children}</div>
    </div>
  );
}

export default Panel;
