import React from 'react';
import { Alert } from 'shared/bootstrap';
import { nt } from 'shared/utils/I18n';
import LoadingError from 'shared/components/LoadingError';
import LoadingAnimation from 'shared/components/LoadingAnimation';

import useExactOnlineConnection from './useExactOnlineConnection';
import Connected from './Connected';
import ConnectionInformation from './ConnectionInformation';
import NotConnected from './NotConnected';

const ns = 'app.profilePage.exact_online_connection';

const ExactOnlineConnection = () => {
  const {
    callbackStatus,
    connected,
    loadingStatus,
    someHaveDivisions,
    username,
  } = useExactOnlineConnection();

  const Title = ({ title }: { title: string }) => (
    <div className="title">
      <h3>{title}</h3>
      <img aria-label="exact-logo" src="/images/exact-logo.svg" />
    </div>
  );

  switch (loadingStatus) {
    case 'error':
      return <LoadingError />;

    case 'loading':
      return <LoadingAnimation />;

    case 'loaded':
      return (
        <div className="ExactOnlineConnection">
          {callbackStatus === 'error' && (
            <Alert type="danger">{nt(ns, 'callback.error')}</Alert>
          )}
          {connected && (
            <>
              {!someHaveDivisions && (
                <Alert type="warning">
                  {nt(ns, 'missing_division', { username: username })}
                </Alert>
              )}
              <Title title={nt(ns, 'title_connected')} />
              <Connected />
              <ConnectionInformation />
            </>
          )}
          {!connected && (
            <>
              <Title title={nt(ns, 'title')} />
              <NotConnected />
            </>
          )}
        </div>
      );

    default:
      return null;
  }
};

export default ExactOnlineConnection;
