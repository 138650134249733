import { getQueryParam } from 'shared/utils/Helpers';
import { endsWith } from 'lodash';

export enum ConsentFeedbackStatus {
  Success,
  Failed,
  Rejected,
  None,
}

function getStatusFromPath(path): ConsentFeedbackStatus {
  if (endsWith(path, 'koppeling-fout')) {
    return ConsentFeedbackStatus.Failed;
  } else if (endsWith(path, 'koppeling-afgewezen')) {
    return ConsentFeedbackStatus.Rejected;
  } else {
    return ConsentFeedbackStatus.None;
  }
}

/**
 * Backward-compatibility hack: detect the return status from either the query
 * parameter OR the path of the current location.
 */
export function getStatusFromQueryStringOrPath(
  location
): ConsentFeedbackStatus {
  switch (getQueryParam(location.search, 'status')) {
    case 'success':
      return ConsentFeedbackStatus.Success;
    case 'failed':
      return ConsentFeedbackStatus.Failed;
    case 'rejected':
      return ConsentFeedbackStatus.Rejected;
    case null:
      return getStatusFromPath(location.pathname);
    default:
      return ConsentFeedbackStatus.Failed;
  }
}
