import React from 'react';
import { Alert } from 'shared/bootstrap';
import { Link } from 'react-router-dom';
import * as I18n from 'shared/utils/I18n';
import Button from 'shared/components/tailwind/Button';
import TextInput from 'hookForm/TextInput';
import { REQUEST_RESET_PASSWORD, SIGNUP } from 'app/routes';
import { Status } from './useLogin';
import useLoginForm, { FormValues } from './useLoginForm';

const ns = 'app.login';

interface Props {
  login: (values: FormValues) => void;
  status: Status;
}

const getErrorMessage = (status: Status) => {
  switch (status) {
    case 'account_locked':
      return I18n.t('application_errors.account_locked');
    case 'invalid_login':
      return I18n.t('application_errors.invalid_login');
    case 'server_error':
      return I18n.t('application_errors.invalid_authenticity_token');
    default:
      return '';
  }
};

const Login = ({ login, status }: Props) => {
  const { control, submit } = useLoginForm(login);
  const errorMessage = getErrorMessage(status);

  const imageVersion = Math.floor(Math.random() * 3) + 1;

  return (
    <div>
      <div className="tw-absolute tw-h-full tw-w-full lg:tw-w-1/2">
        <main className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-gap-8">
          <img src="/images/logo.svg" className="tw-h-20" alt="floryn-logo" />
          {errorMessage && (
            <Alert
              type="danger"
              className="tw-mb-0 tw-w-80 tw-text-center xs:tw-w-96"
            >
              {errorMessage}{' '}
              {status === 'invalid_login' && (
                <Link to={REQUEST_RESET_PASSWORD} className="reset-password">
                  {I18n.nt(ns, 'resetPassword')}
                </Link>
              )}
            </Alert>
          )}
          <form
            onSubmit={submit}
            className="tw-flex tw-w-80 tw-flex-col tw-gap-6 xs:tw-w-96"
          >
            <TextInput
              autoComplete="username"
              control={control}
              label={I18n.label('user', 'email')}
              name="email"
              type="email"
              includeLayoutClass={false}
              autoFocus
            />
            <div>
              <Link
                to={REQUEST_RESET_PASSWORD}
                tabIndex={1}
                className="tw-float-right tw-font-heading tw-text-floryn-green hover:tw-text-floryn-dark-green"
              >
                {I18n.nt(ns, 'forgotPassword')}
              </Link>
              <TextInput
                autoComplete="current-password"
                control={control}
                label={I18n.label('user', 'password')}
                name="password"
                type="password"
                includeLayoutClass={false}
              />
            </div>
            <div className=" tw-w-full tw-items-center tw-justify-between">
              <Button
                className="btn-primary tw-w-full"
                disabled={status === 'authenticating'}
                type="submit"
              >
                {I18n.nt(ns, 'signIn')}
              </Button>
              <div className="tw-mt-4 tw-flex tw-flex-col tw-items-center tw-gap-2">
                <Link
                  to={SIGNUP}
                  className="sign-up tw-font-heading tw-text-floryn-green hover:tw-text-floryn-dark-green"
                >
                  {I18n.nt(ns, 'signUp')}
                </Link>
              </div>
            </div>
          </form>
          <Alert
            type="info"
            className="tw-mb-0 tw-w-80 tw-text-center xs:tw-w-96"
          >
            {I18n.nt(ns, 'are_you_a_partner_html')}
          </Alert>
        </main>
      </div>
      <div
        role="region"
        aria-label="Login background image"
        className="tw-absolute tw-right-0 tw-h-full tw-w-0 tw-object-cover tw-object-right lg:tw-w-1/2"
      >
        <img
          alt="login-background"
          src={`/images/login_background-${imageVersion}.jpg`}
          className="tw-h-full tw-w-full tw-object-cover tw-object-center"
        />
      </div>
    </div>
  );
};

export default Login;
