import React from 'react';
import * as I18n from 'shared/utils/I18n';

const ns = 'charlie.frontpage.warnings';

const InstructionsLink = () => (
  <p>
    <a
      className="instructions-link"
      href="https://www.floryn.com/nl/bankafschriften-downloaden"
      rel="noopener noreferrer"
      target="_blank"
    >
      {I18n.nt(ns, 'download_statement_instructions')}
    </a>
  </p>
);

export default InstructionsLink;
