import { pick, values } from 'lodash';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types';
import { sellerIdsSelector } from 'shared/selectors';

const useSellers = () => {
  const sellerIds = useSelector(sellerIdsSelector);
  const allSellers = useSelector((state: ReduxState) => state.entities.sellers);

  return sellerIds ? values(pick(allSellers, sellerIds)) : [];
};

export default useSellers;
