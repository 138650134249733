import React from 'react';
import classNames from 'classnames';
import { Control } from 'react-hook-form';

import * as Bank from './Bank';
import Psd2Banks from 'shared/components/Psd2Banks/Component';
import { Seller } from 'types';
import * as AddStatementsStatus from './AddStatementsStatus';
import InstructionsLink from 'shared/components/ReviewMessages/InstructionsLink';
import DnbDisclaimer from 'shared/components/DnbDisclaimer';
import { translate } from 'shared/utils/I18n';
import BankStatementUploadForm from './BankStatementUploadForm';
import { WizardProps } from './loadWizard';

const t = translate('review_wizard.add_statements');

interface Props {
  onConnectBank: (bank: Bank.Psd2, redirectPath: string) => void;
  seller: Seller;
  redirectPath: string;
  status: AddStatementsStatus.AddStatementsStatus;
  onUpdate: (status: AddStatementsStatus.AddStatementsStatus) => void;
  reviewId: number;
  control: Control<WizardProps>;
}

const AddStatements = (props: Props) => {
  const { onConnectBank, seller, redirectPath, status, onUpdate } = props;

  const uploading = AddStatementsStatus.isUploading(status);

  return (
    <div className="rw-add-statements">
      <div className="list-group">
        <div
          className={classNames('list-group-item', {
            active: AddStatementsStatus.isPsd2(status),
            inactive: AddStatementsStatus.isPdf(status),
            disabled: uploading,
          })}
        >
          <label className="add-statement-option">
            <div>
              <input
                name="add_statements_type"
                value="psd2"
                type="radio"
                disabled={uploading}
                defaultChecked={AddStatementsStatus.isPsd2(status)}
                onClick={() => onUpdate(AddStatementsStatus.psd2())}
              />
            </div>
            <div className="rw-space-y-16">
              <h5 className="tw-mt-0">{t('psd2.title')}</h5>
              <p>{t('psd2.intro')}</p>
            </div>
          </label>
          <div aria-expanded={AddStatementsStatus.isPsd2(status)}>
            <Psd2Banks
              allowedPdfUpload={false}
              redirectPath={redirectPath}
              onSelectPdf={() => null}
              partner={null}
              seller={seller}
              onRefresh={
                onConnectBank as (bank: string, redirectPath: string) => void
              }
            />
            <DnbDisclaimer />
          </div>
        </div>
        <div
          className={classNames(
            'list-group-item',
            {
              active: AddStatementsStatus.isPdf(status),
              inactive: AddStatementsStatus.isPsd2(status),
            },
            'rw-space-y-16'
          )}
        >
          <label className="add-statement-option">
            <div>
              <input
                name="add_statements_type"
                value="pdf"
                type="radio"
                disabled={uploading}
                defaultChecked={AddStatementsStatus.isPdf(status)}
                onClick={() => onUpdate(AddStatementsStatus.pdf())}
              />
            </div>
            <div className="rw-space-y-16">
              <h5 className="tw-mt-0">{t('pdf.title')}</h5>
              <p>{t('pdf.intro')}</p>
            </div>
          </label>
          <div
            className="rw-space-y-16"
            aria-expanded={AddStatementsStatus.isPdf(status)}
          >
            <InstructionsLink />
            <BankStatementUploadForm {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStatements;
