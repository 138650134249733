import { OnboardingState } from 'types/OnboardingState';
import { UPDATE_IBAN } from './module';

const initialState: OnboardingState = {
  iban: undefined,
};

const reducer = (state: OnboardingState = initialState, action: any = {}) => {
  switch (action.type) {
    case UPDATE_IBAN:
      return {
        ...state,
        iban: action.iban,
      };

    default:
      return state;
  }
};

export default reducer;
