import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import client from 'shared/utils/client';
import { ReduxState } from 'types';
import { Division, Seller } from './types';

type Status = '' | 'error' | 'loading' | 'loaded';

interface Credentials {
  username: string;
  divisions: Division[];
}

export interface ExactOnlineConnectionState {
  connected?: boolean;
  loadingStatus: Status;
  sellers: Seller[];
  credentials: Credentials[];
}

export const loadStatus = createAsyncThunk(
  'exactOnlineConnection/loadStatus',
  async () => {
    const response = await client(
      'GET',
      `/api/exact_online/status`,
      {},
      { raiseError: false }
    );

    return response;
  }
);

const initialState: ExactOnlineConnectionState = {
  loadingStatus: '',
  sellers: [],
  credentials: [],
};

const exactOnlineConnectionSlice = createSlice({
  name: 'exactOnlineConnection',
  initialState,
  reducers: {
    setConnected: (state, { payload }: PayloadAction<boolean>) => {
      state.connected = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadStatus.pending, (state) => {
      state.loadingStatus = 'loading';
    });

    builder.addCase(loadStatus.rejected, (state) => {
      state.loadingStatus = 'error';
    });

    builder.addCase(loadStatus.fulfilled, (state, { payload: response }) => {
      if (response.error) {
        state.loadingStatus = 'error';
      } else {
        const { payload } = response;
        state.credentials = payload.credentials;
        state.connected = payload.connected;
        state.loadingStatus = 'loaded';
        state.sellers = payload.sellers;
        state.sellers.forEach((seller) => {
          seller.divisions = payload.credentials[0]?.divisions;

          const division = payload.credentials[0]?.divisions.find(
            (division) => division.code === seller.currentDivision
          );

          seller.currentDivisionDescription = division?.description || '';
        });
      }
    });
  },
});

export const { setConnected } = exactOnlineConnectionSlice.actions;

export const selectConnected = (state: ReduxState) =>
  state.exactOnlineConnection.connected;

export const selectLoadingStatus = (state: ReduxState) =>
  state.exactOnlineConnection.loadingStatus;

export const selectUsername = (state: ReduxState) =>
  state.exactOnlineConnection.credentials[0]?.username;

export const selectHaveSellersWithDivisions = (state: ReduxState) => {
  const sellers = state.exactOnlineConnection.sellers;

  return sellers.some((seller: Seller) => seller.currentDivision);
};

export const selectHaveSellersWithMissingDivisions = (state: ReduxState) => {
  const sellers = state.exactOnlineConnection.sellers;

  return sellers.some((seller: Seller) => !seller.currentDivision);
};

export const selectSellers = (state: ReduxState) =>
  state.exactOnlineConnection.sellers;

export default exactOnlineConnectionSlice.reducer;
