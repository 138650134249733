import React, { ReactNode } from 'react';
import { Route, Switch, useHistory } from 'react-router';

function addPropsToChildren(children, props) {
  return React.Children.toArray(children).map((child) => {
    return React.isValidElement(child)
      ? React.cloneElement(child, props)
      : child;
  });
}

interface Props {
  children: ReactNode;
  baseRoute: string;
  backUrl?: string;
}

type Section =
  | 'overview'
  | 'finance'
  | 'taxes'
  | 'debtors_creditors'
  | 'balances';

const Router = ({ baseRoute, backUrl, children }: Props) => {
  const history = useHistory();
  const navigateToSection = (section: Section) => {
    switch (section) {
      case 'overview':
        history.push(baseRoute);
        break;
      case 'finance':
        history.push(`${baseRoute}/financiering`);
        break;
      case 'taxes':
        history.push(`${baseRoute}/belasting`);
        break;
      case 'debtors_creditors':
        history.push(`${baseRoute}/omzet`);
        break;
      case 'balances':
        history.push(`${baseRoute}/saldo`);
        break;
    }
  };
  const toOverview = () => history.push(baseRoute);
  const toBack = backUrl ? () => history.push(backUrl) : undefined;
  return (
    <Switch>
      <Route
        path={`${baseRoute}/financiering`}
        render={() =>
          addPropsToChildren(children, {
            navigateToSection,
            onBack: toOverview,
            section: 'finance',
          })
        }
      />
      <Route
        path={`${baseRoute}/belasting`}
        render={() =>
          addPropsToChildren(children, {
            navigateToSection,
            onBack: toOverview,
            section: 'taxes',
          })
        }
      />
      <Route
        path={`${baseRoute}/omzet`}
        render={() =>
          addPropsToChildren(children, {
            navigateToSection,
            onBack: toOverview,
            section: 'debtors_creditors',
          })
        }
      />
      <Route
        path={`${baseRoute}/saldo`}
        render={() =>
          addPropsToChildren(children, {
            navigateToSection,
            onBack: toOverview,
            section: 'balances',
          })
        }
      />
      <Route
        path={baseRoute}
        render={() =>
          addPropsToChildren(children, {
            navigateToSection,
            onBack: toBack,
            section: 'overview',
          })
        }
      />
    </Switch>
  );
};

export default Router;
