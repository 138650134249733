import { pick, values } from 'lodash';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types';

const useAttachments = (ids: number[]) => {
  const allAttachments = useSelector(
    (state: ReduxState) => state.entities.attachments
  );

  return ids ? values(pick(allAttachments, ids)) : [];
};

export default useAttachments;
