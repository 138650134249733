import React from 'react';
import PropTypes from 'prop-types';
import { groupBy, sortBy, map } from 'lodash';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import * as I18n from 'shared/utils/I18n';
import * as Helpers from 'shared/utils/Helpers';

export default class InvoiceEmailHistory extends React.Component {
  static propTypes = {
    emails: PropTypes.array.isRequired,
  };

  static i18nNamespace = 'app.invoiceEmailHistory';

  timeFormatter(time) {
    return format(parseISO(time), 'HH:mm');
  }

  renderEmailStatus(email) {
    const { bouncedAt, readAt, deliveredAt } = email;

    if (bouncedAt) {
      return <span key={email} className="glyphicon glyphicon-remove" />;
    } else {
      const firstClassName = classNames('icon-checkmark', {
        confirmed: readAt || deliveredAt,
      });
      const secondClassName = classNames('icon-checkmark', {
        confirmed: readAt,
      });

      return (
        <div key={email.id}>
          <span className={firstClassName} />
          <span className={secondClassName} />
        </div>
      );
    }
  }

  // Renders the e-mails
  renderEmails(emails) {
    const formatTime = (time) => this.timeFormatter(time);

    return emails.map((email) => {
      const { sentAt, subject, toName, toAddress } = email;
      const emailTo = `${toName} (${toAddress})`;

      return (
        <tr key={email.id}>
          <td className="read-status">{this.renderEmailStatus(email)}</td>
          <td className="time">{formatTime(sentAt)}</td>
          <td className="address">
            <abbr title={emailTo}>{emailTo}</abbr>
          </td>
          <td className="subject">
            <abbr title={subject}>{subject}</abbr>
          </td>
        </tr>
      );
    });
  }

  // Renders the date and calls renderEmails
  renderEmailsByDate() {
    const { emails } = this.props;

    const mailSorting = (email) => {
      return email.sentAt;
    };

    const emailsByDate = groupBy(
      sortBy(emails, mailSorting).reverse(),
      (email) => Helpers.dateFormat(email.sentAt)
    );

    return map(emailsByDate, (emailsForDate, date) => (
      <div key={date} className="mail">
        <h5>{date}</h5>
        <table>
          <tbody>{this.renderEmails(emailsForDate)}</tbody>
        </table>
      </div>
    ));
  }

  render() {
    return (
      <div className="fieldset">
        <div className="InvoiceEmailHistory">
          <h4>{I18n.nt(this, 'sent')}</h4>
          {this.renderEmailsByDate()}
        </div>
      </div>
    );
  }
}
