import React, { useMemo } from 'react';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { nt } from 'shared/utils/I18n';
import { toDutchDate, toEuro } from 'shared/utils/Helpers';
import { Restriction } from './Restriction';
import calculateRestrictionSteps from './calculateRestrictionSteps';

const ns = 'signup.onboarding.restriction.chart';

interface ChartRow {
  limit: number;
  step: number;
  date: string;
}

const generateChartData = (restrictionInfo: Restriction): ChartRow[] =>
  calculateRestrictionSteps(restrictionInfo).map((step) => ({
    limit: step.limit,
    step: step.step,
    date: step.date ?? '',
  }));

const formatLegendKey = () => nt(ns, `credit_limit`);

interface Props {
  restrictionInfo: Restriction;
}

const RestrictionChart = ({ restrictionInfo }: Props) => {
  const data = useMemo(
    () => generateChartData(restrictionInfo),
    [restrictionInfo]
  );

  return (
    <div className="tw-f-full tw-h-[300px]">
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 0, left: 0, bottom: 0, right: 10 }}
        >
          <XAxis
            dataKey="step"
            height={65}
            label={
              restrictionInfo.restrictionFrequency === 'week'
                ? nt(ns, 'week')
                : nt(ns, 'month')
            }
          />
          <YAxis width={60} tickFormatter={(value) => toEuro(value, 0)} />
          <Legend formatter={formatLegendKey} height={40} verticalAlign="top" />
          <Tooltip
            labelFormatter={(index, payload) => {
              const date = payload?.[0]?.payload?.date ?? null;
              const formattedDate = date !== null ? toDutchDate(date) : date;
              return `${nt(ns, 'frequency', { frequency: nt(ns, restrictionInfo.restrictionFrequency ?? '') })} ${index} - ${formattedDate}`;
            }}
            formatter={(value: number) => {
              return [toEuro(value)];
            }}
          />
          <Bar
            type="monotone"
            dataKey="limit"
            stackId="1"
            stroke="#00ABE9"
            fill="#00ABE9"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RestrictionChart;
