import * as React from 'react';

interface TableProps {
  bordered?: boolean;
  responsive?: boolean;
  className?: string;
  children: React.ReactNode;
}

const Table = ({ bordered, responsive, className, children }: TableProps) => {
  const tableClasses = `${bordered ? 'tw-border' : ''} ${className || ''}`;

  return (
    <div className={`${responsive ? 'tw-block tw-overflow-x-auto' : ''} table`}>
      <table className={`${tableClasses} tw-ml-2`}>{children}</table>
    </div>
  );
};

export default Table;
