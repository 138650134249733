import { useDispatch } from 'react-redux';
import { poll } from 'shared/utils/asyncUtils';
import konsole from 'shared/utils/konsole';
import client from 'shared/utils/client';
import { FinancialDashboardStatus } from 'types';
import { updateStatus } from './financialDashboardSlice';

const usePollFinancialDashboard = () => {
  const dispatch = useDispatch();
  const loadData = () => client('GET', '/api/financial_dashboard/status');

  const pollFinancialDashboard = async (isCancelled: () => boolean) => {
    dispatch(updateStatus('processing'));

    try {
      const statusFromPoll: FinancialDashboardStatus | null = await poll(
        loadData,
        (payload) =>
          // Poll the status until its no longer processing, or when the polling
          // is cancelled (by leaving the page, for instance).
          payload !== 'processing' || isCancelled()
      );

      if (isCancelled()) {
        return 'cancelled';
      }

      const status = statusFromPoll || 'timeout';

      dispatch(updateStatus(status));
      return status;
    } catch (e) {
      konsole.error(e);
    }

    return 'failed';
  };

  return pollFinancialDashboard;
};

export default usePollFinancialDashboard;
