export type UploadStatus =
  | { status: 'pending'; file: File }
  | { status: 'success'; file: File }
  | { status: 'failed'; file: File; reason: string };

export type UploadStatuses = Record<string, UploadStatus>;

export function empty(): UploadStatuses {
  return {};
}

export function addUpload(
  uploadStatuses: UploadStatuses,
  id: string,
  file: File
): UploadStatuses {
  uploadStatuses[id] = { status: 'pending', file };
  return uploadStatuses;
}

export function failUpload(
  uploadStatuses: UploadStatuses,
  id: string,
  file: File,
  reason: string
): UploadStatuses {
  uploadStatuses[id] = { status: 'failed', file, reason };
  return uploadStatuses;
}

export function fulfillUpload(
  uploadStatuses: UploadStatuses,
  id: string,
  file: File
): UploadStatuses {
  uploadStatuses[id] = { status: 'success', file };
  return uploadStatuses;
}

/**
 * Check if any of the files is currently pending. If there are no files or all
 * are fulfilled or failed, returns false.
 */
export function someInProgress(uploadStatuses: UploadStatuses): boolean {
  return Object.values(uploadStatuses).some(
    ({ status }: UploadStatus) => status === 'pending'
  );
}
