import React from 'react';
import * as I18n from 'shared/utils/I18n';
import { numberFormat } from 'shared/utils/Helpers';
import AnimatedNumber from 'shared/components/AnimatedNumber';

const ns = 'charlie.frontpage.account_summary';

interface Props {
  amount: number;
  color: string;
  children?: React.ReactNode;
  translationKey: string;
}

const AmountFieldWithLegend = ({
  amount,
  color,
  translationKey,
  children,
}: Props) => (
  <div className="amount-field">
    <span className="legend" style={{ backgroundColor: color }}></span>
    <span className="caption">
      {I18n.nt(ns, translationKey)}
      {': '}
    </span>
    <span className="amount">
      <span className="currency">€</span>
      <AnimatedNumber
        duration={800}
        formatValue={numberFormat}
        value={amount}
      />
    </span>{' '}
    {children}
  </div>
);

export default AmountFieldWithLegend;
