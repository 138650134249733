import { useState } from 'react';
import { formatISO, isToday, parseISO } from 'date-fns';

/**
 * Update a timestamp under a given key in `localStorage`.
 *
 * Side effect: this access the device's `localStorage`.
 */
export const touchLocalStorage = (key: string) => {
  if (!(localStorage && localStorage.getItem)) {
    return;
  }

  localStorage.setItem(key, formatISO(new Date()));
};

const readLocalStorage = (key: string): string | null => {
  if (!(localStorage && localStorage.getItem)) {
    return null;
  }

  return localStorage.getItem(key);
};

const useHasSeenPopupToday = () => {
  const [lastSeenPopup] = useState(readLocalStorage('lastSeenPopup'));

  return lastSeenPopup && isToday(parseISO(lastSeenPopup));
};

export default useHasSeenPopupToday;
