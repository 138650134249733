import React from 'react';
import classNames from 'classnames';

interface Props {
  done: boolean;
  stepIndex: number;
}

const SmallSignupProgressStep = ({ done, stepIndex }: Props) => (
  <div className="tw-relative tw-flex tw-justify-between">
    <div
      className={classNames(
        'tw-z-10 tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-[50%] tw-border-[3px] tw-border-solid tw-text-center tw-font-bold',
        done
          ? 'tw-border-floryn-green tw-bg-floryn-green tw-text-white'
          : 'tw-border-gray-300 tw-bg-white tw-text-gray-400 md:tw-border-gray-400'
      )}
    >
      {stepIndex}
    </div>
  </div>
);

export default SmallSignupProgressStep;
