import { addDays, addWeeks, isAfter, isEqual, nextWednesday } from 'date-fns';
import { round } from 'lodash';

import { CreditDecision } from 'types';
import { crossingQuarterNextWeek, Payment } from './calculateWithdrawalInfo';

interface CalculateInterestAmountPerWeekParams {
  creditDecision: CreditDecision;
  debtCollectionAmount: number;
  withdrawalAmount: number;
}

const calculatePayments = ({
  debtCollectionAmount,
  creditDecision,
  withdrawalAmount,
}: CalculateInterestAmountPerWeekParams): [boolean, Payment[]] => {
  let weekCount = 0;
  let currentDebt = withdrawalAmount;
  let expectedDate = new Date();
  const creditLimit = creditDecision.creditLimit
    ? parseFloat(creditDecision.creditLimit)
    : 0;
  const serviceFee = parseFloat(creditDecision.serviceFeeRate) * creditLimit;
  const interestRate = creditDecision.interestRate
    ? parseFloat(creditDecision.interestRate)
    : 0;
  const repayments: Payment[] = [];
  const debCollectionDateStartDate = nextWednesday(addDays(new Date(), 7));

  while (currentDebt > 0) {
    const previousDebt = currentDebt;

    const interest = round((interestRate / 30) * 7 * currentDebt, 2);
    currentDebt += interest;

    const debtCollectionDate = nextWednesday(expectedDate);
    const currentDebtCollectionAmount =
      isEqual(debtCollectionDate, debCollectionDateStartDate) ||
      isAfter(debtCollectionDate, debCollectionDateStartDate)
        ? debtCollectionAmount
        : 0;

    currentDebt -= currentDebtCollectionAmount;

    if (crossingQuarterNextWeek(expectedDate)) {
      currentDebt += serviceFee;
    }

    currentDebt = round(currentDebt, 2);

    repayments.push({
      currentDebt: previousDebt,
      debtCollection:
        currentDebt < 0
          ? round(previousDebt + interest, 2)
          : currentDebtCollectionAmount,
      interest,
    });

    weekCount += 1;

    if (weekCount >= 500) {
      return [false, []];
    }

    expectedDate = addWeeks(expectedDate, 1);
  }

  return [true, repayments];
};

export default calculatePayments;
