import { useState } from 'react';
import { useParams } from 'react-router';

import client from 'shared/utils/client';
import { OnfidoStatus } from 'shared/components/OnfidoModal';

export default () => {
  const { token } = useParams<{ token: string }>();
  const [modalStatus, setModalStatus] = useState<OnfidoStatus>('ready');

  const completeSubmission = async () => {
    await client(
      'POST',
      `/api/onboarding_users/${token}/complete_verify_identity_action`
    );
    setModalStatus('complete');
  };

  return { completeSubmission, modalStatus };
};
