import { useDispatch } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';

import resolver from 'hookForm/resolver';
import { cleanIban, isDigitalBank } from 'models/Iban';
import { updateIban } from 'Onboarding/module';
import { validateIban } from 'shared/utils/validation';
import { Seller } from 'types/Seller';
import { useCurrentSeller } from 'shared/hooks';
import { pendingCentVerification } from 'models/Seller';

interface Props {
  iban?: string;
}

interface FormValues {
  iban: string;
}

export default ({ iban: initialIban }: Props) => {
  const dispatch = useDispatch();
  const seller = useCurrentSeller() as Seller;

  const validate = ({ iban }: FormValues) => {
    const cleanedIban = cleanIban(iban);

    return {
      iban: cleanedIban.length >= 18 ? validateIban(cleanedIban) : false,
    };
  };

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: { iban: initialIban },
    resolver: resolver(validate),
    mode: 'onChange',
  });

  const iban = useWatch({ control, name: 'iban' });
  const cleanedIban = cleanIban(iban);
  const digitalBank = isDigitalBank(cleanedIban);

  const submit = () => dispatch(updateIban(cleanedIban, digitalBank));

  return {
    control,
    digitalBank,
    isSubmitting,
    isValid,
    pendingCentVerification: pendingCentVerification(seller) || false,
    submitForm: handleSubmit(submit),
  };
};
