import { useDispatch } from 'react-redux';
import { authenticateSuccess } from 'shared/redux/modules/session';
import { trackCrossPlatformEvent, trackFormSubmit } from 'shared/utils/tracker';

const useLoginSuccess = () => {
  const dispatch = useDispatch();

  const handleSuccessfulLogin = (payload: Record<string, unknown>) => {
    dispatch(authenticateSuccess(payload));
    trackFormSubmit('login', true);
    trackCrossPlatformEvent('logged-in');
  };

  return handleSuccessfulLogin;
};

export default useLoginSuccess;
