import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import client from 'shared/utils/client';
import { Partner, Review } from 'types';
import { BankStatementAttachment } from 'types/Attachment';

interface Payload {
  bankStatementAttachments: BankStatementAttachment[];
  partner: Partner;
  review: Review;
}

export interface PartnerSignupState {
  loadStatus: '' | 'loading' | 'loaded' | 'error';
  bankStatementAttachments: BankStatementAttachment[];
  partner: Partner | null;
  review: Review | null;
}

interface ReviewAttributes {
  attachmentIds: number[];
}

const initialState: PartnerSignupState = {
  loadStatus: '',
  bankStatementAttachments: [],
  partner: null,
  review: null,
};

export const load = createAsyncThunk<Payload>(
  'partnerSignup.load',
  async () => {
    const response = await client('get', '/api/partner_signup/load');
    return response.payload as Payload;
  }
);

export const updateBankStatements = createAsyncThunk<Payload, ReviewAttributes>(
  'partnerSignup.updateBankStatements',
  async ({ attachmentIds }) => {
    const response = await client(
      'put',
      '/api/partner_signup/update_bank_statement_uploads',
      {
        bankStatementAttachments: attachmentIds,
      }
    );

    return response.payload as Payload;
  }
);

export const finish = createAsyncThunk<Payload>(
  'partnerSignup.finish',
  async () => {
    const response = await client('post', '/api/partner_signup/finish');
    return response.payload as Payload;
  }
);

const partnerSignupSlice = createSlice({
  name: 'partnerSignup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(load.pending, (state) => {
      state.loadStatus = 'loading';
    });
    builder.addCase(load.fulfilled, (state, { payload }) => {
      state.bankStatementAttachments = payload.bankStatementAttachments;
      state.review = payload.review;
      state.partner = payload.partner;
      state.loadStatus = 'loaded';
    });
    builder.addCase(load.rejected, (state) => {
      state.loadStatus = 'error';
    });
    builder.addCase(updateBankStatements.fulfilled, (state, { payload }) => {
      state.bankStatementAttachments = payload.bankStatementAttachments;
      state.review = payload.review;
    });
    builder.addCase(finish.fulfilled, (state, { payload }) => {
      state.review = payload.review;
    });
  },
});

export default partnerSignupSlice.reducer;
