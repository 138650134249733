import React, { Component } from 'react';

const ANIMATION_DURATION = 300;

interface Props {
  animation: boolean;
  duration: number;
  formatValue: (value: number) => string;
  value: number;
}

interface State {
  currentTime?: number;
  currentValue?: number;
  fromValue?: number;
  startTime?: number;
}

export default class AnimatedNumber extends Component<Props, State> {
  static defaultProps = {
    animation: true,
    duration: ANIMATION_DURATION,
    formatValue: (n: number) => n,
    value: 0,
  };

  tweenHandle: number | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      currentValue: props.value,
    };
  }

  componentDidUpdate() {
    if (this.state.currentValue === this.props.value || this.tweenHandle) {
      return;
    }

    this.prepareTween();
  }

  componentWillUnmount() {
    this.stopAnimation();
  }

  prepareTween() {
    this.tweenHandle = window.requestAnimationFrame((timestamp) => {
      this.tweenValue(timestamp, true);
    });
  }

  stopAnimation() {
    if (this.tweenHandle) {
      window.cancelAnimationFrame(this.tweenHandle);
    }
    this.tweenHandle = null;
  }

  endTween() {
    this.stopAnimation();
    this.setState({
      ...this.state,
      currentValue: this.props.value,
    });
  }

  ensureSixtyFps(timestamp: number) {
    const { currentTime } = this.state;

    return !currentTime || timestamp - currentTime > 16;
  }

  animate = (timestamp: number) => {
    this.tweenValue(timestamp, false);
  };

  tweenValue(timestamp: number, start: boolean) {
    if (!this.ensureSixtyFps(timestamp)) {
      this.tweenHandle = window.requestAnimationFrame(this.animate);
      return;
    }

    const { animation, value, duration } = this.props;

    const { currentValue } = this.state;
    const currentTime = timestamp;
    const startTime = start ? timestamp : this.state.startTime;
    const fromValue = start ? currentValue : this.state.fromValue;
    let newValue: number;

    if (!(fromValue !== undefined && startTime)) {
      return;
    }

    if (currentTime - startTime >= duration || !animation) {
      newValue = value;
    } else {
      newValue =
        fromValue +
        (value - fromValue) * ((currentTime - startTime) / duration);
    }

    if (newValue === value) {
      this.endTween();
      return;
    }

    this.setState({
      currentTime,
      currentValue: newValue,
      fromValue,
      startTime: startTime ? startTime : currentTime,
    });
    this.tweenHandle = window.requestAnimationFrame(this.animate);
  }

  render() {
    const { formatValue } = this.props;
    const { currentValue } = this.state;

    if (currentValue === undefined) {
      return null;
    } else {
      return <span>{formatValue(currentValue)}</span>;
    }
  }
}
