import React from 'react';

interface Props {
  active?: boolean;
  disabled?: boolean;
  divider?: boolean;
  header?: boolean;
  eventKey?: any;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onSelect?: (eventKey: any) => void;
  title?: string;
  className?: string;
  children?: React.ReactNode;
}

const MenuItem = ({
  active,
  disabled,
  divider,
  header,
  eventKey,
  onClick,
  onSelect,
  title,
  className,
  children,
}: Props) => {
  const baseClasses = 'tw-px-5 tw-py-[3px] tw-text-md hover:tw-cursor-pointer';
  const activeClasses = active
    ? 'tw-bg-floryn-light-blue tw-text-white'
    : 'tw-text-gray-700 hover:tw-bg-gray-200';
  const disabledClasses = disabled
    ? 'opacity-50 cursor-not-allowed'
    : 'cursor-pointer';
  const dividerClasses = divider
    ? 'tw-border-t tw-my-2.5 tw-border-gray-200'
    : '';
  const headerClasses = header ? 'tw-font-bold tw-text-lg' : '';

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled && onClick) {
      onClick(e);
    }
    if (!disabled && onSelect) {
      onSelect(eventKey);
    }
  };

  if (divider) {
    return <div className={`my-1 ${dividerClasses}`} />;
  }

  return (
    <div
      className={`${baseClasses} ${activeClasses} ${disabledClasses} ${headerClasses} ${className}`}
      onClick={handleClick}
      title={title}
    >
      {children}
    </div>
  );
};

export default MenuItem;
