import React from 'react';

import * as I18n from 'shared/utils/I18n';
import * as Helpers from 'shared/utils/Helpers';
import { Invoice } from 'types';

import FinancingIcon from './FinancingIcon';

interface Props {
  gotoInvoice: (id: number) => void;
  invoice: any;
  onRequestFinancing: (invoice: Invoice) => void;
}

const InvoiceRow = ({ invoice, gotoInvoice, onRequestFinancing }: Props) => {
  const {
    amount,
    allowedActions,
    dateSent,
    debtor,
    financingState,
    id,
    recent,
    reference,
    filterState,
  } = invoice;

  const handleClick = () => gotoInvoice(id);
  const handleRequestFinancingClick = (e) => {
    onRequestFinancing(invoice);
    e.stopPropagation();
  };

  return (
    <tr
      className={recent ? 'recent-invoice' : ''}
      key={`invoice-${id}`}
      onClick={handleClick}
    >
      <td className={`status invoice-status-${filterState}`}>
        {I18n.ut(`app.invoices.filters.${filterState}`)}
      </td>
      <td>{reference}</td>
      <td>{Helpers.dateFormat(dateSent)}</td>
      <td>{debtor}</td>
      <td className="amount">{Helpers.toEuro(amount)}</td>
      <td className="financing-state">
        <FinancingIcon
          financingState={financingState}
          filterState={filterState}
          onRequestFinancingClick={handleRequestFinancingClick}
          requestFinancingAllowed={allowedActions.includes('request_financing')}
        />
      </td>
    </tr>
  );
};

export default InvoiceRow;
