import React from 'react';
import { Route } from 'react-router-dom';

import Action, { ActionButton } from '../Action';
import UboModal from './UboModal';
import { OnboardingActionStatus } from 'types';
import { SELLER_ONBOARDING_UBOS } from 'app/routes';
import { nt } from '../utils';

const t = nt('signup.onboarding.ultimate_beneficial_owners.action');

interface Props {
  status: OnboardingActionStatus;
}

const UltimateBeneficialOwnersAction = ({ status }: Props) => {
  const Button = () => (
    <ActionButton to={SELLER_ONBOARDING_UBOS}>{t('button')}</ActionButton>
  );

  const renderModal = () => <UboModal />;

  return (
    <>
      <Action
        button={Button}
        description={t('description')}
        path={SELLER_ONBOARDING_UBOS}
        status={status}
        title={t('title')}
      />
      <Route path={SELLER_ONBOARDING_UBOS} render={renderModal} />
    </>
  );
};

export default UltimateBeneficialOwnersAction;
