import { useSelector } from 'react-redux';
import { useCurrentSeller, useUser } from 'shared/hooks';
import { contractSelector } from 'shared/selectors';

const useAccountInformation = () => {
  const currentSeller = useCurrentSeller();
  const user = useUser();
  const contract = useSelector(contractSelector);
  const signedContract = contract?.state === 'signed';

  return { currentSeller, signedContract, user };
};

export default useAccountInformation;
