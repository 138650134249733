import React from 'react';
import { debounce } from 'lodash';
import { Glyphicon } from 'shared/bootstrap';

import * as I18n from 'shared/utils/I18n';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import { search } from 'charlie/modules/dashboardSlice';

const ns = 'charlie.frontpage.search';

interface Props {
  sellerId: number;
}

const SearchForm = ({ sellerId }: Props) => {
  const dispatch = useAppDispatch();

  const handleChange = (query: string) => dispatch(search({ query, sellerId }));
  const debouncedHandleChange = debounce(handleChange, 300);

  return (
    <div className="tw-flex-grow md:tw-max-w-sm">
      <label htmlFor="search-form" className="sr-only">
        {I18n.nt(ns, 'label')}
      </label>
      <span className="input-group">
        <span className="input-group-addon">
          <Glyphicon glyph="search" />
        </span>
        <input
          className="form-control"
          id="search-form"
          onChange={(e) => debouncedHandleChange(e.target.value)}
          placeholder={I18n.nt(ns, 'placeholder')}
          type="search"
        />
      </span>
    </div>
  );
};

export default SearchForm;
