import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import LoadingAnimation from 'shared/components/LoadingAnimation';
import loadWizard from './loadWizard';
import Wizard from './Wizard';
import LoadingFailure from './LoadingFailure';
import { trackEvent } from 'shared/utils/tracker';
import { currentSellerSelector } from 'shared/selectors';
import { MinimalSeller } from 'types';

import {
  deleteAttachment,
  setOverrideWarnings,
  submitDebtorList,
  submitWizard,
  toDebtorList,
  toOverview,
  updateAddStatementsStatus,
  updateSellerComment,
} from 'shared/modules/reviewWizardSlice';

const mapDispatchToProps = {
  onDeleteAttachment: deleteAttachment,
  onOpenDebtorList: toDebtorList,
  onSellerCommentChange: updateSellerComment,
  onSubmit: submitWizard,
  onSubmitDebtorList: submitDebtorList,
  onUpdateAddStatementsStatus: updateAddStatementsStatus,
  setOverrideWarnings,
  toDebtorList,
  toOverview,
};

const ConnectedWizard = connect(loadWizard, mapDispatchToProps)(Wizard);

const LoadingWizard = ({ loading, loadWizardState, onRefresh }) => {
  const seller = useSelector(currentSellerSelector) as MinimalSeller;

  useEffect(() => {
    loadWizardState(seller.id);
  }, []);

  useEffect(() => {
    trackEvent('ReviewWizard.Loading', { type: loading.type });
  }, [loading.type]);

  switch (loading.type) {
    case 'failure':
      return <LoadingFailure />;
    case 'success':
      return <ConnectedWizard onRefresh={onRefresh} />;
    default:
      return <LoadingAnimation />;
  }
};

export default LoadingWizard;
