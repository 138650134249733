import React, { Component } from 'react';
import qs from 'qs';
import { connect } from 'react-redux';

import GuestSignup from 'signup/components/GuestSignup';
import { campaignSelector } from 'shared/selectors';
import { loadCampaignData } from 'signup/actions/GuestSignup';
import { Campaign } from 'types';

interface Props {
  campaignStatus: string;
  loadCampaignData: (
    code: string | qs.ParsedQs | string[] | qs.ParsedQs[],
    email: string | qs.ParsedQs | string[] | qs.ParsedQs[] | undefined
  ) => void;
}

const mapStateToProps = (state) => {
  const campaignState = campaignSelector(state) as Campaign | null;

  return { campaignStatus: campaignState ? campaignState.status : '' };
};

class GuestSignupContainer extends Component<Props> {
  componentDidMount() {
    const { code, email } = qs.parse(location.search.substring(1));

    if (code) {
      this.props.loadCampaignData(code, email);
    }
  }

  render() {
    const { campaignStatus } = this.props;

    return <GuestSignup loading={campaignStatus === 'loading'} />;
  }
}

export default connect(mapStateToProps, { loadCampaignData })(
  GuestSignupContainer
);
