import { isObject, isDate, isRegExp, isBoolean, isArray } from 'lodash';
import { camelize, underscore } from 'inflected';

type ConvertFn = (v: string) => string;

// Function taken from: https://github.com/domchristie/humps
const processKeys = (convertFn: ConvertFn, obj: unknown) => {
  if (!isObject(obj) || isDate(obj) || isRegExp(obj) || isBoolean(obj)) {
    return obj;
  }

  if (isArray(obj)) {
    return obj.map((v) => processKeys(convertFn, v));
  } else {
    const output = {};

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        output[convertFn(key)] = processKeys(convertFn, obj[key]);
      }
    }

    return output;
  }
};

const conditionalCamelize = (v: string) => {
  if (v.toUpperCase() === v || v.startsWith('_')) {
    return v;
  } else {
    return camelize(v, false);
  }
};

export const underscoreKeys = (obj: Record<string, unknown>) =>
  processKeys(underscore, obj);
export const camelizeKeys = (obj: Record<string, unknown>) =>
  processKeys(conditionalCamelize, obj);
