import React from 'react';

const Section = ({ children, title }) => (
  <div className="tw-mb-5">
    <div className="tw-py-2">
      <h4>{title}</h4>
    </div>
    <div className="tw-rounded tw-border tw-border-gray-300 tw-p-0 tw-text-sm">
      {children}
    </div>
  </div>
);

export default Section;
