import React from 'react';
import { dateFormat } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';

const ns = 'charlie.frontpage.account_summary';

interface Props {
  pendingSettlementDate: string;
}

const PendingSettlementPanel = ({ pendingSettlementDate }: Props) => (
  <div className="tw-flex tw-flex-col tw-items-end tw-gap-0 xs:tw-flex-row xs:tw-gap-1">
    <span>{I18n.nt(ns, 'pending_settlement')}:</span>
    <span>{dateFormat(pendingSettlementDate)}</span>
  </div>
);

export default PendingSettlementPanel;
