import {
  bankAccountVerified,
  financeConditions,
  hasBankStatementAttachments,
  hasPsd2Connection,
  pendingCentVerification,
} from 'models/Seller';
import { mainSigner } from 'Onboarding/components/utils';
import { capitalize } from 'shared/utils/Helpers';
import { AlfaSeller, Contract, OnboardingActionStatus, Seller } from 'types';

export const bankAccountVerificationStatus = (
  seller: Seller
): OnboardingActionStatus => {
  if (bankAccountVerified(seller)) {
    return 'Done';
  }

  if (pendingCentVerification(seller)) {
    return 'Pending';
  }

  return 'Todo';
};

export const contractStatus = (contract?: Contract): OnboardingActionStatus => {
  if (!contract) {
    return 'Pending';
  }

  const signer = mainSigner(contract);
  const signed = signer ? signer.signed : contract.state === 'signed';

  if (signed) {
    return 'Done';
  } else if (contract.contractType === 'manual') {
    return 'Pending';
  } else {
    return 'Todo';
  }
};

export const exampleInvoicesStatus = (
  seller: AlfaSeller
): OnboardingActionStatus =>
  seller.exampleInvoices.length > 0 ? 'Done' : 'Todo';

export const guaranteeStatus = (
  contract?: Contract
): OnboardingActionStatus => {
  if (!contract || !contract.guarantee) {
    return 'Pending';
  }

  const { guarantee } = contract;

  if (
    guarantee.signed &&
    (guarantee.guaranteeRequiresCoSigner ? guarantee.coSignerSigned : true)
  ) {
    return 'Done';
  } else {
    return 'Todo';
  }
};

export const mandateStatus = (seller: Seller): OnboardingActionStatus => {
  switch (seller.debtCollectionMandateState) {
    case 'printed': {
      return 'Pending';
    }
    case 'signed': {
      return 'Done';
    }
    default: {
      return 'Todo';
    }
  }
};

export const propertyTaxationStatus = (
  seller: Seller
): OnboardingActionStatus => {
  if (
    seller.propertyAddress &&
    seller.propertyAddress.length > 0 &&
    seller.valuationReports.length > 0
  ) {
    return 'Done';
  } else {
    return 'Todo';
  }
};

export const statementsStatus = (seller: Seller): OnboardingActionStatus =>
  hasBankStatementAttachments(seller) || hasPsd2Connection(seller)
    ? 'Done'
    : 'Pending';

export const swanOnboardingStatus = (seller: Seller): OnboardingActionStatus =>
  seller.swanOnboardingState === 'completed' ? 'Done' : 'Todo';

export const uboStatus = (seller: Seller): OnboardingActionStatus =>
  seller.ubosFinishedAndConfirmed ? 'Done' : 'Todo';

export const verifyIdentityStatus = (seller: Seller): OnboardingActionStatus =>
  capitalize(seller.identifyingPeopleStatus) as OnboardingActionStatus;

export const withdrawalStatus = (seller: Seller): OnboardingActionStatus => {
  const conditions = financeConditions(seller);
  const { currentBalance } = conditions;

  return currentBalance > 0 ? 'Done' : 'Todo';
};
