import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Col, Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import EnableTwoFactorModal from 'shared/components/TwoFactor/EnableTwoFactorModal';
import { disableTwoFactor } from 'shared/redux/modules/session';
import { twoFactorEnabled } from 'app/modules/profilePage';

const ns = 'app.profilePage';

class TwoFactorSettings extends Component {
  static propTypes = {
    enabled: PropTypes.bool.isRequired,
    phone: PropTypes.string,
    disableTwoFactor: PropTypes.func.isRequired,
    twoFactorEnabled: PropTypes.func.isRequired,
  };

  state = {
    enablingTwoFactor: false,
    disablingTwoFactor: false,
  };

  startEnabling() {
    this.setState({ disablingTwoFactor: false, enablingTwoFactor: true });
  }

  startDisabling() {
    this.setState({ disablingTwoFactor: true, enablingTwoFactor: false });
  }

  handleDisableConfirm() {
    this.props.disableTwoFactor();
    this.stopAll();
  }

  stopAll() {
    this.setState({ disablingTwoFactor: false, enablingTwoFactor: false });
  }

  renderNotEnabled() {
    return (
      <Col lg={6}>
        <h4>{I18n.nt(ns, 'twoFactorSettings.title')}</h4>
        <p className="instructions">
          {I18n.nt(ns, 'twoFactorSettings.description')}
        </p>
        <Button className="btn-primary" onClick={this.startEnabling.bind(this)}>
          {I18n.nt(ns, 'twoFactorSettings.enableButton')}
        </Button>

        <EnableTwoFactorModal
          hideModal={() => this.stopAll.bind(this)()}
          show={this.state.enablingTwoFactor}
          twoFactorEnabled={this.props.twoFactorEnabled}
        />
      </Col>
    );
  }

  renderEnabled() {
    return (
      <Col lg={6}>
        <h4>{I18n.nt(ns, 'twoFactorSettings.title')}</h4>
        <p className="instructions">
          {I18n.nt(
            ns,
            'twoFactorSettings.alreadyEnabled.description',
            {},
            { phone_number: this.props.phone }
          )}
        </p>

        <br />
        <br />

        <Button
          className="btn-primary"
          onClick={this.startDisabling.bind(this)}
        >
          {I18n.nt(ns, 'twoFactorSettings.alreadyEnabled.deactivate')}
        </Button>

        <Modal
          show={this.state.disablingTwoFactor}
          onHide={this.stopAll.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {I18n.nt(ns, 'twoFactorSettings.alreadyEnabled.confirm_title')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {I18n.nt(
              ns,
              'twoFactorSettings.alreadyEnabled.confirm_description'
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.stopAll.bind(this)}>
              {I18n.nt(ns, 'twoFactorSettings.alreadyEnabled.cancel')}
            </Button>
            <Button
              className="btn btn-danger"
              onClick={this.handleDisableConfirm.bind(this)}
            >
              {I18n.nt(ns, 'twoFactorSettings.alreadyEnabled.confirm')}
            </Button>
          </Modal.Footer>
        </Modal>
      </Col>
    );
  }

  render() {
    if (this.props.enabled) {
      return this.renderEnabled();
    } else {
      return this.renderNotEnabled();
    }
  }
}

export default connect(null, {
  disableTwoFactor,
  twoFactorEnabled,
})(TwoFactorSettings);
