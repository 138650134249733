import React from 'react';
import { groupBy } from 'lodash';

import Mutation from './Mutation';
import AlfaMutation from './AlfaMutation';
import { DashboardMutation } from 'charlie/modules/dashboardSlice';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import * as I18n from 'shared/utils/I18n';
import { dateFormat, datetimeFormat } from 'shared/utils/Helpers';
import { ExtendedProductVersion } from 'types';

const ns = 'app.mutations';

export interface DashboardMutationWithExtraInfo extends DashboardMutation {
  createdAtDate: number | null;
  identifier: string | number;
  isPending: boolean;
}

interface Props {
  productVersion: ExtendedProductVersion;
  isLoaded: boolean;
  loadMoreMutations: () => void;
  lastPage: boolean;
  mutations: Map<string, DashboardMutationWithExtraInfo>;
  searching: boolean;
  searchQuery: string;
  today: string;
  latestImport: string;
}

const sortMutations = (
  a: DashboardMutationWithExtraInfo,
  b: DashboardMutationWithExtraInfo
): number => {
  /*
     Pending mutations are:
     - Moved to the back with respect to other mutations
     - Sorted based on ID among themselves
   */
  if (a.isPending || b.isPending) {
    if (a.isPending && b.isPending) {
      return a.id - b.id;
    }

    if (a.isPending) {
      return 1;
    }

    if (b.isPending) {
      return -1;
    }
  }

  if (a.date !== null && b.date !== null) {
    if (a.date < b.date) {
      return -1;
    }

    if (a.date > b.date) {
      return 1;
    }
  }

  // For equal dates, sort by createdAtDate
  if (a.createdAtDate !== b.createdAtDate) {
    if (a.createdAtDate === null) {
      return 1;
    }

    if (b.createdAtDate === null) {
      return -1;
    }

    return a.createdAtDate - b.createdAtDate;
  }

  // Compare Mutation IDs. Credit limit changes are of a different type and
  // should be handled by the createdAtDate comparison
  return a.id - b.id;
};

const Mutations = ({
  productVersion,
  isLoaded,
  lastPage,
  loadMoreMutations,
  mutations,
  searching,
  searchQuery,
  today,
  latestImport,
}: Props) => {
  if (searching) {
    return <LoadingAnimation />;
  }

  if (mutations.size === 0) {
    if (searchQuery) {
      return <div>{I18n.nt(ns, 'no_results_html')}</div>;
    }

    return <div>{I18n.nt(ns, `no_mutations.${productVersion}_html`)}</div>;
  }

  const sortedMutations = Array.from(mutations.values())
    .sort(sortMutations)
    .reverse();

  const byDate = groupBy(sortedMutations, 'date');
  const dates = Object.keys(byDate).sort().reverse();
  const maxDateString = dates[0];

  return (
    <div className="c-mutations">
      {dates.map((date) => (
        <div key={date} className="mutation-group">
          <div className="tw-flex">
            <div className="date">
              {date === today ? I18n.nt(ns, 'today') : dateFormat(date)}
            </div>
            <div className="tw-ml-auto">
              {date === maxDateString && latestImport && (
                <div className="tw-pr-3 tw-pt-[3px] tw-text-[13px] tw-text-gray-500">
                  {I18n.nt(ns, 'updated_until', {
                    datetime: datetimeFormat(latestImport, 'long'),
                  })}
                </div>
              )}
            </div>
          </div>
          {productVersion === 'alfa'
            ? byDate[date].map((mutation) => (
                <AlfaMutation key={mutation.identifier} {...mutation} />
              ))
            : byDate[date].map((mutation) => (
                <Mutation key={mutation.identifier} {...mutation} />
              ))}
        </div>
      ))}
      {!lastPage &&
        (isLoaded ? (
          <div className="load-more tw-mt-2 tw-flex tw-justify-center">
            <button
              className="tw-rounded-2xl tw-border-2 tw-border-floryn-green tw-px-8 tw-py-[2px] tw-font-heading tw-text-floryn-green"
              onClick={loadMoreMutations}
            >
              {I18n.nt(ns, 'load_more')}
            </button>
          </div>
        ) : (
          <LoadingAnimation />
        ))}
    </div>
  );
};

export default Mutations;
