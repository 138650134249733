import React from 'react';
import { Alert } from 'shared/bootstrap';
import { nt } from 'shared/utils/I18n';

interface Props {
  className?: string;
}

const ServerError = ({ className }: Props) => (
  <Alert type="danger" className={className}>
    {nt('common', 'server_error')}
  </Alert>
);

export default ServerError;
