import { useSelector } from 'react-redux';
import { ReduxState } from 'types';

const useCanWithdraw = () =>
  useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard?.payload?.canWithdraw ?? false
  );

export default useCanWithdraw;
