import { thunk } from 'redux-thunk';
import promise from 'shared/redux/middleware/promise';
import normalize from 'shared/redux/middleware/normalize';
import session from 'shared/redux/middleware/session';
import { checkBundleVersionMiddleware } from 'shared/redux/middleware/checkBundleVersion';
import sentryMiddleware from 'shared/redux/middleware/sentry-for-redux';
import reportABTestsToTrackerMiddleware from 'shared/redux/middleware/reportABTestsToTrackerMiddleware';
import trackerMiddleware from 'shared/redux/middleware/trackerMiddleware';

export default [
  promise,
  normalize,
  checkBundleVersionMiddleware,
  thunk,
  session,
  reportABTestsToTrackerMiddleware,
  sentryMiddleware,
  trackerMiddleware,
];
