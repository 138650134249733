import React from 'react';
import useSelectorWithShallowCompare from 'shared/hooks/useSelectorWithShallowCompare';
import { dateFormat } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import { ReduxState } from 'types';
import AmountField from './AmountField';
import AmountFieldWithLegend from './AmountFieldWithLegend';
import DebtCollectionPanel from 'charlie/components/AccountSummary/DebtCollectionPanel';

const ns = 'charlie.frontpage.account_summary';

const DeltaSummary = () => {
  const { dashboard } = useSelectorWithShallowCompare((state: ReduxState) => ({
    dashboard: state.charlie.dashboard.payload,
  }));
  if (!dashboard) {
    return null;
  }

  const remainingAnnuityPercentage = (): number => {
    return (
      (parseFloat(dashboard.mortgageRemainingAnnuityAmount) /
        parseFloat(dashboard.creditLimit)) *
        100 +
      interestOnlyPercentage()
    );
  };

  const interestOnlyPercentage = (): number => {
    return (
      (parseFloat(dashboard.mortgageInterestOnlyComponent) /
        parseFloat(dashboard.creditLimit)) *
      100
    );
  };

  return (
    <div className="DashboardSummaryLayout">
      <div className="inner">
        <div className="top-panel">
          <div className="balance-panel">
            <AmountField
              amount={parseFloat(dashboard.mortgageRemainingDebt)}
              translationKey="remaining_debt"
              includeColon={false}
            />
          </div>
          <DebtCollectionPanel />
        </div>
        <div className="mortgage-chart">
          <div
            className="mortgage-chart-fill mortgage-chart-fill--remaining-annuity"
            style={{
              width: `${remainingAnnuityPercentage()}%`,
            }}
          />
          <div
            className="mortgage-chart-fill mortgage-chart-fill--interest-only"
            style={{
              width: `${interestOnlyPercentage()}%`,
            }}
          />
        </div>
        <div className="bottom-panel-lists">
          <div className="bottom-panel-list">
            <AmountFieldWithLegend
              amount={parseFloat(dashboard.mortgageInterestOnlyComponent)}
              translationKey="interest_only_component"
              color="rgb(13, 42, 95)"
            />
            <AmountFieldWithLegend
              amount={parseFloat(dashboard.mortgageRemainingAnnuityAmount)}
              translationKey="mortgage_remaining_annuity_amount"
              color="rgb(98, 172, 94)"
            />
            <AmountFieldWithLegend
              amount={parseFloat(dashboard.mortgageRepaidAnnuityAmount)}
              translationKey="mortgage_repaid_annuity_amount"
              color="rgb(180, 221, 193)"
            />
          </div>
          <div className="bottom-panel-list bottom-panel-list--right">
            <AmountField
              amount={parseFloat(dashboard.creditLimit)}
              translationKey="total_mortgage"
            />
            {dashboard.mortgageCurrentTerm !== null ? (
              <div>
                <span>
                  {I18n.nt(ns, 'mortgage_duration', {
                    current: dashboard.mortgageCurrentTerm,
                    total: dashboard.mortgageDuration,
                  })}
                </span>
              </div>
            ) : null}
            <div>
              <span>
                {I18n.nt(ns, 'mortgage_end_date')}
                {': '}
                {dateFormat(dashboard.mortgageEndDate)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeltaSummary;
