import { useForm } from 'react-hook-form';
import { Attachment, AlfaSeller } from 'types';
import { useCurrentSeller } from 'namespaces/shared/hooks';
import { updateSeller } from 'namespaces/shared/actions/seller';
import useAppDispatch from 'shared/hooks/useAppDispatch';

interface FormData {
  exampleInvoices: Attachment[];
}

/*
 * useExampleInvoicesModal is custom hook for managing the form for entering
 * example invoice files for a seller during the onboarding. It will read the
 * initial values from the seller in the redux state, and on submission, will
 * update the seller immediately on the server using the `updateUser` action.
 */
const useExampleInvoicesModal = (onSubmit: () => void) => {
  const dispatch = useAppDispatch();
  const seller = useCurrentSeller() as AlfaSeller;
  const { control, formState, handleSubmit } = useForm<FormData>({
    defaultValues: {
      exampleInvoices: seller.exampleInvoices,
    },
  });
  const submit = ({ exampleInvoices }) => {
    dispatch(
      updateSeller({
        exampleInvoices: exampleInvoices.map((invoice) => invoice.id),
      })
    );
    onSubmit();
  };

  return {
    control,
    handleSubmit: handleSubmit(submit),
    isSubmittable: !formState.isSubmitting,
  };
};

export default useExampleInvoicesModal;
