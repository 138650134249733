import React, { useState } from 'react';
import classNames from 'classnames';
import { Collapse, Table } from 'shared/bootstrap';
import { formatIban, toEuro } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import { MutationInvoice, DashboardChildMutation } from '../../../../types';

const ns = 'app.mutations';

const mutationClasses = (bankEntry, mutationType) =>
  classNames(
    'mutation-row',
    mutationType ? mutationType.replace(/\.|_/g, '-') : '',
    { collapsable: bankEntry }
  );

interface Props {
  amount: string;
  vatAmount: string | null;
  bankEntry: any | null;
  invoice: MutationInvoice | null;
  childMutations: DashboardChildMutation[] | null;
  description: string | null;
  mutationType: string | null;
  title: string;
}

const AlfaMutation = ({
  amount,
  vatAmount,
  bankEntry,
  invoice,
  childMutations,
  description,
  mutationType,
  title,
}: Props) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div
      className={mutationClasses(bankEntry, mutationType)}
      onClick={() => setCollapsed(!collapsed)}
    >
      <div className="description-and-amount">
        <div className="description-panel">
          <div>
            <span>{title} </span>
            {invoice && (
              <a href={`/klant/factuur/${invoice.id}`}>{invoice.reference}</a>
            )}
          </div>
          <div className="details">
            <span>{description}</span>
            {vatAmount !== null ? (
              <span>
                {I18n.nt(ns, 'vat_detail', { vat: toEuro(vatAmount) })}
              </span>
            ) : null}
          </div>
        </div>
        <div className="amount-panel">
          {amount && <div>{toEuro(amount)}</div>}
        </div>
      </div>
      {(bankEntry || childMutations) && (
        <Collapse in={!collapsed}>
          <dl className="details bank-entry child-mutations">
            {bankEntry && (
              <>
                <dt>{I18n.attribute('bank_entry', 'contra_iban')}</dt>
                <dd>{formatIban(bankEntry.contraIban)}</dd>
                <dt>{I18n.attribute('bank_entry', 'description')} </dt>
                <dd>
                  {bankEntry.description || I18n.nt(ns, 'no_description')}
                </dd>
                {bankEntry.eref && (
                  <>
                    <dt>{I18n.attribute('bank_entry', 'eref')}</dt>
                    <dd>{bankEntry.eref}</dd>
                  </>
                )}
              </>
            )}

            {invoice && (
              <>
                <dt>{I18n.attribute('invoice', 'reference')}</dt>
                <dd>
                  {(invoice.reference && (
                    <a href={`/klant/factuur/${invoice.id}`}>
                      {invoice.reference}
                    </a>
                  )) ||
                    I18n.nt(ns, 'no_description')}
                </dd>
              </>
            )}

            {childMutations && (
              <Table>
                <thead>
                  <tr>
                    <th>{I18n.attribute('invoice', 'reference')}</th>
                    <th>{I18n.nt(ns, 'matched_amount')}</th>
                  </tr>
                </thead>
                <tbody>
                  {childMutations.map(
                    (mutation) =>
                      mutation &&
                      mutation.invoice && (
                        <tr key={mutation.id}>
                          <td>
                            <a href={`/klant/factuur/${mutation.invoice.id}`}>
                              {mutation.invoice.reference}
                            </a>
                          </td>
                          <td>{toEuro(mutation.amount)}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </Table>
            )}
          </dl>
        </Collapse>
      )}
    </div>
  );
};

export default AlfaMutation;
