import { asyncAction } from 'shared/utils/Helpers';

export const LOAD_TWIKEY_SESSION = asyncAction(
  'signup.debtCollectionAuthorization.LOAD_TWIKEY_SESSION'
);

export const loadTwikeySession = () => ({
  normalize: false,
  promise: (client) => client('GET', '/api/twikey_session'),
  type: LOAD_TWIKEY_SESSION,
});
