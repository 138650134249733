import React from 'react';
import { nt } from 'shared/utils/I18n';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { toEuro } from 'shared/utils/Helpers';
import { Seller } from 'types';

const ns = 'signup.onboarding.withdrawal_for_service_fee_info';

const WithdrawalForServiceFeeInfo = () => {
  const seller = useCurrentSeller() as Seller;

  const creditDecision = seller.creditDecision;
  const creditLimit = creditDecision.creditLimit
    ? parseFloat(creditDecision.creditLimit)
    : 0.0;
  const serviceFeeRate = creditDecision.serviceFeeRate
    ? parseFloat(creditDecision.serviceFeeRate)
    : 0.0;
  const withdrawalFeeRate = creditDecision.withdrawalFeeRate
    ? parseFloat(creditDecision.withdrawalFeeRate)
    : 0.0;

  if (serviceFeeRate === 0 || creditLimit === 0 || withdrawalFeeRate > 0) {
    return null;
  }

  return (
    <div className="CostExplanation">
      <h4>{nt(ns, 'title')}</h4>
      <ul>
        <li>
          {nt(ns, 'explanation1_html', {
            limit: toEuro(creditLimit, 0),
          })}
        </li>
        <li>{nt(ns, 'explanation2')}</li>
      </ul>
    </div>
  );
};

export default WithdrawalForServiceFeeInfo;
