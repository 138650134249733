import React from 'react';
import { Col, Row } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import CheckboxInput from 'hookForm/CheckboxInput';
import TextInput from 'hookForm/TextInput';
import { Alert } from 'shared/components/tailwind/Alert';
import { Control } from 'react-hook-form';
import { FormValues } from 'shared/components/GuaranteeForm/GuaranteeForm';

interface Props {
  control: Control<FormValues>;
  guaranteeRequiresCoSigner?: 'true' | 'false';
  t: (key: string) => string;
}

const CoSignerFields = ({ control, guaranteeRequiresCoSigner, t }: Props) => {
  if (guaranteeRequiresCoSigner === undefined) {
    return null;
  }

  if (guaranteeRequiresCoSigner === 'true') {
    return (
      <div className="CoSignerFields">
        <Row>
          <Col md={6}>
            <TextInput
              control={control}
              label={I18n.label('contracting/guarantee', 'coSignerName')}
              name="coSignerName"
              placeholder="A.L.B. Jansen-Boer"
            />
          </Col>
          <Col md={6}>
            <TextInput
              control={control}
              label={I18n.label('contracting/guarantee', 'coSignerEmail')}
              name="coSignerEmail"
              placeholder="a.jansen-boer@example.com"
            />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="CoSignerFields">
        <Alert type="info">
          <CheckboxInput
            control={control}
            label={t('confirm_no_partner')}
            name="confirmNoPartner"
          />
        </Alert>
      </div>
    );
  }
};

export default CoSignerFields;
