import 'whatwg-fetch';

import client, { Result } from 'shared/utils/client';
import { camelizeKeys } from 'shared/utils/humpsInflection';
import { Remote } from 'types';

export interface AisLogin {
  host: string;
  token: string;
}

export interface CorsResponse {
  payload: any;
  statusCode: number;
}

export const loadAisLogin = (
  raiseError = true,
  sellerId?: number | undefined
): Promise<Result<AisLogin>> => {
  return client('GET', '/api/session/ais_login', { sellerId }, { raiseError });
};

export const aisRequest = (path: string): Promise<CorsResponse> =>
  loadAisLogin().then(({ payload }) =>
    aisRequestWithLogin(payload as AisLogin, path)
  );

export const aisRequestRemote = <T>(
  path: string,
  sellerId?: number | undefined
): Promise<Remote<T>> =>
  loadAisLogin(true, sellerId)
    .then(({ payload }) => aisRequestWithLogin(payload as AisLogin, path))
    .then(
      ({ payload, statusCode }) => {
        if (statusCode >= 200 && statusCode <= 299) {
          return { type: 'success', response: payload };
        } else {
          return {
            type: 'failure',
            error: { type: 'bad_status', status: statusCode },
          };
        }
      },
      () => {
        return { type: 'failure', error: { type: 'network_error' } };
      }
    );

export const aisRequestWithLogin = (
  login: AisLogin,
  path: string
): Promise<CorsResponse> => {
  const url = `${login.host}${path}`;
  const method = 'GET';
  const headers = new Headers({
    Authorization: `Bearer ${login.token}`,
  });
  const options = {
    method,
    headers,
  };

  return fetch(url, options).then(parseReponse, (e) => {
    throw e;
  });
};

const getPayload = (response: Response) =>
  response.json().then((json) => camelizeKeys(json));

const parseReponse = (response: Response) =>
  getPayload(response).then((payload) => ({
    payload,
    statusCode: response.status,
  }));
