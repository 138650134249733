import React from 'react';
import { nt } from '../utils';
import { Questions } from 'types/Seller';

const ns = 'signup.onboarding.without_contract';
const t = nt(ns);

interface Props {
  questions: Questions;
}

const QuestionsIntroduction = ({ questions }: Props) => {
  const openInquiryQuestions =
    questions.inquiry.status === 'unanswered_questions';
  const openRequirementQuestions =
    questions.requirement.status === 'unanswered_questions';

  const firstSentence = () => {
    if (openInquiryQuestions && openRequirementQuestions) {
      return t('introduction_questions.both');
    } else if (openInquiryQuestions) {
      return t('introduction_questions.inquiry_only', {
        count: questions.inquiry.unansweredCount,
      });
    } else if (openRequirementQuestions) {
      return t('introduction_questions.requirement_only', {
        count: questions.requirement.unansweredCount,
      });
    }
    return '';
  };

  return (
    <div>{firstSentence() + ' ' + t('introduction_questions.general')}</div>
  );
};

export default QuestionsIntroduction;
