import * as routes from 'app/routes';
import client, { Result } from 'shared/utils/client';
import { pushToPath } from 'shared/utils/routing';
import { asyncAction } from 'shared/utils/Helpers';
import { customerSignupSelector } from 'shared/selectors';

export const UPDATE_UBOS_IDENTIFICATION = asyncAction(
  'if.profile.UPDATE_UBOS_IDENTIFICATION'
);

export const updateUbosIdentification = () => async (dispatch, getState) => {
  dispatch({ type: UPDATE_UBOS_IDENTIFICATION.REQUEST });

  let response: Result | null = null;
  try {
    const customer_signup = customerSignupSelector(getState())!;
    response = await client(
      'POST',
      `/api/customer_signups/${customer_signup.id}/confirm_identified_ubos`
    );
  } catch (e) {
    dispatch({ type: UPDATE_UBOS_IDENTIFICATION.FAILURE, error: true, ...e });
    return e;
  }

  const successAction = dispatch({
    type: UPDATE_UBOS_IDENTIFICATION.SUCCESS,
    normalize: true,
    ...response,
  });

  dispatch(pushToPath(routes.SELLER_ONBOARDING));

  return successAction;
};
