import React from 'react';
import * as I18n from 'shared/utils/I18n';
import * as Helpers from 'shared/utils/Helpers';

const ns = 'app.invoiceStatus';

interface Props {
  invoiceState: string;
  lastActionAt: string;
}

const Finished = ({ lastActionAt, invoiceState }: Props) => {
  const formattedLastActionAt = Helpers.dateFormat(lastActionAt);

  const heading = `finished.${invoiceState}.heading`;
  const bodyHtml = `finished.${invoiceState}.bodyHtml`;

  return (
    <div>
      <div className="heading">{I18n.nt(ns, heading)}</div>
      {I18n.nt(ns, bodyHtml, { lastActionAt: formattedLastActionAt })}
      <p className="lastActionAt">
        {I18n.nt(ns, 'lastChange', { date: formattedLastActionAt })}
      </p>
    </div>
  );
};

export default Finished;
