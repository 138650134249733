import React from 'react';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const ModalBody = ({ children, className, ...props }: Props) => {
  return (
    <div {...props} className={['modal-body', className].join(' ')}>
      {children}
    </div>
  );
};

export default ModalBody;
