import React from 'react';
import { nt } from 'shared/utils/I18n';

const ns = 'signup.onboarding.proposal_summary.automated_offer';

const AutomatedOffer = () => {
  return (
    <>
      <p>{nt(ns, 'header')}</p>
      <p>{nt(ns, 'speedup')}</p>
    </>
  );
};

export default AutomatedOffer;
