import React, { useState } from 'react';
import classNames from 'classnames';
import { Collapse } from 'shared/bootstrap';
import IconTooltip from 'shared/components/IconTooltip';
import { formatIban, toEuro } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';

const ns = 'app.mutations';

const mutationClasses = (bankEntry, mutationType) =>
  classNames(
    'mutation-row',
    mutationType ? mutationType.replace(/\.|_/g, '-') : '',
    { collapsable: bankEntry }
  );

const NegativeAmountInfo = () => (
  <IconTooltip
    tooltipText={I18n.nt(ns, 'negative_amount_info')}
    iconClassName="tw-self-center"
    iconSize="sm"
  />
);

interface BalanceProps {
  amount: number;
  description: string;
  displayNegativeAmountIcon: boolean;
}

const Balance = ({
  amount,
  description,
  displayNegativeAmountIcon,
}: BalanceProps) => {
  if (amount === 0) {
    return null;
  }

  return (
    <div className="balance">
      {description} {toEuro(amount)}
      {displayNegativeAmountIcon && <NegativeAmountInfo />}
    </div>
  );
};

interface Props {
  amount: string;
  bankEntry: any | null;
  currentCommitment: string | null;
  currentLiable: string | null;
  currentTransfer: string | null;
  description: string | null;
  mutationType: string | null;
  title: string;
}

const Mutation = ({
  amount,
  bankEntry,
  currentCommitment,
  currentLiable,
  currentTransfer,
  description,
  mutationType,
  title,
}: Props) => {
  const [collapsed, setCollapsed] = useState(true);

  const currentCommitmentValue =
    currentCommitment === null ? 0.0 : parseFloat(currentCommitment);
  const currentLiableValue =
    currentLiable === null ? 0.0 : parseFloat(currentLiable);
  const currentTransferValue =
    currentTransfer === null ? 0.0 : parseFloat(currentTransfer);

  return (
    <div
      className={mutationClasses(bankEntry, mutationType)}
      onClick={() => setCollapsed(!collapsed)}
    >
      <div className="description-and-amount">
        <div className="description-panel">
          <div>{title}</div>
          <div className="details">
            <span>{description}</span>
          </div>
        </div>
        <div className="amount-panel">
          {amount && <div>{toEuro(amount)}</div>}
          {(currentCommitmentValue !== 0 ||
            currentLiableValue !== 0 ||
            currentTransferValue !== 0) && (
            <div className="commitment">
              <Balance
                amount={currentLiableValue}
                description={I18n.nt(ns, 'liable')}
                displayNegativeAmountIcon={
                  currentLiableValue < 0 && currentCommitmentValue >= 0
                }
              />
              <Balance
                amount={currentCommitmentValue}
                description={I18n.nt(ns, 'commitment')}
                displayNegativeAmountIcon={currentCommitmentValue < 0}
              />
              <Balance
                amount={currentTransferValue}
                description={I18n.nt(ns, 'transfer')}
                displayNegativeAmountIcon={false}
              />
            </div>
          )}
        </div>
      </div>
      {bankEntry && (
        <Collapse in={!collapsed}>
          <dl className="details bank-entry">
            <dt>{I18n.attribute('bank_entry', 'contra_iban')}</dt>
            <dd>{formatIban(bankEntry.contraIban)}</dd>
            <dt>{I18n.attribute('bank_entry', 'description')} </dt>
            <dd>{bankEntry.description || I18n.nt(ns, 'no_description')}</dd>
            {bankEntry.eref && (
              <>
                <dt>{I18n.attribute('bank_entry', 'eref')}</dt>
                <dd>{bankEntry.eref}</dd>
              </>
            )}
          </dl>
        </Collapse>
      )}
    </div>
  );
};

export default Mutation;
