import React from 'react';
import * as I18n from 'shared/utils/I18n';

const ns = 'charlie.frontpage.reactivation_modal.withdraw_step';

interface WithdrawIntroductionProps {
  requireBankStatements: boolean;
  requirePsd2Refresh: boolean;
}

const WithdrawIntroduction = ({
  requireBankStatements,
  requirePsd2Refresh,
}: WithdrawIntroductionProps) => {
  const withdrawalIntroductionKey = () => {
    if (requirePsd2Refresh) {
      return 'intro_psd2';
    } else if (requireBankStatements) {
      return 'intro_bank_statements';
    } else {
      return 'intro_no_action';
    }
  };

  return (
    <div className="description">
      <p>
        <strong>{I18n.nt(ns, 'intro_heading')}</strong>
      </p>
      <p>{I18n.nt(ns, withdrawalIntroductionKey())}</p>
    </div>
  );
};

export default WithdrawIntroduction;
