import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

interface Props extends HTMLAttributes<HTMLSpanElement> {
  className?: string;
  glyph: string;
}

const Glyphicon = ({ className, glyph, ...elementProps }: Props) => (
  <span
    {...elementProps}
    className={classNames('glyphicon', `glyphicon-${glyph}`, className)}
  />
);

export default Glyphicon;
