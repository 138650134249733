import * as Sentry from '@sentry/browser';
import { Middleware } from 'redux';

/* Slightly modified from https://github.com/vidit-sh/redux-sentry-middleware */

const getState = (store) => {
  try {
    return store.getState();
  } catch {
    return {};
  }
};

const sentryMiddleware: Middleware = (store) => {
  let lastAction;

  Sentry.getCurrentScope().addEventProcessor((event) => {
    const state = getState(store);

    event.extra = {
      ...event.extra,
      lastAction,
      state,
    };

    return event;
  });

  return (next) => (action) => {
    Sentry.addBreadcrumb({
      category: 'redux-action',
      message: (action as any).type,
      level: 'info',
    });

    lastAction = action;
    return next(action);
  };
};

export default sentryMiddleware;
