import React from 'react';
import { Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import usePayFeatureInfoModal from 'charlie/components/PayFeatureInfoModal/usePayFeatureInfoModal';
import Button from 'shared/components/tailwind/Button';
import { Link } from 'react-router-dom';

const ns = 'charlie.frontpage.floryn_pay_feature_info';

interface Props {
  parentPath: string;
}

const PayFeatureInfoModal = ({ parentPath }: Props) => {
  const { close, handleSubmit, isSubmitting, status } =
    usePayFeatureInfoModal(parentPath);

  return (
    <Modal className="PayFeatureInfoModal" onHide={close} show>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {status === 'error' && (
          <div className="alert alert-danger">{I18n.nt(ns, 'error')}</div>
        )}
        <div>
          {status === 'success'
            ? I18n.nt(ns, 'success_html')
            : I18n.nt(ns, 'intro_html')}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link
          to="/assets/floryn_pay.pdf"
          target="_blank"
          className="btn btn-primary-inverted"
        >
          {I18n.nt(ns, 'view_brochure')}
        </Link>
        {status === 'success' ? (
          <Button className="btn-primary" onClick={close}>
            {I18n.nt(ns, 'close')}
          </Button>
        ) : (
          <Button
            className="btn-primary"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {I18n.nt(ns, 'action')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default PayFeatureInfoModal;
