import { ReviewStatus } from 'types/Review';
import useReviewInfo from 'shared/hooks/useReviewInfo';

// Review status plus the expiredAndReceived status: the review is expired and a pdf is uploaded
export type ReviewStatusForProduct = ReviewStatus | 'expiredAndReceived';

const useReviewStatusForProduct = (): ReviewStatusForProduct => {
  const review = useReviewInfo();

  if (!review) {
    return 'finished';
  }

  switch (review.status) {
    case 'finished':
    case 'open':
    case 'received':
    case 'incomplete':
      return review.status;

    case 'expired':
      if (review.bankStatementAttachmentIds.length === 0) {
        return 'expired';
      } else {
        return 'expiredAndReceived';
      }

    default:
      return 'finished';
  }
};

export default useReviewStatusForProduct;
