import React, { useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

import {
  DateStringPicker,
  dateToString,
} from 'shared/components/DateStringPicker';
import * as I18n from 'shared/utils/I18n';
import InputWrapper from './InputWrapper';

const ns = 'form.dateRangePicker';

interface Props<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  maxDate?: Date;
}

const DateRangePickerInput = <TFieldValues extends FieldValues>({
  control,
  name,
  maxDate,
}: Props<TFieldValues>) => {
  const {
    field: {
      onBlur,
      onChange,
      value: { startDate, endDate },
    },
    fieldState: { error },
  } = useController<TFieldValues>({ control, name });
  const [hasFocus, setHasFocus] = useState(false);

  const endDateId = `${name}-end-date`;
  const startDateId = `${name}-start-date`;

  const handleFocus = () => setHasFocus(true);
  const handleBlur = () => {
    setHasFocus(false);
    onBlur();
  };

  return (
    <div className="DateRangePicker">
      <InputWrapper
        id={startDateId}
        invalid={Boolean(error) && !hasFocus}
        label={I18n.nt(ns, 'from')}
        name={name}
      >
        <DateStringPicker
          className="form-control"
          dateFormat="dd-MM-yyyy"
          disabledKeyboardNavigation
          endDate={endDate}
          id={startDateId}
          maxDate={maxDate ? dateToString(maxDate) : undefined}
          onBlur={handleBlur}
          onChange={(startDate) => onChange({ endDate, startDate })}
          onFocus={handleFocus}
          selected={startDate}
          selectsStart
          startDate={startDate}
        />
      </InputWrapper>
      <InputWrapper
        id={endDateId}
        invalid={Boolean(error) && !hasFocus}
        label={I18n.nt(ns, 'until')}
        name={name}
      >
        <DateStringPicker
          className="form-control"
          dateFormat="dd-MM-yyyy"
          disabledKeyboardNavigation
          endDate={endDate}
          id={endDateId}
          maxDate={maxDate ? dateToString(maxDate) : undefined}
          onBlur={handleBlur}
          onChange={(endDate) => onChange({ endDate, startDate })}
          onFocus={handleFocus}
          selected={endDate}
          selectsEnd
          startDate={startDate}
        />
      </InputWrapper>
    </div>
  );
};

export default DateRangePickerInput;
