import React from 'react';
interface Props {
  children?: React.ReactNode;
  className?: string;
}

const ModalFooter = ({ children, className, ...props }: Props) => {
  return (
    <div {...props} className={['modal-footer', className].join(' ')}>
      {children}
    </div>
  );
};

export default ModalFooter;
