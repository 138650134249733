import React from 'react';

import * as I18n from 'shared/utils/I18n';
import { formatIban } from 'shared/utils/Helpers';
import { BankStatementAttachment } from 'types/Attachment';

const ns = 'app.partner_signup.overview_step';

interface Props {
  bankStatement: BankStatementAttachment;
}

const bankLogo = (bank) => {
  if (!bank || bank === 'unknown' || bank === 'unsupported') {
    return <i>{I18n.nt(ns, 'unknown')}</i>;
  }

  return <img src={`/images/banks/${bank}.svg`} />;
};

const PdfStatement = ({ bankStatement }: Props) => (
  <div className="Account">
    <div className="logo">{bankLogo(bankStatement.bank)}</div>
    <div className="name">{bankStatement.fileName}</div>
    <div className="iban">{formatIban(bankStatement.iban)}</div>
    <div className="date">{bankStatement.diagnostics?.date}</div>
  </div>
);

export default PdfStatement;
