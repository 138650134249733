import React from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import * as I18n from 'shared/utils/I18n';
import { invoiceCounts } from 'types/AppState';

const filters = ['draft', 'assessment', 'open', 'overdue', 'paid', 'withdrawn'];

interface Props {
  invoiceCounts: invoiceCounts;
  selectStatusFilter: (filter: string) => (dispatch) => void;
  selectedStatusFilter: string;
}

const StatusFilters = ({
  invoiceCounts,
  selectStatusFilter,
  selectedStatusFilter,
}: Props) => {
  const dispatch = useDispatch();

  const count = (filter, counts) => {
    if (filter === 'all') {
      return null;
    } else {
      const count = counts[filter] || 0;

      return <span className="count">&nbsp;({count})</span>;
    }
  };

  const statusFilter = (filter) => {
    return (
      <li
        className={classNames(
          { active: selectedStatusFilter === filter },
          `invoice-status-${filter}`
        )}
        key={filter}
        onClick={() => selectStatusFilter(filter)(dispatch)}
      >
        <span className="name">
          {I18n.ut(`app.invoices.filters.${filter}`)}
        </span>
        {count(filter, invoiceCounts)}
      </li>
    );
  };

  return (
    <ul className="statusFilters">
      {statusFilter('all')}
      {filters.map(statusFilter)}
    </ul>
  );
};

export default StatusFilters;
