import { Attachment } from 'types';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { AnswerQuestion } from './useQuestions';
import Question from './question';

interface FormValues {
  answer?: string;
  answerFiles?: Attachment[];
}

type Status = '' | 'no_answer' | 'requesting' | 'error';

interface Parameters {
  answerQuestion: AnswerQuestion;
  question: Question;
}

const useOpenQuestion = ({ answerQuestion, question }: Parameters) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      answer: question.answer || '',
      answerFiles: question.answerFiles,
    },
  });

  const [status, setStatus] = useState<Status>('');

  const submit = async ({ answer, answerFiles }: FormValues) => {
    setStatus('requesting');

    if (!answer && !answerFiles?.length) {
      setStatus('no_answer');
    } else {
      await answerQuestion({
        answer,
        answerFiles,
        id: question.id,
      }).catch(() => {
        setStatus('error');
      });
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(submit),
    isSubmitting,
    status,
  };
};

export default useOpenQuestion;
