import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import IconTooltip from 'shared/components/IconTooltip';

import * as Helpers from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';

const t = (subject) => I18n.nt('app.invoicesDashboard', subject);

const InvoicesDashboard = ({ status, ...dashboard_data }) =>
  status === 'loaded' && <InvoicesDashboardWithData {...dashboard_data} />;

InvoicesDashboard.propTypes = {
  status: PropTypes.string.isRequired,
  currentAccount: PropTypes.object,
  invoices: PropTypes.object,
  limit: PropTypes.object,
};

export default InvoicesDashboard;

const InvoicesDashboardWithData = ({ currentAccount, invoices, limit }) => (
  <div className="InvoicesDashboard">
    <DebtorSaldo
      overdueAmount={invoices.overdue}
      debtorSaldo={invoices.debtorSaldo}
    />
    <LimitPanel currentLimit={limit.current} maximumLimit={limit.maximum} />
    <CurrentAccount
      currentAccount={currentAccount.amount}
      saldo={currentAccount.saldo}
    />
  </div>
);

InvoicesDashboardWithData.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  invoices: PropTypes.object.isRequired,
  limit: PropTypes.object.isRequired,
};

const DashboardPanel = ({
  amount1,
  amount2,
  className,
  icon,
  line_percentage,
  title1,
  title2,
  tooltipText,
}) => (
  <div className={classNames('DashboardPanel', className)}>
    {tooltipText && <IconTooltip tooltipText={tooltipText} icon={icon} />}
    <div className="title title1">{title1}</div>
    <div className="amount-large">{Helpers.toEuro(amount1)}</div>
    <div className="line">
      <div className="inner-line" style={{ width: `${line_percentage}%` }} />
    </div>
    <span className="title">{title2}</span>
    <span className="amount-small">{Helpers.toEuro(amount2)}</span>
  </div>
);

DashboardPanel.propTypes = {
  amount1: PropTypes.number.isRequired,
  amount2: PropTypes.number.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  line_percentage: PropTypes.number.isRequired,
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

const DebtorSaldo = ({ overdueAmount, debtorSaldo }) => (
  <DashboardPanel
    amount1={overdueAmount}
    amount2={debtorSaldo}
    line_percentage={Helpers.calculatePercentage(overdueAmount, debtorSaldo)}
    title1={t('overdue')}
    title2={t('debtorSaldo')}
  />
);

DebtorSaldo.propTypes = {
  overdueAmount: PropTypes.number.isRequired,
  debtorSaldo: PropTypes.number.isRequired,
};

const LimitPanel = ({ currentLimit, maximumLimit }) => (
  <DashboardPanel
    amount1={currentLimit}
    amount2={maximumLimit}
    line_percentage={Helpers.calculatePercentage(currentLimit, maximumLimit)}
    title1={t('currentLimit')}
    title2={t('maximumLimit')}
  />
);

LimitPanel.propTypes = {
  currentLimit: PropTypes.number.isRequired,
  maximumLimit: PropTypes.number.isRequired,
};

const getToolTipProps = (saldo) => {
  if (saldo === 0) {
    return {};
  } else if (saldo < 0) {
    return {
      tooltipText: t('toPayHelp'),
    };
  } else {
    return {
      tooltipText: t('toReceiveHelp'),
    };
  }
};

const getCurrentAccountClassName = (saldo) => {
  if (saldo === 0) {
    return 'receive-zero';
  } else if (saldo < 0) {
    return 'to-pay';
  } else {
    return 'to-receive';
  }
};

const getCurrentAccountPercentage = (currentAccount, saldo) => {
  if (saldo === 0) {
    return 100;
  } else if (saldo < 0) {
    return Helpers.calculatePercentage(
      Math.abs(saldo),
      Math.abs(currentAccount)
    );
  } else {
    return Helpers.calculatePercentage(
      Math.abs(saldo),
      Math.abs(currentAccount) + Math.abs(saldo)
    );
  }
};

const CurrentAccount = ({ currentAccount, saldo }) => (
  <DashboardPanel
    amount1={Math.abs(saldo)}
    amount2={currentAccount}
    className={getCurrentAccountClassName(saldo)}
    icon={saldo >= 0 ? 'info-light' : 'alert'}
    line_percentage={getCurrentAccountPercentage(currentAccount, saldo)}
    title1={saldo >= 0 ? t('toReceive') : t('toPay')}
    title2={t('currentAccount')}
    {...getToolTipProps(saldo)}
  />
);

CurrentAccount.propTypes = {
  currentAccount: PropTypes.number.isRequired,
  saldo: PropTypes.number.isRequired,
};
