import { Guarantee } from 'types';

export type LoginStatus =
  | ''
  | 'loading'
  | 'error'
  | 'expired_token'
  | 'logged_in';

export type SignStatus = 'needs_cosigner' | 'not_signed' | 'signed' | '';

export const getSignStatus = (guarantee: Guarantee): SignStatus => {
  if (guarantee.signed) {
    if (guarantee.guaranteeRequiresCoSigner && !guarantee.coSignerSigned) {
      return 'needs_cosigner';
    } else {
      return 'signed';
    }
  } else {
    return 'not_signed';
  }
};
