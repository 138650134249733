import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  title: string;
  value: string;
  subText?: string;
}

const SummaryBlock = ({ children, title, value, subText }: Props) => {
  return (
    <div className="tw-flex tw-flex-col tw-rounded-lg tw-border tw-py-4 tw-text-center xs:tw-py-6 xs:tw-shadow-lg">
      <div className="tw-mx-auto tw-h-9 tw-w-9 tw-text-floryn-green">
        {children}
      </div>

      <div className="tw-mt-4">{title}</div>
      <div className="tw-whitespace-nowrap tw-text-xl tw-font-bold">
        {value}
      </div>
      {subText && <div className="tw-whitespace-nowrap">{subText}</div>}
    </div>
  );
};

export default SummaryBlock;
