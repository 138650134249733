import { useEffect, useState } from 'react';
import client from 'shared/utils/client';
import Remote, {
  notAsked,
  pending,
  success,
  failureFromError,
} from 'types/Remote';

/* Hook that loads data from the server.

Parameters
* path: url on server
* params: query parameters (optional)

Result:
* { type: 'not_asked' }  before doing the query.
* { type: 'pending' }  the query is pending.
* { type: 'failure', error: HttpError }  there was an eror
* { type: 'success', response: }  the query was successful

Details:
* The type parameter is the type of the reponse data.
* The query is cancelled when the component is disconnected.
* When the path parameter is empty, no query is performed.
  * This can be useful because you can't call a hook conditionally.

Example:

interface User {
  email: string;
  name: string;
}

const response = useResource<User>('/api/user');

switch (response.type) {
  case 'not_asked':
  case 'pending':
    console.log('pending');
    break;
  case 'success':
    console.log(response.response);
    break;
  case 'failure':
    console.log('error', response.error);
    break;
}
*/
function useResource<T>(path: string, params?: Record<string, unknown>) {
  const [response, setResponse] = useState<Remote<T>>(notAsked());

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      setResponse(pending());
      try {
        const { payload } = await client('GET', path, params);
        if (!ignore) setResponse(success(payload));
      } catch (error) {
        setResponse(failureFromError(error));
      }
    }

    if (path) {
      fetchData();
    } else {
      setResponse(notAsked());
    }

    return () => {
      ignore = true;
    };
  }, [path, params]);

  return response;
}

export default useResource;
