import { IbanInfo } from 'shared/components/UploadBankStatements/IbanInfoList';
import { uniq } from 'lodash';
import { MissingPeriod } from 'types/Review';
import { Review } from 'types';
import { BankStatementAttachment } from 'types/Attachment';
import { isBankStatementAttachment } from 'models/Attachment';

function getUploadedIbans(
  bankStatementAttachments: BankStatementAttachment[]
): string[] {
  return uniq(
    bankStatementAttachments
      .map((attachment) => {
        if (attachment && isBankStatementAttachment(attachment)) {
          return attachment.iban || '';
        } else {
          return '';
        }
      })
      .filter((iban) => iban !== '')
  );
}

export function getMissingPeriodsPerIban(
  missingPeriods: MissingPeriod[]
): Map<string, MissingPeriod[]> {
  const missingPeriodsPerIban = new Map<string, MissingPeriod[]>();

  missingPeriods.forEach((missingPeriod) => {
    const periodsForIban = missingPeriodsPerIban.get(missingPeriod.iban) || [];
    periodsForIban.push(missingPeriod);
    missingPeriodsPerIban.set(missingPeriod.iban, periodsForIban);
  });

  return missingPeriodsPerIban;
}

export function requiredAndOtherIbans(
  review: Review,
  bankStatementAttachments: BankStatementAttachment[]
): {
  requiredIbans: IbanInfo[];
  otherIbans: IbanInfo[];
} {
  const { missingPeriods, requiredIbans, fullyAvailableAccounts } = review;
  const uploadedIbans = getUploadedIbans(bankStatementAttachments); // This only retrieves the ibans from the cat scan, not the uploaded ibans themselves
  const aisIbans = fullyAvailableAccounts.map((account) => account.iban);

  const aisIbansSet = new Set(aisIbans);
  const uploadedIbansSet = new Set(uploadedIbans);
  const requiredIbansSet = new Set(requiredIbans);
  const uploadedButNotRequiredIbans = uniq(
    [...uploadedIbans, ...aisIbansSet].filter(
      (iban) => !requiredIbansSet.has(iban)
    )
  );

  const missingPeriodsPerIban = getMissingPeriodsPerIban(missingPeriods);

  const createIbanInfo = (iban: string) => ({
    iban,
    missingPeriods: missingPeriodsPerIban.get(iban) || [],
    uploaded: aisIbansSet.has(iban) || uploadedIbansSet.has(iban),
  });

  const requiredIbanInfoList = requiredIbans.slice().sort().map(createIbanInfo);
  const otherIbanInfoList = uploadedButNotRequiredIbans
    .slice()
    .sort()
    .map(createIbanInfo);

  return {
    requiredIbans: requiredIbanInfoList,
    otherIbans: otherIbanInfoList,
  };
}
