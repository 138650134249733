import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import Button from 'shared/components/tailwind/Button';
import LoadingAnimation from 'shared/components/LoadingAnimation';

const ns = 'app.profilePage';

const ChangePasswordRequest = ({
  email,
  loading,
  requestChangePassword,
  requested,
}) => {
  if (loading) {
    return <LoadingAnimation />;
  } else if (!requested) {
    return (
      <Col lg={6}>
        <h4>{I18n.nt(ns, 'changePassword.title')}</h4>
        <p className="instructions">{I18n.nt(ns, 'changePassword.text')}</p>
        <Button className="btn-primary" onClick={requestChangePassword}>
          {I18n.nt(ns, 'changePassword.button')}
        </Button>
      </Col>
    );
  } else {
    return (
      <Col lg={6}>
        <h4>{I18n.nt(ns, 'changePassword.title')}</h4>
        <Alert type="warning">
          {I18n.nt(ns, 'changePassword.confirmationText', { email })}
        </Alert>
      </Col>
    );
  }
};

ChangePasswordRequest.propTypes = {
  email: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  requestChangePassword: PropTypes.func.isRequired,
  requested: PropTypes.bool.isRequired,
};

export default ChangePasswordRequest;
