import React from 'react';
import { Table } from 'shared/bootstrap';
import { dateFormat, toEuro } from 'shared/utils/Helpers';
import Section from 'FinancialDashboard/Insights/Section';
import { translate } from 'shared/utils/I18n';

const t = translate('insights.tax_transactions_table');

const TaxTransactionsTable = ({ title, transactions }) => {
  return (
    <Section title={title}>
      {transactions.length === 0 ? (
        <div className="ti-p-10">{t('no_transactions')}</div>
      ) : (
        <Table responsive>
          <thead>
            <tr>
              <th>{t('date')}</th>
              <th>{t('period')}</th>
              <th>{t('type')}</th>
              <th>{t('total')}</th>
              <th>{t('tax_nr')}</th>
              <th>{t('delay_in_days')}</th>
              <th>{t('description')}</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, i) => (
              <tr key={i} className="odd:tw-bg-gray-50">
                <td>{dateFormat(transaction.transactionDate)}</td>
                <td>{dateFormat(transaction.taxPeriod)}</td>
                <td>{transaction.type}</td>
                <td>{toEuro(transaction.amount)}</td>
                <td>{transaction.taxReferenceNumber}</td>
                <td>{transaction.delayInDays}</td>
                <td>{transaction.description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Section>
  );
};

export default TaxTransactionsTable;
