import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import * as I18n from 'shared/utils/I18n';
import TimeOut from './TimeOut';

const Overlay = ({ children }) => {
  return <div className="Overlay">{children}</div>;
};

interface LoadingInfoProps {
  timedOut: boolean;
  connectionMethod: 'psd2' | 'statements';
}

export const LoadingInfo = ({
  timedOut,
  connectionMethod,
}: LoadingInfoProps) => {
  const ns = `signup.onboarding.financial_dashboard.loading_popup`;
  const connectionNs = `${ns}.${connectionMethod}`;
  return (
    <div className="Loading">
      {timedOut && <TimeOut />}
      <h3> {I18n.nt(ns, 'title')}</h3>
      <ul>
        <li>{I18n.nt(connectionNs, 'check_1')}</li>
        <li className={classNames({ Loader: !timedOut })}>
          {I18n.nt(connectionNs, 'check_2')}{' '}
        </li>
        <li>{I18n.nt(connectionNs, 'check_3')}</li>
        <li className="EuroItem">{I18n.nt(connectionNs, 'check_4')}</li>
      </ul>
    </div>
  );
};

interface MaybeLoadingProps {
  children: ReactNode;
  isLoading: boolean;
  renderLoadingComponent: () => ReactElement;
}

export const MaybeLoading = ({
  children,
  isLoading,
  renderLoadingComponent,
}: MaybeLoadingProps) => {
  if (isLoading) {
    return (
      <div className="Container">
        {renderLoadingComponent()}
        <Overlay>{children}</Overlay>
      </div>
    );
  }
  return <>{children}</>;
};
