/**
 * AddStatementsStatus is the state machine type for the AddStatements component
 * controlling what the user can do at a given time. It controls both what group
 * to display (none opened, PSD2 openend and PDF openend) and whether we are
 * curently uploading any PDFs (which excludes the other status).
 */
import * as UploadStatuses from './UploadStatuses';

export type AddStatementsStatus =
  | { status: 'none' }
  | { status: 'psd2' }
  | { status: 'pdf' }
  | { status: 'uploading'; files: UploadStatuses.UploadStatuses };

export function none(): AddStatementsStatus {
  return { status: 'none' };
}

export function psd2(): AddStatementsStatus {
  return { status: 'psd2' };
}

export function pdf(): AddStatementsStatus {
  return { status: 'pdf' };
}

export function uploading(): AddStatementsStatus {
  return { status: 'uploading', files: UploadStatuses.empty() };
}

export function isPdf(addStatementsStatus: AddStatementsStatus): boolean {
  return (
    addStatementsStatus.status === 'pdf' ||
    addStatementsStatus.status === 'uploading'
  );
}

export function isPsd2({ status }: AddStatementsStatus): boolean {
  return status === 'psd2';
}

export function isUploading(addStatementsStatus: AddStatementsStatus): boolean {
  return (
    addStatementsStatus.status === 'uploading' &&
    UploadStatuses.someInProgress(addStatementsStatus.files)
  );
}

function mapUploadFiles(
  addStatementsStatus: AddStatementsStatus,
  fun: (
    uploadStatuses: UploadStatuses.UploadStatuses
  ) => UploadStatuses.UploadStatuses
) {
  if (addStatementsStatus.status === 'uploading') {
    addStatementsStatus.files = fun(addStatementsStatus.files);
    return addStatementsStatus;
  } else {
    return addStatementsStatus;
  }
}

export function addUpload(
  addStatementsStatus: AddStatementsStatus,
  id: string,
  file: File
) {
  return mapUploadFiles(addStatementsStatus, (f) =>
    UploadStatuses.addUpload(f, id, file)
  );
}

export function failUpload(
  addStatementsStatus: AddStatementsStatus,
  id: string,
  file: File,
  reason: string
) {
  return mapUploadFiles(addStatementsStatus, (f) =>
    UploadStatuses.failUpload(f, id, file, reason)
  );
}

export function fulfillUpload(
  addStatementsStatus: AddStatementsStatus,
  id: string,
  file: File
) {
  return mapUploadFiles(addStatementsStatus, (f) =>
    UploadStatuses.fulfillUpload(f, id, file)
  );
}

/*
 * Invoke given function with the number of uploading files, if the given status
 * is uploading.
 */
export function whenUploading<T>(
  addStatementsStatus: AddStatementsStatus,
  fun: (uploadStatuses: UploadStatuses.UploadStatuses) => T
): T | undefined {
  if (addStatementsStatus.status === 'uploading') {
    return fun(addStatementsStatus.files);
  } else {
    return undefined;
  }
}
