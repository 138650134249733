import { useForm } from 'react-hook-form';
import { Attachment, Seller } from 'types';
import { useCurrentSeller } from 'namespaces/shared/hooks';
import { updateSeller } from 'namespaces/shared/actions/seller';
import useAppDispatch from 'shared/hooks/useAppDispatch';

interface FormData {
  propertyAddress: string;
  valuationReports: Attachment[];
}

/*
 * useAddressForm is custom hook for managing the form for entering an address
 * and valuation reports for a seller during the onboarding. It will read the
 * initial values from the seller in the redux state, and on submission, will
 * update the seller immediately on the server using the `updateUser` action.
 */
const useAddressForm = (onSubmit: () => void) => {
  const dispatch = useAppDispatch();
  const seller = useCurrentSeller() as Seller;
  const { control, formState, handleSubmit } = useForm<FormData>({
    defaultValues: {
      valuationReports: seller.valuationReports,
      propertyAddress: seller.propertyAddress || '',
    },
  });
  const submit = ({ propertyAddress, valuationReports }) => {
    dispatch(
      updateSeller({
        propertyAddress,
        valuationReports: valuationReports.map((a) => a.id),
      })
    );
    onSubmit();
  };

  return {
    control,
    handleSubmit: handleSubmit(submit),
    isSubmittable: !formState.isSubmitting,
  };
};

export default useAddressForm;
