import React from 'react';

import { nt } from 'shared/utils/I18n';
import { Button, Modal } from 'shared/bootstrap';
import CostsSummary from 'shared/components/InvoiceCostsSummary';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import ServerError from 'namespaces/shared/components/ServerError';
import { useCurrentSeller, useResource } from 'shared/hooks';
import { Invoice } from 'types';

const ns = 'app.invoices.request_financing_modal';

interface Props {
  invoiceId?: number;
  onApprove: () => void;
  onCancel: () => void;
}

export default ({ invoiceId, onApprove, onCancel }: Props) => {
  const seller = useCurrentSeller();
  const url = invoiceId ? `/api/invoices/${invoiceId}` : '';

  const response = useResource<Invoice>(url);

  if (!invoiceId) {
    return null;
  }

  const invoice = response.type === 'success' ? response.response : null;

  return (
    <Modal show={Boolean(invoiceId)} onHide={onCancel}>
      <Modal.Header closeButton>
        <h4 className="modal-title">
          {invoice && nt(ns, 'title', { reference: invoice.reference })}
        </h4>
      </Modal.Header>
      <Modal.Body className="InvoiceSummary">
        {response.type === 'pending' && <LoadingAnimation />}
        {response.type === 'failure' && <ServerError />}
        {invoice && (
          <>
            <CostsSummary invoice={invoice} seller={seller} />
            <p>{nt(ns, 'confirmation')}</p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {invoice && (
          <>
            <Button className="btn-light" onClick={onCancel}>
              {nt(ns, 'cancel')}
            </Button>
            <Button className="btn-primary" onClick={onApprove}>
              {nt(ns, 'submit')}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
