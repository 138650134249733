import * as I18n from 'shared/utils/I18n';
import { Attachment } from 'types';

/*
 * The debtor list task contains all the information needed for the form or
 * task panel to be displayed.
 *
 * Note that the name of the list of uploaded document IDs is important: it is
 * used in the form used to upload files, and therefore used to post to the
 * API, and therefore translated into a association name on the Review model.
 */
export type Task = {
  debtorLists: Array<Attachment>;
  debtorListDate: Date;
  debtorListTotal: number;
};

/*
 * Validation errors for a task, consisting of an optional string value for each
 * key.
 */
type Errors = Partial<Record<keyof Task, string>>;

/*
 * The main debtor list type combines whether the debtor list is required at
 * all, and if it is, what its contents are.
 */
export type DebtorList =
  | { type: 'not_applicable' }
  | { type: 'empty' }
  | ({ type: 'invalid' } & Partial<Task>)
  | ({ type: 'valid' } & Task);

// Constructor functions

/*
 * Create an object containing the data that will be submitted.
 */
export const getSubmitData = (debtorList): any => ({
  debtor_lists: debtorList.debtorLists?.map(({ id }) => id),
  debtor_list_total: debtorList.debtorListTotal,
  debtor_list_date: debtorList.debtorListDate,
});

/*
 * Returns `true` if the debtor list data is valid,
 * and `false` otherwise.
 */
export const isSubmittable = (debtorList: DebtorList): boolean =>
  ['not_applicable', 'valid'].includes(debtorList.type);

export const notApplicable = (): DebtorList => ({ type: 'not_applicable' });

export const empty = (): DebtorList => ({ type: 'empty' });

export const valid = (task: Task): DebtorList => ({ type: 'valid', ...task });

export const invalid = (task: Partial<Task>): DebtorList => ({
  type: 'invalid',
  ...task,
});

// Query functions

export const isEmpty = ({
  debtorListDate,
  debtorListTotal,
  debtorLists,
}: Partial<Task>): boolean =>
  !debtorListDate &&
  !debtorListTotal &&
  (!debtorLists || debtorLists.length === 0);

export const isValid = (
  debtorList: DebtorList
): debtorList is { type: 'valid' } & Task => debtorList.type === 'valid';

export const isInvalid = (
  debtorList: DebtorList
): debtorList is { type: 'invalid' } & Partial<Task> =>
  debtorList.type === 'invalid';

export const isApplicable = ({ type }: DebtorList): boolean =>
  type !== 'not_applicable';

// Factory functions

/*
 * Given a review entity from the API, build a new DebtorList value. This
 * includes parsing string values into date and number instances.
 */
export const fromReview = (review): DebtorList => {
  if (!review.uploadDebtorList) {
    return notApplicable();
  }
  const { debtorListDate, debtorListTotal, debtorLists } = review;
  const task = {
    debtorListDate: debtorListDate ? new Date(debtorListDate) : undefined,
    debtorListTotal: debtorListTotal ? parseFloat(debtorListTotal) : undefined,
    debtorLists: debtorLists,
  };
  if (!hasErrors(task)) {
    return valid(task as Task);
  }
  if (isEmpty(task)) {
    return empty();
  }
  return invalid(task);
};

/*
 * Create a task suitable for loading into a redux form from an existing
 * DebtorList value.
 */
export const toTask = (debtorList: DebtorList): Partial<Task> => {
  switch (debtorList.type) {
    case 'invalid':
    case 'valid':
      return {
        debtorListDate: debtorList.debtorListDate,
        debtorListTotal: debtorList.debtorListTotal,
        debtorLists: debtorList.debtorLists,
      };
    default:
      return {};
  }
};

// Validation

/*
 * Validate the contents of a debtor list form, returning an empty object when
 * valid or an error message per invalid field if not.
 */
export const validate = ({
  debtorLists,
  debtorListDate,
  debtorListTotal,
}: Partial<Task>): Errors => {
  const errors: Errors = {};

  if (!debtorLists || debtorLists.length === 0) {
    errors.debtorLists = I18n.t('errors.messages.empty');
  }

  if (!debtorListDate) {
    errors.debtorListDate = I18n.t('errors.messages.empty');
  }

  if (!debtorListTotal) {
    errors.debtorListTotal = I18n.t('errors.messages.empty');
  }

  return errors;
};

const hasErrors = (task: Partial<Task>): boolean =>
  Object.values(validate(task)).length > 0;

/*
 * Given a valid or invalid task from a form, turn it into an appropriate
 * DebtorList value.
 */
export const toDebtorList = (task: Partial<Task>): DebtorList => {
  if (hasErrors(task)) {
    return { type: 'invalid', ...task };
  }
  return { type: 'valid', ...(task as Task) };
};
