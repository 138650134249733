import { pick, values } from 'lodash';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types';

const useInvoiceContact = (ids: number[]) => {
  const allInvoiceContacts = useSelector(
    (state: ReduxState) => state.entities.invoiceContacts
  );

  return ids ? values(pick(allInvoiceContacts, ids)) : [];
};

export default useInvoiceContact;
