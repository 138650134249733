import React, { useEffect, useMemo, useState } from 'react';
import { DASHBOARD, FINANCIAL_DASHBOARD } from 'app/routes';
import { CUSTOMER_APP_ROOT } from 'app/routes';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import { useResource } from 'shared/hooks';
import { trackEvent } from 'shared/utils/tracker';
import { translate } from 'shared/utils/I18n';
import Page from './Page';
import Layout from './Layout';
import NotFound from './NotFound';
import { TransactionInsights } from './TransactionInsights';
import { TimeFilter } from './TimeFilterDropdown';
import EmptyPage from './EmptyPage';
import Header from './Header';
import Router from './Router';

interface Props {
  type: 'charlie' | 'lead' | 'onboarding';
  compact?: boolean;
  hideDefaultLoadingAnimation?: boolean;
  processing?: boolean;
  showHealthRating?: boolean;
}

const InsightsError = ({ error }) => {
  useEffect(() => {
    trackEvent('FinancialDashboard.Error', { error });
  }, [error]);

  return <NotFound />;
};

const InsightsLoading = () => {
  useEffect(() => {
    trackEvent('FinancialDashboard.Loading');
  });
  const t = translate('insights.loading');

  return (
    <EmptyPage cover>
      <LoadingAnimation />
      <p>{t('message')}</p>
    </EmptyPage>
  );
};

const InsightsProcessing = () => {
  useEffect(() => {
    trackEvent('FinancialDashboard.Processing');
  });

  return <EmptyPage cover />;
};

const InsightsLoaded = ({
  data,
  months,
  setMonths,
  type,
  showHealthRating,
}) => {
  useEffect(() => {
    trackEvent('FinancialDashboard.Loaded', { months, type });
  }, [data]);

  switch (type) {
    case 'charlie':
      return (
        <Router baseRoute={DASHBOARD} backUrl={CUSTOMER_APP_ROOT}>
          <Page
            months={months}
            setMonths={setMonths}
            showHealthRating={showHealthRating}
            {...data}
          />
        </Router>
      );

    default:
      return (
        <Router baseRoute={FINANCIAL_DASHBOARD}>
          <Page
            months={months}
            setMonths={setMonths}
            showHealthRating={showHealthRating}
            {...data}
          >
            <Header />
          </Page>
        </Router>
      );
  }
};

const Insights = ({ type, showHealthRating }) => {
  const [months, setMonths] = useState<TimeFilter>('12');
  const params = useMemo(() => ({ from: months }), [months]);
  const response = useResource<TransactionInsights>(
    '/charlie/insights',
    params
  );

  switch (response.type) {
    case 'failure':
      return <InsightsError error={response.error} />;
    case 'success':
      return (
        <InsightsLoaded
          data={response.response}
          months={months}
          setMonths={setMonths}
          type={type}
          showHealthRating={showHealthRating}
        />
      );
    default:
      return <InsightsLoading />;
  }
};

const Wrapped = ({
  type,
  compact = false,
  processing = false,
  showHealthRating = false,
}: Props) => {
  return (
    <Layout compact={compact}>
      {processing ? (
        <InsightsProcessing />
      ) : (
        <Insights type={type} showHealthRating={showHealthRating} />
      )}
    </Layout>
  );
};

export default Wrapped;
