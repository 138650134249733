import aisRedirector from 'shared/components/Consent/AisRedirector';
import { Partner, ReduxState, Seller } from 'types';
import Psd2Banks, { Props } from './Component';
import { connect } from 'react-redux';
import { partnerSelector, mainSellerSelector } from 'shared/selectors';

interface StateProps {
  partner: Partner | null;
  seller: Seller;
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  partner: partnerSelector(state),
  seller: mainSellerSelector(state) as Seller,
});

export default connect(mapStateToProps)(aisRedirector<Props>(Psd2Banks));
