import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  className?: string;
}

const ShadowBox = ({ children, className }: Props) => (
  <div
    className={`tw-grow tw-rounded-md tw-shadow-[0_0_6px_4px_rgba(0,0,0,0.07)] ${className}`}
  >
    {children}
  </div>
);

export default ShadowBox;
