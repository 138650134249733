import React from 'react';

import SmallSignupProgress from './SmallSignupProgress';
import MediumSignupProgress from './MediumSignupProgress';

const SignupProgress = () => (
  <>
    <div className="md:tw-hidden">
      <SmallSignupProgress />
    </div>
    <div className="tw-hidden md:tw-block">
      <MediumSignupProgress />
    </div>
  </>
);

export default SignupProgress;
