import React from 'react';
import { Button, Modal } from 'shared/bootstrap';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import * as I18n from 'shared/utils/I18n';
import DisplayAnnouncements from 'shared/components/DisplayAnnouncements';
import ServerError from 'shared/components/ServerError';
import { State } from './types';
import { Partner } from 'types';

const ns = 'shared.psd2_banks.psd2_announcement';

interface Props {
  bank: string | null;
  onHide: () => void;
  onSubmit: () => void;
  partner: Partner | null;
  state: State;
}

const Psd2Announcement = ({
  bank,
  onHide,
  onSubmit,
  partner,
  state,
}: Props) => {
  const bankName = bank ? I18n.nt(ns, `bank_name.${bank}`) : '';

  return (
    <Modal className="Psd2Announcement" show={Boolean(bank)} onHide={onHide}>
      <Modal.Header closeButton />
      <Modal.Body>
        <DisplayAnnouncements keys={['psd2.start.' + bank]} />
        <div className="bank-logo">
          {bank && <img src={`/images/banks/${bank}.svg`} />}
        </div>
        <p>{I18n.nt(ns, 'introduction_html', { bankName })}</p>
        {partner && (
          <p>
            {I18n.nt(ns, 'partner_addition_html', {
              partner: partner.companyName,
              days_available: partner.financialDashboardDaysAvailable,
            })}
          </p>
        )}
        {state === 'error' && <ServerError />}
        {state === 'redirecting' && <LoadingAnimation />}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-primary"
          onClick={onSubmit}
          disabled={state === 'redirecting'}
        >
          {I18n.nt(ns, 'submit', { bankName })}
          <span className="icon icon-arrow-right2" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Psd2Announcement;
