import React from 'react';
import classNames from 'classnames';

import * as I18n from 'shared/utils/I18n';
import { ContactsInputValue } from './types';

interface Props {
  contact: ContactsInputValue;
  name: string;
  selected: boolean;
  toggleContact: (contact: ContactsInputValue) => void;
}

const ContactListItem = ({ contact, name, selected, toggleContact }: Props) => (
  <div className={classNames('list-group-item', { active: selected })}>
    <label
      className={classNames('tw-font-normal', selected ? 'checked' : '')}
      htmlFor={name}
    >
      <span className={'tw-mr-2'}>
        <input
          type="checkbox"
          id={name}
          name={name}
          checked={selected}
          onChange={() => toggleContact(contact)}
        />
      </span>
      <span>
        {contact.fullName} ({contact.email}){' '}
        <strong className="locale">{I18n.nt('app', contact.locale)}</strong>
      </span>
    </label>
  </div>
);

export default ContactListItem;
