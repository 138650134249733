import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import * as Helpers from 'shared/utils/Helpers';

const GAmountSummary = ({ amount, gAmount }) => {
  return (
    <Row>
      <div>
        <dt>{I18n.attribute('invoice', 'gAmount')}</dt>
        <dd>{Helpers.toEuro(gAmount)}</dd>
      </div>
      <div>
        <dt>{I18n.attribute('invoice', 'ifAmount')}</dt>
        <dd>{Helpers.toEuro(amount - gAmount)}</dd>
      </div>
    </Row>
  );
};

GAmountSummary.propTypes = {
  amount: PropTypes.string.isRequired,
  gAmount: PropTypes.string.isRequired,
};
export default GAmountSummary;
