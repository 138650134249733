import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SELLER_ONBOARDING } from 'app/routes';
import { pushToPath } from 'shared/utils/routing';
import { currentSellerSelector } from 'shared/selectors';
import BankTransactionsImportModal from './Component';
import { load as loadReview } from 'shared/modules/review';
import { reload } from 'FinancialDashboard/financialDashboardSlice';
import { setBankStatementUploadType } from 'signup/actions/SellerSignup';
import { Review, ReduxState, Seller } from 'types';
import * as selectors from 'signup/selectors';
import { BankStatementUploadType } from 'types/BankStatementUploadType';

interface Props {
  bankStatementUploadType: BankStatementUploadType;
  closeUploadModal: () => void;
  loadReview: (sellerId: number) => void;
  reloadFinancialDashboardStatus: () => void;
  review: Review | null;
  seller: Seller;
  setBankStatementUploadType: (type: BankStatementUploadType) => void;
}

class BankTransactionsImportModalContainer extends React.Component<Props> {
  public render() {
    if (this.props.review) {
      return (
        <BankTransactionsImportModal
          {...this.props}
          review={this.props.review}
        />
      );
    } else {
      return null;
    }
  }

  public componentDidMount() {
    this.props.loadReview(this.props.seller.id);
  }
}

const mapStateToProps = (state: ReduxState) => {
  const seller = currentSellerSelector(state) as Seller;
  const review = state.charlie.review.payload;
  const bankStatementUploadType = selectors.bankStatementUploadType(state);

  return {
    bankStatementUploadType,
    review,
    seller,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeUploadModal: () => pushToPath(SELLER_ONBOARDING),
      loadReview,
      reloadFinancialDashboardStatus: reload,
      setBankStatementUploadType,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankTransactionsImportModalContainer);
