import React from 'react';
import Badge from './Badge';
import { IbanTask } from './modules/IbanTask';
import { formatIban, dateFormat } from 'shared/utils/Helpers';
import { translate } from 'shared/utils/I18n';
import Panel from './Panel';
import Warnings from './Warnings';
import { currentReviewSelector } from 'shared/selectors';
import { useSelector } from 'react-redux';
import { MissingPeriod } from 'types/Review';

const t = translate('review_wizard.iban_panel');

interface Props {
  ibanTask: IbanTask;
}

const DeliveryType = ({ ibanTask }: Props) => {
  switch (ibanTask.delivery) {
    case 'pdf':
      return <div>{t('pdf')}</div>;
    case 'psd2':
      return <div>{t('psd2')}</div>;
    case 'both':
      return <div>{t('both')}</div>;
    default:
      return <div />;
  }
};

const okOrWarning = (status) => {
  if (status === 'pending' || status == 'warnings') {
    return 'warning';
  } else {
    return 'ok';
  }
};

const fullPeriod = (review, iban): MissingPeriod => {
  return {
    iban: iban,
    startDate: review.requestedStartDate,
    endDate: review.requestedEndDate,
  };
};

const IbanPanel = ({ ibanTask }: Props) => {
  const review = useSelector(currentReviewSelector);

  return (
    <Panel
      data-testid="iban-panel"
      className="rw-sidebar rw-sidebar--left rw-gap-12"
    >
      <div>
        <Badge status={okOrWarning(ibanTask.status)} />
      </div>
      <div className="rw-sidebar rw-sidebar--right rw-items-start">
        <div>
          <div className="rw-panel__title">{formatIban(ibanTask.iban)}</div>
          <div className="rw-cluster">
            <div>
              <DeliveryType ibanTask={ibanTask} />
            </div>
            <div className="rw-truncate">
              {ibanTask.consent && ibanTask.consent.expiresOn
                ? t('expires_on', {
                    date: dateFormat(new Date(ibanTask.consent.expiresOn)),
                  })
                : ibanTask.message}
            </div>
          </div>
          <div>
            {ibanTask.status === 'expiresSoon' &&
              ibanTask.missingPeriods.length > 0 && (
                <div>
                  {t('expires_soon', {
                    date: dateFormat(
                      new Date(ibanTask.missingPeriods[0].endDate)
                    ),
                  })}
                </div>
              )}
            {ibanTask.status === 'warnings' && (
              <Warnings missingPeriods={ibanTask.missingPeriods} />
            )}
            {ibanTask.status === 'pending' && (
              <Warnings missingPeriods={[fullPeriod(review, ibanTask.iban)]} />
            )}
          </div>
        </div>
        <div className="md:text-right hidden md:block">
          {ibanTask.bank !== 'unknown' && (
            <img
              className="iban_panel__bank_logo"
              src={`/images/banks/${ibanTask.bank}.svg`}
            />
          )}
        </div>
      </div>
    </Panel>
  );
};

export default IbanPanel;
