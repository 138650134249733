import { get } from 'lodash';
import { LOCATION_CHANGE } from 'redux-first-history';
import { asyncAction } from 'shared/utils/Helpers';

const LOAD_INVOICE = 'if.invoice.LOAD_INVOICE';
const LOAD_INVOICE_SUCCESS = 'if.invoice.LOAD_INVOICE_SUCCESS';
const LOAD_INVOICE_FAILURE = 'if.invoice.LOAD_INVOICE_FAILURE';

const LOAD_SELLER = 'if.invoice.LOAD_SELLER';
const LOAD_SELLER_SUCCESS = 'if.invoice.LOAD_SELLER_SUCCESS';
const LOAD_SELLER_FAILURE = 'if.invoice.LOAD_SELLER_FAILURE';

const SAVE_INVOICE = 'if.invoice.SAVE_INVOICE';

const INVOICE_ACTION = 'if.invoice.TRANSITION';
const INVOICE_ACTION_SUCCESS = 'if.invoice.TRANSITION_SUCCESS';
const INVOICE_ACTION_FAILURE = 'if.invoice.TRANSITION_FAILURE';

const MAIL_INVOICE_CONTACT = 'if.invoice.MAIL_INVOICE_CONTACT';
const MAIL_INVOICE_CONTACT_SUCCESS = 'if.invoice.MAIL_INVOICE_CONTACT_SUCCESS';
const MAIL_INVOICE_CONTACT_FAILURE = 'if.invoice.MAIL_INVOICE_CONTACT_FAILURE';

const SUBMIT_INVOICE = asyncAction('if.invoice.SUBMIT_INVOICE');
const REQUEST_FINANCING = asyncAction('if.invoice.REQUEST_FINANCING');

const SHOW_FORM = 'if.invoice.SHOW_FORM';

export const SUBMITTED_ALERT = 'SUBMITTED_ALERT';
export const FINANCING_REQUESTED_ALERT = 'FINANCING_REQUESTED_ALERT';

const initialMailContact = {
  busy: false,
  error: null,
};

const initialState = {
  saving: false,
  performingAction: false,
  invoiceLoading: false,
  invoice: {},

  sellerLoading: false,
  seller: {},

  mailContact: initialMailContact,

  showSubmitSummary: false,
  showAlert: null,
};

export const invoiceReducer = (state = initialState, action = {}) => {
  let invoice;

  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        showAlert: null,
        showSubmitSummary: false,
      };

    case LOAD_INVOICE:
      return {
        ...state,
        invoiceLoading: true,
      };

    case LOAD_INVOICE_SUCCESS:
      invoice = action.payload.entities.invoices[action.payload.result];
      return {
        ...state,
        invoiceLoading: false,
        showSubmitSummary: invoice.allowedActions.includes('submit'),
      };

    case LOAD_INVOICE_FAILURE:
      return {
        ...state,
        invoiceLoading: false,
      };

    case LOAD_SELLER:
      return {
        ...state,
        sellerLoading: true,
      };

    case LOAD_SELLER_FAILURE:
    case LOAD_SELLER_SUCCESS:
      return {
        ...state,
        sellerLoading: false,
      };

    case SAVE_INVOICE:
      return {
        ...state,
        showSubmitSummary: action.invoice.allowedActions.includes('submit'),
      };

    case SUBMIT_INVOICE.REQUEST:
      return {
        ...state,
        performingAction: true,
      };

    case SUBMIT_INVOICE.SUCCESS:
      return {
        ...state,
        performingAction: false,
        showSubmitSummary: false,
        showAlert: SUBMITTED_ALERT,
      };

    case SUBMIT_INVOICE.FAILURE:
      return {
        ...state,
        performingAction: false,
      };

    case INVOICE_ACTION:
      return {
        ...state,
        performingAction: true,
      };

    case INVOICE_ACTION_SUCCESS:
      return {
        ...state,
        performingAction: false,
        showSubmitSummary: false,
        showAlert:
          ((action.invoiceAction === 'submit' ||
            action.invoiceAction === 'softcheck') &&
            SUBMITTED_ALERT) ||
          null,
      };

    case INVOICE_ACTION_FAILURE:
      return {
        ...state,
        performingAction: false,
      };

    case SHOW_FORM:
      return {
        ...state,
        showSubmitSummary: false,
      };

    case REQUEST_FINANCING.REQUEST:
      return {
        ...state,
        performingAction: true,
      };

    case REQUEST_FINANCING.FAILURE:
      return {
        ...state,
        performingAction: false,
      };

    case REQUEST_FINANCING.SUCCESS:
      return {
        ...state,
        showAlert: FINANCING_REQUESTED_ALERT,
        showSubmitSummary: false,
        performingAction: false,
      };

    case MAIL_INVOICE_CONTACT.REQUEST:
      return {
        ...state,
        mailContact: {
          busy: true,
          error: null,
        },
      };

    case MAIL_INVOICE_CONTACT.SAVE_SUCCESS:
      return {
        ...state,
        mailContact: {
          busy: false,
          error: null,
        },
      };

    case MAIL_INVOICE_CONTACT_FAILURE:
      return {
        ...state,
        mailContact: {
          busy: false,
          error: get(action.payload, 'validationErrors.base[0]'),
        },
      };

    default:
      return state;
  }
};

const loadInvoiceById = (id) => ({
  type: [LOAD_INVOICE, LOAD_INVOICE_SUCCESS, LOAD_INVOICE_FAILURE],
  normalize: true,
  promise: (client) => client('GET', `/api/invoices/${id}`),
});

const loadSellerById = (id) => ({
  type: [LOAD_SELLER, LOAD_SELLER_SUCCESS, LOAD_SELLER_FAILURE],
  normalize: true,
  promise: (client) => client('GET', `/api/sellers/${id}`),
});

const invoiceAction = (invoice, invoiceAction, params = {}) => ({
  type: [INVOICE_ACTION, INVOICE_ACTION_SUCCESS, INVOICE_ACTION_FAILURE],
  normalize: true,
  promise: (client) =>
    client('POST', `/api/invoices/${invoice.id}/action`, {
      invoiceAction,
      ...params,
    }),
  invoiceAction,
});

const requestFinancing = (invoiceId) => ({
  type: REQUEST_FINANCING,
  normalize: true,
  promise: (client) =>
    client('PUT', `/api/invoices/${invoiceId}/request_financing`),
});

const submitInvoice = (invoiceId, params) => ({
  type: SUBMIT_INVOICE,
  normalize: true,
  promise: (client) =>
    client('POST', `/api/invoices/${invoiceId}/submit`, params),
});

const mailInvoiceContact = (invoiceContact) => ({
  type: [
    MAIL_INVOICE_CONTACT,
    MAIL_INVOICE_CONTACT_SUCCESS,
    MAIL_INVOICE_CONTACT_FAILURE,
  ],
  normalize: true,
  promise: (client) =>
    client('POST', `/api/invoice_contacts/${invoiceContact.id}/mail`),
});

const saveInvoice = (invoice) => ({
  type: SAVE_INVOICE,
  invoice,
});

const showForm = () => ({
  type: SHOW_FORM,
});

export const actionCreators = {
  invoiceAction,
  loadInvoiceById,
  loadSellerById,
  mailInvoiceContact,
  requestFinancing,
  saveInvoice,
  showForm,
  submitInvoice,
};
