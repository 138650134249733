import React, { ReactNode } from 'react';
import { translate } from 'shared/utils/I18n';

const t = translate('insights.page');

const FakePanel = () => (
  <div className="ti-panel">
    <div className="ti-panel__header">
      <h4>
        <span className="fake-lg" />
      </h4>
    </div>
    <div className="ti-panel__body">
      <div className="fake-chart" />
    </div>
  </div>
);

interface Props {
  children?: ReactNode;
  cover?: boolean;
}

const EmptyPage = ({ cover, children }: Props) => (
  <div className="cover__container">
    {cover && (
      <React.Fragment>
        <div className="cover__bg"></div>
        <div className="cover__body" role="modal">
          {children}
        </div>
      </React.Fragment>
    )}
    {cover || children}
    <div className="ti-space-y-4">
      <div className="ti-row">
        <span className="fake-lg" />
        <h1>{t('title')}</h1>
        <span className="fake-lg" />
      </div>
      <div className="ti-panels">
        <FakePanel />
        <FakePanel />
        <FakePanel />
        <FakePanel />
        <FakePanel />
        <FakePanel />
      </div>
    </div>
  </div>
);

export default EmptyPage;
