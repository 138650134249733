/*
 * Wrap an exiting function in behaviour that prevents the default
 * behaviour of DOM events.
 */
export function preventDefault(fun) {
  return function (e) {
    e.preventDefault();
    return fun();
  };
}
