import React from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from 'shared/hooks';
import useAppDispatch from 'shared/hooks/useAppDispatch';

import EnableTwoFactorModal from './EnableTwoFactorModal';
import { twoFactorEnabled } from 'app/modules/profilePage';

function NoOp({ children }) {
  return children;
}

const EnsureTwoFactorAuthentication = ({ disabledWrapper, children }) => {
  const user = useUser();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const Wrapper = disabledWrapper || NoOp;

  if (user.twoFactorEnabled) {
    return children;
  }

  return (
    <Wrapper>
      <EnableTwoFactorModal
        hideModal={() => {
          history.goBack();
        }}
        hideOnSuccess={false}
        show={true}
        twoFactorEnabled={(phone) => dispatch(twoFactorEnabled(phone))}
        dashboardWarning={true}
      />
    </Wrapper>
  );
};

export default EnsureTwoFactorAuthentication;
