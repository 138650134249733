import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  children: ReactNode;
  className?: string;
  id?: string;
  xs?: number;
  md?: number;
  lg?: number;
  mdOffset?: number;
}

const sizeClassesForBreakpoint = (
  breakpoint: string,
  size: number | undefined
) => (size ? `col-${breakpoint}-${size}` : null);

const offsetClassesForBreakpoint = (
  breakpoint: string,
  size: number | undefined
) => (size ? `col-${breakpoint}-offset-${size}` : null);

const Col = ({ children, className, id, xs, md, mdOffset, lg }: Props) => (
  <div
    className={classNames(
      sizeClassesForBreakpoint('xs', xs),
      sizeClassesForBreakpoint('md', md),
      sizeClassesForBreakpoint('lg', lg),
      offsetClassesForBreakpoint('md', mdOffset),
      className
    )}
    id={id}
  >
    {children}
  </div>
);

export default Col;
