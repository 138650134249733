import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import { frontpageRoute } from 'shared/routes';
import {
  currentSellerSelector,
  customerAccountSelector,
} from 'shared/selectors';

const RedirectToFrontpage = () => {
  const customerAccount = useSelector(customerAccountSelector);
  const seller = useSelector(currentSellerSelector);
  const location = useLocation();

  const redirectUrl = frontpageRoute(
    customerAccount,
    seller,
    location.pathname
  );

  return <Redirect to={redirectUrl} />;
};

export default RedirectToFrontpage;
