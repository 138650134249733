import React from 'react';
import SellerActions from '../SellerActions';
import VerifyIdentity from '../VerifyIdentity';
import UltimateBeneficialOwners from '../UltimateBeneficialOwners';
import Mandate from '../Mandate';
import PropertyTaxation from '../PropertyTaxation';
import QuestionsAction from '../Questions/QuestionsAction';
import VerifyAccount from '../VerifyAccount';

import { Seller } from 'types';
import {
  bankAccountVerificationStatus,
  mandateStatus,
  propertyTaxationStatus,
  uboStatus,
  verifyIdentityStatus,
} from 'models/Onboarding';
import { isAlfa } from 'models/Seller';

const identificationAction = (seller: Seller) => {
  if (!seller.readyForIdentification) {
    return <VerifyIdentity status="Pending" />;
  } else if (seller.eligibleForDirectIdentification) {
    return <VerifyIdentity status={verifyIdentityStatus(seller)} />;
  }
  return <UltimateBeneficialOwners status={uboStatus(seller)} />;
};

interface Props {
  basePath: string;
  seller: Seller;
}

const SubmittedActions = ({ basePath, seller }: Props) => (
  <SellerActions>
    {identificationAction(seller)}
    {!isAlfa(seller) && (
      <Mandate basePath={basePath} status={mandateStatus(seller)} />
    )}
    {seller.productVersion === 'delta' && (
      <PropertyTaxation
        basePath={basePath}
        status={propertyTaxationStatus(seller)}
      />
    )}
    {!isAlfa(seller) && (
      <VerifyAccount
        basePath={basePath}
        seller={seller}
        status={bankAccountVerificationStatus(seller)}
      />
    )}
    {seller.questions.inquiry.status === 'all_questions_answered' && (
      <QuestionsAction basePath={basePath} status="Done" kind="inquiry" />
    )}
    {seller.questions.requirement.status === 'all_questions_answered' && (
      <QuestionsAction basePath={basePath} status="Done" kind="requirement" />
    )}
  </SellerActions>
);

export default SubmittedActions;
