import React from 'react';
import { Modal } from 'shared/bootstrap';
import { User } from 'types';
import * as I18n from 'shared/utils/I18n';
import ChangeUserPermissionsForm from './ChangeUserPermissionsForm';

interface Props {
  afterSavePermissions: (user: User) => void;
  currentUser: User | null;
  onHide: () => void;
  show: boolean;
}

const ChangeUserPermissionsModal = ({
  afterSavePermissions,
  currentUser,
  onHide,
  show,
}: Props) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>
        {I18n.nt('charlie.users.change_permissions', 'title', {
          user_name: currentUser?.fullName || '',
        })}
      </Modal.Title>
    </Modal.Header>
    {currentUser && (
      <ChangeUserPermissionsForm
        afterSavePermissions={afterSavePermissions}
        onHide={onHide}
        user={currentUser}
      />
    )}
  </Modal>
);

export default ChangeUserPermissionsModal;
