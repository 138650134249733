import React from 'react';
import { useSelector } from 'react-redux';
import { truncate } from 'lodash';

import Support from 'shared/components/DefaultSidebar/Support';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import useUser from 'shared/hooks/useUser';
import { nt } from 'shared/utils/I18n';
import { contractSelector } from 'shared/selectors';
import OnboardingProgressStep from './OnboardingProgressStep';
import { Seller } from 'types';

const ns = 'signup.onboarding.onboarding_progress';

const OnboardingProgress = () => {
  const seller = useCurrentSeller() as Seller;
  const { email } = useUser();
  const contract = useSelector(contractSelector);
  const lastStep = 5;
  const finishedStep = contract ? 4 : 3;

  return (
    <div className="OnboardingProgress tw-hidden tw-grid-cols-[min-content_auto] tw-gap-x-4 tw-gap-y-5 md:tw-grid">
      <OnboardingProgressStep
        stepIndex={1}
        lastStep={lastStep}
        finishedStep={finishedStep}
        title={nt(ns, 'title1')}
        description={nt(ns, 'description1_html', {
          email: truncate(email, { length: 40 }),
        })}
      />
      <OnboardingProgressStep
        stepIndex={2}
        lastStep={lastStep}
        finishedStep={finishedStep}
        title={nt(ns, 'title2')}
        description={nt(ns, 'description2')}
      />
      <OnboardingProgressStep
        stepIndex={3}
        lastStep={lastStep}
        finishedStep={finishedStep}
        title={nt(ns, 'title3')}
        description={nt(ns, 'description3')}
      />
      <OnboardingProgressStep
        stepIndex={4}
        lastStep={lastStep}
        finishedStep={finishedStep}
        title={nt(ns, 'title4')}
        description={nt(ns, 'description4')}
      />
      <OnboardingProgressStep
        stepIndex={5}
        lastStep={lastStep}
        finishedStep={finishedStep}
        title={nt(ns, `title5_${seller.productVersion}`)}
        description={nt(ns, `description5_${seller.productVersion}`)}
      />
      <div />
      <div className="tw-mt-8">
        <Support />
      </div>
    </div>
  );
};

export default OnboardingProgress;
