import React from 'react';

import TextInput from 'hookForm/TextInput';
import useChangeEmailRequest from './useChangeEmailRequest';

import { Alert, Col } from 'shared/bootstrap';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import ServerError from 'shared/components/ServerError';
import Button from 'shared/components/tailwind/Button';
import * as I18n from 'shared/utils/I18n';

const ns = 'app.profilePage.changeEmail';

const ChangeEmailRequest = () => {
  const { control, handleSubmit, isSubmitting, newEmail, status } =
    useChangeEmailRequest();

  return (
    <Col lg={6} className="change-email">
      <h4>{I18n.nt(ns, 'title')}</h4>
      {status === 'error' && <ServerError />}
      {status === 'requesting' && <LoadingAnimation />}
      {status === 'requested' && (
        <Alert type="warning">{I18n.nt(ns, 'text', { value: newEmail })}</Alert>
      )}
      {status === '' && (
        <form onSubmit={handleSubmit}>
          <p>{I18n.nt(ns, 'intro')}</p>
          <TextInput
            control={control}
            label={I18n.nt(ns, 'new_email')}
            name="newEmail"
            type="email"
          />
          <TextInput
            control={control}
            label={I18n.nt(ns, 'new_email_confirmation')}
            name="newEmailConfirmation"
            type="email"
          />
          <Button type="submit" className="btn-primary" disabled={isSubmitting}>
            {I18n.nt(ns, 'submit')}
          </Button>
        </form>
      )}
    </Col>
  );
};

export default ChangeEmailRequest;
