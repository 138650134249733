import React from 'react';
import { nt, attribute } from 'shared/utils/I18n';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

const financing_filters = [
  'all',
  'not_requested',
  'requested',
  'financed',
  'finished',
  'rejected',
];

const t = (name) => nt('app.invoices.finance-filters', name);
const translateFinancingState = (financingState) => {
  if (financingState === 'all') return t('all');

  return attribute('invoice', `financing_states.${financingState}`);
};

const icon = (financingState) =>
  classNames(
    'icon',
    financingState === 'all'
      ? 'icon-all-invoices'
      : `icon-financing-${financingState.replace('_', '-')}`
  );

interface Props {
  mustDisplayTitle: boolean;
  selectedFinancingFilter: string;
  selectFinancingFilter: (filter: string) => (dispatch) => void;
}

const FinancingFilters = ({
  mustDisplayTitle = true,
  selectedFinancingFilter,
  selectFinancingFilter,
}: Props) => {
  const dispatch = useDispatch();

  return (
    <div className="FinancingFilterList">
      {mustDisplayTitle && <h3>{t('title')}</h3>}
      <ul>
        {financing_filters.map((financingState) => (
          <li
            className={
              financingState === selectedFinancingFilter ? 'active' : ''
            }
            key={financingState}
            onClick={() => selectFinancingFilter(financingState)(dispatch)}
          >
            <span className={icon(financingState)} />
            {translateFinancingState(financingState)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FinancingFilters;
