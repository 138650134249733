import React from 'react';

import Action from '../Action';
import { Contract, OnboardingActionStatus, Seller } from 'types';
import { mainSigner, nt } from '../utils';
import useContract from 'shared/hooks/useContract';

const t = nt('signup.onboarding.contract');

interface Props {
  contract: Contract;
  seller: Seller;
  status: OnboardingActionStatus;
}

const ContractAction = ({ contract, seller, status }: Props) => {
  const { requestStatus, redirectToSignUrl } = useContract(seller.id);

  if (!contract) {
    return null;
  }

  const { contractType } = contract;
  const manual = contractType === 'manual';
  const signer = mainSigner(contract);
  const signed = signer ? signer.signed : contract.state === 'signed';

  const descriptionKey = `description.${manual ? 'manual' : 'auto'}.${
    signed ? 'signed' : 'published'
  }`;

  const description = t(descriptionKey);

  const Button = () => {
    if (manual || signed) {
      return null;
    }

    return (
      <button
        className="btn btn-block btn-primary-inverted btn-sm btn-fixed-narrow btn-signup"
        disabled={requestStatus === 'loading'}
        onClick={redirectToSignUrl}
        type="button"
      >
        {t('button')}
      </button>
    );
  };

  return (
    <Action
      button={Button}
      onClickButton={redirectToSignUrl}
      description={description}
      errorMessage={requestStatus === 'error' ? t('errorMessage') : undefined}
      status={status}
      title={t('title')}
    />
  );
};

export default ContractAction;
