import React, { ReactNode } from 'react';
import { nt } from 'shared/utils/I18n';
import CheckRoundSvg from 'svg/check_round.svg';
import GoogleSvg from 'svg/google.svg';

const ns = 'app.sidebars.advantages';

interface BulletLineProps {
  children?: ReactNode;
}

const BulletLine = ({ children }: BulletLineProps) => (
  <li className="tw-flex tw-items-center tw-gap-x-2">
    <CheckRoundSvg className="tw-h-5 tw-w-5 tw-fill-floryn-green lg:tw-h-6 lg:tw-w-6" />
    {children}
  </li>
);

const Advantages = () => (
  <ul className="tw-mb-0 tw-ml-0 tw-flex tw-list-none tw-flex-col tw-gap-y-6">
    <BulletLine>
      <span className="tw-text-base tw-font-bold md:tw-text-sm lg:tw-text-base">
        {nt(ns, 'bullet_quick')}
      </span>
    </BulletLine>
    <BulletLine>
      <span className="tw-text-base tw-font-bold md:tw-text-sm lg:tw-text-base">
        {nt(ns, 'bullet_requirements')}
      </span>
    </BulletLine>
    <BulletLine>
      <span className="tw-text-base tw-font-bold md:tw-text-sm lg:tw-text-base">
        {nt(ns, 'bullet_companies')}
      </span>
    </BulletLine>
    <BulletLine>
      <div className="tw-flex tw-items-center tw-gap-x-2">
        <span className="tw-text-base tw-font-bold md:tw-text-sm lg:tw-text-base">
          {nt(ns, 'bullet_service')}
        </span>
        <GoogleSvg className="tw-w-5" />
        <div className="tw-h-[22px] tw-w-[105px] tw-bg-[url('/images/star_inactive.svg')] tw-bg-[length:21px_21px]">
          <div className="tw-h-[22px] tw-w-[93px] tw-bg-[url('/images/star_active.svg')] tw-bg-[length:21px_21px]" />
        </div>
      </div>
    </BulletLine>
  </ul>
);

export default Advantages;
