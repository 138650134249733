const steps = [
  'bedrijfsgegevens',
  'bankgegevens',
  'aanvullende-gegevens',
  'ubo-identificatie',
] as const;

export type SignupStep = (typeof steps)[number];

export const firstStep = 'bedrijfsgegevens';

export const isValidStep = (step: string) => steps.includes(step as SignupStep);

export const previousStep = (currentStep: string): SignupStep | undefined => {
  switch (currentStep) {
    case 'bankgegevens':
      return 'bedrijfsgegevens';

    case 'aanvullende-gegevens':
      return 'bankgegevens';

    case 'ubo-identificatie':
      return 'aanvullende-gegevens';

    default:
      return undefined;
  }
};

export const currentStepIndex = (
  currentStep: string | undefined
): number | undefined => {
  switch (currentStep) {
    case 'bedrijfsgegevens':
      return 1;

    case 'bankgegevens':
      return 2;

    case 'aanvullende-gegevens':
      return 3;

    case 'ubo-identificatie':
      return 4;

    default:
      return undefined;
  }
};

export const nextStep = (currentStep: string): SignupStep | undefined => {
  switch (currentStep) {
    case 'bedrijfsgegevens':
      return 'bankgegevens';
    case 'bankgegevens':
      return 'aanvullende-gegevens';
    case 'aanvullende-gegevens':
      return 'ubo-identificatie';

    default:
      return undefined;
  }
};
