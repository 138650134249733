import React from 'react';
import { nt } from 'shared/utils/I18n';

import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { Seller } from 'types';
import { toEuro, toPercentage } from 'shared/utils/Helpers';

const ns = 'signup.onboarding.closing_commission_info';

const ClosingCommisionInfo = () => {
  const seller = useCurrentSeller() as Seller;
  const creditDecision = seller.creditDecision;

  const closingCommissionRate = creditDecision.closingCommissionRate
    ? parseFloat(creditDecision.closingCommissionRate)
    : 0.0;
  const closingCommissionAmount = creditDecision.closingCommissionAmount
    ? parseFloat(creditDecision.closingCommissionAmount)
    : 0.0;

  if (closingCommissionRate === 0 || closingCommissionAmount === 0) {
    return null;
  }

  return (
    <div className="CostExplanation">
      <h4>{nt(ns, 'title')}</h4>
      <ul>
        <li>
          {nt(ns, 'explanation1_html', {
            amount: toEuro(closingCommissionAmount),
            percentage: toPercentage(closingCommissionRate),
          })}
        </li>
        <li>{nt(ns, 'explanation2')}</li>
      </ul>
    </div>
  );
};

export default ClosingCommisionInfo;
