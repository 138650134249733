import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from 'types';
import { BankStatementAttachment } from 'types/Attachment';
import { gracePeriodABTestEnabled } from 'models/Seller';
import * as I18n from 'shared/utils/I18n';
import IbanInfoList, { IbanInfo } from './IbanInfoList';
import EarlyRefreshExplanation from './EarlyRefreshExplanation';
import { currentSellerSelector } from 'shared/selectors';
import { requiredAndOtherIbans } from 'shared/utils/requiredIbans';

const ns = 'shared.required_ibans';

interface Props {
  requiredIbans: IbanInfo[];
  otherIbans: IbanInfo[];
  sellerIsInGracePeriodGroup: boolean;
}

const RequiredIbans = ({
  requiredIbans,
  otherIbans,
  sellerIsInGracePeriodGroup,
}: Props) => (
  <div className="RequiredIbans">
    {sellerIsInGracePeriodGroup && (
      <EarlyRefreshExplanation requiredIbans={requiredIbans} />
    )}
    {requiredIbans.length !== 0 && (
      <>
        <p>{I18n.nt(ns, 'required_ibans_intro')}</p>
        <IbanInfoList ibanInfoList={requiredIbans} />
      </>
    )}
    {otherIbans.length !== 0 && (
      <div className="OtherIbans">
        {requiredIbans.length !== 0 && <p>{I18n.nt(ns, 'other_ibans')}</p>}
        <IbanInfoList ibanInfoList={otherIbans} />
      </div>
    )}
  </div>
);

const mapStateToProps = (
  state: ReduxState,
  {
    bankStatementAttachments,
  }: { bankStatementAttachments: BankStatementAttachment[] }
) => {
  const review = state.charlie.review.payload;
  const seller = currentSellerSelector(state);

  if (!review || !seller) {
    return {
      requiredIbans: [],
      otherIbans: [],
      sellerIsInGracePeriodGroup: false,
    };
  } else {
    return {
      ...requiredAndOtherIbans(review, bankStatementAttachments),
      sellerIsInGracePeriodGroup: gracePeriodABTestEnabled(seller),
    };
  }
};

export default connect(mapStateToProps)(RequiredIbans);
