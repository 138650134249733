import React, { useImperativeHandle, useRef } from 'react';
import { RefCallBack } from 'react-hook-form';

function useSharedRef<T>(
  hookFormRef: RefCallBack,
  inputRef?: React.RefObject<T>
) {
  const internalRef = useRef<T>(null);
  const activeRef = inputRef || internalRef;

  useImperativeHandle(hookFormRef, () => activeRef.current);

  return activeRef;
}

export default useSharedRef;
