import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  load as loadSession,
  authenticateWithToken,
} from 'shared/redux/modules/session';
import { getQueryParam } from 'shared/utils/Helpers';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import { ReduxState } from 'types';

const useLoadSession = () => {
  const dispatch = useAppDispatch();
  const session = useSelector((state: ReduxState) => state.session);
  const location = useLocation();

  useEffect(() => {
    if (!session.loaded) {
      const token = getQueryParam(location.search, 'token');

      if (token) {
        dispatch(authenticateWithToken(token));
      } else {
        const sellerId = getQueryParam(location.search, 'seller_id');
        dispatch(loadSession(sellerId));
      }
    }
  }, [session.loaded]);

  return {
    loaded: session.loaded,
    tokenError: session.tokenError,
  };
};

export default useLoadSession;
