import { useSelector } from 'react-redux';
import { useState } from 'react';
import { ReduxState } from 'types/ReduxState';
import client from 'shared/utils/client';
import {
  loadStatus,
  selectHaveSellersWithMissingDivisions,
  selectSellers,
} from '../exactOnlineConnectionSlice';
import useAppDispatch from 'shared/hooks/useAppDispatch';

type Status = '' | 'error' | 'updating' | 'updated';

const selectHasFirstExport = (state: ReduxState) => {
  return state.exactOnlineConnection.sellers.some((seller) => {
    if (!seller.exportedStartDate) {
      return false;
    }

    if (!seller.lastExportAt) {
      return true;
    }

    // If a Seller reconnects, we do want to show the first export message again
    return new Date(seller.lastExportAt) < new Date(seller.exportedStartDate);
  });
};

const useConnected = () => {
  const [changingDivisions, setChangingDivisions] = useState<number[]>([]);
  const hasFirstExport = useSelector(selectHasFirstExport);
  const haveMissingDivisions = useSelector(
    selectHaveSellersWithMissingDivisions
  );

  const sellers = useSelector(selectSellers);
  const [status, setStatus] = useState<Status>('');

  const dispatch = useAppDispatch();

  const handleSave = async (data: {
    sellers: { id: string; currentDivision: string }[];
  }) => {
    setStatus('updating');

    if (data.sellers.length === 0) {
      setStatus('');
      return;
    }

    const response = await client(
      'POST',
      `/api/exact_online/update_division`,
      {
        sellers: data.sellers.map((seller) => {
          const convertedCode = parseInt(seller.currentDivision);
          const code = Number.isNaN(convertedCode) ? null : convertedCode;
          return { id: seller.id, code };
        }),
      },
      { acceptHtml: true, raiseError: false }
    );

    if (response.error) {
      setStatus('error');
    } else {
      setStatus('updated');
      dispatch(loadStatus());
    }
  };

  return {
    changingDivisions,
    handleSave,
    hasFirstExport,
    haveMissingDivisions,
    sellers,
    setChangingDivisions,
    status,
  };
};

export default useConnected;
