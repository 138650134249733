import React from 'react';

import { useCurrentSeller } from 'shared/hooks';
import { isCancelled } from 'models/Seller';
import useReviewInfo from 'shared/hooks/useReviewInfo';
import useReviewStatusForProduct, {
  ReviewStatusForProduct,
} from 'charlie/components/ReactivationModal/useReviewStatusForProduct';
import { Alert, AlertWithLink } from 'shared/bootstrap';
import { AlertType } from 'shared/components/tailwind/Alert';
import * as I18n from 'shared/utils/I18n';
import * as routes from 'app/routes';
import ReviewMessage from './ReviewMessage';

const ns = 'charlie.frontpage.warnings';

const getAlertType = (
  reviewStatusForProduct: ReviewStatusForProduct
): AlertType => {
  switch (reviewStatusForProduct) {
    case 'expired':
    case 'expiredAndReceived':
      return 'danger';
    case 'open':
    case 'incomplete':
      return 'warning';
    case 'received':
      return 'success';
    default:
      return 'info';
  }
};

const ReviewWarning = () => {
  const currentSeller = useCurrentSeller();
  const reviewStatusForProduct = useReviewStatusForProduct();
  const reviewInfo = useReviewInfo();

  if (isCancelled(currentSeller)) {
    return null;
  }

  if (
    !reviewInfo ||
    reviewInfo?.status === 'finished' ||
    reviewInfo?.status === 'complete'
  ) {
    return null;
  }

  const alertType = getAlertType(reviewStatusForProduct);

  if (reviewInfo.isProcessing) {
    return (
      <Alert type={alertType}>
        <ReviewMessage
          status={reviewStatusForProduct}
          reviewInfo={reviewInfo}
          seller={currentSeller}
        />
        <div className="tw-mt-2">{I18n.nt(ns, 'analysis_is_in_progress')}</div>
      </Alert>
    );
  } else {
    const actionText = I18n.nt(
      ns,
      reviewStatusForProduct === 'received' ||
        reviewStatusForProduct === 'expiredAndReceived' ||
        reviewStatusForProduct === 'incomplete'
        ? 'upload_more'
        : 'upload'
    );

    return (
      <AlertWithLink
        actionText={actionText}
        actionUrl={routes.REVIEW}
        type={alertType}
      >
        <ReviewMessage
          status={reviewStatusForProduct}
          reviewInfo={reviewInfo}
          seller={currentSeller}
        />
      </AlertWithLink>
    );
  }
};

export default ReviewWarning;
