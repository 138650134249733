import React from 'react';
import AmountField from './AmountField';

interface Props {
  amount: number;
}

const Settlement = ({ amount }: Props) => (
  <AmountField
    amount={Math.abs(amount)}
    translationKey={amount > 0 ? 'still_to_pay' : 'still_to_receive'}
  />
);

export default Settlement;
