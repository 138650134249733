import { useEffect, useState } from 'react';

import useLoadFullSellersForCustomerAccount from './useLoadFullSellersForCustomerAccount';
import useSellers from 'shared/hooks/useSellers';
import { Seller } from 'types';

type Status = '' | 'loading' | 'loaded' | 'error';

/* Loads all sellers belonging to the customer account
  based on the seller ids stored in the session
 */
const useEnsureFullSellers = (customerAccountId: number) => {
  const loadFullSellers =
    useLoadFullSellersForCustomerAccount(customerAccountId);
  const [status, setStatus] = useState<Status>('');

  const sellerEntities = useSellers();

  const sellers = sellerEntities.filter(
    (seller) => seller.entityKind === 'Seller'
  ) as Seller[];

  const handle = async () => {
    setStatus('loading');
    const result = await loadFullSellers();
    if (result) {
      setStatus('loaded');
    } else {
      setStatus('error');
    }
  };

  const fetchedSellerIds = sellers.map((seller) => seller.id);
  const areSellersLoaded =
    JSON.stringify(fetchedSellerIds.sort()) ===
    JSON.stringify(sellerEntities.map((seller) => seller.id));

  useEffect(() => {
    if (areSellersLoaded) {
      setStatus('loaded');
    } else if (status !== 'error') {
      handle();
    }
  }, []);

  return { sellers, status };
};

export default useEnsureFullSellers;
