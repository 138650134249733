import { useState } from 'react';

import client from 'shared/utils/client';
import { dateFormat } from 'shared/utils/Helpers';
import updateEntities from 'shared/modules/updateEntities';
import * as I18n from 'shared/utils/I18n';
import useEnsureFullSeller from 'namespaces/shared/hooks/useEnsureFullSeller';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import { Seller } from 'types';

const ns = 'app.profile.reactivation';

const getStatusMessage = (seller: Seller): string => {
  switch (seller.status) {
    case 'lost':
      return I18n.nt(ns, 'message_lost');

    case 'rejected':
      return I18n.nt(ns, 'message_rejected', {
        date: dateFormat(seller.lastTransitionDate),
      });

    default:
      return '';
  }
};

type SubmitStatus = '' | 'error' | 'submitted' | 'submitting' | 'success';

const useReactivation = () => {
  const seller = useCurrentSeller();
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>('');
  const dispatch = useAppDispatch();

  // Load a Seller entity if not loaded yet.
  const { seller: fullSeller, status: loadSellerStatus } =
    useEnsureFullSeller();

  const reactivate = async () => {
    if (loadSellerStatus !== 'loaded') {
      return;
    }

    setSubmitStatus('submitting');

    const response = await client(
      'post',
      `/api/sellers/${seller.id}/reactivate`,
      {},
      { raiseError: false }
    );

    if (response.error) {
      setSubmitStatus('error');
    } else {
      setSubmitStatus('success');
      dispatch(updateEntities(response.payload));
    }
  };

  const statusMessage = fullSeller ? getStatusMessage(fullSeller) : '';

  return {
    loadSellerStatus,
    reactivate,
    statusMessage,
    submitStatus,
  };
};

export default useReactivation;
