import React from 'react';
import { Attachment } from './types';
import { translate } from 'shared/utils/I18n';

const t = translate('review_wizard.uploaded_file');

interface Props {
  onDeleteAttachment: (id: number) => void;
  uploadedFile: Attachment;
}

const UploadedFile = ({ onDeleteAttachment, uploadedFile }: Props) => {
  const { fileName, deletionStatus } = uploadedFile;
  const status = deletionStatus.type;
  return (
    <li
      className={`rw-uploaded-file rw-uploaded-file--${status} rw-space-x-16 rw-items-center`}
    >
      <span className="rw-uploaded-file__filename">{fileName}</span>
      {status === 'pending' && <i className="rw-spinner" />}
      {(status === 'not_asked' || status === 'failure') && (
        <button
          className="rw-uploaded-file__delete"
          onClick={() => onDeleteAttachment(uploadedFile.id)}
        >
          <span className="rw-uploaded-file__delete-icon" />
          <span className="sr-only">{t('delete')}</span>
        </button>
      )}
    </li>
  );
};

export default UploadedFile;
