import React, { useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { generateHtmlElementId } from 'shared/utils/Helpers';
import InputWrapper from './InputWrapper';

interface Props<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  label: string;
  name: Path<TFieldValues>;
}

const CheckboxToggleInput = <TFieldValues extends FieldValues>({
  control,
  label,
  name,
}: Props<TFieldValues>) => {
  const [id] = useState(generateHtmlElementId);

  const {
    field: { onChange, ref, value: fieldValue },
  } = useController<TFieldValues>({ control, name });

  const checked = fieldValue === true;

  return (
    <InputWrapper id={id} label={label} name={name} type="checkbox">
      <div className="checkbox-toggle pull-right">
        <input
          checked={checked}
          id={id}
          onChange={onChange}
          ref={ref}
          type="checkbox"
        />
        <label htmlFor={id} />
      </div>
    </InputWrapper>
  );
};

export default CheckboxToggleInput;
