import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as I18n from 'shared/utils/I18n';
import { getQueryParam } from 'shared/utils/Helpers';
import Feedback from 'shared/components/Consent/Feedback';
import { Partner, Review } from 'types';
import { BankStatementUploadType } from 'types/BankStatementUploadType';
import { PARTNER_SIGNUP_BANK_STATEMENTS_OVERVIEW } from 'app/routes';

import ConnectWithBank from './ConnectWithBank';
import DocumentUpload from './DocumentUpload';
import MoreInformationModal from './MoreInformationModal';

const ns = 'app.partner_signup.bank_statements_step';

interface Props {
  review: Review;
  partner: Partner;
}

const BankStatementsStep = ({ review, partner }: Props) => {
  const statementsPresent =
    review.aisConsents.length > 0 ||
    review.bankStatementAttachmentIds.length > 0;
  const history = useHistory();
  const [type, setType] = useState<BankStatementUploadType>('psd2');
  const [showOverviewLink] = useState<boolean>(statementsPresent);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

  const statusFromAis = getQueryParam(location.search, 'status');

  // If there already are any consents or bank statements present,
  // display a link to navigate (back) to the overview step.
  const goToOverviewStep = (e) => {
    e.preventDefault();
    history.push(PARTNER_SIGNUP_BANK_STATEMENTS_OVERVIEW + '?status=success');
  };
  const showMoreInfoModal = (e) => {
    e.preventDefault();
    setShowInfoModal(true);
  };

  return (
    <div className="PartnerSignupBankStatementStep">
      {showOverviewLink && (
        <div className="tw-mb-9">
          <a
            href="#"
            onClick={goToOverviewStep}
            className="tw-text-black hover:tw-no-underline"
          >
            <i className="icon-arrow-left2 tw-mr-3 tw-align-text-bottom tw-text-xl tw-leading-5" />
            {I18n.nt(ns, 'to_overview')}
          </a>
        </div>
      )}
      {statusFromAis && (
        <Feedback t={(key) => I18n.nt(ns, `consentFeedback.${key}`)} />
      )}
      <h1 className="tw-mb-4">
        {I18n.nt(ns, 'title')}
        <span className="icon icon-lock tw-ml-2 tw-align-top tw-text-floryn-green" />
      </h1>
      <p>{I18n.nt(ns, 'introduction', { partner: partner.companyName })}</p>
      <p>
        {I18n.nt(ns, 'privacy_info', { partner: partner.companyName })}
        <a
          href="#"
          className="tw-text-black tw-underline"
          onClick={showMoreInfoModal}
        >
          {I18n.nt(ns, 'privacy_info_link')}
        </a>
      </p>
      {type === 'psd2' ? (
        <ConnectWithBank onSelectPdf={() => setType('documents')} />
      ) : (
        <DocumentUpload handleBankConnectionLink={() => setType('psd2')} />
      )}

      <MoreInformationModal
        show={showInfoModal}
        closeModal={() => setShowInfoModal(false)}
        companyName={partner.companyName}
        FinancialDashboardDaysAvailable={
          partner.financialDashboardDaysAvailable
        }
      />
    </div>
  );
};

export default BankStatementsStep;
