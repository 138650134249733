import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ConsentsModal from './Modal';
import { transformAccountsIntoBanks, requireAnyRefresh } from './helpers';
import { Consents, MinimalSeller, ReduxState, Remote } from 'types';
import withAisRemote from 'shared/utils/withAisRemote';
import aisRedirector, { AisRedirectorProps } from '../AisRedirector';
import ConnectModal from 'shared/components/Consent/ConnectModal';
import { mainSellerSelector } from 'shared/selectors';
import { compilePath } from 'shared/utils/routing';
import { PROFILE_PAGE_CONSENTS } from 'app/routes';

interface Props extends AisRedirectorProps {
  showPopup: boolean;
  remoteData: Remote<Consents>;
  seller: MinimalSeller;
}

type DisplayedModal = 'none' | 'consentModal' | 'connectBank';

const ConsentsFetcher = ({ showPopup, remoteData, onRefresh }: Props) => {
  const [displayedModal, setDisplayedModal] =
    useState<DisplayedModal>('consentModal');
  const [isDisabled, setDisabled] = useState(false);

  const redirectUrl = compilePath(PROFILE_PAGE_CONSENTS, {
    tabKey: 'rekeningen',
  });

  const handleRefresh = (bank) => {
    setDisabled(true);
    onRefresh(bank, redirectUrl);
  };

  const mainSeller = useSelector(
    (state: ReduxState) => mainSellerSelector(state) as MinimalSeller
  );

  let banks;
  let consents: Consents;

  switch (remoteData.type) {
    case 'success':
      consents = remoteData.response;
      banks = transformAccountsIntoBanks(consents.consents);
      return (
        <>
          {showPopup && (
            <ConsentsModal
              banks={banks}
              isDisabled={isDisabled}
              isVisible={
                requireAnyRefresh(banks, mainSeller, consents.type) &&
                displayedModal === 'consentModal'
              }
              onHide={() => setDisplayedModal('none')}
              onRefresh={handleRefresh}
              onAddAccount={() => setDisplayedModal('connectBank')}
            />
          )}
          {displayedModal === 'connectBank' && (
            <ConnectModal
              redirectPath={redirectUrl}
              onClose={() => setDisplayedModal('consentModal')}
            />
          )}
        </>
      );
    default:
      return <></>;
  }
};

export default withAisRemote(
  aisRedirector(ConsentsFetcher),
  '/v2/user/consents'
);
