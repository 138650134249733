import { addWeeks, isEqual, startOfQuarter } from 'date-fns';
import { round, sum } from 'lodash';
import { CreditDecision, Seller } from 'types';
import calculatePayments from './calculatePayments';

export const crossingQuarterNextWeek = (date: Date) =>
  !isEqual(startOfQuarter(date), startOfQuarter(addWeeks(date, 1)));

export interface Payment {
  currentDebt: number;
  debtCollection: number;
  interest: number;
}

export interface WithdrawalInfo {
  closingCommissionCosts: number;
  debtCollectionAmount: number;
  outpaymentAmount: number;
  payments: Payment[];
  paymentsValid: boolean;
  totalInterest: number;
  withdrawalCosts: number;
}

const calculateWithdrawalInfo = (
  withdrawalAmount: number,
  seller: Seller
): WithdrawalInfo => {
  const creditDecision: CreditDecision = seller.creditDecision;
  const creditLimit = creditDecision.creditLimit
    ? parseFloat(creditDecision.creditLimit)
    : 0;

  const closingCommissionCosts = round(
    creditLimit * parseFloat(creditDecision.closingCommissionRate),
    2
  );
  const debtCollectionAmount = creditDecision.debtCollectionRate
    ? round(withdrawalAmount * parseFloat(creditDecision.debtCollectionRate), 2)
    : 0;

  const withdrawalCosts = round(
    withdrawalAmount * parseFloat(creditDecision.withdrawalFeeRate),
    2
  );
  const outpaymentAmount = Math.max(
    withdrawalAmount - closingCommissionCosts - withdrawalCosts,
    0
  );

  const [paymentsValid, payments] = calculatePayments({
    debtCollectionAmount,
    creditDecision,
    withdrawalAmount,
  });

  return {
    closingCommissionCosts,
    debtCollectionAmount,
    payments,
    paymentsValid,
    outpaymentAmount,
    totalInterest: sum(payments.map((payment) => payment.interest)),
    withdrawalCosts,
  };
};

export default calculateWithdrawalInfo;
