import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import CurrencyInput from 'hookForm/CurrencyInput';
import { nt } from 'shared/utils/I18n';

const ns = 'app.signup';

const expectedRevenueLabel = () => {
  const currentDate = new Date();
  let year = currentDate.getFullYear();

  // If the current date is somewhere in december, use next year instead.
  if (currentDate.getMonth() === 11) {
    year += 1;
  }

  return nt(ns, 'expected_revenue_next_year', { year: year });
};

interface Props<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  onBlur?: (value: string) => void;
}

const ExpectedRevenueInput = <TFieldValues extends FieldValues>({
  control,
  name,
  onBlur,
}: Props<TFieldValues>) => (
  <CurrencyInput
    control={control}
    label={expectedRevenueLabel()}
    name={name}
    onBlur={onBlur}
    placeholder={nt(ns, 'rounded_to_1000_euro')}
  />
);

export default ExpectedRevenueInput;
