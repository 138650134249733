import { useSelector } from 'react-redux';
import { userSelector } from 'shared/selectors';

const useUser = () => {
  const user = useSelector(userSelector);

  if (!user) {
    throw Error('useUser: a user must exist');
  }

  return user;
};

export default useUser;
