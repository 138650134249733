import React from 'react';
import * as I18n from 'shared/utils/I18n';
import BankStatementInfo from 'shared/components/UploadBankStatements/BankStatementInfo';
import Button from 'shared/components/tailwind/Button';
import Psd2Banks from 'shared/components/Psd2Banks';
import DnbDisclaimer from 'shared/components/DnbDisclaimer';
import { FINANCIAL_DASHBOARD } from 'app/routes';
import DontWorryMessage from 'shared/components/DontWorryMessage';
import useConnectWithBank from './useConnectWithBank';

const ns = 'app.profile.bankStatementsStep.connectWithBank';

const ConnectWithBank = () => {
  const { goToNextStep, goToPreviousStep, isSubmittedSeller, onSelectPdf } =
    useConnectWithBank();

  return (
    <div className="ConnectWithBank">
      <label className="tw-mb-2">{I18n.nt(ns, 'connection_title')}</label>
      <Psd2Banks
        allowedPdfUpload
        onSelectPdf={onSelectPdf}
        redirectPath={FINANCIAL_DASHBOARD}
      />
      <DontWorryMessage />
      <DnbDisclaimer includeTopMargin={false} />
      <BankStatementInfo icon="info">
        {I18n.nt(ns, 'multiple_banks_html')}
      </BankStatementInfo>
      <div className="tw-mt-6 tw-flex">
        <Button
          className="btn-light-inverted sm:tw-auto tw-mt-2"
          onClick={goToPreviousStep}
        >
          {I18n.ut('common.goBack')}
        </Button>
        {isSubmittedSeller && (
          <Button
            className="btn-primary tw-mt-2 tw-grow sm:tw-grow-0"
            onClick={goToNextStep}
          >
            {I18n.nt(ns, 'continue')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ConnectWithBank;
