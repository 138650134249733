import React from 'react';
import { dateFormat, toPercentage } from 'shared/utils/Helpers';
import { nt } from 'shared/utils/I18n';

const ns = 'charlie.frontpage.account_summary.interest_summary';

interface Props {
  baseInterestRate: string;
  euriborRate: string;
  latestInterestChange?: {
    date: string;
    rateChange: string;
  };
}

const InterestSummaryTooltip = ({
  baseInterestRate,
  euriborRate,
  latestInterestChange,
}: Props) => (
  <div className="tw-flex tw-flex-col tw-gap-1">
    <div className="tw-grid tw-w-fit tw-grid-cols-2 tw-gap-x-2">
      <div>{nt(ns, 'base_rate')} </div>
      <div className="tw-font-bold">{toPercentage(baseInterestRate)}</div>
      {parseFloat(euriborRate) > 0.0 && (
        <>
          <div>{nt(ns, 'euribor_rate')} </div>
          <div className="tw-font-bold">{toPercentage(euriborRate)}</div>
        </>
      )}
    </div>
    {latestInterestChange && (
      <div>
        {nt(ns, 'latest_change', {
          rate: toPercentage(latestInterestChange.rateChange),
          date: dateFormat(latestInterestChange.date),
          sign: parseFloat(latestInterestChange.rateChange) > 0.0 ? '+' : '',
        })}
      </div>
    )}
  </div>
);

export default InterestSummaryTooltip;
