import client from 'shared/utils/client';
import { trackEvent } from 'shared/utils/tracker';
import { asyncAction } from 'shared/utils/Helpers';
import {
  campaignSelector,
  initialProductPreferenceSelector,
} from 'shared/selectors';

export const LOAD_CAMPAIGN_DATA = asyncAction('if.signup.LOAD_CAMPAIGN_DATA');
export const SIGNUP = asyncAction('if.signup.SIGNUP');

export const signup = (params) => async (dispatch, getState) => {
  const state = getState();
  const campaign = campaignSelector(state);
  const initialProductPreference = initialProductPreferenceSelector(state);

  const mergedParams = { ...params };

  if (campaign && campaign.code) {
    mergedParams.signedUpWithCampaignCode = campaign.code;
  }

  if (initialProductPreference) {
    mergedParams.initialProductPreference = initialProductPreference;
  }

  dispatch({ type: SIGNUP.REQUEST });

  const response = await client('POST', '/api/sellers', mergedParams, {
    raiseError: false,
  });

  if (response.error) {
    const errors = response.payload && response.payload.validationErrors;
    trackEvent('Signup failure', { errors });
    return dispatch({ type: SIGNUP.FAILURE, ...response });
  } else {
    const successAction = dispatch({
      type: SIGNUP.SUCCESS,
      normalize: true,
      ...response,
    });

    trackEvent('aanmelding', {
      email: params.email,
      expectedFinancingAmount: params.expectedFinancingAmount,
      initialProductPreference,
    });

    return successAction;
  }
};

export const loadCampaignData = (code, email) => ({
  type: LOAD_CAMPAIGN_DATA,
  code,
  email,
  normalize: true,
  promise: (client) => client('GET', `/api/campaign_companies/${code}`),
});
