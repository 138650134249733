import { useSelector } from 'react-redux';
import { currentSellerSelector } from 'shared/selectors';

/* Select the current seller.
- Assumes a seller exists. Will throw an error otherwise.
*/
const useCurrentSeller = () => {
  const seller = useSelector(currentSellerSelector);

  if (!seller) {
    throw Error('useCurrentSeller: a seller must exist');
  }

  return seller;
};

export default useCurrentSeller;
