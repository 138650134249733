import React, { RefObject, useState } from 'react';

interface Props {
  errorMessageId: string;
  id: string;
  inputRef: RefObject<HTMLInputElement>;
  invalid: boolean;
  onBlur: () => void;
  onChange: (text: string) => void;
  onFocus: () => void;
  readOnly?: boolean;
}

const SearchInput = ({
  errorMessageId,
  id,
  inputRef,
  invalid,
  onBlur,
  onChange,
  onFocus,
  readOnly,
}: Props) => {
  const [searchText, setSearchText] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    onChange(e.target.value);
  };

  return (
    <>
      <input
        aria-errormessage={invalid ? errorMessageId : undefined}
        aria-invalid={invalid ? true : undefined}
        autoComplete="off"
        autoCorrect="off"
        className="form-control"
        id={id}
        type="text"
        maxLength={80}
        name="search"
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        readOnly={readOnly}
        ref={inputRef}
        value={searchText}
      />
    </>
  );
};

export default SearchInput;
