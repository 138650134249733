import { Middleware } from 'redux';
import {
  setSentryUserContext,
  removeSentryUserContext,
} from 'shared/utils/Helpers';
import { userSelector } from 'shared/selectors';

import {
  sessionExpired,
  ACTIVATE_ACCOUNT,
  AUTHENTICATE_SUCCESS,
  DEAUTHENTICATE,
  LOAD_SUCCESS,
} from 'shared/redux/modules/session';
import { SIGNUP } from 'signup/actions/GuestSignup';

const hsq = () => (window as any)._hsq || [];

const identifyHubspotUser = ({ email }) => {
  hsq().push(['identify', { email }]);
  hsq().push(['trackPageView']);
};

const clearHubspotUser = () => {
  hsq().push(['identify', { email: null }]);
  hsq().push(['trackPageView']);
};

const sessionMiddleware: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch ((action as any).type) {
      case ACTIVATE_ACCOUNT:
      case AUTHENTICATE_SUCCESS:
      case LOAD_SUCCESS:
      case SIGNUP.SUCCESS: {
        const state = getState();
        const user = userSelector(state);

        if (user) {
          setSentryUserContext(user);
          identifyHubspotUser(user);
        }
        break;
      }

      case DEAUTHENTICATE: {
        removeSentryUserContext();
        clearHubspotUser();
        break;
      }
    }

    if ((action as any).statusCode === 401) {
      dispatch(sessionExpired());
    }

    return result;
  };

export default sessionMiddleware;
