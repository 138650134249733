export const calculateNewDebtCollectionAmount = ({
  currentDebtCollectionAmount,
  pendingCurrentLimit,
  debtCollectionRate,
  requestedAmount,
  withdrawAmount,
}) => {
  const newAmount =
    (pendingCurrentLimit + requestedAmount + withdrawAmount) *
    debtCollectionRate;

  return Math.max(newAmount, currentDebtCollectionAmount);
};

export const usesClientBankAccount = (seller) => {
  /* For later: this check might not be sufficient.
   * - it has no support for migration
   * - changes are directly visible to the user (e.g. after onboarding).
   */
  return !!seller.clientBankAccount;
};
