import React from 'react';
import { useSelector } from 'react-redux';
import { isApproved, isCancelled } from 'models/Seller';
import NavigationBarDraftAndSubmitted from './NavigationBarDraftAndSubmitted';
import NavigationBarApproved, { LinkDefinition } from './NavigationBarApproved';
import { currentSellerSelector } from 'shared/selectors';
import { useLocation } from 'react-router';
import { LOGIN } from 'app/routes';

interface Props {
  links?: LinkDefinition[];
}

const Container = ({ links }: Props) => {
  const seller = useSelector(currentSellerSelector);
  const pathName = useLocation().pathname;

  if (seller && (isApproved(seller) || isCancelled(seller))) {
    return <NavigationBarApproved links={links || []} />;
  } else if (pathName === LOGIN) {
    return null;
  } else {
    return <NavigationBarDraftAndSubmitted />;
  }
};

export default Container;
