import React, { useState } from 'react';
import classNames from 'classnames';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { generateHtmlElementId } from 'shared/utils/Helpers';

interface Props<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  label: string;
  name: Path<TFieldValues>;
  readOnly?: boolean;
  value: string;
}

const RadioInput = <TFieldValues extends FieldValues>({
  control,
  label,
  name,
  readOnly,
  value,
}: Props<TFieldValues>) => {
  const [id] = useState(generateHtmlElementId);

  const {
    field: { onChange, ref, value: fieldValue },
    fieldState: { error },
  } = useController<TFieldValues>({ control, name });

  const checked = value === fieldValue;

  return (
    <div
      className={classNames(['radiobutton', 'radio'], {
        invalid: Boolean(error),
      })}
    >
      <label className={checked ? 'checked' : ''} htmlFor={id}>
        <input
          checked={checked}
          disabled={readOnly}
          id={id}
          name={name}
          onChange={onChange}
          ref={ref}
          type="radio"
          value={value}
        />
        {label}
      </label>
    </div>
  );
};

export default RadioInput;
