import React from 'react';
import { Well } from 'shared/bootstrap';
import { parseISO } from 'date-fns';
import { explicitDateFormat } from 'shared/utils/Helpers';
import { Consent, Translator } from 'types';

interface Props {
  t: Translator;
  consents: Consent[];
}

const PendingBanks = ({ t, consents }: Props) => (
  <Well>
    <h5>{t('pending')}</h5>
    {consents.map(({ bank, expiresOn }) => (
      <div key={bank}>
        <strong>{t(bank)}</strong>:{' '}
        {t('expires', {
          date: expiresOn
            ? explicitDateFormat(parseISO(expiresOn), 'd MMMM yyyy')
            : '',
        })}
      </div>
    ))}
  </Well>
);

export default PendingBanks;
