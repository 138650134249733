import { FieldErrors, FieldValues, ResolverResult, set } from 'react-hook-form';
import { isEmpty } from 'lodash';

type ValidateFunction<TFieldValues extends FieldValues> = (
  values: TFieldValues
) => Partial<Record<keyof TFieldValues, string | null>>;

function resolver<TFieldValues extends FieldValues>(
  validate: ValidateFunction<TFieldValues>
): (values: TFieldValues) => ResolverResult<TFieldValues> {
  return (values: TFieldValues) => {
    const validations = validate(values);
    const errors: FieldErrors<TFieldValues> = {};

    Object.keys(validations).forEach((key: string) => {
      if (validations[key] !== null) {
        set(errors, key, { message: validations[key] });
      }
    });

    if (isEmpty(errors)) {
      return { errors: {}, values };
    } else {
      return {
        errors,
        values: {},
      };
    }
  };
}

export default resolver;
