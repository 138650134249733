import React from 'react';
import { connect } from 'react-redux';
import * as I18n from 'shared/utils/I18n';
import { hasSignedContract, hasSignedGuarantee } from 'models/Seller';
import {
  attachmentSelector,
  contractSelector,
  currentSellerSelector,
} from 'shared/selectors';
import useContract from 'shared/hooks/useContract';
import { Attachment, Contract, ReduxState, Seller } from 'types';
import { preventDefault } from 'namespaces/shared/utils/functions';

const ns = 'app.profilePage.contract';

interface Props {
  contract?: Contract;
  seller: Seller;
  signedContractFiles: Attachment[];
}

const ProfileContract = ({ contract, seller, signedContractFiles }: Props) => {
  const { requestStatus, redirectToSignUrl } = useContract(seller.id, true);

  if (
    signedContractFiles.length > 0 ||
    (contract && (hasSignedContract(contract) || hasSignedGuarantee(contract)))
  ) {
    return (
      <>
        <h4>{I18n.nt(ns, 'title')}</h4>
        <ul className="list-unstyled">
          {signedContractFiles.map((signedContractFile) => (
            <li key={signedContractFile.id}>
              <a
                href={`/api/attachments/${signedContractFile.id}/download`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {signedContractFile?.fileName}
              </a>
            </li>
          ))}

          {contract && hasSignedContract(contract) && (
            <li>
              <a href="#" onClick={preventDefault(redirectToSignUrl)}>
                {I18n.nt(ns, 'to_contract')}
              </a>
              {requestStatus === 'error' && (
                <span className="text-danger tw-ml-4">
                  {I18n.nt(ns, 'docusign_error')}
                </span>
              )}
            </li>
          )}

          {contract && hasSignedGuarantee(contract) && (
            <li>
              <a href={`/api/guarantee`} target="_blank">
                {I18n.nt(ns, 'to_guarantee')}
              </a>
            </li>
          )}
        </ul>
      </>
    );
  }

  return null;
};

const mapStateToProps = (state: ReduxState) => {
  const seller = currentSellerSelector(state) as Seller;
  const contract = contractSelector(state);
  const signedContractFiles = seller.signedContractFiles.reduce<Attachment[]>(
    (result, attachmentId): Attachment[] => {
      const attachment = attachmentSelector(state, attachmentId);
      if (attachment) {
        result.push(attachment);
      }
      return result;
    },
    []
  );

  return { contract, seller, signedContractFiles };
};

export default connect(mapStateToProps)(ProfileContract);
