import React, { forwardRef, HTMLProps } from 'react';
import {
  FloatingArrow,
  FloatingPortal,
  useMergeRefs,
} from '@floating-ui/react';
import classNames from 'classnames';
import { useTooltipContext } from './context';
import { TooltipSize } from './types';

const getTooltipSizeClass = (tooltipSize: TooltipSize) => {
  switch (tooltipSize) {
    case 'xs':
      return 'tw-max-w-xs';
    case 'md':
      return 'tw-max-w-xs md:tw-max-w-md';
    case 'xl':
      return 'tw-max-w-xs md:tw-max-w-xl';
    default:
      return '';
  }
};

const TooltipContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  function TooltipContent({ style, ...props }, propRef) {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.isMounted) {
      return null;
    }

    return (
      <FloatingPortal>
        <div
          className="tw-z-[1060]"
          ref={ref}
          style={{
            ...context.floatingStyles,
            ...context.transitionStyles,
            ...style,
          }}
          role="tooltip"
          {...context.getFloatingProps(props)}
        >
          <FloatingArrow
            className="tw-m-[-1px]"
            context={context.context}
            fill="white"
            ref={context.arrowRef}
            stroke="#bdbdbd"
            strokeWidth={1}
            width={14}
          />
          <div
            className={classNames(
              getTooltipSizeClass(context.tooltipSize),
              'tw-inline-block tw-rounded tw-border tw-border-gray-400 tw-bg-white tw-px-3 tw-py-2 tw-text-base tw-text-gray-900 tw-shadow-[0_5px_10px_rgba(0,0,0,0.2)]'
            )}
          >
            {props.children}
          </div>
        </div>
      </FloatingPortal>
    );
  }
);

export default TooltipContent;
