import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';

import { financeConditions } from 'models/Seller';
import { useCurrentSeller } from 'shared/hooks';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import client from 'shared/utils/client';
import resolver from 'hookForm/resolver';
import { toEuro } from 'shared/utils/Helpers';
import { nt } from '../utils';
import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';
import { trackEvent, trackFormSubmit } from 'shared/utils/tracker';
import { validateAll, validatePresence } from 'shared/utils/validation';
import updateEntities from 'shared/modules/updateEntities';
import { Seller } from 'types';
import { FinanceConditions } from 'models/Seller';

interface FormValues {
  amount: number;
}

const t = nt('signup.onboarding.withdrawal');

const validateClosingCommission =
  (closingCommissionAmount: number) => (amount?: number) =>
    amount && closingCommissionAmount > 0 && amount < closingCommissionAmount
      ? t('amount_invalid_closing_commission')
      : null;

const validateMinimumAmount =
  (minimumWithdrawalAmount: number) => (amount?: number) =>
    amount && amount < minimumWithdrawalAmount
      ? t('amount_ge_minimum', {
          amount: toEuro(minimumWithdrawalAmount),
        })
      : null;

const validateCreditLimit = (creditLimit: number) => (amount?: number) =>
  amount && amount > creditLimit ? t('cannot_withdraw') : null;

const validateAmount = (
  amount: number | undefined,
  conditions: FinanceConditions
) =>
  validateAll(
    validatePresence,
    validateClosingCommission(conditions.closingCommissionAmount),
    validateMinimumAmount(conditions.minimumWithdrawalAmount),
    validateCreditLimit(conditions.creditLimit)
  )(amount);

const useWithdrawal = (basePath: string) => {
  const history = useHistory();
  const seller = useCurrentSeller() as Seller;
  const dispatch = useAppDispatch();
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);

  const conditions = financeConditions(seller);
  const hasWithdrawal = conditions.currentBalance > 0;

  const validate = ({ amount }: Partial<FormValues>) => ({
    amount: validateAmount(amount, conditions),
  });

  const defaultValues = {
    amount: hasWithdrawal ? conditions.currentBalance : undefined,
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
    setValue,
  } = useForm<FormValues>({
    defaultValues,
    resolver: resolver(validate),
  });

  const amount = useWatch({ control, name: 'amount' }) || 0.0;

  const closeModal = () => history.push(basePath);

  const submit = async ({ amount }: FormValues) => {
    const response = await client(
      'PUT',
      '/api/onboarding/withdrawal',
      { amount },
      {
        raiseError: false,
      }
    );

    if (response.error) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        response,
        setError
      );
      setServerErrorMessages(generalErrorMessages);

      trackFormSubmit('withdrawal-onboarding', true);
    } else {
      trackFormSubmit('withdrawal-onboarding', true);
      trackEvent('Onboarding withdrawal', {
        amount,
      });
      dispatch(updateEntities(response.payload));

      closeModal();
    }
  };

  const withdrawAll = () => {
    setServerErrorMessages([]);
    setValue('amount', conditions.availableBalance);
  };

  return {
    amount,
    conditions,
    control,
    hasWithdrawal,
    isSubmitting,
    newDebtCollectionAmount: amount * conditions.debtCollectionRate,
    serverErrorMessages,
    submit: handleSubmit(submit),
    withdrawAll,
  };
};

export default useWithdrawal;
