import React from 'react';
import GuaranteeForm from 'shared/components/GuaranteeForm';
import { getSignStatus } from './util';
import DisplaySignStatus from './DisplaySignStatus';
import useSignToken from './useSignToken';
import DisplayLoginStatus from './DisplayLoginStatus';

const Sign = () => {
  const { guarantee, loginStatus, sellerEmail, signGuarantee } = useSignToken();

  if (loginStatus === 'logged_in' && guarantee) {
    const signStatus = getSignStatus(guarantee);

    if (signStatus === 'not_signed') {
      return (
        <GuaranteeForm
          guarantee={guarantee}
          sellerEmail={sellerEmail}
          onSubmit={signGuarantee}
        />
      );
    } else {
      return (
        <DisplaySignStatus
          coSignerEmail={guarantee.coSignerEmail || ''}
          signStatus={signStatus}
        />
      );
    }
  } else {
    return <DisplayLoginStatus loginStatus={loginStatus} />;
  }
};

export default Sign;
