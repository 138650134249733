import { useSelector } from 'react-redux';
import { currentStepSelector } from 'shared/selectors';
import { currentStepIndex } from 'models/SignupOrder';
import { nt } from 'shared/utils/I18n';

const ns = 'app.profile.signupProgress';

const useSignupProgress = () => {
  const currentStep = useSelector(currentStepSelector);

  const titles = [
    nt(ns, 'step1'),
    nt(ns, 'step2'),
    nt(ns, 'step3'),
    nt(ns, 'step4'),
  ];
  const duration = nt(ns, 'one_minute');
  const currentIndex = currentStepIndex(currentStep) || 0;

  const steps = titles.length;
  const stepsArray = [...Array(steps)].map((_el, i) => i + 1); // => [1, 2, 3, 4]

  return { currentIndex, duration, steps, stepsArray, titles };
};

export default useSignupProgress;
